import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import { organizationevaluation } from "../../redux/organizationEval/organizationEvalAction";

//component
import Loading from "../layout/Loading";

function OrganizationEvalViewModal({ id }) {
  const dispatch = useDispatch();
  const { loading, one } = useSelector(
    (state) => state.organizationEvalReducer
  );
  const [myData, setMyData] = useState();

  useEffect(() => {
    if (one?.data !== undefined) {
      let data = {
        help: one.data.help,
        new_role: one.data.new_role ? "Yes" : "No",
        lives_touched: one.data.lives_touched,
        organization_role: one.data.organization_role,
        underrepresented_individuals: one.data.underrepresented_individuals,
        underprivileged_individuals: one.data.underprivileged_individuals,
        others: one.data.others,
        trees_saved: one.data.trees_saved,
        wildlife_habitats_preserved: one.data.wildlife_habitats_preserved,
        natural_areas_cleaned: one.data.natural_areas_cleaned,
        historical_buildings_restored: one.data.historical_buildings_restored,
        cultural_artifacts_conserved: one.data.cultural_artifacts_conserved,
        local_traditions_promoted: one.data.local_traditions_promoted,
        inkind_supplies_distributed: one.data.inkind_supplies_distributed,
        buildings_improved: one.data.buildings_improved,
        buildings_constructed: one.data.buildings_constructed,
        public_spaces_beautified: one.data.public_spaces_beautified,
        sports_activities_organized: one.data.sports_activities_organized
          ? "Yes"
          : "No",
        sports_participants_number: one.data.sports_participants_number,
        events_organized: one.data.events_organized ? "Yes" : "No",
        events_participants_number: one.data.events_participants_number,
      };
      setMyData(data);
    }
  }, [one]);

  useEffect(() => {
    if (id !== 0) dispatch(organizationevaluation(id));
  }, [dispatch, id]);

  return (
    <div className="modal fade" id="view-organizationEval-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Organization Evaluations View More </h4>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form>
                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Help
                      </span>
                      <input
                        type="text"
                        value={
                          myData?.help !== null ? myData?.help || "" : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>

                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        New role
                      </span>
                      <input
                        type="text"
                        value={
                          myData?.new_role !== null
                            ? myData?.new_role || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Lives touched
                      </span>
                      <input
                        type="text"
                        value={
                          myData?.lives_touched !== null
                            ? myData?.lives_touched || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Organization role
                      </span>
                      <input
                        type="text"
                        value={
                          myData?.organization_role !== null
                            ? myData?.organization_role || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="form-group">
                      <label
                        htmlFor="underrepresented_individuals"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Underrepresented Individuals
                      </label>
                      <input
                        type="text"
                        id="underrepresented_individuals"
                        value={myData?.underrepresented_individuals || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="underprivileged_individuals"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Underprivileged Individuals
                      </label>
                      <input
                        type="text"
                        id="underprivileged_individuals"
                        value={myData?.underprivileged_individuals || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>

                  <div className="formRow">
                    <div className="form-group">
                      <label
                        htmlFor="others"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Others
                      </label>
                      <input
                        type="text"
                        id="others"
                        value={myData?.others || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="trees_saved"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Trees Saved
                      </label>
                      <input
                        type="text"
                        id="trees_saved"
                        value={myData?.trees_saved || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>

                  <div className="formRow">
                    <div className="form-group">
                      <label
                        htmlFor="wildlife_habitats_preserved"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Wildlife Habitats Preserved
                      </label>
                      <input
                        type="text"
                        id="wildlife_habitats_preserved"
                        value={myData?.wildlife_habitats_preserved || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="natural_areas_cleaned"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Natural Areas Cleaned
                      </label>
                      <input
                        type="text"
                        id="natural_areas_cleaned"
                        value={myData?.natural_areas_cleaned || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>

                  <div className="formRow">
                    <div className="form-group">
                      <label
                        htmlFor="historical_buildings_restored"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Historical Buildings Restored
                      </label>
                      <input
                        type="text"
                        id="historical_buildings_restored"
                        value={myData?.historical_buildings_restored || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="cultural_artifacts_conserved"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Cultural Artifacts Conserved
                      </label>
                      <input
                        type="text"
                        id="cultural_artifacts_conserved"
                        value={myData?.cultural_artifacts_conserved || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>

                  <div className="formRow">
                    <div className="form-group">
                      <label
                        htmlFor="local_traditions_promoted"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Local Traditions Promoted
                      </label>
                      <input
                        type="text"
                        id="local_traditions_promoted"
                        value={myData?.local_traditions_promoted || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inkind_supplies_distributed"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        In-kind Supplies Distributed
                      </label>
                      <input
                        type="text"
                        id="inkind_supplies_distributed"
                        value={myData?.inkind_supplies_distributed || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>

                  <div className="formRow">
                    <div className="form-group">
                      <label
                        htmlFor="buildings_improved"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Buildings Improved
                      </label>
                      <input
                        type="text"
                        id="buildings_improved"
                        value={myData?.buildings_improved || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="buildings_constructed"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Buildings Constructed
                      </label>
                      <input
                        type="text"
                        id="buildings_constructed"
                        value={myData?.buildings_constructed || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>

                  <div className="formRow">
                    <div className="form-group">
                      <label
                        htmlFor="public_spaces_beautified"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Public Spaces Beautified
                      </label>
                      <input
                        type="text"
                        id="public_spaces_beautified"
                        value={myData?.public_spaces_beautified || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="sports_activities_organized"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Sports Activities Organized
                      </label>
                      <input
                        type="text"
                        id="sports_activities_organized"
                        value={myData?.sports_activities_organized || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>

                  <div className="formRow">
                    <div className="form-group">
                      <label
                        htmlFor="sports_participants_number"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Sports Participants Number
                      </label>
                      <input
                        type="text"
                        id="sports_participants_number"
                        value={myData?.sports_participants_number || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="events_organized"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Events Organized
                      </label>
                      <input
                        type="text"
                        id="events_organized"
                        value={myData?.events_organized || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>

                  <div className="formRow">
                    <div className="form-group">
                      <label
                        htmlFor="events_participants_number"
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Events Participants Number
                      </label>
                      <input
                        type="text"
                        id="events_participants_number"
                        value={myData?.events_participants_number || "None"}
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="close-btn"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrganizationEvalViewModal;
