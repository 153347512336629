import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import {
  addVolunteerSpotlightsQA,
  clear,
} from "../../redux/volunteerSpotlightsQA/volunteerSpotlightsQAActions";
import { getVolunteerSpotlights } from "../../redux/volunteerSpotlights/volunteerSpotlightsActions";

//components
import Loading from "../layout/Loading";

//css
import "./VolunteerSpotlightsQA.css";

function VolunteerSpotlightsQAAddModal() {
  const dispatch = useDispatch();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [volunteerSpotlightsId, setVolunteerSpotlightsId] = useState("");
  const { addLoading } = useSelector(
    (state) => state.volunteerSpotlightsQAReducer
  );
  const { volunteerSpotlights } = useSelector(
    (state) => state.volunteerSpotlightsReducer
  );
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if (question !== "" && answer !== "" && volunteerSpotlightsId !== "" ) setButtonState(false);
    else setButtonState(true);
  }, [question, answer,volunteerSpotlightsId]);
  useEffect(() => {
    dispatch(getVolunteerSpotlights());
  }, [dispatch]);

  const resetForm = () => {
    setQuestion("");
    setAnswer("");
    setVolunteerSpotlightsId("");
    dispatch(clear());
  };

  const addVolunteerSpotlightsQAHandle = (e) => {
    e.preventDefault();
    dispatch(addVolunteerSpotlightsQA(volunteerSpotlightsId, question, answer));
    setQuestion("");
    setAnswer("");
    setVolunteerSpotlightsId("");
  };
  return (
    <div>
      <div
        className="modal fade"
        id="add-volunteerSpotlightsQA-modal"
        data-reset="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add Volunteer spotlight QA</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={addVolunteerSpotlightsQAHandle}>
                  <div className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-3 textForm">
                        Volunteer spotlight :
                      </span>
                      <select
                        onChange={(e) =>
                          setVolunteerSpotlightsId(e.target.value)
                        }
                        required
                        defaultValue={""}
                      >
                        <option value="">
                          Please select Volunteer spotlight
                        </option>
                        {volunteerSpotlights?.data?.map(
                          (volunteerSpotlight) => (
                            <option
                              key={volunteerSpotlight.id}
                              value={volunteerSpotlight.id}
                            >
                              {volunteerSpotlight.title}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Question :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={question}
                        placeholder="Enter the question"
                        required
                        onChange={(e) => {
                          setQuestion(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm">Answer :</span>
                      <input
                        type="answer"
                        className="  inputt col-md-8 form-controls"
                        value={answer}
                        placeholder="Enter the answer"
                        required
                        onChange={(e) => {
                          setAnswer(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addVolunteerSpotlightsQAHandle}
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      disabled={buttonState ? true : false}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VolunteerSpotlightsQAAddModal;
