import axios from "axios";

//redux
import {
  FETCH_EPISODEQUIZUSER_SUCCESS,
  FETCH_EPISODEQUIZUSER_FAIL,
  FETCH_EPISODEQUIZUSER_REQUEST,
} from "./episodeQuizUserTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_EPISODEQUIZUSER_REQUEST,
  };
};

export const fetchSuccess = (disability) => {
  return {
    type: FETCH_EPISODEQUIZUSER_SUCCESS,
    payload: disability,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_EPISODEQUIZUSER_FAIL,
    payload: error,
  };
};

export const getEpisodeQuizUser = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/episode_quiz_users";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};
