import axios from "axios";

//redux
import {
    FETCH_DISABILITY_REQUEST,
    FETCH_DISABILITY_SUCCESS,
    FETCH_DISABILITY_FAIL,
  
    ADD_DISABILITY_REQUEST,
    ADD_DISABILITY_SUCCESS,
    ADD_DISABILITY_FAIL,
  
    EDIT_DISABILITY_REQUEST,
    EDIT_DISABILITY_SUCCESS,
    EDIT_DISABILITY_FAIL,
  
    DELETE_DISABILITY_REQUEST,
    DELETE_DISABILITY_SUCCESS,
    DELETE_DISABILITY_FAIL,
  
    CLEAR
} from "./disabilityTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_DISABILITY_REQUEST,
  };
};

export const fetchSuccess = (disability) => {
  return {
    type: FETCH_DISABILITY_SUCCESS,
    payload: disability,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_DISABILITY_FAIL,
    payload: error,
  };
};

export const addDisabilityRequest = () => {
  return {
    type: ADD_DISABILITY_REQUEST,
  };
};

export const addDisabilitySuccess = () => {
  return {
    type: ADD_DISABILITY_SUCCESS,
  };
};

export const addDisabilityFail = (error) => {
  return {
    type: ADD_DISABILITY_FAIL,
    payload: error,
  };
};

export const editDisabilityRequest = () => {
  return {
    type: EDIT_DISABILITY_REQUEST,
  };
};

export const editDisabilitySuccess = () => {
  return {
    type: EDIT_DISABILITY_SUCCESS,
  };
};

export const editDisabilityFail = (error) => {
  return {
    type: EDIT_DISABILITY_FAIL,
    payload: error,
  };
};

export const deleteDisabilityRequest = () => {
  return {
    type: DELETE_DISABILITY_REQUEST,
  };
};

export const deleteDisabilitySuccess = () => {
  return {
    type: DELETE_DISABILITY_SUCCESS,
  };
};

export const deleteDisabilityFail = (error) => {
  return {
    type: DELETE_DISABILITY_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getDisability = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/disabilities";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteDisability = (id) => async (dispatch) => {
  dispatch(deleteDisabilityRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/disabilities/" + id);
    dispatch(deleteDisabilitySuccess());
    dispatch(getDisability());
  } catch (e) {
    dispatch(deleteDisabilityFail(e.response.data));
  }
};

export const addDisability = (text,text_ar,description, description_ar) => async (dispatch) => {
  dispatch(addDisabilityRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/disabilities", {
        
        text,
        text_ar,
        description,
        description_ar,
    });
    dispatch(addDisabilitySuccess());
    dispatch(getDisability());
  } catch (e) {
    dispatch(addDisabilityFail(e.response.data));
  }
};

export const updateDisability= (id,text,text_ar,description, description_ar) => async (dispatch) => {
  dispatch(editDisabilityRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/disabilities/" + id, {
        text,
        text_ar,
        description,
        description_ar,
    });
    dispatch(editDisabilitySuccess());
    dispatch(getDisability());
  } catch (e) {
    dispatch(editDisabilityFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
