import axios from "axios";

//redux
import {
  FETCH_FAQ_REQUEST,
  FETCH_FAQ_SUCCESS,
  FETCH_FAQ_FAIL,

  ADD_FAQ_REQUEST,
  ADD_FAQ_SUCCESS,
  ADD_FAQ_FAIL,

  EDIT_FAQ_REQUEST,
  EDIT_FAQ_SUCCESS,
  EDIT_FAQ_FAIL,

  DELETE_FAQ_REQUEST,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAIL,

  CLEAR
} from "./faqTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_FAQ_REQUEST,
  };
};

export const fetchSuccess = (faqs) => {
  return {
    type: FETCH_FAQ_SUCCESS,
    payload: faqs,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_FAQ_FAIL,
    payload: error,
  };
};

export const addfaqRequest = () => {
  return {
    type: ADD_FAQ_REQUEST,
  };
};

export const addfaqSuccess = () => {
  return {
    type: ADD_FAQ_SUCCESS,
  };
};

export const addfaqFail = (error) => {
  return {
    type: ADD_FAQ_FAIL,
    payload: error,
  };
};

export const editfaqRequest = () => {
  return {
    type: EDIT_FAQ_REQUEST,
  };
};

export const editfaqSuccess = () => {
  return {
    type: EDIT_FAQ_SUCCESS,
  };
};

export const editfaqFail = (error) => {
  return {
    type: EDIT_FAQ_FAIL,
    payload: error,
  };
};

export const deletefaqRequest = () => {
  return {
    type: DELETE_FAQ_REQUEST,
  };
};

export const deletefaqSuccess = () => {
  return {
    type: DELETE_FAQ_SUCCESS,
  };
};

export const deletefaqFail = (error) => {
  return {
    type: DELETE_FAQ_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getfaqs = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/faqs";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteFaq = (id) => async (dispatch) => {
  dispatch(deletefaqRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/faqs/" + id);
    dispatch(deletefaqSuccess());
    dispatch(getfaqs());
  } catch (e) {
    dispatch(deletefaqFail(e.response.data));
  }
};

export const addFaq = (question, question_ar, answer, answer_ar) => async (dispatch) => {
  dispatch(addfaqRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/faqs", {
      question,
      question_ar,
      answer,
      answer_ar
    });
    dispatch(addfaqSuccess());
    dispatch(getfaqs());
  } catch (e) {
    dispatch(addfaqFail(e.response.data));
  }
};

export const updateFaq = (id, question, question_ar, answer, answer_ar) => async (dispatch) => {
  dispatch(editfaqRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/faqs/" + id, {
      question,
      question_ar,
      answer,
      answer_ar
    });
    dispatch(editfaqSuccess());
    dispatch(getfaqs());
  } catch (e) {
    dispatch(editfaqFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
