/* eslint-disable eqeqeq */
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";

import ReactDatatable from "@ashvin27/react-datatable";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { tokens } from "../Theme/Theme";
//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import AdminAddModal from "./AdminAddModal";
import AdminEditModel from "./AdminEditModal";
import AdminDeleteModal from "./AdminDeleteModal";

//redux
import { getAdmins } from "../../redux/admin/adminActions";

//utils
import isAuth from "../../utils/isAuth";
import "../../Style/Style.css";
function Admin() {
  function exportToExcel() {
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const recordWithoutFirstKey = Object.fromEntries(
        Object.entries(record).slice(1)
      );
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Admins.xlsx");
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, admins, error, addError, editError, deleteError } =
    useSelector((state) => state.adminReducer);
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState(2);

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getAdmins());
  }, [dispatch]);
  useEffect(() => {
    if (admins && admins.data) {
      const mappedRecords = admins.data.map((admin) => {
        return {
          key: admin.id,
          id: admin.id,
          email: admin.email,
          username: admin.username,
          verification: admin.verified ? "Verified" : "NOT Verified",
          super_Admin: admin.role === 1 ? "YES" : "NO",
        };
      });
      setRecords(mappedRecords);
    }
  }, [admins]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "username",
      headerName: "Username",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "verification",
      headerName: "Verification",
      flex: 1,
    },
    {
      field: "super_Admin",
      headerName: "Super Admin",
      flex: 1,
    },
  ];

  if (localStorage.getItem("role") == 1) {
    columns.push({
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-admin-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setUsername(record.row.username);
                setEmail(record.row.email);
                setRole(record.row.super_Admin === "YES" ? 1 : 2);
              }}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              data-toggle="modal"
              data-target="#delete-admin-modal"
              className="actions"
              onClick={() => {
                setId(record.row.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </Fragment>
        );
      },
    });
  }

  const handleReloadClick = () => {
    window.location.reload();
  };

  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />

        {localStorage.getItem("role") == 1 && <AdminAddModal />}
        {localStorage.getItem("role") == 1 && (
          <AdminEditModel
            id={id}
            email={email}
            username={username}
            role={role}
            setEmail={setEmail}
            setUsername={setUsername}
            setRole={setRole}
          />
        )}
        {localStorage.getItem("role") == 1 && <AdminDeleteModal id={id} />}
        <div className="container-fluid" id="page-content-wrapper">
          <div>
            {localStorage.getItem("role") == 1 && (
              <button
                className="add"
                data-toggle="modal"
                data-target="#add-admin-modal"
              >
                <FontAwesomeIcon icon={faPlus} /> Add Admin
              </button>
            )}

            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
              {addError && (
                <div style={{ color: "red" }}> {addError.message}</div>
              )}
              {editError && (
                <div style={{ color: "red" }}> {editError.message}</div>
              )}
              {deleteError && (
                <div style={{ color: "red" }}> {deleteError.message}</div>
              )}
            </div>

            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header title="Admin" subtitle="List of Admins" />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      disableSelectionOnClick
                      multiSelect
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
