export const FETCH_VOLUNTEER_SPOTLIGHTS_REQUEST = "FETCH_VOLUNTEER_SPOTLIGHTS_REQUEST";
export const FETCH_VOLUNTEER_SPOTLIGHTS_SUCCESS = "FETCH_VOLUNTEER_SPOTLIGHTS_SUCCESS";
export const FETCH_VOLUNTEER_SPOTLIGHTS_FAIL = "FETCH_VOLUNTEER_SPOTLIGHTS_FAIL";

export const ADD_VOLUNTEER_SPOTLIGHTS_REQUEST = "ADD_VOLUNTEER_SPOTLIGHTS_REQUEST";
export const ADD_VOLUNTEER_SPOTLIGHTS_SUCCESS = "ADD_VOLUNTEER_SPOTLIGHTS_SUCESS";
export const ADD_VOLUNTEER_SPOTLIGHTS_FAIL = "ADD_VOLUNTEER_SPOTLIGHTS_FAIL";

export const EDIT_VOLUNTEER_SPOTLIGHTS_REQUEST = "ADD_VOLUNTEER_SPOTLIGHTS_REQUEST";
export const EDIT_VOLUNTEER_SPOTLIGHTS_SUCCESS = "ADD_VOLUNTEER_SPOTLIGHTS_SUCESS";
export const EDIT_VOLUNTEER_SPOTLIGHTS_FAIL = "ADD_VOLUNTEER_SPOTLIGHTS_FAIL";

export const DELETE_VOLUNTEER_SPOTLIGHTS_REQUEST = "ADD_VOLUNTEER_SPOTLIGHTS_REQUEST";
export const DELETE_VOLUNTEER_SPOTLIGHTS_SUCCESS = "ADD_VOLUNTEER_SPOTLIGHTS_SUCESS";
export const DELETE_VOLUNTEER_SPOTLIGHTS_FAIL = "ADD_VOLUNTEER_SPOTLIGHTS_FAIL";

export const CLEAR = "CLEAR";