export const FETCH_SECTOR_REQUEST = "FETCH_SECTOR_REQUEST";
export const FETCH_SECTOR_SUCCESS = "FETCH_SECTOR_SUCCESS";
export const FETCH_SECTOR_FAIL = "FETCH_SECTOR_FAIL";

export const ADD_SECTOR_REQUEST = "ADD_SECTOR_REQUEST";
export const ADD_SECTOR_SUCCESS = "ADD_SECTOR_SUCESS";
export const ADD_SECTOR_FAIL = "ADD_SECTOR_FAIL";

export const EDIT_SECTOR_REQUEST = "EDIT_SECTOR_REQUEST";
export const EDIT_SECTOR_SUCCESS = "EDIT_SECTOR_SUCESS";
export const EDIT_SECTOR_FAIL = "EDIT_SECTOR_FAIL";

export const DELETE_SECTOR_REQUEST = "DELETE_SECTOR_REQUEST";
export const DELETE_SECTOR_SUCCESS = "DELETE_SECTOR_SUCESS";
export const DELETE_SECTOR_FAIL = "DELETE_SECTOR_FAIL";

export const CLEAR = "CLEAR";