export const FETCH_TRUSTEDPART_REQUEST = "FETCH_TRUSTEDPART_REQUEST";
export const FETCH_TRUSTEDPART_SUCCESS = "FETCH_TRUSTEDPART_SUCCESS";
export const FETCH_TRUSTEDPART_FAIL = "FETCH_TRUSTEDPART_FAIL";

export const ADD_TRUSTEDPART_REQUEST = "ADD_TRUSTEDPART_REQUEST";
export const ADD_TRUSTEDPART_SUCCESS = "ADD_TRUSTEDPART_SUCESS";
export const ADD_TRUSTEDPART_FAIL = "ADD_TRUSTEDPART_FAIL";

export const EDIT_TRUSTEDPART_REQUEST = "ADD_TRUSTEDPART_REQUEST";
export const EDIT_TRUSTEDPART_SUCCESS = "ADD_TRUSTEDPART_SUCESS";
export const EDIT_TRUSTEDPART_FAIL = "ADD_TRUSTEDPART_FAIL";

export const DELETE_TRUSTEDPART_REQUEST = "ADD_TRUSTEDPART_REQUEST";
export const DELETE_TRUSTEDPART_SUCCESS = "ADD_TRUSTEDPART_SUCESS";
export const DELETE_TRUSTEDPART_FAIL = "ADD_TRUSTEDPART_FAIL";

export const CLEAR = "CLEAR";