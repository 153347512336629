import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import { clear, updateAdmin } from "../../redux/admin/adminActions";

//component
import Loading from "../layout/Loading";

//css
import "./Admin.css";

function AdminEditModal({
  id,
  email,
  username,
  role,
  setEmail,
  setUsername,
  setRole,
}) {
  const dispatch = useDispatch();
  const { editLoading } = useSelector((state) => state.adminReducer);
  const [buttonState, setButtonState] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const resetForm = () => {
    setEmail("");
    setUsername("");
    setRole("");
    dispatch(clear());
  };

  const editAdminHandle = (e) => {
    e.preventDefault();
    dispatch(updateAdmin(id, email, username, role));
    setEmail("");
    setUsername("");
    setRole("");
  };

  useEffect(() => {
    if (email !== "") {
      if (!emailRegEx.test(email)) {
        setButtonState(true);
        setErrorMessage("This Email is not valid !");
      } else {
        setButtonState(false);
        setErrorMessage("");
      }
    } else {
      setButtonState(true);
      setErrorMessage("");
    }
  }, [email, emailRegEx]);

  return (
    <div className="modal fade" id="update-admin-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Edit Admin</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={resetForm}
            >
              &times;
            </button>
          </div>
          {editLoading ? (
            <Loading />
          ) : (
            <>
              <form onSubmit={editAdminHandle}>
                <div className="modal-body">
                  <div className="row formRow">
                    <span className="col-md-2 textForm">Email :</span>
                    <input
                      value={email}
                      placeholder="Enter the admin's email"
                      onChange={(e) => setEmail(e.target.value)}
                      className="  inputt col-md-9 form-controls"
                    />
                  </div>
                  {errorMessage && (
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        paddingTop: "10px",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )}
                  <br />

                  <div className="row formRow">
                    <span className="col-md-2 textForm">Username :</span>
                    <input
                      value={username ? username : ""}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Enter the admin's username"
                      className="  inputt col-md-9 form-controls"
                    />
                  </div>
                  <br />

                  <div className="row formRow">
                    <span className="col-md-2 textForm">Role :</span>
                    <select
                      onChange={(e) => setRole(e.target.value)}
                      value={role}
                    >
                      <option value={1}>Super Admin</option>
                      <option value={2}>Admin</option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="close-btn"
                    data-dismiss="modal"
                    onClick={resetForm}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="add-btn"
                    data-dismiss="modal"
                    disabled={buttonState ? true : false}
                    onClick={editAdminHandle}
                  >
                    Edit
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminEditModal;
