import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ReactDatatable from "@ashvin27/react-datatable";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";

//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import * as XLSX from "xlsx";
import MasterclassEditModal from "./MasterclassEditModal";
import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
import "./Masterclass.css";
//redux
import {
  getMasterclass,
  changeStatusMasterclass,
} from "../../redux/Masterclass/MasterclassAction";

//utils
import isAuth from "../../utils/isAuth";

function Masterclass() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, masterclass, error, addError, editError, deleteError } =
    useSelector((state) => state.masterclassReducer);
  const [records, setRecords] = useState([]);
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [job, setJob] = useState("");
  const [name, setName] = useState("");
  const [user_name, setUser_name] = useState("");

  function exportToExcel() {
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter(
        (entry, index) => index !== 0 && index !== 2
      );
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Master Class.xlsx");
  }

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getMasterclass());
  }, [dispatch]);

  useEffect(() => {
    if (masterclass && masterclass.data) {
      const mappedRecords = masterclass.data.map((masterclass) => {
        return {
          key: masterclass.id,
          id: masterclass.id,
          user_id: masterclass.user_id,
          name: masterclass.name,
          user_name: masterclass.user_name,
          is_Group: masterclass.is_group ? "Yes" : "No",
          job: masterclass.job,
          email: masterclass.email,
          verification: masterclass.verify,
          status: masterclass.organization?.cashs[0]?.status,
          cashId: masterclass.organization?.cashs[0]?.id,
        };
      });
      setRecords(mappedRecords);
    }
  }, [masterclass]);

  const columns = [
    {
      field: "name",
      headerName: "Organization Name",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "user_name",
      headerName: "User Name",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "is_Group",
      headerName: "Ticket Size",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
      renderCell: (params) => (
        <span>{params.row.is_Group === 1 ? "Group" : "Individual"}</span>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "job",
      headerName: "Position",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "verification",
      headerName: "Status",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        const handleCircleClick = (e) => {
          e.preventDefault();
          dispatch(changeStatusMasterclass(params.row.cashId, params.row.id));
        };

        let circleColor = params.row.status === false ? "red" : "green";

        if (params.row.status !== undefined) {
          return (
            <span style={{ cursor: "pointer" }} onClick={handleCircleClick}>
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: circleColor,
                  display: "inline-block",
                }}
              ></div>
            </span>
          );
        } else {
          return null; // Or <></> if you prefer an empty element
        }
      },
    },

    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      className: "centerText action",
      align: "left",
      sortable: false,
      renderCell: (params) => (
        <Fragment>
          <button
            data-toggle="modal"
            data-target="#update-master-modal"
            className="actions"
            style={{ marginRight: "5px" }}
            onClick={() => {
              setId(params.row.id);
              setName(params.row.name);
              setUser_name(params.row.user_name);
              setJob(params.row.job);
              setEmail(params.row.email);
            }}
          >
            <i className="fa fa-edit"></i>
          </button>
        </Fragment>
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <MasterclassEditModal
          id={id}
          name={name}
          user_name={user_name}
          email={email}
          job={job}
          setName={setName}
          setUser_name={setUser_name}
          setEmail={setEmail}
          setJob={setJob}
        />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
              {addError && (
                <div style={{ color: "red" }}> {addError.message}</div>
              )}
              {editError && (
                <div style={{ color: "red" }}> {editError.message}</div>
              )}
              {deleteError && (
                <div style={{ color: "red" }}> {deleteError.message}</div>
              )}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header
                    title="Master Class"
                    subtitle="List of Master Classes"
                  />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Masterclass;
