export const FETCH_ADMIN_REQUEST = "FETCH_ADMIN_REQUEST";
export const FETCH_ADMIN_SUCCESS = "FETCH_ADMIN_SUCCESS";
export const FETCH_ADMIN_FAIL = "FETCH_ADMIN_FAIL";

export const ADD_ADMIN_REQUEST = "ADD_ADMIN_REQUEST";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCESS";
export const ADD_ADMIN_FAIL = "ADD_ADMIN_FAIL";

export const EDIT_ADMIN_REQUEST = "EDIT_ADMIN_REQUEST";
export const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCESS";
export const EDIT_ADMIN_FAIL = "EDIT_ADMIN_FAIL";

export const DELETE_ADMIN_REQUEST = "DELETE_ADMIN_REQUEST";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCESS";
export const DELETE_ADMIN_FAIL = "DELETE_ADMIN_FAIL";

export const CLEAR = "CLEAR";
