import axios from "axios";

//redux
import {
  FETCH_FAIL,
  FETCH_ONE_SUCCESS,
  FETCH_REQUEST,
  FETCH_SUCCESS,
} from "./organizationEvalTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchSuccess = (data) => {
  return {
    type: FETCH_SUCCESS,
    payload: data,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_FAIL,
    payload: error,
  };
};

export const fetchOneSuccess = (data) => {
  return {
    type: FETCH_ONE_SUCCESS,
    payload: data,
  };
};
export const getOrganizationEvaluations = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/organizationevaluation";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const organizationevaluation = (id) => async (dispatch) => {
  try {
    let url = process.env.REACT_APP_API + "/organizationevaluation/" + id;
    const response = await axios.get(url);
    dispatch(fetchOneSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};
