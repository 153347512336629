import React,{useEffect,useState} from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import { clear, updateSector } from "../../redux/sector/sectorActions";

//component
import Loading from "../layout/Loading";

//css
import "./Sector.css";

function SectorEditModal({
  id,
  name,
  name_ar,
  description,
  description_ar,
  setName,
  setName_ar,
  setDescription,
  setDescription_ar,
}) {
  const dispatch = useDispatch();
  const { editLoading } = useSelector((state) => state.sectorReducer);
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if (name !== "" && name_ar !== "" && description !== "" && description_ar !== "" ) setButtonState(false);
    else setButtonState(true);
  }, [name, name_ar,description,description_ar]);
  const resetForm = () => {
    setName("");
    setName_ar("");
    setDescription("");
    setDescription_ar("");
    dispatch(clear());
  };

  const editSectorHandle = (e) => {
    e.preventDefault();
    dispatch(updateSector(id, name, name_ar, description, description_ar));
    setName("");
    setName_ar("");
    setDescription("");
    setDescription_ar("");
  };

  return (
    <div className="modal fade" id="update-sector-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Edit Sector</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={resetForm}
            >
              &times;
            </button>
          </div>
          {editLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form onSubmit={editSectorHandle}>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Name :</span>
                    <input
                      value={name}
                      placeholder="Enter the sector's name"
                      onChange={(e) => setName(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Arabic Name :</span>
                    <input
                      value={name_ar}
                      placeholder="Enter the sector's name in Arabic"
                      onChange={(e) => setName_ar(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Description :</span>
                    <input
                      value={description}
                      placeholder="Enter the sector's description"
                      onChange={(e) => setDescription(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">
                      Arabic description :
                    </span>
                    <input
                      value={description_ar}
                      placeholder="Enter the sector's description in Arabic"
                      onChange={(e) => setDescription_ar(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="modal-footer">
                    <button
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={resetForm}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      onClick={editSectorHandle}
                      disabled={buttonState ? true : false}
                    >
                      Edit
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SectorEditModal;
