import React, { useState ,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import { addFaq, clear } from "../../redux/faq/faqActions";

//components
import Loading from "../layout/Loading";

//css
import "./Faq.css";

function FaqAddModal() {
  const dispatch = useDispatch();
  const [question, setQuestion] = useState("");
  const [question_ar, setQuestion_ar] = useState("");
  const [answer, setAnswer] = useState("");
  const [answer_ar, setAnswer_ar] = useState("");
  const { addLoading } = useSelector((state) => state.faqReducer);
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if (question !== "" && question_ar !== "" && answer !== "" && answer_ar !== "") setButtonState(false);
    else setButtonState(true);
  }, [question, question_ar,answer, answer_ar]);
  const resetForm = () => {
    setQuestion("");
    setQuestion_ar("");
    setAnswer("");
    setAnswer_ar("");
    dispatch(clear());
  };

  const addFaqHandle = (e) => {
    e.preventDefault();
    dispatch(addFaq(question, question_ar, answer, answer_ar));
    setQuestion("");
    setQuestion_ar("");
    setAnswer("");
    setAnswer_ar("");
  };

  return (
    <div>
      <div className="modal fade" id="add-faq-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add Faq</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={addFaqHandle}>
                  <div className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Question :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={question}
                        placeholder="Enter the question"
                        required
                        onChange={(e) => {
                          setQuestion(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm">
                        Arabic Question :
                      </span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={question_ar}
                        placeholder="Enter the question in Arabic"
                        required
                        onChange={(e) => {
                          setQuestion_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="formRow row">
                      <span className="col-md-3 textForm">Answer :</span>
                      <textarea
                        placeholder="Enter the answer"
                        className="  inputt col-md-8 form-controls"
                        value={answer}
                        onChange={(e) => {
                          setAnswer(e.target.value);
                        }}
                        required
                      ></textarea>
                    </div>
                    <br />

                    <div className="formRow row">
                      <span className="col-md-3 textForm">Arabic Answer :</span>
                      <textarea
                        placeholder="Enter the answer in Arabic"
                        className="  inputt col-md-8 form-controls"
                        value={answer_ar}
                        onChange={(e) => {
                          setAnswer_ar(e.target.value);
                        }}
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addFaqHandle}
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"

                      disabled={buttonState ? true : false}
                      >
                      Add
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqAddModal;
