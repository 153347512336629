import axios from "axios";

//redux
import {
  FETCH_ORGANIZATION_REQUEST,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_FAIL,
  FETCH_ONE_ORGANIZATION_REQUEST,
  FETCH_ONE_ORGANIZATION_SUCCESS,
  FETCH_ONE_ORGANIZATION_FAIL,
    
  EDIT_ORGANIZATION_REQUEST,
  EDIT_ORGANIZATION_SUCCESS,
  EDIT_ORGANIZATION_FAIL,
} from "./organizationTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_ORGANIZATION_REQUEST,
  };
};

export const fetchSuccess = (organizations) => {
  return {
    type: FETCH_ORGANIZATION_SUCCESS,
    payload: organizations,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_ORGANIZATION_FAIL,
    payload: error,
  };
};


export const editOrganizationRequest = () => {
  return {
    type: EDIT_ORGANIZATION_REQUEST,
  };
};

export const editOrganizationSuccess = () => {
  return {
    type: EDIT_ORGANIZATION_SUCCESS,
  };
};

export const editOrganizationFail = (error) => {
  return {
    type: EDIT_ORGANIZATION_FAIL,
    payload: error,
  };
};

export const fetchOneRequest = () => {
  return {
    type: FETCH_ONE_ORGANIZATION_REQUEST,
  };
};

export const fetchOneSuccess = (oneorganization) => {
  return {
    type: FETCH_ONE_ORGANIZATION_SUCCESS,
    payload: oneorganization,
  };
};

export const fetchOneFail = (error) => {
  return {
    type: FETCH_ONE_ORGANIZATION_FAIL,
    payload: error,
  };
};

export const getOrganizations = (sector = 0 , contact = '') => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let filters = '';
    if(sector){
      filters = 'sector_id='+ sector?.value + '&'
    }
    if(contact!== ''){
      filters += 'contact=' + contact ;
    }
    let url = process.env.REACT_APP_API + "/organizations/getAll?" + filters;
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const getOrganizationById = (id) => async (dispatch) => {
  dispatch(fetchOneRequest());
  try {
    let url = process.env.REACT_APP_API + "/organizations/getone/" + id;
    const response = await axios.get(url);
    dispatch(fetchOneSuccess(response.data));
  } catch (e) {
    dispatch(fetchOneFail(e.response.data));
  }
};
export const updateOrganization= (id) => async (dispatch) => {
  dispatch(editOrganizationRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/organizations/change/" + id, {
        
      
    });
    dispatch(editOrganizationSuccess());
    dispatch(getOrganizations());
  } catch (e) {
    dispatch(editOrganizationFail(e.response.data));
  }
};
