export const FETCH_TESTIMONIAL_REQUEST = "FETCH_TESTIMONIAL_REQUEST";
export const FETCH_TESTIMONIAL_SUCCESS = "FETCH_TESTIMONIAL_SUCCESS";
export const FETCH_TESTIMONIAL_FAIL = "FETCH_TESTIMONIAL_FAIL";

export const ADD_TESTIMONIAL_REQUEST = "ADD_TESTIMONIAL_REQUEST";
export const ADD_TESTIMONIAL_SUCCESS = "ADD_TESTIMONIAL_SUCESS";
export const ADD_TESTIMONIAL_FAIL = "ADD_TESTIMONIAL_FAIL";

export const EDIT_TESTIMONIAL_REQUEST = "EDIT_TESTIMONIAL_REQUEST";
export const EDIT_TESTIMONIAL_SUCCESS = "EDIT_TESTIMONIAL_SUCESS";
export const EDIT_TESTIMONIAL_FAIL = "EDIT_TESTIMONIAL_FAIL";

export const DELETE_TESTIMONIAL_REQUEST = "DELETE_TESTIMONIAL_REQUEST";
export const DELETE_TESTIMONIAL_SUCCESS = "DELETE_TESTIMONIAL_SUCESS";
export const DELETE_TESTIMONIAL_FAIL = "DELETE_TESTIMONIAL_FAIL";

export const CLEAR = "CLEAR";