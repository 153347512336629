export const FETCH_REASONS_REQUEST = "FETCH_REASONS_REQUEST";
export const FETCH_REASONS_SUCCESS = "FETCH_REASONS_SUCCESS";
export const FETCH_REASONS_FAIL = "FETCH_REASONS_FAIL";

export const ADD_REASONS_REQUEST = "ADD_REASONS_REQUEST";
export const ADD_REASONS_SUCCESS = "ADD_REASONS_SUCESS";
export const ADD_REASONS_FAIL = "ADD_REASONS_FAIL";

export const EDIT_REASONS_REQUEST = "EDIT_REASONS_REQUEST";
export const EDIT_REASONS_SUCCESS = "EDIT_REASONS_SUCESS";
export const EDIT_REASONS_FAIL = "EDIT_REASONS_FAIL";

export const DELETE_REASONS_REQUEST = "DELETE_REASONS_REQUEST";
export const DELETE_REASONS_SUCCESS = "DELETE_REASONS_SUCESS";
export const DELETE_REASONS_FAIL = "DELETE_REASONS_FAIL";

export const CLEAR = "CLEAR";