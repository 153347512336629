import {
  FETCH_CAROUSEL_REQUEST,
  FETCH_CAROUSEL_SUCCESS,
  FETCH_CAROUSEL_FAIL,

  ADD_CAROUSEL_REQUEST,
  ADD_CAROUSEL_SUCCESS,
  ADD_CAROUSEL_FAIL,

  EDIT_CAROUSEL_REQUEST,
  EDIT_CAROUSEL_SUCCESS,
  EDIT_CAROUSEL_FAIL,

  DELETE_CAROUSEL_REQUEST,
  DELETE_CAROUSEL_SUCCESS,
  DELETE_CAROUSEL_FAIL,

  CLEAR
} from "./carouselTypes";

export const initialState = {
  loading: false,
  carousels: [],
  error: "",
  addLoading: false,
  addError: "",
  editLoading: false,
  editError: "",
  deleteLoading: false,
  deleteError: "",
};

const carouselReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_CAROUSEL_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_CAROUSEL_SUCCESS:
      return {
        loading: false,
        carousels: action.payload,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_CAROUSEL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };

    case ADD_CAROUSEL_REQUEST:
      return {
        ...state,
        addLoading: true,
        addError: "",
      };
    case ADD_CAROUSEL_SUCCESS:
      return {
        ...state,
        addLoading: false,
        addError: ""
      };
    case ADD_CAROUSEL_FAIL:
      return {
        ...state,
        addLoading: false,
        addError: action.payload,
      };

    case EDIT_CAROUSEL_REQUEST:
      return {
        ...state,
        editLoading: true,
        editError: "",
      };
    case EDIT_CAROUSEL_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editError: ""
      };
    case EDIT_CAROUSEL_FAIL:
      return {
        ...state,
        editLoading: false,
        editError: action.payload,
      };

    case DELETE_CAROUSEL_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: "",
      };
    case DELETE_CAROUSEL_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: ""
      };
    case DELETE_CAROUSEL_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.payload,
      };

    case CLEAR:
      return {
        ...state,
        loading: false,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    default:
      return state;
  }
};

export default carouselReducer;
