import {
  FETCH_ADMIN_REQUEST,
  FETCH_ADMIN_SUCCESS,
  FETCH_ADMIN_FAIL,
  ADD_ADMIN_REQUEST,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAIL,
  EDIT_ADMIN_REQUEST,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAIL,
  DELETE_ADMIN_REQUEST,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAIL,
  CLEAR,
} from "./adminTypes";

export const initialState = {
  loading: false,
  admins: [],
  error: "",
  addLoading: false,
  addError: "",
  editLoading: false,
  editError: "",
  deleteLoading: false,
  deleteError: "",
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_ADMIN_SUCCESS:
      return {
        loading: false,
        admins: action.payload,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };

    case ADD_ADMIN_REQUEST:
      return {
        ...state,
        addLoading: true,
        addError: "",
      };
    case ADD_ADMIN_SUCCESS:
      return {
        ...state,
        addLoading: false,
        addError: "",
      };
    case ADD_ADMIN_FAIL:
      return {
        ...state,
        addLoading: false,
        addError: action.payload,
      };

    case EDIT_ADMIN_REQUEST:
      return {
        ...state,
        editLoading: true,
        editError: "",
      };
    case EDIT_ADMIN_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editError: "",
      };
    case EDIT_ADMIN_FAIL:
      return {
        ...state,
        editLoading: false,
        editError: action.payload,
      };

    case DELETE_ADMIN_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: "",
      };
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: "",
      };
    case DELETE_ADMIN_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.payload,
      };

    case CLEAR:
      return {
        ...state,
        loading: false,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    default:
      return state;
  }
};

export default adminReducer;
