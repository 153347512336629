import axios from "axios";

//redux
import {
    FETCH_TESTIMONIAL_REQUEST,
    FETCH_TESTIMONIAL_SUCCESS,
    FETCH_TESTIMONIAL_FAIL,
  
    ADD_TESTIMONIAL_REQUEST,
    ADD_TESTIMONIAL_SUCCESS,
    ADD_TESTIMONIAL_FAIL,
  
    EDIT_TESTIMONIAL_REQUEST,
    EDIT_TESTIMONIAL_SUCCESS,
    EDIT_TESTIMONIAL_FAIL,
  
    DELETE_TESTIMONIAL_REQUEST,
    DELETE_TESTIMONIAL_SUCCESS,
    DELETE_TESTIMONIAL_FAIL,
  
    CLEAR
} from "./testimonialTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_TESTIMONIAL_REQUEST,
  };
};

export const fetchSuccess = (Testimonial) => {
  return {
    type: FETCH_TESTIMONIAL_SUCCESS,
    payload: Testimonial,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_TESTIMONIAL_FAIL,
    payload: error,
  };
};

export const addTestimonialRequest = () => {
  return {
    type: ADD_TESTIMONIAL_REQUEST,
  };
};

export const addTestimonialSuccess = () => {
  return {
    type: ADD_TESTIMONIAL_SUCCESS,
  };
};

export const addTestimonialFail = (error) => {
  return {
    type: ADD_TESTIMONIAL_FAIL,
    payload: error,
  };
};

export const editTestimonialRequest = () => {
  return {
    type: EDIT_TESTIMONIAL_REQUEST,
  };
};

export const editTestimonialSuccess = () => {
  return {
    type: EDIT_TESTIMONIAL_SUCCESS,
  };
};

export const editTestimonialFail = (error) => {
  return {
    type: EDIT_TESTIMONIAL_FAIL,
    payload: error,
  };
};

export const deleteTestimonialRequest = () => {
  return {
    type: DELETE_TESTIMONIAL_REQUEST,
  };
};

export const deleteTestimonialSuccess = () => {
  return {
    type: DELETE_TESTIMONIAL_SUCCESS,
  };
};

export const deleteTestimonialFail = (error) => {
  return {
    type: DELETE_TESTIMONIAL_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getTestimonial = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/testimonial";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteTestimonial = (id) => async (dispatch) => {
  dispatch(deleteTestimonialRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/testimonial/" + id);
    dispatch(deleteTestimonialSuccess());
    dispatch(getTestimonial());
  } catch (e) {
    dispatch(deleteTestimonialFail(e.response.data));
  }
};

export const addTestimonial = (name, name_ar,text,text_ar) => async (dispatch) => {
  dispatch(addTestimonialRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/testimonial", {
        name,
        name_ar,
        text,
        text_ar,
    });
    dispatch(addTestimonialSuccess());
    dispatch(getTestimonial());
  } catch (e) {
    dispatch(addTestimonialFail(e.response.data));
  }
};

export const updateTestimonial= (id, name, name_ar,text,text_ar) => async (dispatch) => {
  dispatch(editTestimonialRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/testimonial/" + id, {
      name,
      name_ar,
      text,
      text_ar,
    });
    dispatch(editTestimonialSuccess());
    dispatch(getTestimonial());
  } catch (e) {
    dispatch(editTestimonialFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
