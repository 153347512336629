import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import ReactDatatable from "@ashvin27/react-datatable";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";

//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import GovernateAddModel from "./GovernateAddModal";
import GovernateEditModel from "./GovernateEditModal";
import GovernateDeleteModal from "./GovernateDeleteModal";
import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
//redux
import { getGovernates } from "../../redux/governate/governateActions";

//utils
import isAuth from "../../utils/isAuth";

function Governate() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, governates, error, addError, editError, deleteError } =
    useSelector((state) => state.governateReducer);
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [name_ar, setName_ar] = useState("");
  const [country_id, setCountry_id] = useState("");
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(records);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Governate.xlsx");
  };
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getGovernates());
  }, [dispatch]);

  useEffect(() => {
    if (governates && governates.data) {
      const mappedRecords = governates.data.map((governate) => {
        return {
          key: governate.id,
          id: governate.id,
          country_id: governate.country.id,
          country_name: governate.country.name,
          name: governate.name,
          name_ar: governate.name_ar,
        };
      });
      setRecords(mappedRecords);
    }
  }, [governates]);

  const columns = [
    {
      field: "country_name",
      headerName: "Country",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Governate",
      flex: 1,
    },
    {
      field: "name_ar",
      headerName: "Arabic Governate",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      renderCell: (record) => {
        return (
          <Fragment>
            {/* <button
                        data-toggle="modal"
                        data-target="#update-governate-modal"
                        className="btn btn-primary btn-sm"
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                            setId(record.id);
                            setName(record.name);
                            setName_ar(record.name_ar);
                            setCountry_id(record.country_id);
                        }}
                    >
                        <i className="fa fa-edit"></i>
                    </button> */}
            <button
              data-toggle="modal"
              data-target="#delete-governate-modal"
              className="actions"
              onClick={() => {
                setId(record.row.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const handleReloadClick = () => {
    window.location.reload();
  };
  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <GovernateAddModel />
        <GovernateEditModel
          id={id}
          name={name}
          name_ar={name_ar}
          country_id={country_id}
          setName={setName}
          setName_ar={setName_ar}
          setCountry_id={setCountry_id}
        />
        <GovernateDeleteModal id={id} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            {/* <button
              className="btn btn-outline-primary float-right mt-3 mr-2"
              data-toggle="modal"
              data-target="#add-governate-modal"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Governate
            </button> */}
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
              {addError && (
                <div style={{ color: "red" }}> {addError.message}</div>
              )}
              {editError && (
                <div style={{ color: "red" }}> {editError.message}</div>
              )}
              {deleteError && (
                <div style={{ color: "red" }}> {deleteError.message}</div>
              )}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header title="Governate" subtitle="List of Governates" />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Governate;
