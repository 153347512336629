import axios from "axios";

//redux
import {
  FETCH_IMPACT_REQUEST,
  FETCH_IMPACT_SUCCESS,
  FETCH_IMPACT_FAIL,
  ADD_IMPACT_REQUEST,
  ADD_IMPACT_SUCCESS,
  ADD_IMPACT_FAIL,
  EDIT_IMPACT_REQUEST,
  EDIT_IMPACT_SUCCESS,
  EDIT_IMPACT_FAIL,
  DELETE_IMPACT_REQUEST,
  DELETE_IMPACT_SUCCESS,
  DELETE_IMPACT_FAIL,
  CLEAR,
} from "./impactTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_IMPACT_REQUEST,
  };
};

export const fetchSuccess = (Impact) => {
  return {
    type: FETCH_IMPACT_SUCCESS,
    payload: Impact,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_IMPACT_FAIL,
    payload: error,
  };
};

export const addImpactRequest = () => {
  return {
    type: ADD_IMPACT_REQUEST,
  };
};

export const addImpactSuccess = () => {
  return {
    type: ADD_IMPACT_SUCCESS,
  };
};

export const addImpactFail = (error) => {
  return {
    type: ADD_IMPACT_FAIL,
    payload: error,
  };
};

export const editImpactRequest = () => {
  return {
    type: EDIT_IMPACT_REQUEST,
  };
};

export const editImpactSuccess = () => {
  return {
    type: EDIT_IMPACT_SUCCESS,
  };
};

export const editImpactFail = (error) => {
  return {
    type: EDIT_IMPACT_FAIL,
    payload: error,
  };
};

export const deleteImpactRequest = () => {
  return {
    type: DELETE_IMPACT_REQUEST,
  };
};

export const deleteImpactSuccess = () => {
  return {
    type: DELETE_IMPACT_SUCCESS,
  };
};

export const deleteImpactFail = (error) => {
  return {
    type: DELETE_IMPACT_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getImpact = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/impacts/getAll";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteImpact = (id) => async (dispatch) => {
  dispatch(deleteImpactRequest());
  try {
    await axios.delete(
      process.env.REACT_APP_API + "/impacts/deleteImpact/" + id
    );
    dispatch(deleteImpactSuccess());
    dispatch(getImpact());

  } catch (e) {
    dispatch(deleteImpactFail(e.response.data));
  }
};

export const addImpact = (img_url, title, title_ar) => async (dispatch) => {
  dispatch(addImpactRequest());
  var formData = new FormData();
  if (img_url) formData.append("img_url", img_url);
  try {
    const res = await axios.post(
      process.env.REACT_APP_API + "/impacts/createImpact",
      {
        title,
        title_ar,
      }
    );

    await axios.put(
      process.env.REACT_APP_API +
        "/impacts/assignUrlImg/" +
        res?.data?.Impact?.id,
      formData
      
    );
    dispatch(addImpactSuccess());
    dispatch(getImpact());
  } catch (e) {
    dispatch(addImpactFail(e.response.data));
  }
};

export const updateimpact =
  (id, img_url, title, title_ar) => async (dispatch) => {
    dispatch(editImpactRequest());
    var formData = new FormData();
    if (img_url) formData.append("img_url", img_url);

    try {
      await axios.put(process.env.REACT_APP_API + "/impacts/editImpact/" + id, {
        title,
        title_ar,
      });

      await axios.put(
        process.env.REACT_APP_API + "/impacts/assignUrlImg/" + id,
        formData
      );
      dispatch(editImpactSuccess());
      dispatch(getImpact());
    } catch (e) {
      dispatch(editImpactFail(e.response.data));
    }
  };

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
