import {
  FETCH_SKILLS_REQUEST,
  FETCH_SKILLS_SUCCESS,
  FETCH_SKILLS_FAIL,
  FETCH_ONE_SKILLS_REQUEST,
  FETCH_ONE_SKILLS_SUCCESS,
  FETCH_ONE_SKILLS_FAIL,
  FETCH_ONE_EXPERTISES_REQUEST,
  FETCH_ONE_EXPERTISES_SUCCESS,
  FETCH_ONE_EXPERTISES_FAIL,
  ADD_SKILLS_REQUEST,
  ADD_SKILLS_SUCCESS,
  ADD_SKILLS_FAIL,
  EDIT_SKILLS_REQUEST,
  EDIT_SKILLS_SUCCESS,
  EDIT_SKILLS_FAIL,
  DELETE_SKILLS_REQUEST,
  DELETE_SKILLS_SUCCESS,
  DELETE_SKILLS_FAIL,
  CLEAR,
} from "./SkillsTypes";

export const initialState = {
  loading: false,
  skills: [],
  volunteerSkills: [],
  volunteerExpertises: [],
  error: "",
  addLoading: false,
  addError: "",
  editLoading: false,
  editError: "",
  deleteLoading: false,
  deleteError: "",
};

const SkillsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SKILLS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_SKILLS_SUCCESS:
      return {
        loading: false,
        skills: action.payload,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_SKILLS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };

    case FETCH_ONE_SKILLS_REQUEST:
      return {
        ...state,
        loading: false,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_ONE_SKILLS_SUCCESS:
      return {
        loading: false,
        volunteerSkills: action.payload,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_ONE_SKILLS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };

    case FETCH_ONE_EXPERTISES_REQUEST:
      return {
        ...state,
        loading: false,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_ONE_EXPERTISES_SUCCESS:
      return {
        loading: false,
        volunteerExpertises: action.payload,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_ONE_EXPERTISES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };

    case ADD_SKILLS_REQUEST:
      return {
        ...state,
        addLoading: true,
        addError: "",
      };
    case ADD_SKILLS_SUCCESS:
      return {
        ...state,
        addLoading: false,
        addError: "",
      };
    case ADD_SKILLS_FAIL:
      return {
        ...state,
        addLoading: false,
        addError: action.payload,
      };

    case EDIT_SKILLS_REQUEST:
      return {
        ...state,
        editLoading: true,
        editError: "",
      };
    case EDIT_SKILLS_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editError: "",
      };
    case EDIT_SKILLS_FAIL:
      return {
        ...state,
        editLoading: false,
        editError: action.payload,
      };

    case DELETE_SKILLS_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: "",
      };
    case DELETE_SKILLS_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: "",
      };
    case DELETE_SKILLS_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.payload,
      };

    case CLEAR:
      return {
        ...state,
        loading: false,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    default:
      return state;
  }
};

export default SkillsReducer;
