import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import { addImpact, clear } from "../../redux/impact/impactAction";

//components
import Loading from "../layout/Loading";

//css
import "./Impact.css";

function ImpactAddModal() {
  const dispatch = useDispatch();
  const [title_ar, setTitle_ar] = useState("");
  const [title, setTitle] = useState("");
  const [img_url, setImg_url] = useState("");
  const { addLoading } = useSelector((state) => state.impactReducer);
  const [buttonState, setButtonState] = useState(true);
  const [newImgSource, setNewImgSource] = useState("");
  const [imgName, setImgName] = useState("default.png");
  const defaultSource = process.env.REACT_APP_API + "/Impact/default.png";
  const re = /(?:\.([^.]+))?$/;

  const resetForm = () => {
    setImg_url("");
    setTitle("");
    setTitle_ar("");
    setNewImgSource(defaultSource);
    setImgName("default.png");
    dispatch(clear());
  };

  const addimpactHandle = (e) => {
    e.preventDefault();
    dispatch(addImpact(img_url, title, title_ar));
    setImg_url("");
    setTitle("");
    setTitle_ar("");
    setNewImgSource(defaultSource);
    setImgName("default.png");
  };

  const removeImg = (e) => {
    e.preventDefault();
    setNewImgSource(defaultSource);
    setImgName("default.png");
    setImg_url("");
    document.getElementById("files").value = null;
  };

  const changeImgName = (Name) => {
    setImgName(Name);
  };

  useEffect(() => {
    if (title_ar !== "" && title !== "") setButtonState(false);
    else setButtonState(true);
  }, [title_ar, title]);

  return (
    <div>
      <div className="modal fade" id="add-impact-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add impact</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={addimpactHandle} encType="multipart/form-data">
                  <div className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-3 textForm2">Image :</span>

                      <div className="row">
                        <div className="row">
                          <img
                            src={
                              newImgSource !== "" ? newImgSource : defaultSource
                            }
                            style={{
                              height: "130px",
                              width: "130px",
                              marginLeft: "40px",
                            }}
                            alt={img_url}
                          />
                          {imgName !== "default.png" && (
                            <button className="tag-remove" onClick={removeImg}>
                              &#10006;
                            </button>
                          )}
                        </div>

                        <div className="row">
                          <label
                            htmlFor="files"
                            className="button"
                            style={{
                              marginLeft: "50px",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            {imgName !== "default.png"
                              ? "Change Image"
                              : "Choose Image"}
                          </label>
                          <input
                            id="files"
                            type="file"
                            className="inputt"
                            style={{ visibility: "hidden", width: "1px" }}
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                setImg_url(e.target.files[0]);
                                if (e.target.files[0].name.length > 15)
                                  changeImgName(
                                    e.target.files[0].name.substring(0, 15) +
                                      "...." +
                                      re.exec(e.target.files[0].name)[1]
                                  );
                                else changeImgName(e.target.files[0].name);
                                setNewImgSource(
                                  URL.createObjectURL(e.target.files[0])
                                );
                              }
                            }}
                          />

                          <h6
                            style={{
                              marginLeft: "15px",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            {imgName !== "default.png" ? imgName : ""}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm2">Title :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        placeholder="Enter the impact's title"
                        value={title}
                        required
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm2">Arabic Title :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        placeholder="Enter the impact's title in Arabic"
                        value={title_ar}
                        required
                        onChange={(e) => {
                          setTitle_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                  </div>
                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addimpactHandle}
                      type="submit"
                      disabled={buttonState ? true : false}
                      className="add-btn"
                      data-dismiss="modal"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImpactAddModal;
