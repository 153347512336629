import axios from "axios";

//redux
import {
  FETCH_MASTERCLASS_TESTIMONIAL_REQUEST,
  FETCH_MASTERCLASS_TESTIMONIAL_SUCCESS,
  FETCH_MASTERCLASS_TESTIMONIAL_FAIL,
  ADD_MASTERCLASS_TESTIMONIAL_REQUEST,
  ADD_MASTERCLASS_TESTIMONIAL_SUCCESS,
  ADD_MASTERCLASS_TESTIMONIAL_FAIL,
  EDIT_MASTERCLASS_TESTIMONIAL_REQUEST,
  EDIT_MASTERCLASS_TESTIMONIAL_SUCCESS,
  EDIT_MASTERCLASS_TESTIMONIAL_FAIL,
  DELETE_MASTERCLASS_TESTIMONIAL_REQUEST,
  DELETE_MASTERCLASS_TESTIMONIAL_SUCCESS,
  DELETE_MASTERCLASS_TESTIMONIAL_FAIL,
  CLEAR,
} from "./MasterClassTestimonialTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_MASTERCLASS_TESTIMONIAL_REQUEST,
  };
};

export const fetchSuccess = (masterclasstestimonial) => {
  return {
    type: FETCH_MASTERCLASS_TESTIMONIAL_SUCCESS,
    payload: masterclasstestimonial,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_MASTERCLASS_TESTIMONIAL_FAIL,
    payload: error,
  };
};

export const addMasterClassTestimonialRequest = () => {
  return {
    type: ADD_MASTERCLASS_TESTIMONIAL_REQUEST,
  };
};

export const addMasterClassTestimonialSuccess = () => {
  return {
    type: ADD_MASTERCLASS_TESTIMONIAL_SUCCESS,
  };
};

export const addMasterClassTestimonialFail = (error) => {
  return {
    type: ADD_MASTERCLASS_TESTIMONIAL_FAIL,
    payload: error,
  };
};

export const editMasterClassTestimonialRequest = () => {
  return {
    type: EDIT_MASTERCLASS_TESTIMONIAL_REQUEST,
  };
};

export const editMasterClassTestimonialSuccess = () => {
  return {
    type: EDIT_MASTERCLASS_TESTIMONIAL_SUCCESS,
  };
};

export const editMasterClassTestimonialFail = (error) => {
  return {
    type: EDIT_MASTERCLASS_TESTIMONIAL_FAIL,
    payload: error,
  };
};
export const deleteMasterClassTestimonialRequest = () => {
  return {
    type: DELETE_MASTERCLASS_TESTIMONIAL_REQUEST,
  };
};

export const deleteMasterClassTestimonialSuccess = () => {
  return {
    type: DELETE_MASTERCLASS_TESTIMONIAL_SUCCESS,
  };
};

export const deleteMasterClassTestimonialFail = (error) => {
  return {
    type: DELETE_MASTERCLASS_TESTIMONIAL_FAIL,
    payload: error,
  };
};
export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getMasterClassTestimonial = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url =
      process.env.REACT_APP_API + "/masterclasses/getmastertestimonials";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const addMasterClassTestimonial =
  (name, name_ar, text, text_ar) => async (dispatch) => {
    dispatch(addMasterClassTestimonialRequest());
    try {
      await axios.post(
        process.env.REACT_APP_API + "/masterclasses/createmastertestimonials",
        {
          name,
          name_ar,
          text,
          text_ar,
        }
      );
      dispatch(addMasterClassTestimonialSuccess());
      dispatch(getMasterClassTestimonial());
    } catch (e) {
      dispatch(addMasterClassTestimonialFail(e.response.data));
    }
  };

export const deleteMasterClassTestimonial = (id) => async (dispatch) => {
  dispatch(deleteMasterClassTestimonialRequest());
  try {
    await axios.delete(
      process.env.REACT_APP_API +
        "/masterclasses/deletemastertestimonials/" +
        id
    );
    dispatch(deleteMasterClassTestimonialSuccess());

    dispatch(getMasterClassTestimonial());
  } catch (e) {
    dispatch(deleteMasterClassTestimonialFail(e.response.data));
  }
};

export const updateMasterClassTestimonial =
  (id, name, name_ar, text, text_ar) => async (dispatch) => {
    dispatch(editMasterClassTestimonialRequest());
    try {
      await axios.put(
        process.env.REACT_APP_API +
          "/masterclasses/editmastertestimonials/" +
          id,
        {
          name,
          name_ar,
          text,
          text_ar,
        }
      );
      dispatch(editMasterClassTestimonialSuccess());
      dispatch(getMasterClassTestimonial());
    } catch (e) {
      dispatch(editMasterClassTestimonialFail(e.response.data));
    }
  };

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
