import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

function VolunteersSkill(props) {
  return (
    <div
      className="col-lg-4 col-sm-4 shadow-lg"
      style={{
        backgroundColor: "#ffffff",
        padding: "30px",
        borderRadius: "20px",
      }}
    >
      <div className="chart-title">Skill development among volunteers</div>
      <div class="horizontal-line"></div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={props.volunteersSkill}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="skill" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill="#F16B81" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default VolunteersSkill;
