import axios from "axios";

//redux
import {
  FETCH_DONATE_REQUEST,
  FETCH_DONATE_SUCCESS,
  FETCH_DONATE_FAIL,
  CLEAR,
} from "./DonateTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_DONATE_REQUEST,
  };
};

export const fetchSuccess = (donates) => {
  return {
    type: FETCH_DONATE_SUCCESS,
    payload: donates,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_DONATE_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getDonates = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/donations/getDonationPayments";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
