export const FETCH_EMAIL_REQUEST = "FETCH_EMAIL_REQUEST";
export const FETCH_EMAIL_SUCCESS = "FETCH_EMAIL_SUCCESS";
export const FETCH_EMAIL_FAIL = "FETCH_EMAIL_FAIL";

export const ADD_EMAIL_REQUEST = "ADD_EMAIL_REQUEST";
export const ADD_EMAIL_SUCCESS = "ADD_EMAIL_SUCESS";
export const ADD_EMAIL_FAIL = "ADD_EMAIL_FAIL";

export const DELETE_EMAIL_REQUEST = "ADD_EMAIL_REQUEST";
export const DELETE_EMAIL_SUCCESS = "ADD_EMAIL_SUCESS";
export const DELETE_EMAIL_FAIL = "ADD_EMAIL_FAIL";

export const CLEAR = "CLEAR";
