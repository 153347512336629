import {
  FETCH_VOLUNTEER_REQUEST,
  FETCH_VOLUNTEER_SUCCESS,
  FETCH_VOLUNTEER_FAIL,
  FETCH_ONE_VOLUNTEER_REQUEST,
  FETCH_ONE_VOLUNTEER_SUCCESS,
  FETCH_ONE_VOLUNTEER_FAIL,

  EDIT_VOLUNTEER_REQUEST,
  EDIT_VOLUNTEER_SUCCESS,
  EDIT_VOLUNTEER_FAIL,

  CLEAR,
} from "./volunteerTypes";

export const initialState = {
  loading: false,
  oneLoading: false,
  volunteers: [],
  onevolenteer: [],
  error: "",
};

const volunteerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VOLUNTEER_REQUEST:
      return {
        ...state,
        oneLoading: true,
        loading: true,
        error: "",
      };
    case FETCH_VOLUNTEER_SUCCESS:
      return {
        loading: false,
        oneLoading: true,
        volunteers: action.payload,
        error: "",
      };
    case FETCH_VOLUNTEER_FAIL:
      return {
        ...state,
        loading: false,
        oneLoading: true,
        error: action.payload.message,
      };
      case EDIT_VOLUNTEER_REQUEST:
        return {
          ...state,
          editLoading: true,
          editError: "",
        };
      case EDIT_VOLUNTEER_SUCCESS:
        return {
          ...state,
          editLoading: false,
          editError: ""
        };
      case EDIT_VOLUNTEER_FAIL:
        return {
          ...state,
          editLoading: false,
          editError: action.payload,
        };

    case FETCH_ONE_VOLUNTEER_REQUEST:
      return {
        ...state,
        loading: false,
        oneLoading: true,
        error: "",
      };
    case FETCH_ONE_VOLUNTEER_SUCCESS:
      return {
        ...state,
        loading: false,
        oneLoading: false,
        onevolenteer: action.payload,
        error: "",
      };
    case FETCH_ONE_VOLUNTEER_FAIL:
      return {
        ...state,
        loading: false,
        oneLoading: false,
        error: action.payload.message,
      };

    case CLEAR:
      return {
        ...state,
        loading: false,
        oneLoading: false,
        error: "",
      };
    default:
      return state;
  }
};

export default volunteerReducer;
