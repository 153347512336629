import React, { useState, useEffect, Fragment } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import ReactDatatable from "@ashvin27/react-datatable";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import QuizEdit from "./QuizEdit";
import QuizDelete from "../quiz/QuizDelete";

//redux
import { getQuiz } from "../../redux/quiz/quizAction";
//utils
import isAuth from "../../utils/isAuth";

function QuizEditDelete() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, quizzes, error, addError, editError, deleteError } =
    useSelector((state) => state.quizReducer);

  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);

  const [question, setQuestion] = useState("");
  const [question_ar, setQuestion_ar] = useState("");
  const [type, setType] = useState("");

  function exportToExcel() {
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter((entry, index) => index !== 0);
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Quizzes.xlsx");
  }

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getQuiz());
  }, [dispatch]);

  useEffect(() => {
    setRecords(
      quizzes?.data?.map((quizzes) => {
        return {
          key: quizzes.id,
          id: quizzes.id,
          question: quizzes.question,
          arabic_Question: quizzes.question_ar,
          type: quizzes.type,
        };
      })
    );
  }, [quizzes]);

  const columns = [
    {
      field: "question",
      headerName: "Question",
      flex: 1,
      className: "centerText",
      align: "center",
      sortable: true,
    },
    {
      field: "arabic_Question",
      headerName: "Arabic Question",
      flex: 1,
      className: "centerText",
      align: "center",
      sortable: true,
    },
    {
      field: "type",
      headerName: "Question Type",
      flex: 1,
      className: "centerText",
      align: "center",
      sortable: true,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      className: "centerText action",
      align: "center",
      sortable: false,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-quiz-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setQuestion(record.row.question);
                setQuestion_ar(record.row.arabic_Question);
                setType(record.row.type);
              }}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              data-toggle="modal"
              data-target="#delete-quiz-modal"
              className="actions"
              onClick={() => {
                setId(record.row.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];
  

  const ToggleMenu = (e) => {
    var element = document.getElementById("wrapper");
    if (element.classList.contains("toggled")) {
      document.getElementById("wrapper").classList.remove("toggled");
    } else {
      document.getElementById("wrapper").classList.add("toggled");
    }
  };

  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <QuizEdit
          id={id}
          question={question}
          question_ar={question_ar}
          type={type}
          setQuestion={setQuestion}
          setQuestion_ar={setQuestion_ar}
          setType={setType}
        />
        <QuizDelete id={id} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
  
            <Link to="/quizzes">
              <button className="add">
                <i className=" fa fa-arrow-left" aria-hidden="true"></i> Back to
                Answer
              </button>
            </Link>
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
              {addError && (
                <div style={{ color: "red" }}> {addError.message}</div>
              )}
              {editError && (
                <div style={{ color: "red" }}> {editError.message}</div>
              )}
              {deleteError && (
                <div style={{ color: "red" }}> {deleteError.message}</div>
              )}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
              <Box m="20px">
                <Header title="QuizEditDelete"  />
                <Box
                  m="40px 0 0 0"
                  height="75vh"
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                    "& .name-column--cell": {
                      color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: colors.greenAccent[700],
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                      borderTop: "none",
                      backgroundColor: colors.greenAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                      color: `${colors.grey[100]} !important`,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  }}
                >
              <DataGrid className="table" rows={records} columns={columns} components={{ Toolbar: GridToolbar }}   checkboxSelection={true}/>
              </Box>
              </Box>
              </div>  )}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizEditDelete;
