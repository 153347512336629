import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Login.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
//redux
import { loginAdmin, verifyAdmin } from "../../redux/auth/authActions";
import myImage from './../layout/tvcimg.png';

//components
import Loading from "../layout/Loading";
 import "./../../App.css"
function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const[otp,setOtp]=useState(false);
  const { loading, error, isAuthenticated } = useSelector(
    (state) => state.authReducer
  );
  const [email, setEmail] = useState(null);
  const [OTP, setOTP] = useState(null);

  useEffect(() => {
    if (isAuthenticated) navigate("/dashboard");
  }, [isAuthenticated, navigate]);

  const login = async (e) => {
    e.preventDefault();
    dispatch(loginAdmin({ email }));
  };

  const verify = async (e) => {
    dispatch(verifyAdmin({ id: localStorage.getItem("id"), OTP }));
  };

  useEffect(() => {
    const isRefreshed = window.performance.navigation.type === 1;
    if (isRefreshed) {
      localStorage.removeItem("log");
      
    }
  }, []);

  return (
    <div className="container log">
      <div className="row mt-5 ">
        <div className="col-md-4 mx-auto card shadow-lg bg-white  carddd">
          <div className="card-body p-1">
          <img src={myImage} className="imagee" alt="My Image" />
          {/* <h5 className="text-center ">Admin Panel</h5>
          <h9 className="text-center ">Control Panel login</h9> */}
            {loading ? (
              <Loading />
            ) : (
              <>
              {!localStorage.getItem("log")  &&   (<form onSubmit={login} className="white">
              <div className="row formRow">
        
                <input
                
                  required
                  name="email"
                  type="email"
                  className="form-controling in col-md-11 ml-3"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder= "Enter Your Email"
                  
                />  
                <br />
                </div>
                <p className="text-center pb-0 mt-2">
                  <button
                    type="submit"
                    className="btn btn-large btn-danger mt-2 px-5"
                  >
                    Send OTP
                  </button>
                </p>
                <div className="text- text-center mb-1">{error}</div>
              </form>)}

              {  localStorage.getItem("log") === "logged" && (<form onSubmit={verify} className="white">
              <div className="row formRow">
              
                <input
                  required
                  name="OTP"
                  type="number"
                  className="form-controling in col-md-11 ml-3"
                  onChange={(e) => setOTP(e.target.value)}
                  placeholder="Enter The OTP"
                />
                <br />
                </div>
                <p className="text-center pb-0 mt-2">
                  <button
                    type="submit"
                    className="btn btn-large btn-danger mt-2 px-5"
                  >
                  &nbsp; &nbsp; Verify &nbsp;&nbsp;
                  </button>
                </p>
              </form>)}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
