import axios from "axios";


import {
    FETCH_EPISODEVIDEO_REQUEST,
    FETCH_EPISODEVIDEO_SUCCESS,
    FETCH_EPISODEVIDEO_FAIL,
  
    ADD_EPISODEVIDEO_REQUEST,
    ADD_EPISODEVIDEO_SUCCESS,
    ADD_EPISODEVIDEO_FAIL,
  
    EDIT_EPISODEVIDEO_REQUEST,
    EDIT_EPISODEVIDEO_SUCCESS,
    EDIT_EPISODEVIDEO_FAIL,
  
    DELETE_EPISODEVIDEO_REQUEST,
    DELETE_EPISODEVIDEO_SUCCESS,
    DELETE_EPISODEVIDEO_FAIL,
  
    CLEAR
} from "./episodeVideoTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_EPISODEVIDEO_REQUEST,
  };
};

export const fetchSuccess = (episodeVideo) => {
  return {
    type: FETCH_EPISODEVIDEO_SUCCESS,
    payload: episodeVideo,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_EPISODEVIDEO_FAIL,
    payload: error,
  };
};

export const addEpisodeVideoRequest = () => {
  return {
    type: ADD_EPISODEVIDEO_REQUEST,
  };
};

export const addEpisodeVideoSuccess = () => {
  return {
    type: ADD_EPISODEVIDEO_SUCCESS,
  };
};

export const addEpisodeVideoFail = (error) => {
  return {
    type: ADD_EPISODEVIDEO_FAIL,
    payload: error,
  };
};

export const editEpisodeVideoRequest = () => {
  return {
    type: EDIT_EPISODEVIDEO_REQUEST,
  };
};

export const editEpisodeVideoSuccess = () => {
  return {
    type: EDIT_EPISODEVIDEO_SUCCESS,
  };
};

export const editEpisodeVideoFail = (error) => {
  return {
    type: EDIT_EPISODEVIDEO_FAIL,
    payload: error,
  };
};

export const deleteEpisodeVideoRequest = () => {
  return {
    type: DELETE_EPISODEVIDEO_REQUEST,
  };
};

export const deleteEpisodeVideoSuccess = () => {
  return {
    type: DELETE_EPISODEVIDEO_SUCCESS,
  };
};

export const deleteEpisodeVideoFail = (error) => {
  return {
    type: DELETE_EPISODEVIDEO_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getEpisodeVideo = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/episode_videos";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteEpisodeVideo = (id) => async (dispatch) => {
  dispatch(deleteEpisodeVideoRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/episode_videos/" + id);
    dispatch(deleteEpisodeVideoSuccess());
    dispatch(getEpisodeVideo());
  } catch (e) {
    dispatch(deleteEpisodeVideoFail(e.response.data));
  }
};

 export const addEpisodeVideo = (id,title,title_ar,desc,desc_ar,length, link,name_trai,name_ar_trai,desc_trai,desc_ar_trai,linked_in) => async (dispatch) => {
  dispatch(addEpisodeVideoRequest());
   try {
      await axios.post(process.env.REACT_APP_API + "/episode_videos", {
        episode_id: id,
        title,
        title_ar,
        desc,
        desc_ar,
        length,
        link,
        name_trai,
        name_ar_trai,
        desc_trai,
        desc_ar_trai,
        linked_in,
      });
      dispatch(addEpisodeVideoSuccess());
      dispatch(getEpisodeVideo());
    } catch (e) {
      dispatch(addEpisodeVideoFail(e.response.data));
    }
  };

  export const updateEpisodeVideo= (id,episode_id,title,title_ar,desc,desc_ar,length, link,name_trai,name_ar_trai,desc_trai,desc_ar_trai,linked_in) => async (dispatch) => {
    dispatch(editEpisodeVideoRequest());
    try {
      await axios.put(process.env.REACT_APP_API + "/episode_videos/" + id, {
        episode_id,
        title,
        title_ar,
        desc,
        desc_ar,
        length,
        link,
        name_trai,
        name_ar_trai,
        desc_trai,
        desc_ar_trai,
        linked_in,
      });
      dispatch(editEpisodeVideoSuccess());
      dispatch(getEpisodeVideo());
    } catch (e) {
      dispatch(editEpisodeVideoFail(e.response.data));
    }
  };



export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
