import axios from "axios";

//redux
import {
  FETCH_DISTRICT_REQUEST,
  FETCH_DISTRICT_SUCCESS,
  FETCH_DISTRICT_FAIL,
  ADD_DISTRICT_REQUEST,
  ADD_DISTRICT_SUCCESS,
  ADD_DISTRICT_FAIL,
  EDIT_DISTRICT_REQUEST,
  EDIT_DISTRICT_SUCCESS,
  EDIT_DISTRICT_FAIL,
  DELETE_DISTRICT_REQUEST,
  DELETE_DISTRICT_SUCCESS,
  DELETE_DISTRICT_FAIL,
  CLEAR,
} from "./districtTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_DISTRICT_REQUEST,
  };
};

export const fetchSuccess = (districts) => {
  return {
    type: FETCH_DISTRICT_SUCCESS,
    payload: districts,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_DISTRICT_FAIL,
    payload: error,
  };
};

export const addDistrictRequest = () => {
  return {
    type: ADD_DISTRICT_REQUEST,
  };
};

export const addDistrictSuccess = () => {
  return {
    type: ADD_DISTRICT_SUCCESS,
  };
};

export const addDistrictFail = (error) => {
  return {
    type: ADD_DISTRICT_FAIL,
    payload: error,
  };
};

export const editDistrictRequest = () => {
  return {
    type: EDIT_DISTRICT_REQUEST,
  };
};

export const editDistrictSuccess = () => {
  return {
    type: EDIT_DISTRICT_SUCCESS,
  };
};

export const editDistrictFail = (error) => {
  return {
    type: EDIT_DISTRICT_FAIL,
    payload: error,
  };
};

export const deleteDistrictRequest = () => {
  return {
    type: DELETE_DISTRICT_REQUEST,
  };
};

export const deleteDistrictSuccess = () => {
  return {
    type: DELETE_DISTRICT_SUCCESS,
  };
};

export const deleteDistrictFail = (error) => {
  return {
    type: DELETE_DISTRICT_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getDistricts = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/districts";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const getDistrictByGovernateId = (id) => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url =
      process.env.REACT_APP_API + "/districts/getDistrictByGovernate/" + id;
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteDistrict = (id) => async (dispatch) => {
  dispatch(deleteDistrictRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/districts/" + id);
    dispatch(deleteDistrictSuccess());
    dispatch(getDistricts());
  } catch (e) {
    dispatch(deleteDistrictFail(e.response.data));
  }
};

export const addDistrict =
  (governate_id, name, name_ar) => async (dispatch) => {
    dispatch(addDistrictRequest());
    try {
      await axios.post(process.env.REACT_APP_API + "/districts", {
        governate_id,
        name,
        name_ar,
      });
      dispatch(addDistrictSuccess());
      dispatch(getDistricts());
    } catch (e) {
      dispatch(addDistrictFail(e.response.data));
    }
  };

export const updateDistrict =
  (id, governate_id, name, name_ar) => async (dispatch) => {
    dispatch(editDistrictRequest());
    try {
      await axios.put(process.env.REACT_APP_API + "/districts/" + id, {
        governate_id,
        name,
        name_ar,
      });
      dispatch(editDistrictSuccess());
      dispatch(getDistricts());
    } catch (e) {
      dispatch(editDistrictFail(e.response.data));
    }
  };

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
