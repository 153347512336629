import axios from "axios";

//redux
import {
  FETCH_EPISODEQUIZ_REQUEST,
  FETCH_EPISODEQUIZ_SUCCESS,
  FETCH_EPISODEQUIZ_FAIL,
  ADD_EPISODEQUIZ_REQUEST,
  ADD_EPISODEQUIZ_SUCCESS,
  ADD_EPISODEQUIZ_FAIL,
  EDIT_EPISODEQUIZ_REQUEST,
  EDIT_EPISODEQUIZ_SUCCESS,
  EDIT_EPISODEQUIZ_FAIL,
  DELETE_EPISODEQUIZ_REQUEST,
  DELETE_EPISODEQUIZ_SUCCESS,
  DELETE_EPISODEQUIZ_FAIL,
  ADD_EPISODEQUIZANSWER_REQUEST,
  ADD_EPISODEQUIZANSWER_SUCCESS,
  ADD_EPISODEQUIZANSWER_FAIL,
  CLEAR,
} from "./episodeQuizTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_EPISODEQUIZ_REQUEST,
  };
};

export const fetchSuccess = (disability) => {
  return {
    type: FETCH_EPISODEQUIZ_SUCCESS,
    payload: disability,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_EPISODEQUIZ_FAIL,
    payload: error,
  };
};

export const addEpisodeQuizRequest = () => {
  return {
    type: ADD_EPISODEQUIZ_REQUEST,
  };
};

export const addEpisodeQuizSuccess = () => {
  return {
    type: ADD_EPISODEQUIZ_SUCCESS,
  };
};

export const addEpisodeQuizFail = (error) => {
  return {
    type: ADD_EPISODEQUIZ_FAIL,
    payload: error,
  };
};

export const editEpisodeQuizRequest = () => {
  return {
    type: EDIT_EPISODEQUIZ_REQUEST,
  };
};

export const editEpisodeQuizSuccess = () => {
  return {
    type: EDIT_EPISODEQUIZ_SUCCESS,
  };
};

export const editEpisodeQuizFail = (error) => {
  return {
    type: EDIT_EPISODEQUIZ_FAIL,
    payload: error,
  };
};

export const deleteEpisodeQuizRequest = () => {
  return {
    type: DELETE_EPISODEQUIZ_REQUEST,
  };
};

export const deleteEpisodeQuizSuccess = () => {
  return {
    type: DELETE_EPISODEQUIZ_SUCCESS,
  };
};

export const deleteEpisodeQuizFail = (error) => {
  return {
    type: DELETE_EPISODEQUIZ_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getEpisodeQuiz = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/episode_quizes";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteEpisodeQuiz = (id) => async (dispatch) => {
  dispatch(deleteEpisodeQuizRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/episode_quizes/" + id);
    dispatch(deleteEpisodeQuizSuccess());
    dispatch(getEpisodeQuiz());
  } catch (e) {
    dispatch(deleteEpisodeQuizFail(e.response.data));
  }
};

export const addEpisodeQuiz =
  (id, question, question_ar, type) => async (dispatch) => {
    dispatch(addEpisodeQuizRequest());
    try {
      await axios.post(process.env.REACT_APP_API + "/episode_quizes", {
        episode_id: id,
        question,
        question_ar,
        type,
      });
      dispatch(addEpisodeQuizSuccess());
      dispatch(getEpisodeQuiz());
    } catch (e) {
      dispatch(addEpisodeQuizFail(e.response.data));
    }
  };

export const updateEpisodeQuiz =
  (id, episode_id, question, question_ar) => async (dispatch) => {
    dispatch(editEpisodeQuizRequest());
    try {
      await axios.put(process.env.REACT_APP_API + "/episode_quizes/" + id, {
        episode_id,
        question,
        question_ar,
      });
      dispatch(editEpisodeQuizSuccess());
      dispatch(getEpisodeQuiz());
    } catch (e) {
      dispatch(editEpisodeQuizFail(e.response.data));
    }
  };

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};

export const addEpisodeQuizANSWERRequest = () => {
  return {
    type: ADD_EPISODEQUIZANSWER_REQUEST,
  };
};

export const addEpisodeQuizANSWERSuccess = () => {
  return {
    type: ADD_EPISODEQUIZANSWER_SUCCESS,
  };
};

export const addEpisodeQuizANSWERFail = (error) => {
  return {
    type: ADD_EPISODEQUIZANSWER_FAIL,
    payload: error,
  };
};

export const addEpisodeQuizAnswers =
  (id, question, question_ar, type, episode_quiz_answer) =>
  async (dispatch) => {
    dispatch(addEpisodeQuizANSWERRequest());
    try {
      await axios.post(process.env.REACT_APP_API + "/episode_quizes", {
        episode_id: id,
        question,
        question_ar,
        type,
        episode_quiz_answer,
      });
      dispatch(addEpisodeQuizANSWERSuccess());
      dispatch(getEpisodeQuiz());
    } catch (e) {
      dispatch(addEpisodeQuizANSWERFail(e.response.data));
    }
  };
