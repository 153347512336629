import axios from "axios";

//redux
import {
  FETCH_ORGANIZATIONFEEDBACK_SUCCESS,
  FETCH_ORGANIZATIONFEEDBACK_FAIL,
  FETCH_ORGANIZATIONFEEDBACK_REQUEST,
} from "./organizationFeedbackTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_ORGANIZATIONFEEDBACK_REQUEST,
  };
};

export const fetchSuccess = (disability) => {
  return {
    type: FETCH_ORGANIZATIONFEEDBACK_SUCCESS,
    payload: disability,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_ORGANIZATIONFEEDBACK_FAIL,
    payload: error,
  };
};

export const getOrganizationFeedback = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/Organization_feedback";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteOrganizationFeedback = (id) => async (dispatch) => {
  // dispatch(deleteNationalityRequest());
  try {
    await axios.delete(
      process.env.REACT_APP_API + "/Organization_feedback/" + id
    );
    // dispatch(deleteNationalitySuccess());
    dispatch(getOrganizationFeedback());
  } catch (e) {
    // dispatch(deleteNationalityFail(e.response.data));
  }
};
