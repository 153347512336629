import axios from "axios";

//redux
import {
  FETCH_QUIZ_REQUEST,
  FETCH_QUIZ_SUCCESS,
  FETCH_QUIZ_FAIL,
  ADD_QUIZ_REQUEST,
  ADD_QUIZ_SUCCESS,
  ADD_QUIZ_FAIL,
  EDIT_QUIZ_REQUEST,
  EDIT_QUIZ_SUCCESS,
  EDIT_QUIZ_FAIL,
  DELETE_QUIZ_REQUEST,
  DELETE_QUIZ_SUCCESS,
  DELETE_QUIZ_FAIL,
  CLEAR,
} from "./quizTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_QUIZ_REQUEST,
  };
};

export const fetchSuccess = (quizzes) => {
  return {
    type: FETCH_QUIZ_SUCCESS,
    payload: quizzes,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_QUIZ_FAIL,
    payload: error,
  };
};

export const addQuizRequest = () => {
  return {
    type: ADD_QUIZ_REQUEST,
  };
};

export const addQuizSuccess = () => {
  return {
    type: ADD_QUIZ_SUCCESS,
  };
};

export const addQuizFail = (error) => {
  return {
    type: ADD_QUIZ_FAIL,
    payload: error,
  };
};

export const editQuizRequest = () => {
  return {
    type: EDIT_QUIZ_REQUEST,
  };
};

export const editQuizSuccess = () => {
  return {
    type: EDIT_QUIZ_SUCCESS,
  };
};

export const editQuizFail = (error) => {
  return {
    type: EDIT_QUIZ_FAIL,
    payload: error,
  };
};

export const deleteQuizRequest = () => {
  return {
    type: DELETE_QUIZ_REQUEST,
  };
};

export const deleteQuizSuccess = () => {
  return {
    type: DELETE_QUIZ_SUCCESS,
  };
};

export const deleteQuizFail = (error) => {
  return {
    type: DELETE_QUIZ_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getQuiz = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/quizes";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteQuiz = (id) => async (dispatch) => {
  dispatch(deleteQuizRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/quizes/" + id);
    dispatch(deleteQuizSuccess());
    dispatch(getQuiz());
  } catch (e) {
    dispatch(deleteQuizFail(e.response.data));
  }
};

export const addQuiz = (question, question_ar, type) => async (dispatch) => {
  dispatch(addQuizRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/quizes", {
      question,
      question_ar,
      type,
    });
    dispatch(addQuizSuccess());
    dispatch(getQuiz());
  } catch (e) {
    dispatch(addQuizFail(e.response.data));
  }
};

export const updateQuiz =
  (id, question, question_ar, type) => async (dispatch) => {
    dispatch(editQuizRequest());
    try {
      await axios.put(process.env.REACT_APP_API + "/quizes/" + id, {
        question,
        question_ar,
        type,
      });
      dispatch(editQuizSuccess());
      dispatch(getQuiz());
    } catch (e) {
      dispatch(editQuizFail(e.response.data));
    }
  };

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
