import axios from "axios";

//redux
import {
    FETCH_SECTOR_REQUEST,
    FETCH_SECTOR_SUCCESS,
    FETCH_SECTOR_FAIL,
  
    ADD_SECTOR_REQUEST,
    ADD_SECTOR_SUCCESS,
    ADD_SECTOR_FAIL,
  
    EDIT_SECTOR_REQUEST,
    EDIT_SECTOR_SUCCESS,
    EDIT_SECTOR_FAIL,
  
    DELETE_SECTOR_REQUEST,
    DELETE_SECTOR_SUCCESS,
    DELETE_SECTOR_FAIL,
  
    CLEAR
} from "./sectorTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_SECTOR_REQUEST,
  };
};

export const fetchSuccess = (sector) => {
  return {
    type: FETCH_SECTOR_SUCCESS,
    payload: sector,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_SECTOR_FAIL,
    payload: error,
  };
};

export const addSectorRequest = () => {
  return {
    type: ADD_SECTOR_REQUEST,
  };
};

export const addSectorSuccess = () => {
  return {
    type: ADD_SECTOR_SUCCESS,
  };
};

export const addSectorFail = (error) => {
  return {
    type: ADD_SECTOR_FAIL,
    payload: error,
  };
};

export const editSectorRequest = () => {
  return {
    type: EDIT_SECTOR_REQUEST,
  };
};

export const editSectorSuccess = () => {
  return {
    type: EDIT_SECTOR_SUCCESS,
  };
};

export const editSectorFail = (error) => {
  return {
    type: EDIT_SECTOR_FAIL,
    payload: error,
  };
};

export const deleteSectorRequest = () => {
  return {
    type: DELETE_SECTOR_REQUEST,
  };
};

export const deleteSectorSuccess = () => {
  return {
    type: DELETE_SECTOR_SUCCESS,
  };
};

export const deleteSectorFail = (error) => {
  return {
    type: DELETE_SECTOR_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getSector = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/sectors";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteSector = (id) => async (dispatch) => {
  dispatch(deleteSectorRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/sectors/" + id);
    dispatch(deleteSectorSuccess());
    dispatch(getSector());
  } catch (e) {
    dispatch(deleteSectorFail(e.response.data));
  }
};

export const addSector = (name,name_ar,description, description_ar) => async (dispatch) => {
  dispatch(addSectorRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/sectors", {
        
        name,
        name_ar,
        description,
        description_ar,
    });
    dispatch(addSectorSuccess());
    dispatch(getSector());
  } catch (e) {
    dispatch(addSectorFail(e.response.data));
  }
};

export const updateSector= (id,name,name_ar,description, description_ar) => async (dispatch) => {
  dispatch(editSectorRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/sectors/" + id, {
        name,
        name_ar,
        description,
        description_ar,
    });
    dispatch(editSectorSuccess());
    dispatch(getSector());
  } catch (e) {
    dispatch(editSectorFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
