export const FETCH_TEAM_REQUEST = "FETCH_TEAM_REQUEST";
export const FETCH_TEAM_SUCCESS = "FETCH_TEAM_SUCCESS";
export const FETCH_TEAM_FAIL = "FETCH_TEAM_FAIL";

export const ADD_TEAM_REQUEST = "ADD_TEAM_REQUEST";
export const ADD_TEAM_SUCCESS = "ADD_TEAM_SUCESS";
export const ADD_TEAM_FAIL = "ADD_TEAM_FAIL";

export const EDIT_TEAM_REQUEST = "EDIT_TEAM_REQUEST";
export const EDIT_TEAM_SUCCESS = "EDIT_TEAM_SUCESS";
export const EDIT_TEAM_FAIL = "EDIT_TEAM_FAIL";

export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCESS";
export const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL";

export const CLEAR = "CLEAR";
