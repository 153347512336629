import axios from "axios";

//redux
import {
  FETCH_BLOG_REQUEST,
  FETCH_BLOG_SUCCESS,
  FETCH_BLOG_FAIL,
  ADD_BLOG_REQUEST,
  ADD_BLOG_SUCCESS,
  ADD_BLOG_FAIL,
  EDIT_BLOG_REQUEST,
  EDIT_BLOG_SUCCESS,
  EDIT_BLOG_FAIL,
  DELETE_BLOG_REQUEST,
  DELETE_BLOG_SUCCESS,
  DELETE_BLOG_FAIL,
  CLEAR,
} from "./blogTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_BLOG_REQUEST,
  };
};

export const fetchSuccess = (blogs) => {
  return {
    type: FETCH_BLOG_SUCCESS,
    payload: blogs,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_BLOG_FAIL,
    payload: error,
  };
};

export const addBlogRequest = () => {
  return {
    type: ADD_BLOG_REQUEST,
  };
};

export const addBlogSuccess = () => {
  return {
    type: ADD_BLOG_SUCCESS,
  };
};

export const addBlogFail = (error) => {
  return {
    type: ADD_BLOG_FAIL,
    payload: error,
  };
};

export const editBlogRequest = () => {
  return {
    type: EDIT_BLOG_REQUEST,
  };
};

export const editBlogSuccess = () => {
  return {
    type: EDIT_BLOG_SUCCESS,
  };
};

export const editBlogFail = (error) => {
  return {
    type: EDIT_BLOG_FAIL,
    payload: error,
  };
};

export const deleteBlogRequest = () => {
  return {
    type: DELETE_BLOG_REQUEST,
  };
};

export const deleteBlogSuccess = () => {
  return {
    type: DELETE_BLOG_SUCCESS,
  };
};

export const deleteBlogFail = (error) => {
  return {
    type: DELETE_BLOG_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getBlogs = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/blog";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response));
  }
};

export const deleteBlog = (id) => async (dispatch) => {
  dispatch(deleteBlogRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/blog/" + id);
    dispatch(deleteBlogSuccess());
    dispatch(getBlogs());
  } catch (e) {
    dispatch(deleteBlogFail(e.response.data));
  }
};

export const addBlog = (title, text, picture, author) => async (dispatch) => {
  dispatch(addBlogRequest());
  var formData = new FormData();
  if (picture) formData.append("picture", picture);
  formData.append("title", title);
  formData.append("text", text);
  formData.append("author", author);
  try {
    await axios.post(process.env.REACT_APP_API + "/blog", formData);
    dispatch(addBlogSuccess());
    dispatch(getBlogs());
  } catch (e) {
    dispatch(addBlogFail(e.response.data));
  }
};

export const updateBlog =
  (id, title, text, picture, author) => async (dispatch) => {
    dispatch(editBlogRequest());
    var formData = new FormData();
    if (picture) formData.append("picture", picture);
    formData.append("title", title);
    formData.append("text", text);
    formData.append("author", author);
    try {
      await axios.put(process.env.REACT_APP_API + "/blog/" + id, formData);
      dispatch(editBlogSuccess());
      dispatch(getBlogs());
    } catch (e) {
      dispatch(editBlogFail(e.response.data));
    }
  };

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
