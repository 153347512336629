import axios from "axios";

//redux
import {
  FETCH_VOLUNTEER_REQUEST,
  FETCH_VOLUNTEER_SUCCESS,
  FETCH_VOLUNTEER_FAIL,
  FETCH_ONE_VOLUNTEER_REQUEST,
  FETCH_ONE_VOLUNTEER_SUCCESS,
  FETCH_ONE_VOLUNTEER_FAIL,
  EDIT_VOLUNTEER_REQUEST,
  EDIT_VOLUNTEER_SUCCESS,
  EDIT_VOLUNTEER_FAIL,
  CLEAR,
} from "./volunteerTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_VOLUNTEER_REQUEST,
  };
};

export const fetchSuccess = (volunteers) => {
  return {
    type: FETCH_VOLUNTEER_SUCCESS,
    payload: volunteers,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_VOLUNTEER_FAIL,
    payload: error,
  };
};

export const editVolunteerRequest = () => {
  return {
    type: EDIT_VOLUNTEER_REQUEST,
  };
};

export const editVolunteerSuccess = () => {
  return {
    type: EDIT_VOLUNTEER_SUCCESS,
  };
};

export const editVolunteerFail = (error) => {
  return {
    type: EDIT_VOLUNTEER_FAIL,
    payload: error,
  };
};

export const fetchOneRequest = () => {
  return {
    type: FETCH_ONE_VOLUNTEER_REQUEST,
  };
};

export const fetchOneSuccess = (onevolenteer) => {
  return {
    type: FETCH_ONE_VOLUNTEER_SUCCESS,
    payload: onevolenteer,
  };
};

export const fetchOneFail = (error) => {
  return {
    type: FETCH_ONE_VOLUNTEER_FAIL,
    payload: error,
  };
};
export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};
export const getOpenToWork = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/volunteers/getOpenToWork";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const getVolunteers = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/volunteers";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const updateVolunteer =
  (id, timeBased, skillBased) => async (dispatch) => {
    dispatch(editVolunteerRequest());
    try {
      await axios.put(process.env.REACT_APP_API + "/volunteers/" + id, {
        timeBased,
        skillBased,
      });
      dispatch(editVolunteerSuccess());
      dispatch(getVolunteers());
    } catch (e) {
      dispatch(editVolunteerFail(e.response.data));
    }
  };

export const getVolunteerById = (id) => async (dispatch) => {
  dispatch(fetchOneRequest());
  try {
    let url = process.env.REACT_APP_API + "/volunteers/" + id;
    const response = await axios.get(url);
    dispatch(fetchOneSuccess(response.data));
  } catch (e) {
    dispatch(fetchOneFail(e.response.data));
  }
};
export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
