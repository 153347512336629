import {
  FETCH_GOVERNATE_REQUEST,
  FETCH_GOVERNATE_SUCCESS,
  FETCH_GOVERNATE_FAIL,

  ADD_GOVERNATE_REQUEST,
  ADD_GOVERNATE_SUCCESS,
  ADD_GOVERNATE_FAIL,

  EDIT_GOVERNATE_REQUEST,
  EDIT_GOVERNATE_SUCCESS,
  EDIT_GOVERNATE_FAIL,

  DELETE_GOVERNATE_REQUEST,
  DELETE_GOVERNATE_SUCCESS,
  DELETE_GOVERNATE_FAIL,

  CLEAR
} from "./governateTypes";

export const initialState = {
  loading: false,
  governates: [],
  error: "",
  addLoading: false,
  addError: "",
  editLoading: false,
  editError: "",
  deleteLoading: false,
  deleteError: "",
};

const governateReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_GOVERNATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_GOVERNATE_SUCCESS:
      return {
        loading: false,
        governates: action.payload,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_GOVERNATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };

    case ADD_GOVERNATE_REQUEST:
      return {
        ...state,
        addLoading: true,
        addError: "",
      };
    case ADD_GOVERNATE_SUCCESS:
      return {
        ...state,
        addLoading: false,
        addError: ""
      };
    case ADD_GOVERNATE_FAIL:
      return {
        ...state,
        addLoading: false,
        addError: action.payload,
      };

    case EDIT_GOVERNATE_REQUEST:
      return {
        ...state,
        editLoading: true,
        editError: "",
      };
    case EDIT_GOVERNATE_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editError: ""
      };
    case EDIT_GOVERNATE_FAIL:
      return {
        ...state,
        editLoading: false,
        editError: action.payload,
      };

    case DELETE_GOVERNATE_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: "",
      };
    case DELETE_GOVERNATE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: ""
      };
    case DELETE_GOVERNATE_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.payload,
      };

    case CLEAR:
      return {
        ...state,
        loading: false,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    default:
      return state;
  }
};

export default governateReducer;
