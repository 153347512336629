export const FETCH_OPPORTUNITY_REQUEST = "FETCH_OPPORTUNITY_REQUEST";
export const FETCH_OPPORTUNITY_SUCCESS = "FETCH_OPPORTUNITY_SUCCESS";
export const FETCH_OPPORTUNITY_FAIL = "FETCH_OPPORTUNITY_FAIL";

export const FETCH_ONE_OPPORTUNITY_REQUEST = "FETCH_ONE_OPPORTUNITY_REQUEST";
export const FETCH_ONE_OPPORTUNITY_SUCCESS = "FETCH_ONE_OPPORTUNITY_SUCCESS";
export const FETCH_ONE_OPPORTUNITY_FAIL = "FETCH_ONE_OPPORTUNITY_FAIL";

export const EDIT_OPPORTUNITY_REQUEST = "ADD_OPPORTUNITY_REQUEST";
export const EDIT_OPPORTUNITY_SUCCESS = "ADD_OPPORTUNITY_SUCESS";
export const EDIT_OPPORTUNITY_FAIL = "ADD_OPPORTUNITY_FAIL";


export const CLEAR = "CLEAR";
