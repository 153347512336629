import React ,{useEffect,useState}from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import {
  clear,
  updateDisability,
} from "../../redux/disability/disabilityActions";

//component
import Loading from "../layout/Loading";

//css
import "./Disability.css";

function DisabilityEditModal({
  id,
  text,
  text_ar,
  description,
  description_ar,
  setText,
  setText_ar,
  setDescription,
  setDescription_ar,
}) {
  const dispatch = useDispatch();
  const { editLoading } = useSelector((state) => state.disabilityReducer);
  const [buttonState, setButtonState] = useState(true);
  const resetForm = () => {
    setText("");
    setText_ar("");
    setDescription("");
    setDescription_ar("");
    dispatch(clear());
  };
  useEffect(() => {
    if (text !== "" && text_ar !== "" && description !== "" && description_ar !== "" ) setButtonState(false);
    else setButtonState(true);
  }, [text, text_ar,description, description_ar]);
  const editDisabilityHandle = (e) => {
    e.preventDefault();
    dispatch(updateDisability(id, text, text_ar, description, description_ar));
    setText("");
    setText_ar("");
    setDescription("");
    setDescription_ar("");
  };

  return (
    <div className="modal fade" id="update-disability-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Edit Disability</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={resetForm}
            >
              &times;
            </button>
          </div>
          {editLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form onSubmit={editDisabilityHandle}>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Disability :</span>
                    <input
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      placeholder="Enter disability's name"
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">
                      Arabic Disability :
                    </span>
                    <input
                      value={text_ar}
                      onChange={(e) => setText_ar(e.target.value)}
                      placeholder="Enter disability's name in Arabic"
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Description :</span>
                    <input
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Enter disability's description"
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">
                      Arabic Description :
                    </span>
                    <input
                      value={description_ar}
                      onChange={(e) => setDescription_ar(e.target.value)}
                      placeholder="Enter disability's description in Arabic"
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={resetForm}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      onClick={editDisabilityHandle}
                      disabled={buttonState ? true : false}
                    >
                      Edit
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default DisabilityEditModal;
