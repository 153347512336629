import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import {
  addEmailSubscription,
  clear,
} from "../../redux/email_subscription/emailActions";

//components
import Loading from "../layout/Loading";

//css
import "./EmailSubscription.css";

function EmailAddModal() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const { addLoading } = useSelector((state) => state.emailReducer);
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonState, setButtonState] = useState(true);
  const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const resetForm = () => {
    setEmail("");
    dispatch(clear());
  };

  useEffect(() => {
    if (email !== "") {
      if (!emailRegEx.test(email)) {
        setButtonState(true);
        setErrorMessage("This Email is not valid !");
      } else {
        setButtonState(false);
        setErrorMessage("");
      }
    } else {
      setButtonState(true);
      setErrorMessage("");
    }
  }, [email, emailRegEx]);

  const addEmailSubscriptionHandle = (e) => {
    e.preventDefault();
    dispatch(addEmailSubscription(email));
    setEmail("");
  };

  return (
    <div>
      <div className="modal fade" id="add-email-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add Email</h4>
              <button
                type="button"
                className="btn close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={addEmailSubscriptionHandle}>
                  <div className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-2 textForm">Email :</span>
                      <input
                        type="text"
                        className="  inputt col-md-9 form-controls"
                        value={email}
                        placeholder="Enter your email"
                        required
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    {errorMessage && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "center",
                          paddingTop: "10px",
                        }}
                      >
                        {errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      disabled={buttonState ? true : false}
                      onClick={addEmailSubscriptionHandle}
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailAddModal;
