import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import {
  addVolunteerSpotlights,
  clear,
} from "../../redux/volunteerSpotlights/volunteerSpotlightsActions";

//components
import Loading from "../layout/Loading";

//css
import "./VolunteerSpotlights.css";

function VolunteerSpotlightsAddModal() {
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const { addLoading } = useSelector(
    (state) => state.volunteerSpotlightsReducer
  );
  const [buttonState, setButtonState] = useState(true);
  const [newImgSource, setNewImgSource] = useState("");
  const [imgName, setImgName] = useState("default.png");
  const defaultSource =
    process.env.REACT_APP_API + "/VolunteerSpotlight/default.png";
  const re = /(?:\.([^.]+))?$/;

  const resetForm = () => {
    setImage("");
    setTitle("");
    setDescription("");
    setNewImgSource(defaultSource);
    setImgName("default.png");
    dispatch(clear());
  };

  const addVolunteerSpotlightsHandle = (e) => {
    e.preventDefault();
    dispatch(addVolunteerSpotlights(image, title, description));
    setImage();
    setTitle("");
    setDescription("");
    setNewImgSource(defaultSource);
    setImgName("default.png");
  };

  const removeImg = (e) => {
    e.preventDefault();
    setNewImgSource(defaultSource);
    setImgName("default.png");
    setImage("");
    document.getElementById("files").value = null;
  };

  const changeImgName = (Name) => {
    setImgName(Name);
  };

  useEffect(() => {
    if (description !== "" && title !== "") setButtonState(false);
    else setButtonState(true);
  }, [description, title]);

  return (
    <div>
      <div
        className="modal fade"
        id="add-volunteer_spotlights-modal"
        data-reset="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add volunteer spotlights</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form
                  onSubmit={addVolunteerSpotlightsHandle}
                  encType="multipart/form-data"
                >
                  <div className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-3 textForm2">Image :</span>

                      <div className="row">
                        <div className="row">
                          <img
                            src={
                              newImgSource !== "" ? newImgSource : defaultSource
                            }
                            style={{
                              height: "130px",
                              width: "130px",
                              marginLeft: "40px",
                            }}
                            alt={image}
                          />
                          {imgName !== "default.png" && (
                            <button className="tag-remove" onClick={removeImg}>
                              &#10006;
                            </button>
                          )}
                        </div>

                        <div className="row">
                          <label
                            htmlFor="files"
                            className="button"
                            style={{
                              marginLeft: "50px",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            {imgName !== "default.png"
                              ? "Change Image"
                              : "Choose Image"}
                          </label>
                          <input
                            id="files"
                            type="file"
                            className="inputt"
                            style={{ visibility: "hidden", width: "1px" }}
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                setImage(e.target.files[0]);
                                if (e.target.files[0].name.length > 15)
                                  changeImgName(
                                    e.target.files[0].name.substring(0, 15) +
                                      "...." +
                                      re.exec(e.target.files[0].name)[1]
                                  );
                                else changeImgName(e.target.files[0].name);
                                setNewImgSource(
                                  URL.createObjectURL(e.target.files[0])
                                );
                              }
                            }}
                          />

                          <h6
                            style={{
                              marginLeft: "15px",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            {imgName !== "default.png" ? imgName : ""}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm2">Title :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        placeholder="Enter the volunteer spotlights' title"
                        value={title}
                        required
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm2">Description :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        placeholder="Enter the volunteer spotlights' description"
                        value={description}
                        required
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                  </div>
                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addVolunteerSpotlightsHandle}
                      type="submit"
                      disabled={buttonState ? true : false}
                      className="add-btn"
                      data-dismiss="modal"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VolunteerSpotlightsAddModal;
