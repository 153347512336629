import React from "react";
import DashboardCardStat from "../DashboardCardStat";
import { MdTimer } from 'react-icons/md';

function completedVolHours(props) {
  return (
    <>
    <DashboardCardStat
      title={props.completedVolHours}
      description="Number of completed Volunteer hours"
      link="/volunteers"
      icon={<div className="circle-container"><MdTimer  size={30} color="gray"/></div>}
      iconColor="iconc  fa-2x"
    /></>
  );
}

export default completedVolHours;
