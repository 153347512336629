import axios from "axios";

//redux
import {
  FETCH_VOLUNTEER_SPOTLIGHTS_QA_REQUEST,
  FETCH_VOLUNTEER_SPOTLIGHTS_QA_SUCCESS,
  FETCH_VOLUNTEER_SPOTLIGHTS_QA_FAIL,
  ADD_VOLUNTEER_SPOTLIGHTS_QA_REQUEST,
  ADD_VOLUNTEER_SPOTLIGHTS_QA_SUCCESS,
  ADD_VOLUNTEER_SPOTLIGHTS_QA_FAIL,
  EDIT_VOLUNTEER_SPOTLIGHTS_QA_REQUEST,
  EDIT_VOLUNTEER_SPOTLIGHTS_QA_SUCCESS,
  EDIT_VOLUNTEER_SPOTLIGHTS_QA_FAIL,
  DELETE_VOLUNTEER_SPOTLIGHTS_QA_REQUEST,
  DELETE_VOLUNTEER_SPOTLIGHTS_QA_SUCCESS,
  DELETE_VOLUNTEER_SPOTLIGHTS_QA_FAIL,
  CLEAR,
} from "./volunteerSpotlightsQATypes";

export const fetchRequest = () => {
  return {
    type: FETCH_VOLUNTEER_SPOTLIGHTS_QA_REQUEST,
  };
};

export const fetchSuccess = (volunteerSpotlightsQA) => {
  return {
    type: FETCH_VOLUNTEER_SPOTLIGHTS_QA_SUCCESS,
    payload: volunteerSpotlightsQA,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_VOLUNTEER_SPOTLIGHTS_QA_FAIL,
    payload: error,
  };
};

export const addVolunteerSpotlightsQARequest = () => {
  return {
    type: ADD_VOLUNTEER_SPOTLIGHTS_QA_REQUEST,
  };
};

export const addVolunteerSpotlightsQASuccess = () => {
  return {
    type: ADD_VOLUNTEER_SPOTLIGHTS_QA_SUCCESS,
  };
};

export const addVolunteerSpotlightsQAFail = (error) => {
  return {
    type: ADD_VOLUNTEER_SPOTLIGHTS_QA_FAIL,
    payload: error,
  };
};

export const editVolunteerSpotlightsQARequest = () => {
  return {
    type: EDIT_VOLUNTEER_SPOTLIGHTS_QA_REQUEST,
  };
};

export const editVolunteerSpotlightsQASuccess = () => {
  return {
    type: EDIT_VOLUNTEER_SPOTLIGHTS_QA_SUCCESS,
  };
};

export const editVolunteerSpotlightsQAFail = (error) => {
  return {
    type: EDIT_VOLUNTEER_SPOTLIGHTS_QA_FAIL,
    payload: error,
  };
};

export const deleteVolunteerSpotlightsQARequest = () => {
  return {
    type: DELETE_VOLUNTEER_SPOTLIGHTS_QA_REQUEST,
  };
};

export const deleteVolunteerSpotlightsQASuccess = () => {
  return {
    type: DELETE_VOLUNTEER_SPOTLIGHTS_QA_SUCCESS,
  };
};

export const deleteVolunteerSpotlightsQAFail = (error) => {
  return {
    type: DELETE_VOLUNTEER_SPOTLIGHTS_QA_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getVolunteerSpotlightsQA = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/volspotlightqa";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteVolunteerSpotlightsQA = (id) => async (dispatch) => {
  dispatch(deleteVolunteerSpotlightsQARequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/volspotlightqa/" + id);
    dispatch(deleteVolunteerSpotlightsQASuccess());
    dispatch(getVolunteerSpotlightsQA());
  } catch (e) {
    dispatch(deleteVolunteerSpotlightsQAFail(e.response.data));
  }
};

export const addVolunteerSpotlightsQA =
  (volunteer_spotlight_id, question, answer) => async (dispatch) => {
    dispatch(addVolunteerSpotlightsQARequest());
    try {
      await axios.post(process.env.REACT_APP_API + "/volspotlightqa", {
        volunteer_spotlight_id,
        question,
        answer,
      });
      dispatch(addVolunteerSpotlightsQASuccess());
      dispatch(getVolunteerSpotlightsQA());
    } catch (e) {
      dispatch(addVolunteerSpotlightsQAFail(e.response.data));
    }
  };

export const updateVolunteerSpotlightsQA =
  (id, volunteer_spotlights_id, question, answer) => async (dispatch) => {
    dispatch(editVolunteerSpotlightsQARequest());

    try {
      await axios.put(process.env.REACT_APP_API + "/volspotlightqa/" + id, {
        volunteer_spotlights_id,
        question,
        answer,
      });
      dispatch(editVolunteerSpotlightsQASuccess());
      dispatch(getVolunteerSpotlightsQA());
    } catch (e) {
      dispatch(editVolunteerSpotlightsQAFail(e.response.data));
    }
  };

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
