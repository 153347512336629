import {
  FETCH_EMAIL_REQUEST,
  FETCH_EMAIL_SUCCESS,
  FETCH_EMAIL_FAIL,
  ADD_EMAIL_REQUEST,
  ADD_EMAIL_SUCCESS,
  ADD_EMAIL_FAIL,
  DELETE_EMAIL_REQUEST,
  DELETE_EMAIL_SUCCESS,
  DELETE_EMAIL_FAIL,
  CLEAR,
} from "./emailTypes";

export const initialState = {
  loading: false,
  emails: [],
  error: "",
  addLoading: false,
  addError: "",
  deleteLoading: false,
  deleteError: "",
};

const emailReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        addLoading: false,
        addError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_EMAIL_SUCCESS:
      return {
        loading: false,
        emails: action.payload,
        error: "",
        addLoading: false,
        addError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        addLoading: false,
        addError: "",
        deleteLoading: false,
        deleteError: "",
      };

    case ADD_EMAIL_REQUEST:
      return {
        ...state,
        addLoading: true,
        addError: "",
      };
    case ADD_EMAIL_SUCCESS:
      return {
        ...state,
        addLoading: false,
        addError: "",
      };
    case ADD_EMAIL_FAIL:
      return {
        ...state,
        addLoading: false,
        addError: action.payload,
      };

    case DELETE_EMAIL_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: "",
      };
    case DELETE_EMAIL_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: "",
      };
    case DELETE_EMAIL_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.payload,
      };

    case CLEAR:
      return {
        ...state,
        loading: false,
        error: "",
        addLoading: false,
        addError: "",
        deleteLoading: false,
        deleteError: "",
      };
    default:
      return state;
  }
};

export default emailReducer;
