import React,{useEffect,useState} from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import { clear, updateQuiz } from "../../redux/quiz/quizAction";

//component
import Loading from "../layout/Loading";

//css

function QuizEdit({
  id,
  question,
  question_ar,
  type,
  setQuestion,
  setQuestion_ar,
  setType,
}) {
  const dispatch = useDispatch();
  const { editLoading } = useSelector((state) => state.reasonsReducer);
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if (question !== "" &&question_ar !== "" && type !== "") setButtonState(false);
    else setButtonState(true);
  }, [question,question_ar ,type]);
  const resetForm = () => {
    setQuestion("");
    setQuestion_ar("");
    setType("");
    dispatch(clear());
  };

  const editQuizHandle = (e) => {
    e.preventDefault();
    dispatch(updateQuiz(id, question, question_ar, type));
    setQuestion("");
    setQuestion_ar("");
    setType("");
  };

  return (
    <div className="modal fade" id="update-quiz-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Edit Quiz</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={resetForm}
            >
              &times;
            </button>
          </div>
          {editLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form onSubmit={editQuizHandle}>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Question :</span>
                    <input
                      value={question}
                      placeholder="Enter the question"
                      onChange={(e) => setQuestion(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Arabic Question :</span>
                    <input
                      value={question_ar}
                      onChange={(e) => setQuestion_ar(e.target.value)}
                      placeholder="Enter the question in Arabic"
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Type :</span>
                    <select
                      className="  inputt col-md-8 form-controls"
                      value={type}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      required
                    >
                      <option value="" disabled>
                        Please select Type
                      </option>
                      <option value="organization">Organization</option>
                      <option value="location">Location</option>
                      <option value="impact">Impact</option>
                      <option value="sector">Sector</option>
                      <option value="skill">Skill</option>
                    </select>{" "}
                  </div>
                  <br></br>
                  <div className="modal-footer">
                    <button
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={resetForm}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      onClick={editQuizHandle}
                      disabled={buttonState ? true : false}
                    >
                      Edit
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuizEdit;
