//redux
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_LOADING,
  LOGOUT,
  VERIFY,
  STATISTICS_SUCCESS ,
  STATISTICS_FAIL,
  STATISTICS_LOADING
} from "./authTypes";

const initialState = {
  isAuthenticated: false,
  loading: false,
  error: "",
  statistics: [],
  loadingStatistics: false,
  errorStatistics: ""
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: action.payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        error: "",
      };
    case VERIFY:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: "",
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: "",
      };

      case STATISTICS_LOADING:
        return {
          ...state,
          loadingStatistics: true,
        };
      case STATISTICS_FAIL:
        return {
          ...state,
          statistics:[],
          loadingStatistics: false,
          errorStatistics: action.payload,
        };
      case STATISTICS_SUCCESS:
        return {
          ...state,
          statistics: action.payload,
          loadingStatistics: false,
          errorStatistics: "",
        };

    default:
      return state;
  }
}
