import axios from "axios";

//redux
import {
  FETCH_NATIONALITY_REQUEST,
  FETCH_NATIONALITY_SUCCESS,
  FETCH_NATIONALITY_FAIL,

  ADD_NATIONALITY_REQUEST,
  ADD_NATIONALITY_SUCCESS,
  ADD_NATIONALITY_FAIL,

  EDIT_NATIONALITY_REQUEST,
  EDIT_NATIONALITY_SUCCESS,
  EDIT_NATIONALITY_FAIL,

  DELETE_NATIONALITY_REQUEST,
  DELETE_NATIONALITY_SUCCESS,
  DELETE_NATIONALITY_FAIL,

  CLEAR
} from "./nationalityTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_NATIONALITY_REQUEST,
  };
};

export const fetchSuccess = (nationalities) => {
  return {
    type: FETCH_NATIONALITY_SUCCESS,
    payload: nationalities,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_NATIONALITY_FAIL,
    payload: error,
  };
};

export const addNationalityRequest = () => {
  return {
    type: ADD_NATIONALITY_REQUEST,
  };
};

export const addNationalitySuccess = () => {
  return {
    type: ADD_NATIONALITY_SUCCESS,
  };
};

export const addNationalityFail = (error) => {
  return {
    type: ADD_NATIONALITY_FAIL,
    payload: error,
  };
};

export const editNationalityRequest = () => {
  return {
    type: EDIT_NATIONALITY_REQUEST,
  };
};

export const editNationalitySuccess = () => {
  return {
    type: EDIT_NATIONALITY_SUCCESS,
  };
};

export const editNationalityFail = (error) => {
  return {
    type: EDIT_NATIONALITY_FAIL,
    payload: error,
  };
};

export const deleteNationalityRequest = () => {
  return {
    type: DELETE_NATIONALITY_REQUEST,
  };
};

export const deleteNationalitySuccess = () => {
  return {
    type: DELETE_NATIONALITY_SUCCESS,
  };
};

export const deleteNationalityFail = (error) => {
  return {
    type: DELETE_NATIONALITY_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getNationalities = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/nationalities";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteNationality = (id) => async (dispatch) => {
  dispatch(deleteNationalityRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/nationalities/" + id);
    dispatch(deleteNationalitySuccess());
    dispatch(getNationalities());
  } catch (e) {
    dispatch(deleteNationalityFail(e.response.data));
  }
};

export const addNationality = (name, name_ar) => async (dispatch) => {
  dispatch(addNationalityRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/nationalities", {
      name,
      name_ar,
    });
    dispatch(addNationalitySuccess());
    dispatch(getNationalities());
  } catch (e) {
    dispatch(addNationalityFail(e.response.data));
  }
};

export const updateNationality = (id, name, name_ar) => async (dispatch) => {
  dispatch(editNationalityRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/nationalities/" + id, {
      name,
      name_ar,
    });
    dispatch(editNationalitySuccess());
    dispatch(getNationalities());
  } catch (e) {
    dispatch(editNationalityFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
