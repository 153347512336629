export const FETCH_INSTITUTIONS_REQUEST = "FETCH_INSTITUTIONS_REQUEST";
export const FETCH_INSTITUTIONS_SUCCESS = "FETCH_INSTITUTIONS_SUCCESS";
export const FETCH_INSTITUTIONS_FAIL = "FETCH_INSTITUTIONS_FAIL";

export const ADD_INSTITUTIONS_REQUEST = "ADD_INSTITUTIONS_REQUEST";
export const ADD_INSTITUTIONS_SUCCESS = "ADD_INSTITUTIONS_SUCESS";
export const ADD_INSTITUTIONS_FAIL = "ADD_INSTITUTIONS_FAIL";

export const EDIT_INSTITUTIONS_REQUEST = "EDIT_INSTITUTIONS_REQUEST";
export const EDIT_INSTITUTIONS_SUCCESS = "EDIT_INSTITUTIONS_SUCESS";
export const EDIT_INSTITUTIONS_FAIL = "EDIT_INSTITUTIONS_FAIL";

export const DELETE_INSTITUTIONS_REQUEST = "DELETE_INSTITUTIONS_REQUEST";
export const DELETE_INSTITUTIONS_SUCCESS = "DELETE_INSTITUTIONS_SUCESS";
export const DELETE_INSTITUTIONS_FAIL = "DELETE_INSTITUTIONS_FAIL";

export const CLEAR = "CLEAR";