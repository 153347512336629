import axios from "axios";

//redux
import {
    FETCH_TRUSTEDPART_REQUEST,
    FETCH_TRUSTEDPART_SUCCESS,
    FETCH_TRUSTEDPART_FAIL,

    ADD_TRUSTEDPART_REQUEST,
    ADD_TRUSTEDPART_SUCCESS,
    ADD_TRUSTEDPART_FAIL,

    EDIT_TRUSTEDPART_REQUEST,
    EDIT_TRUSTEDPART_SUCCESS,
    EDIT_TRUSTEDPART_FAIL,

    DELETE_TRUSTEDPART_REQUEST,
    DELETE_TRUSTEDPART_SUCCESS,
    DELETE_TRUSTEDPART_FAIL,

    CLEAR
} from "./TrustedPartTypes";

export const fetchRequest = () => {
    return {
        type: FETCH_TRUSTEDPART_REQUEST,
    };
};

export const fetchSuccess = (trustedpart) => {
    return {
        type: FETCH_TRUSTEDPART_SUCCESS,
        payload: trustedpart,
    };
};

export const fetchFail = (error) => {
    return {
        type: FETCH_TRUSTEDPART_FAIL,
        payload: error,
    };
};

export const addTRUSTEDPARTRequest = () => {
    return {
        type: ADD_TRUSTEDPART_REQUEST,
    };
};

export const addTRUSTEDPARTSuccess = () => {
    return {
        type: ADD_TRUSTEDPART_SUCCESS,
    };
};

export const addTRUSTEDPARTFail = (error) => {
    return {
        type: ADD_TRUSTEDPART_FAIL,
        payload: error,
    };
};

export const editTRUSTEDPARTRequest = () => {
    return {
        type: EDIT_TRUSTEDPART_REQUEST,
    };
};

export const editTRUSTEDPARTSuccess = () => {
    return {
        type: EDIT_TRUSTEDPART_SUCCESS,
    };
};

export const editTRUSTEDPARTFail = (error) => {
    return {
        type: EDIT_TRUSTEDPART_FAIL,
        payload: error,
    };
};

export const deleteTRUSTEDPARTRequest = () => {
    return {
        type: DELETE_TRUSTEDPART_REQUEST,
    };
};

export const deleteTRUSTEDPARTSuccess = () => {
    return {
        type: DELETE_TRUSTEDPART_SUCCESS,
    };
};

export const deleteTRUSTEDPARTFail = (error) => {
    return {
        type: DELETE_TRUSTEDPART_FAIL,
        payload: error,
    };
};

export const clearSuccess = () => {
    return {
        type: CLEAR,
    };
};


export const getTRUSTEDPARTs = () => async (dispatch) => {
    dispatch(fetchRequest());
    try {
        let url = process.env.REACT_APP_API + "/trusted_partners";
        const response = await axios.get(url);
        dispatch(fetchSuccess(response.data));
        
    } catch (e) {
        dispatch(fetchFail(e.response.data));
       
    }
};

export const deleteTRUSTEDPART = (id) => async (dispatch) => {
    dispatch(deleteTRUSTEDPARTRequest());
    try {
        await axios.delete(process.env.REACT_APP_API + "/trusted_partners/" + id);
        dispatch(deleteTRUSTEDPARTSuccess());
        dispatch(getTRUSTEDPARTs());
    } catch (e) {
        dispatch(deleteTRUSTEDPARTFail(e.response.data));
    }
};

export const addTRUSTEDPART = ( image, section, section_ar, name, name_ar, description, description_ar, description_title, description_title_ar) => async (dispatch) => {
    dispatch(addTRUSTEDPARTRequest());
    var formData = new FormData();
 if (image) formData.append("img_url", image);
    try {
        const res = await axios.post(process.env.REACT_APP_API + "/trusted_partners", {
           
            section,
            section_ar,
            name,
            name_ar,
            description,
            description_ar,
            description_title,
            description_title_ar,
        });
        await axios.post(process.env.REACT_APP_API + "/trusted_partners/assignUrlImg/" + res?.data?.data?.id, formData)
        dispatch(addTRUSTEDPARTSuccess());
        dispatch(getTRUSTEDPARTs());
    } catch (e) {
        dispatch(addTRUSTEDPARTFail(e.response.data));
    }
};

export const updateTRUSTEDPART = (id, image,section, section_ar, name, name_ar, description, description_ar, description_title, description_title_ar) => async (dispatch) => {
    dispatch(editTRUSTEDPARTRequest());
    var formData = new FormData();
    if (image) formData.append("img_url", image);
    try {
        await axios.put(process.env.REACT_APP_API + "/trusted_partners/" + id, {
        
            section,
            section_ar,
            name,
            name_ar,
            description,
            description_ar,
            description_title,
            description_title_ar,
        });
        await axios.post(process.env.REACT_APP_API + "/trusted_partners/assignUrlImg/" + id, formData)
        dispatch(editTRUSTEDPARTSuccess());
        dispatch(getTRUSTEDPARTs());
    } catch (e) {
        dispatch(editTRUSTEDPARTFail(e.response.data));
    }
};

export const clear = () => (dispatch) => {
    dispatch(clearSuccess());
};