import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import { addDisability, clear } from "../../redux/disability/disabilityActions";

//components
import Loading from "../layout/Loading";

//css
import "./Disability.css";

function DisabilityAddModal() {
  const dispatch = useDispatch();

  const [text, setText] = useState("");
  const [text_ar, setText_ar] = useState("");
  const [description, setDescription] = useState("");
  const [description_ar, setDescription_ar] = useState("");
  const { addLoading } = useSelector((state) => state.disabilityReducer);
  const [buttonState, setButtonState] = useState(true);
  const resetForm = () => {
    setText("");
    setText_ar("");
    setDescription("");
    setDescription_ar("");
    dispatch(clear());
  };

  const addDisabilityHandle = (e) => {
    e.preventDefault();
    dispatch(addDisability(text, text_ar, description, description_ar));
    setText("");
    setText_ar("");
    setDescription("");
    setDescription_ar("");
    setText_ar("");
  };
  useEffect(() => {
    if (text !== "" && text_ar !== "" && description !== "" && description_ar !== "" ) setButtonState(false);
    else setButtonState(true);
  }, [text, text_ar,description, description_ar]);
  return (
    <div>
      <div className="modal fade" id="add-disability-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add Disability</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={addDisabilityHandle}>
                  <div className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Disability :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={text}
                        placeholder="Enter disability's name"
                        required
                        onChange={(e) => {
                          setText(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm">
                        Arabic Disability :
                      </span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={text_ar}
                        placeholder="Enter disability's name in Arabic"
                        required
                        onChange={(e) => {
                          setText_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Description :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={description}
                        placeholder="Enter disability's description"
                        required
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">
                        Arabic Description :
                      </span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={description_ar}
                        placeholder="Enter disability's description in Arabic"
                        required
                        onChange={(e) => {
                          setDescription_ar(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addDisabilityHandle}
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      disabled={buttonState ? true : false}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisabilityAddModal;
