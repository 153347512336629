import React from "react";
import DashboardCardStat from "../DashboardCardStat";
import { VscOrganization } from 'react-icons/vsc';

function numberOfOrg(props) {
  return (
    <>
    <DashboardCardStat
      title={props.total_organizations}
      description="Number of organizations"
      link="/organizations"
      icon={<div className="circle-container">  <div className="centered-icon"><VscOrganization size={100} color="gray" /></div></div>}
      iconColor="iconc  fa-2x"
    /></>
  );
}

export default numberOfOrg;
