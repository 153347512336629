export const FETCH_IMPACT_REQUEST = "FETCH_IMPACT_REQUEST";
export const FETCH_IMPACT_SUCCESS = "FETCH_IMPACT_SUCCESS";
export const FETCH_IMPACT_FAIL = "FETCH_IMPACT_FAIL";

export const ADD_IMPACT_REQUEST = "ADD_IMPACT_REQUEST";
export const ADD_IMPACT_SUCCESS = "ADD_IMPACT_SUCESS";
export const ADD_IMPACT_FAIL = "ADD_IMPACT_FAIL";

export const EDIT_IMPACT_REQUEST = "EDIT_IMPACT_REQUEST";
export const EDIT_IMPACT_SUCCESS = "EDIT_IMPACT_SUCESS";
export const EDIT_IMPACT_FAIL = "EDIT_IMPACT_FAIL";

export const DELETE_IMPACT_REQUEST = "DELETE_IMPACT_REQUEST";
export const DELETE_IMPACT_SUCCESS = "DELETE_IMPACT_SUCESS";
export const DELETE_IMPACT_FAIL = "DELETE_IMPACT_FAIL";

export const CLEAR = "CLEAR";
