import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Navbar from "./../layout/Navbar";
//components
import Sidebar from "../layout/Sidebar";
import NumberOfOrg from "./dashboardCards/numberOfOrg";
import JobPlacements from "./dashboardCards/jobPlacements";
import LivesTouched from "./dashboardCards/livesTouched";
import NumberOfVolunteers from "./dashboardCards/numberOfVolunteers";
import FirstTimeVolunteers from "./dashboardCards/firstTimeVolunteers";
import TotalVolunteerMatches from "./dashboardCards/totalVolunteerMatches";
import CompletedVolHours from "./dashboardCards/completedVolHours";
import { getStatistics } from "../../redux/auth/authActions";
import OrganizationsTypes from "./dashboardCards/organizationsTypes";
import CountryOfRegistration from "./dashboardCards/countryOfRegistration";
import VolunteersNationalities from "./dashboardCards/volunteersNationalities";
import VolunteersDistrict from "./dashboardCards/volunteersDistrict";
import VolunteersExpertise from "./dashboardCards/volunteersExpertise";
import CostsSaved from "./dashboardCards/costsSaved";
import VolunteersSkill from "./dashboardCards/volunteersSkill";
import VolunteersOpportunitiesPosted from "./dashboardCards/volunteersOpportunitiesPosted";
//utils
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import isAuth from "../../utils/isAuth";
import "./dashboard.css";

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [statisticsData, setStatisticsData] = useState([]);
  const [googleChartData, setGoogleChartData] = useState([]);
  const [countryOfRegistrationData, setCountryOfRegistrationData] = useState(
    []
  );
  const [volunteersNationalitiesData, setVolunteersNationalitiesData] =
    useState([]);
  const [volunteersDistrictData, setVolunteersDistrictData] = useState([]);
  const [volunteersExpertiseData, setVolunteersExpertiseData] = useState([]);
  const [costSavedData, setCostSavedData] = useState([]);
  const [volunteersSkillData, setVolunteersSkillData] = useState([]);
  const [
    volunteersOpportunitiesPostedData,
    setVolunteersOpportunitiesPostedData,
  ] = useState([]);
  const [dash, setDash] = useState(
    localStorage.getItem("dashboardExpanded") === "true"
  );
  useEffect(() => {
    localStorage.setItem("dashboardExpanded", dash);
  }, [dash]);

  const { statistics } = useSelector((state) => state.authReducer);

  useEffect(() => {
    dispatch(getStatistics());
  }, [dispatch]);

  useEffect(() => {
    if (statistics) {
      setStatisticsData(statistics);
      if (statistics?.organization_type != undefined) {
        const googleChartTableData = [
          ["Type", "Count"],
          ...statistics?.organization_type?.map((item) => [
            item.type,
            item.count,
          ]),
        ];
        setGoogleChartData(googleChartTableData);
      }
      if (statistics?.total_orgs_country_of_registration != undefined) {
        const countryOfRegistration = [
          ["country_of_registration", "count"],
          ...statistics?.total_orgs_country_of_registration?.map((item) => [
            item.country?.name,
            item.count,
          ]),
        ];
        setCountryOfRegistrationData(countryOfRegistration);
      }

      if (statistics?.total_volunteers_nationalities != undefined) {
        const volunteersNationalities = [
          ["country_of_registration", "count"],
          ...statistics?.total_volunteers_nationalities?.map((item) => [
            item.nationality?.name,
            item.count,
          ]),
        ];
        setVolunteersNationalitiesData(volunteersNationalities);
      }

      if (statistics?.total_volunteers_district != undefined) {
        const volunteersDistrict = [
          ["district_id", "count"],
          ...statistics?.total_volunteers_district?.map((item) => [
            item.district?.name,
            item.count,
          ]),
        ];
        setVolunteersDistrictData(volunteersDistrict);
      }

      if (statistics?.total_volunteers_expertise != undefined) {
        const volunteersExpertise = [
          ["expertise_id", "count"],
          ...statistics?.total_volunteers_expertise?.map((item) => [
            item.skill?.name,
            item.count,
          ]),
        ];
        setVolunteersExpertiseData(volunteersExpertise);
      }

      if (statistics?.total_cost_per_12_month != undefined) {
        const CostSaved = [
          ...statistics?.total_cost_per_12_month?.map((item) => ({
            year: item.year,
            month: item.month,
            totalCost: Number(item.total_cost),
          })),
        ];
        setCostSavedData(CostSaved);
      }

      if (statistics?.total_volunteers_skill != undefined) {
        const VolunteersSkill = [
          ...statistics?.total_volunteers_skill?.map((item) => ({
            count: item.count,
            skill: item?.skill?.name,
          })),
        ];
        setVolunteersSkillData(VolunteersSkill);
      }

      if (statistics?.total_community_action_per_12_month != undefined) {
        const VolunteersOpportunitiesPosted = [
          ...statistics?.total_community_action_per_12_month?.map((item) => ({
            year: item.year,
            month: item.month,
            total_community_data: Number(item.total_community_data),
          })),
        ];
        setVolunteersOpportunitiesPostedData(VolunteersOpportunitiesPosted);
      }
    }
  }, [statistics]);

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  const ToggleMenu = (e) => {
    var element = document.getElementById("wrapper");
    if (element.classList.contains("toggled")) {
      document.getElementById("wrapper").classList.remove("toggled");
    } else {
      document.getElementById("wrapper").classList.add("toggled");
    }
  };

  return (
    <div className="all">
      <div className="d-flex" id="wrapper">
        <Sidebar />

        <div id="page-content-wrapper">
          <div className="container-fluid home">
            <br></br>
            <br></br>
            <div className="row px-2">
              <div class="charts-div total-width">
                <NumberOfOrg
                  total_organizations={statisticsData?.total_organizations}
                />
                <JobPlacements jobPlacements={statisticsData?.total_job} />
                <LivesTouched
                  livesTouched={statisticsData?.total_beneficiary}
                />
              </div>
              <div class="charts-div total-width">
                <NumberOfVolunteers
                  numberOfVolunteers={statisticsData?.total_volunteers}
                />
                <CostsSaved coastSaved={statisticsData?.total_cost} />

                <FirstTimeVolunteers
                  firstTimeVolunteers={
                    statisticsData?.total_first_time_volunteer
                  }
                />
              </div>
              <div class="charts-div">
                <CompletedVolHours
                  completedVolHours={
                    statisticsData?.total_number_of_completed_hours
                  }
                />
              </div>
              <div class="charts-div">
                {googleChartData && (
                  <OrganizationsTypes organizationsTypes={googleChartData} />
                )}

                {countryOfRegistrationData && (
                  <CountryOfRegistration
                    countryOfRegistration={countryOfRegistrationData}
                  />
                )}
                {volunteersDistrictData && (
                  <VolunteersDistrict
                    volunteersDistrict={volunteersDistrictData}
                  />
                )}
              </div>
              <div class="charts-div">
                {volunteersExpertiseData && (
                  <VolunteersExpertise
                    volunteersExpertise={volunteersExpertiseData}
                  />
                )}
                {volunteersNationalitiesData && (
                  <VolunteersNationalities
                    volunteersNationalities={volunteersNationalitiesData}
                  />
                )}

                <div className="col-lg-4 col-sm-4"></div>
              </div>
              <div class="charts-div">
                {volunteersSkillData && (
                  <VolunteersSkill volunteersSkill={volunteersSkillData} />
                )}
                {/* {costSavedData && <CostsSaved costSaved={costSavedData} />}

                {volunteersOpportunitiesPostedData && (
                  <VolunteersOpportunitiesPosted
                    volunteersOpportunitiesPosted={
                      volunteersOpportunitiesPostedData
                    }
                  />
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
