import React,{useEffect,useState} from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import { clear, updateMasterclass } from "../../redux/Masterclass/MasterclassAction";

//component
import Loading from "../layout/Loading";

//css
import "./Masterclass.css";

function MasterclassEditModal({ id, email,job,name,user_name, setEmail,setJob,setName,setUser_name }) {
  const dispatch = useDispatch();
  const { editLoading } = useSelector((state) => state.reasonsReducer);
  const [buttonState, setButtonState] = useState(true);
//   useEffect(() => {
//     if (name !== "" && name_ar !== "" ) setButtonState(false);
//     else setButtonState(true);
//   }, [name, name_ar]);
  const resetForm = () => {
    setName("");
    setEmail("");
    setJob("");
    setUser_name("");
    dispatch(clear());
  };

  const editMasterclassHandle = (e) => {
    e.preventDefault();
    dispatch(updateMasterclass(id, email,job,name,user_name,));
    setName("");
    setEmail("");
    setJob("");
    setUser_name("");
  };

  return (
    <div className="modal fade" id="update-master-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Edit Masterclass</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={resetForm}
            >
              &times;
            </button>
          </div>
          {editLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form onSubmit={editMasterclassHandle}>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Email :</span>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter the Email"
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Job :</span>
                    <input
                      value={job}
                      placeholder="Enter the Job"
                      onChange={(e) => setJob(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
              
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Name :</span>
                    <input
                      value={name}
                      placeholder="Enter the Name"
                      onChange={(e) => setName(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">User Name :</span>
                    <input
                      value={user_name}
                      onChange={(e) => setUser_name(e.target.value)}
                      placeholder="Enter the User Name"
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={resetForm}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      onClick={editMasterclassHandle}
                  
                    >
                      Edit
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MasterclassEditModal;
