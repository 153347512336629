import axios from "axios";

//redux
import {
  FETCH_SKILLS_REQUEST,
  FETCH_SKILLS_SUCCESS,
  FETCH_SKILLS_FAIL,
  FETCH_ONE_SKILLS_REQUEST,
  FETCH_ONE_SKILLS_SUCCESS,
  FETCH_ONE_SKILLS_FAIL,
  FETCH_ONE_EXPERTISES_REQUEST,
  FETCH_ONE_EXPERTISES_SUCCESS,
  FETCH_ONE_EXPERTISES_FAIL,
  ADD_SKILLS_REQUEST,
  ADD_SKILLS_SUCCESS,
  ADD_SKILLS_FAIL,
  EDIT_SKILLS_REQUEST,
  EDIT_SKILLS_SUCCESS,
  EDIT_SKILLS_FAIL,
  DELETE_SKILLS_REQUEST,
  DELETE_SKILLS_SUCCESS,
  DELETE_SKILLS_FAIL,
  CLEAR,
} from "./SkillsTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_SKILLS_REQUEST,
  };
};

export const fetchSuccess = (Skills) => {
  return {
    type: FETCH_SKILLS_SUCCESS,
    payload: Skills,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_SKILLS_FAIL,
    payload: error,
  };
};

export const fetchOneRequest = () => {
  return {
    type: FETCH_ONE_SKILLS_REQUEST,
  };
};

export const fetchOneSuccess = (volunteerSkills) => {
  return {
    type: FETCH_ONE_SKILLS_SUCCESS,
    payload: volunteerSkills,
  };
};

export const fetchOneFail = (error) => {
  return {
    type: FETCH_ONE_SKILLS_FAIL,
    payload: error,
  };
};

export const fetchOneExpertiseRequest = () => {
  return {
    type: FETCH_ONE_EXPERTISES_REQUEST,
  };
};

export const fetchOneExpertiseSuccess = (volunteerExpertises) => {
  return {
    type: FETCH_ONE_EXPERTISES_SUCCESS,
    payload: volunteerExpertises,
  };
};

export const fetchOneExpertiseFail = (error) => {
  return {
    type: FETCH_ONE_EXPERTISES_FAIL,
    payload: error,
  };
};

export const addSkillsRequest = () => {
  return {
    type: ADD_SKILLS_REQUEST,
  };
};

export const addSkillsSuccess = () => {
  return {
    type: ADD_SKILLS_SUCCESS,
  };
};

export const addSkillsFail = (error) => {
  return {
    type: ADD_SKILLS_FAIL,
    payload: error,
  };
};

export const editSkillsRequest = () => {
  return {
    type: EDIT_SKILLS_REQUEST,
  };
};

export const editSkillsSuccess = () => {
  return {
    type: EDIT_SKILLS_SUCCESS,
  };
};

export const editSkillsFail = (error) => {
  return {
    type: EDIT_SKILLS_FAIL,
    payload: error,
  };
};

export const deleteSkillsRequest = () => {
  return {
    type: DELETE_SKILLS_REQUEST,
  };
};

export const deleteSkillsSuccess = () => {
  return {
    type: DELETE_SKILLS_SUCCESS,
  };
};

export const deleteSkillsFail = (error) => {
  return {
    type: DELETE_SKILLS_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getSkills = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/skills";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const getVolunteerSkills = (id) => async (dispatch) => {
  dispatch(fetchOneRequest());
  try {
    let url = process.env.REACT_APP_API + "/volunteer_skills/" + id;
    const response = await axios.get(url);
    dispatch(fetchOneSuccess(response.data));
  } catch (e) {
    dispatch(fetchOneFail(e.response.data));
  }
};

export const getVolunteerExpertises = (id) => async (dispatch) => {
  dispatch(fetchOneExpertiseRequest());
  try {
    let url = process.env.REACT_APP_API + "/volunteer_expertises/" + id;
    const response = await axios.get(url);
    dispatch(fetchOneExpertiseSuccess(response.data));
  } catch (e) {
    dispatch(fetchOneExpertiseFail(e.response.data));
  }
};

export const deleteSkills = (id) => async (dispatch) => {
  dispatch(deleteSkillsRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/skills/" + id);
    dispatch(deleteSkillsSuccess());
    dispatch(getSkills());
  } catch (e) {
    dispatch(deleteSkillsFail(e.response.data));
  }
};

export const addSkills = (name, name_ar, type) => async (dispatch) => {
  dispatch(addSkillsRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/skills", {
      name,
      name_ar,
      type,
    });
    dispatch(addSkillsSuccess());
    dispatch(getSkills());
  } catch (e) {
    dispatch(addSkillsFail(e.response.data));
  }
};

export const getSkillsByIds = (skills) => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    const response = await axios.post(
      process.env.REACT_APP_API + "/skills/skills",
      {
        skills,
      }
    );

    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const updateSkills = (id, name, name_ar, type) => async (dispatch) => {
  dispatch(editSkillsRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/skills/" + id, {
      name,
      name_ar,
      type,
    });
    dispatch(editSkillsSuccess());
    dispatch(getSkills());
  } catch (e) {
    dispatch(editSkillsFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
