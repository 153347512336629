import axios from "axios";

//redux
import {
  FETCH_OPPORTUNITYFEEDBACK_SUCCESS,
  FETCH_OPPORTUNITYFEEDBACK_FAIL,
  FETCH_OPPORTUNITYFEEDBACK_REQUEST,
} from "./opportunityFeedbacksTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_OPPORTUNITYFEEDBACK_REQUEST,
  };
};

export const fetchSuccess = (data) => {
  return {
    type: FETCH_OPPORTUNITYFEEDBACK_SUCCESS,
    payload: data,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_OPPORTUNITYFEEDBACK_FAIL,
    payload: error,
  };
};

export const getOpportunityFeedbacks = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/opportunity_feedbacks";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};
