import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import { getVolunteerById } from "../../redux/volunteer/volunteerActions";
import { getVolunteerSkills } from "../../redux/Skills/SkillsAction";
import { getVolunteerExpertises } from "../../redux/Skills/SkillsAction";

//component
import Loading from "../layout/Loading";

//css
import "./Volunteer.css";

function VolunteerViewModal({ id }) {
  const dispatch = useDispatch();
  const { oneLoading, onevolenteer } = useSelector(
    (state) => state.volunteerReducer
  );
  const { volunteerSkills, volunteerExpertises } = useSelector(
    (state) => state.skillsReducer
  );
  const [myVolunteer, setMyVolunteer] = useState();

  useEffect(() => {
    if (onevolenteer?.data?.length) {
      let vol = {
        nationality_name: onevolenteer.data[0].nationality?.name,
        reason: onevolenteer.data[0].reason,
        district_name: onevolenteer.data[0].district?.name,
        date_of_birth: onevolenteer.data[0].date_of_birth,
        first_name: onevolenteer.data[0].first_name,
        last_name: onevolenteer.data[0].last_name,
        gender: onevolenteer.data[0].gender,
        experience: onevolenteer.data[0].experience,
        linked_in: onevolenteer.data[0].linked_in,
        occupation: onevolenteer.data[0].occupation,
        frequency: onevolenteer.data[0].frequency,
        phone_number: onevolenteer.data[0].phone_number,
        availability: onevolenteer.data[0].availability,
        mySkills: volunteerSkills?.data,
        myExpertise: volunteerExpertises?.data,
        any_date: onevolenteer.data[0].any_date,
        date_from: new Date(onevolenteer.data[0].date_from)
          .toISOString()
          .split("T")[0],
        date_to: new Date(onevolenteer.data[0].date_to)
          .toISOString()
          .split("T")[0],
        volunteered_before: onevolenteer.data[0].volunteered_before,
        comments: onevolenteer.data[0].comments,
        points: onevolenteer.data[0].points,
        hours: onevolenteer.data[0].hours,
        open_to_work: onevolenteer.data[0].open_to_work,
        institution: onevolenteer.data[0].institution,
        objective: onevolenteer.data[0].objective,
        country_id:onevolenteer.data[0].country_id?onevolenteer.data[0].country_id.name:"Lebanon",



      };

      if (vol.mySkills?.length > 0) {
        let skillsString = "";
        for (let i = 0; i < vol.mySkills.length; i++) {
          skillsString += vol.mySkills[i].skill.name;
          skillsString += "/";
        }
        vol.mySkills = skillsString.split("/");
      }
      setMyVolunteer(vol);

      if (vol.myExpertise?.length > 0) {
        let expertisesString = "";
        for (let i = 0; i < vol.myExpertise.length; i++) {
          expertisesString += vol.myExpertise[i].skill.name;
          expertisesString += "/";
        }
        vol.myExpertise = expertisesString.split("/");
      }
      setMyVolunteer(vol);
    }
  }, [onevolenteer, volunteerSkills, volunteerExpertises]);

  const showSkills = (ourSkills) => {
    let skillsString = "";
    for (let i = 0; i < ourSkills.length - 1; i++) {
      skillsString += "- ";
      skillsString += ourSkills[i];
      skillsString += "\n";
    }
    return skillsString;
  };

  useEffect(() => {
    if (id !== 0) {
      dispatch(getVolunteerById(id));
      dispatch(getVolunteerSkills(id));
      dispatch(getVolunteerExpertises(id));
    }
  }, [dispatch, id]);

  return (
    <div className="modal fade" id="view-volunteer-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Volunteer View More </h4>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          {oneLoading ? (
            <Loading />
          ) : (
            <>
           
              <div className="modal-body">
                <form>
                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        First Name
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.first_name !== null
                            ? myVolunteer?.first_name || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Last Name
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.last_name !== null
                            ? myVolunteer?.last_name || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "-20px" }}
                    >
                      Objective
                    </span>
                    <input
                      type="text"
                      value={
                        myVolunteer?.objective !== null
                          ? myVolunteer?.objective || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Nationality
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.nationality_name !== null
                            ? myVolunteer?.nationality_name || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        District
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.district_name !== undefined
                            ? myVolunteer?.district_name || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />
                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-35px" }}
                      >
                        Country 
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.country_id !== null
                            ? myVolunteer?.country_id || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Experience
                    </span>
                    <input
                      type="text"
                      value={
                        myVolunteer?.experience !== null
                          ? myVolunteer?.experience || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        DOB
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.date_of_birth !== null
                            ? myVolunteer?.date_of_birth || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        LinkedIn
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.linked_in !== null
                            ? myVolunteer?.linked_in || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Skills
                    </span>
                    <textarea
                      value={
                        myVolunteer?.mySkills?.length > 0
                          ? showSkills(myVolunteer.mySkills) || ""
                          : "None"
                      }
                      readOnly={true}
                      rows={myVolunteer?.mySkills?.length > 0 ? "5" : "1"}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Availability
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.availability !== null
                            ? myVolunteer?.availability || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Gender
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.gender !== undefined
                            ? myVolunteer?.gender || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Expertises
                    </span>
                    <textarea
                      value={
                        myVolunteer?.myExpertise?.length > 0
                          ? showSkills(myVolunteer.myExpertise) || ""
                          : "None"
                      }
                      readOnly={true}
                      rows={myVolunteer?.myExpertise?.length > 0 ? "5" : "1"}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Any Date
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.any_date !== null
                            ? myVolunteer?.any_date || "false"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Date From
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.date_from !== null
                            ? myVolunteer?.date_from || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Institution
                    </span>
                    <input
                      type="text"
                      value={
                        myVolunteer?.institution !== null
                          ? myVolunteer?.institution || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Date To
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.date_to !== null
                            ? myVolunteer?.date_to || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Volunteered Before
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.volunteered_before !== null
                            ? myVolunteer?.volunteered_before || "false"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Phone
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.phone_number !== null &&
                          myVolunteer?.phone_number !== ""
                            ? myVolunteer?.phone_number || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Frequency
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.frequency !== null
                            ? myVolunteer?.frequency || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Comments
                    </span>
                    <input
                      type="text"
                      value={
                        myVolunteer?.comments !== null
                          ? myVolunteer?.comments || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Points
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.points !== null
                            ? myVolunteer?.points || "0"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Hours
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.hours !== null
                            ? myVolunteer?.hours || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Open To Work
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.open_to_work !== null
                            ? myVolunteer?.open_to_work || "false"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Occupation
                      </span>
                      <input
                        type="text"
                        value={
                          myVolunteer?.occupation !== null
                            ? myVolunteer?.occupation || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Reason
                    </span>
                    <input
                      type="text"
                      value={
                        myVolunteer?.reason !== null
                          ? myVolunteer?.reason || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>
                  <br />

                  <div className="modal-footer">
                    <button
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default VolunteerViewModal;
