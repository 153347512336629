import axios from "axios";

//redux
import {
  FETCH_COUNTRY_REQUEST,
  FETCH_COUNTRY_SUCCESS,
  FETCH_COUNTRY_FAIL,

  ADD_COUNTRY_REQUEST,
  ADD_COUNTRY_SUCCESS,
  ADD_COUNTRY_FAIL,

  EDIT_COUNTRY_REQUEST,
  EDIT_COUNTRY_SUCCESS,
  EDIT_COUNTRY_FAIL,

  DELETE_COUNTRY_REQUEST,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_FAIL,

  CLEAR
} from "./countryTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_COUNTRY_REQUEST,
  };
};

export const fetchSuccess = (countries) => {
  return {
    type: FETCH_COUNTRY_SUCCESS,
    payload: countries,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_COUNTRY_FAIL,
    payload: error,
  };
};

export const addCountryRequest = () => {
  return {
    type: ADD_COUNTRY_REQUEST,
  };
};

export const addCountrySuccess = () => {
  return {
    type: ADD_COUNTRY_SUCCESS,
  };
};

export const addCountryFail = (error) => {
  return {
    type: ADD_COUNTRY_FAIL,
    payload: error,
  };
};

export const editCountryRequest = () => {
  return {
    type: EDIT_COUNTRY_REQUEST,
  };
};

export const editCountrySuccess = () => {
  return {
    type: EDIT_COUNTRY_SUCCESS,
  };
};

export const editCountryFail = (error) => {
  return {
    type: EDIT_COUNTRY_FAIL,
    payload: error,
  };
};

export const deleteCountryRequest = () => {
  return {
    type: DELETE_COUNTRY_REQUEST,
  };
};

export const deleteCountrySuccess = () => {
  return {
    type: DELETE_COUNTRY_SUCCESS,
  };
};

export const deleteCountryFail = (error) => {
  return {
    type: DELETE_COUNTRY_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getCountries = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/countries";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteCountry = (id) => async (dispatch) => {
  dispatch(deleteCountryRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/countries/" + id);
    dispatch(deleteCountrySuccess());
    dispatch(getCountries());
  } catch (e) {
    dispatch(deleteCountryFail(e.response.data));
  }
};

export const addCountry = (name, name_ar) => async (dispatch) => {
  dispatch(addCountryRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/countries", {
      name,
      name_ar,
    });
    dispatch(addCountrySuccess());
    dispatch(getCountries());
  } catch (e) {
    dispatch(addCountryFail(e.response.data));
  }
};

export const updateCountry = (id, name, name_ar) => async (dispatch) => {
  dispatch(editCountryRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/countries/" + id, {
      name,
      name_ar,
    });
    dispatch(editCountrySuccess());
    dispatch(getCountries());
  } catch (e) {
    dispatch(editCountryFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
