import {
  FETCH_NATIONALITY_REQUEST,
  FETCH_NATIONALITY_SUCCESS,
  FETCH_NATIONALITY_FAIL,

  ADD_NATIONALITY_REQUEST,
  ADD_NATIONALITY_SUCCESS,
  ADD_NATIONALITY_FAIL,

  EDIT_NATIONALITY_REQUEST,
  EDIT_NATIONALITY_SUCCESS,
  EDIT_NATIONALITY_FAIL,

  DELETE_NATIONALITY_REQUEST,
  DELETE_NATIONALITY_SUCCESS,
  DELETE_NATIONALITY_FAIL,

  CLEAR
} from "./nationalityTypes";

export const initialState = {
  loading: false,
  nationalities: [],
  error: "",
  addLoading: false,
  addError: "",
  editLoading: false,
  editError: "",
  deleteLoading: false,
  deleteError: "",
};

const nationalityReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_NATIONALITY_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_NATIONALITY_SUCCESS:
      return {
        loading: false,
        nationalities: action.payload,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_NATIONALITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };

    case ADD_NATIONALITY_REQUEST:
      return {
        ...state,
        addLoading: true,
        addError: "",
      };
    case ADD_NATIONALITY_SUCCESS:
      return {
        ...state,
        addLoading: false,
        addError: ""
      };
    case ADD_NATIONALITY_FAIL:
      return {
        ...state,
        addLoading: false,
        addError: action.payload,
      };

    case EDIT_NATIONALITY_REQUEST:
      return {
        ...state,
        editLoading: true,
        editError: "",
      };
    case EDIT_NATIONALITY_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editError: ""
      };
    case EDIT_NATIONALITY_FAIL:
      return {
        ...state,
        editLoading: false,
        editError: action.payload,
      };

    case DELETE_NATIONALITY_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: "",
      };
    case DELETE_NATIONALITY_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: ""
      };
    case DELETE_NATIONALITY_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.payload,
      };

    case CLEAR:
      return {
        ...state,
        loading: false,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    default:
      return state;
  }
};

export default nationalityReducer;
