import {
  FETCH_OPPORTUNITYFEEDBACK_REQUEST,
  FETCH_OPPORTUNITYFEEDBACK_SUCCESS,
  FETCH_OPPORTUNITYFEEDBACK_FAIL,
} from "./opportunityFeedbacksTypes";

export const initialState = {
  loading: false,
  OpportunityFeedback: [],
  error: "",
};

const opportunityFeedbacksReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OPPORTUNITYFEEDBACK_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_OPPORTUNITYFEEDBACK_SUCCESS:
      return {
        loading: false,
        OpportunityFeedback: action.payload,
        error: "",
      };
    case FETCH_OPPORTUNITYFEEDBACK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default opportunityFeedbacksReducer;
