import React from "react";
import DashboardCardStat from "../DashboardCardStat";
import { GrCurrency } from "react-icons/gr";

function costSaved(props) {
  return (
    <>
      <DashboardCardStat
        title={props.coastSaved}
        description="Coast Saved"
        link="/"
        icon={
          <div className="circle-container">
            <GrCurrency size={30} />
          </div>
        }
        iconColor="iconc  fa-2x"
      />
    </>
  );
}

export default costSaved;
