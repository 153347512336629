import { number } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import {
    clear,
    updateOpportunity,
} from "../../redux/opportunity/opportunityActions";

//component
import Loading from "../layout/Loading";

//css
import "./Opportunity.css";

function OpportunityEditModel({ id, title, final_comments, nb_of_volunteers, cost, hours, languages, type, is_group, transporation, online, food, wheelchair_accessible, start_date, end_date, deadline_date,nb_of_spots,supports_disability,physical_efforts,committed,is_call_to_action,tasks_required,occupation,frequency, setTitle, setFinal_comments, setNb_of_volunteers, setCost, setHours, setLanguages, setType, setIs_group, setTransporation, setOnline, setFood, setWheelchair_accessible, setStart_date, setEnd_date, setDeadline_date,setNb_of_spots,setSupports_disability,setPhysical_efforts,setCommitted,setIs_call_to_action,setTasks_required,setOccupation,setFrequency}) {
    const dispatch = useDispatch();
    const { editLoading } = useSelector((state) => state.organizationReducer);
    const [buttonState, setButtonState] = useState(true);
    //   useEffect(() => {
    //     if (name !== "" && name_ar !== "" ) setButtonState(false);
    //     else setButtonState(true);
    //   }, [name, name_ar]);
    const resetForm = () => {
        setTitle("");
        setFinal_comments("");
        setNb_of_volunteers("");
        setCost("");
        setHours("");
        setLanguages("");
        setType("");
        setIs_group("");
        setTransporation("");
        setOnline("");
        setFood("");
        setWheelchair_accessible("");
        setStart_date("");
        setEnd_date("");
        setTasks_required("");
        setOccupation("");
        setDeadline_date("");
        setNb_of_spots("");
        setSupports_disability("");
        setPhysical_efforts("");
        setCommitted("");
        setIs_call_to_action("");
        setOccupation("");
        setFrequency("");
        dispatch(clear());
    };

    const editOpportunityHandle = (e) => {
        e.preventDefault();
        dispatch(updateOpportunity(id, title, final_comments, nb_of_volunteers, cost, hours, languages, type, is_group, transporation, online, food, wheelchair_accessible, start_date, end_date, deadline_date,nb_of_spots,supports_disability,physical_efforts,committed,is_call_to_action,tasks_required,occupation,frequency));
        setTitle("");
        setFinal_comments("");
        setNb_of_volunteers("");
        setCost("");
        setHours("");
        setLanguages("");
        setType("");
        setIs_group("");
        setTransporation("");
        setOnline("");
        setFood("");
        setWheelchair_accessible("");
        setStart_date("");
        setEnd_date("");
        setNb_of_spots("");
        setDeadline_date("");
        setSupports_disability("");
        setPhysical_efforts("");
        setTasks_required("");
        setCommitted("");
        setIs_call_to_action("");
        setOccupation("");
        setFrequency("");
 
    };
    const handleTypeChange = (event) => {
        setType(event.target.value);
    }

    
  
    
    const handletransChange = (event) => {
        setTransporation(event.target.value);
    }
   
    const handlefnbChange = (event) => {
        setFood(event.target.value);
    }
    const handleOnChange = (event) => {
        setOnline(event.target.value);
    }
  
    return (
        <div className="modal fade" id="update-opp-modal">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-headers">
                        <h4 className="modal-title">Edit Opportunity</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={resetForm}
                        >
                            &times;
                        </button>
                    </div>
                    {editLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="modal-body">
                                <form onSubmit={editOpportunityHandle}>
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Title :</span>
                                        <input
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            placeholder="Enter the Title"
                                            className="  inputt col-md-8 form-controls"
                                        />
                                    </div>
                                    <br />
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Comment :</span>
                                        <textarea
                                            value={final_comments}
                                            onChange={(e) => setFinal_comments(e.target.value)}
                                            placeholder="Enter the Comment"
                                            className="  inputt col-md-8 form-controls"
                                        />
                                    </div>
                                    <br />
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Task Require :</span>
                                        <textarea
                                            value={tasks_required}
                                            onChange={(e) => setTasks_required(e.target.value)}
                                            placeholder="Enter the Task Require"
                                            className="  inputt col-md-8 form-controls"
                                        />
                                    </div>
                                    <br />
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Volunteer :</span>
                                        <input
                                            value={nb_of_volunteers}
                                            onChange={(e) => setNb_of_volunteers(e.target.value)}
                                            placeholder="Enter the Number of volunteer"
                                            className="  inputt col-md-8 form-controls"
                                            type="number"
                                        />
                                    </div>
                                    <br></br>
                                    
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Spots :</span>
                                        <input
                                            value={nb_of_spots}
                                            onChange={(e) => setNb_of_spots(e.target.value)}
                                            placeholder="Enter the Number of Spots"
                                            className="  inputt col-md-8 form-controls"
                                            type="number"
                                        />
                                    </div>
                                   <br></br>
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Cost :</span>
                                        <input
                                            value={cost}
                                            type="number"
                                            onChange={(e) => setCost(e.target.value)}
                                            placeholder="Enter the Cost"
                                            className="  inputt col-md-8 form-controls"
                                        />
                                    </div>
                                    <br />
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Hours :</span>
                                        <input
                                            value={hours}
                                            type="number"
                                            onChange={(e) => setHours(e.target.value)}
                                            placeholder="Enter the Hours"
                                            className="  inputt col-md-8 form-controls"
                                        />
                                    </div>
                                    <br />
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Language :</span>
                                        <input
                                            value={languages}
                                            onChange={(e) => setLanguages(e.target.value)}
                                            placeholder="Enter the Languages"
                                            className="  inputt col-md-8 form-controls"
                                        />
                                    </div>
                                    <br />
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Type :</span>
                                        <label>
                                            <input
                                                type="radio"
                                                value="Time-based"
                                                checked={type === "Time-based"}
                                                onChange={handleTypeChange}
                                            />
                                            Time-based
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label>
                                            <input
                                                type="radio"
                                                value="Skill-based"
                                                checked={type === "Skill-based"}
                                                onChange={handleTypeChange}
                                            />
                                            Skill-based
                                        </label>
                                    </div>
                                    <br />
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Group :</span>
                                        <label>
                                            <input
                                                type="radio"
                                                value={true}
                                                checked={is_group === true ? true : false}
                                    
                                         
                                                onChange={() => setIs_group(true)}
                                            />
                                            True
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label>
                                            <input
                                                type="radio"
                                                value={false}
                                               
                                                checked={is_group === false ? true : false}
                                                onChange={() => setIs_group(false)}
                                            />
                                            False
                                        </label>
                                    </div>
                                    <br></br>
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Support Disabiility :</span>
                                        <label>
                                            <input
                                                type="radio"
                                                value={true}
                                                checked={supports_disability === true ? true : false}
                                    
                                         
                                                onChange={() => setSupports_disability(true)}
                                            />
                                            True
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label>
                                            <input
                                                type="radio"
                                                value={false}
                                               
                                                checked={supports_disability === false ? true : false}
                                                onChange={() => setSupports_disability(false)}
                                            />
                                            False
                                        </label>
                                    </div>
                                    <br></br>
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Physical Efforts :</span>
                                        <label>
                                            <input
                                                type="radio"
                                                value={true}
                                                checked={physical_efforts === true ? true : false}
                                    
                                         
                                                onChange={() => setPhysical_efforts(true)}
                                            />
                                            True
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label>
                                            <input
                                                type="radio"
                                                value={false}
                                               
                                                checked={physical_efforts === false ? true : false}
                                                onChange={() => setPhysical_efforts(false)}
                                            />
                                            False
                                        </label>
                                    </div>
                                    <br></br>
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">committed :</span>
                                        <label>
                                            <input
                                                type="radio"
                                                value={true}
                                                checked={committed === true ? true : false}
                                    
                                         
                                                onChange={() => setCommitted(true)}
                                            />
                                            True
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label>
                                            <input
                                                type="radio"
                                                value={false}
                                               
                                                checked={committed === false ? true : false}
                                                onChange={() => setCommitted(false)}
                                            />
                                            False
                                        </label>
                                    </div>
                                    <br></br>
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Call Action :</span>
                                        <label>
                                            <input
                                                type="radio"
                                                value={true}
                                                checked={is_call_to_action === true ? true : false}
                                    
                                         
                                                onChange={() => setIs_call_to_action(true)}
                                            />
                                            True
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label>
                                            <input
                                                type="radio"
                                                value={false}
                                               
                                                checked={is_call_to_action === false ? true : false}
                                                onChange={() => setIs_call_to_action(false)}
                                            />
                                            False
                                        </label>
                                    </div>
                                    <br></br>
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Transporation :</span>
                                        <label>
                                            <input
                                                type="radio"
                                                value="Required"
                                                checked={transporation === "Required"}
                                                onChange={handletransChange}
                                            />
                                            Required
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label>
                                            <input
                                                type="radio"
                                                value="Not Required"
                                                checked={transporation === "Not Required"}
                                                onChange={handletransChange}
                                            />
                                            Not Required
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label>
                                            <input
                                                type="radio"
                                                value="Provided"
                                                checked={transporation === "Provided"}
                                                onChange={handletransChange}
                                            />
                                            Provided
                                        </label>
                                    </div>
                                    <br></br>
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Online :</span>
                                        <label>
                                            <input
                                                type="radio"
                                                value="Yes"
                                                checked={online === "Yes"}
                                                onChange={handleOnChange}
                                            />
                                            Yes
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label>
                                            <input
                                                type="radio"
                                                value="No"
                                                checked={online === "No"}
                                                onChange={handleOnChange}
                                            />
                                            No
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label>
                                            <input
                                                type="radio"
                                                value="Both (online and on-site)"
                                                checked={online === "Both (online and on-site)"}
                                                onChange={handleOnChange}
                                            />
                                            Both (Online and on site)
                                        </label>
                                    </div>
                                    <br></br>
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Food :</span>
                                        <label>
                                            <input
                                                type="radio"
                                                value="Required"
                                                checked={food === "Required"}
                                                onChange={handlefnbChange}
                                            />
                                            Required
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label>
                                            <input
                                                type="radio"
                                                value="Not Required"
                                                checked={food === "Not Required"}
                                                onChange={handlefnbChange}
                                            />
                                            Not Required
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label>
                                            <input
                                                type="radio"
                                                value="Provided"
                                                checked={food === "Provided"}
                                                onChange={handlefnbChange}
                                            />
                                            Provided
                                        </label>
                                    </div>
                                    <br></br>
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Wheelchair :</span>
                                        <label>
                                            <input
                                                 type="radio"
                                                 value={true}
                                                 checked={wheelchair_accessible === true ? true : false}
                                     
                                          
                                                 onChange={() => setWheelchair_accessible(true)}
                                            />
                                            Yes
                                        </label>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label>
                                            <input
                                                type="radio"
                                    
                                                value={false}
                                                checked={wheelchair_accessible === false ? true : false}
                                    
                                         
                                                onChange={() => setWheelchair_accessible(false)}
                                            />
                                            No
                                        </label>

                                    </div>
                                    <br></br>
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Start Date :</span>
                                        <input
                                            value={start_date}
                                            onChange={(e) => setStart_date(e.target.value)}
                                          type="date"
                                            className="  inputt col-md-8 form-controls"
                                        />
                                    </div>
                                    <br />
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">End Date :</span>
                                        <input
                                            value={end_date}
                                            onChange={(e) => setEnd_date(e.target.value)}
                                          type="date"
                                            className="  inputt col-md-8 form-controls"
                                        />
                                    </div>
                                    <br />
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Deadline Date :</span>
                                        <input
                                            value={deadline_date}
                                            onChange={(e) => setDeadline_date(e.target.value)}
                                          type="date"
                                            className="  inputt col-md-8 form-controls"
                                        />
                                    </div>
                                    <br />
                                    {/* <div className="row formRow">
                                        <span className="col-md-3 textForm">Number of volunteer :</span>
                                        <input
                                            value={nb_of_volunteers}
                                            onChange={(e) => setNb_of_volunteers(e.target.value)}
                                            placeholder="Enter the Number of volunteer"
                                            className="  inputt col-md-8 form-controls"
                                            type={number}
                                        />
                                    </div> */}
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Occupation :</span>
                                        <select id="occupation" value={occupation} onChange={(e) => setOccupation(e.target.value)}>
                                            <option value="Unoccupied">Unoccupied</option>
                                            <option value="School Student">School Student</option>
                                            <option value="University Student">University Student</option>
                                            <option value="Employee">Employee</option>
                                            <option value="Self Employed">Self Employed</option>
                                        </select>
                                    </div>
                                    <br></br>
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm">Frequency :</span>
                                        <select id="frequency" value={frequency} onChange={(e) => setFrequency(e.target.value)}>
                                            <option value="Any">Any</option>
                                            <option value="Daily">Daily</option>
                                            <option value="Weekly">Weekly</option>
                                            <option value="Monthly">Monthly</option>
                                            <option value="Bi-Weekly">Bi-Weekly</option>
                                            <option value="Bi-Monthly">Bi-Monthly</option>
                                            <option value="One Time Only">One Time Only</option>
                                         
                                        </select>
                                    </div>



                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                             className="close-btn"
                                            data-dismiss="modal"
                                            onClick={resetForm}
                                        >
                                            Close
                                        </button>
                                        <button
                                            type="submit"
                                            className="add-btn"
                                            data-dismiss="modal"
                                            onClick={editOpportunityHandle}
                                        //   disabled={buttonState ? true : false}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default OpportunityEditModel;
