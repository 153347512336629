import {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAIL,
  FETCH_ONE_SUCCESS,
} from "./organizationEvalTypes";

export const initialState = {
  loading: false,
  organizationEval: [],
  one: {},
  error: "",
};

const organizationEvalReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_SUCCESS:
      return {
        loading: false,
        organizationEval: action.payload,
        error: "",
      };
    case FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case FETCH_ONE_SUCCESS:
      return {
        ...state,
        one: action.payload,
      };

    default:
      return state;
  }
};

export default organizationEvalReducer;
