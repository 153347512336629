import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import {
  addEpisodeVideo,
  clear,
} from "../../redux/episodeVideo/episodeVideoActions";
import { getEpisode } from "../../redux/episode/episodeActions";

//components
import Loading from "../layout/Loading";

//css
import "./EpisodeVideo.css";

function EpisodeVideoAddModal() {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [title_ar, setTitle_ar] = useState("");
  const [desc, setDesc] = useState("");
  const [desc_ar, setDesc_ar] = useState("");
  const [length, setLength] = useState("");
  const [name_trai, setName_trai] = useState("");
  const [name_ar_trai, setName_ar_trai] = useState("");
  const [desc_trai, setDesc_trai] = useState("");
  const [desc_ar_trai, setDesc_ar_trai] = useState("");
  const [linked_in, setLinked_in] = useState("");
  const [link, setLink] = useState("");
  const [episode_id, setEpisode_id] = useState("");
  const { addLoading } = useSelector((state) => state.episodeVideoReducer);
  const { episode } = useSelector((state) => state.episodeReducer);
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if (title !== "" && title_ar !== "" && desc !== "" && desc_ar !== ""&& length !== "" && link !== ""&& linked_in !== "" && name_trai !== "" && name_ar_trai !== ""&& desc_trai !== "" && desc_ar_trai !== ""&& episode_id !== ""   ) setButtonState(false);
    else setButtonState(true);
  }, [title, title_ar,desc,desc_ar,length,link,linked_in,name_trai, name_ar_trai,desc_trai,desc_ar_trai,episode_id]);
  useEffect(() => {
    dispatch(getEpisode());
  }, [dispatch]);

  const resetForm = () => {
    setTitle("");
    setTitle_ar("");
    setDesc("");
    setDesc_ar("");
    setName_trai("");
    setName_ar_trai("");
    setDesc_ar_trai("");
    setDesc_trai("");
    setLinked_in("");
    setLength("");
    setLink("");
    setEpisode_id("");
    dispatch(clear());
  };

  const addEpisodeVideoHandle = (e) => {
    e.preventDefault();
    dispatch(
      addEpisodeVideo(
        episode_id,
        title,
        title_ar,
        desc,
        desc_ar,
        length,
        link,
        name_trai,
        name_ar_trai,
        desc_trai,
        desc_ar_trai,
        linked_in
      )
    );
    setTitle("");
    setTitle_ar("");
    setDesc("");
    setDesc_ar("");
    setName_trai("");
    setName_ar_trai("");
    setDesc_ar_trai("");
    setDesc_trai("");
    setLinked_in("");
    setLength("");
    setLink("");
    setEpisode_id("");
    setEpisode_id("");
  };

  return (
    <div>
      <div className="modal fade" id="add-episodeVideo-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add Episode Video</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={addEpisodeVideoHandle}>
                  <div className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Episode :</span>
                      <select
                        onChange={(e) => setEpisode_id(e.target.value)}
                        required
                        defaultValue={""}
                      >
                        <option value="" disabled>
                          Please select Episode
                        </option>
                        {episode.data?.map((episode) => (
                          <option key={episode.id} value={episode.id}>
                            {episode.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm">Title :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={title}
                        placeholder="Enter the title"
                        required
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm">
                        Title in Arabic :
                      </span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={title_ar}
                        placeholder="Enter the title in Arabic"
                        required
                        onChange={(e) => {
                          setTitle_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Description :</span>
                      <textarea
                        type="textarea"
                        className="  inputt col-md-8 form-controls"
                        value={desc}
                        placeholder="Enter the Description"
                        required
                        onChange={(e) => {
                          setDesc(e.target.value);
                        }}
                      ></textarea>
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">
                        Discription in Arabic :
                      </span>
                      <textarea
                        type="textarea"
                        className="  inputt col-md-8 form-controls"
                        value={desc_ar}
                        placeholder="Enter the Description in Arabic"
                        required
                        onChange={(e) => {
                          setDesc_ar(e.target.value);
                        }}
                      ></textarea>
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Length :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={length}
                        placeholder="Enter the video's length in format   'HH:mm:ss'"
                        required
                        onChange={(e) => {
                          setLength(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm">Link :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={link}
                        placeholder="Enter the video's link"
                        required
                        onChange={(e) => {
                          setLink(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm">Trainer :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={name_trai}
                        placeholder="Enter the name of trainer"
                        required
                        onChange={(e) => {
                          setName_trai(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm">
                        name in Arabic :
                      </span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={name_ar_trai}
                        placeholder="Enter the name of trainer in Arabic"
                        required
                        onChange={(e) => {
                          setName_ar_trai(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm">
                        Trainer Description :
                      </span>
                      <textarea
                        type="textarea"
                        className="  inputt col-md-8 form-controls"
                        value={desc_trai}
                        placeholder="Enter the Description of Trainer"
                        required
                        onChange={(e) => {
                          setDesc_trai(e.target.value);
                        }}
                      ></textarea>
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">
                        Trainer Discription in Arabic :
                      </span>
                      <textarea
                        type="textarea"
                        className="  inputt col-md-8 form-controls"
                        value={desc_ar_trai}
                        placeholder="Enter the Description of Trainer in Arabic"
                        required
                        onChange={(e) => {
                          setDesc_ar_trai(e.target.value);
                        }}
                      ></textarea>
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm">Linked In :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={linked_in}
                        placeholder="Enter the Linked In"
                        required
                        onChange={(e) => {
                          setLinked_in(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addEpisodeVideoHandle}
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      disabled={buttonState ? true : false}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EpisodeVideoAddModal;
