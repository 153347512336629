import axios from "axios";

//redux
import {
  FETCH_TEAM_REQUEST,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAM_FAIL,
  ADD_TEAM_REQUEST,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FAIL,
  EDIT_TEAM_REQUEST,
  EDIT_TEAM_SUCCESS,
  EDIT_TEAM_FAIL,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAIL,
  CLEAR,
} from "./teamTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_TEAM_REQUEST,
  };
};

export const fetchSuccess = (teams) => {
  return {
    type: FETCH_TEAM_SUCCESS,
    payload: teams,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_TEAM_FAIL,
    payload: error,
  };
};

export const addTeamRequest = () => {
  return {
    type: ADD_TEAM_REQUEST,
  };
};

export const addTeamSuccess = () => {
  return {
    type: ADD_TEAM_SUCCESS,
  };
};

export const addTeamFail = (error) => {
  return {
    type: ADD_TEAM_FAIL,
    payload: error,
  };
};

export const editTeamRequest = () => {
  return {
    type: EDIT_TEAM_REQUEST,
  };
};

export const editTeamSuccess = () => {
  return {
    type: EDIT_TEAM_SUCCESS,
  };
};

export const editTeamFail = (error) => {
  return {
    type: EDIT_TEAM_FAIL,
    payload: error,
  };
};

export const deleteTeamRequest = () => {
  return {
    type: DELETE_TEAM_REQUEST,
  };
};

export const deleteTeamSuccess = () => {
  return {
    type: DELETE_TEAM_SUCCESS,
  };
};

export const deleteTeamFail = (error) => {
  return {
    type: DELETE_TEAM_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getTeam = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/teams";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteTeam = (id) => async (dispatch) => {
  dispatch(deleteTeamRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/teams/" + id);
    dispatch(deleteTeamSuccess());
    dispatch(getTeam());
  } catch (e) {
    dispatch(deleteTeamFail(e.response.data));
  }
};

export const addTeam =
  (
    name,
    name_ar,
    description,
    description_ar,
    position,
    position_ar,
    linked_in,
    image
  ) =>
  async (dispatch) => {
    dispatch(addTeamRequest());
    var formData = new FormData();
    if (image) formData.append("image", image);
    formData.append("name", name);
    formData.append("name_ar", name_ar);
    formData.append("description", description);
    formData.append("description_ar", description_ar);
    formData.append("position", position);
    formData.append("position_ar", position_ar);
    formData.append("linked_in", linked_in);
    try {
      await axios.post(process.env.REACT_APP_API + "/teams", formData);
      dispatch(addTeamSuccess());
      dispatch(getTeam());
    } catch (e) {
      dispatch(addTeamFail(e.response.data));
    }
  };

export const updateTeam =
  (
    id,
    name,
    name_ar,
    description,
    description_ar,
    position,
    position_ar,
    linked_in,
    image
  ) =>
  async (dispatch) => {
    dispatch(editTeamRequest());
    var formData = new FormData();
    if (image) formData.append("image", image);
    formData.append("name", name);
    formData.append("name_ar", name_ar);
    formData.append("description", description);
    formData.append("description_ar", description_ar);
    formData.append("position", position);
    formData.append("position_ar", position_ar);
    formData.append("linked_in", linked_in);
    try {
      await axios.put(process.env.REACT_APP_API + "/teams/" + id, formData);
      dispatch(editTeamSuccess());
      dispatch(getTeam());
    } catch (e) {
      dispatch(editTeamFail(e.response.data));
    }
  };

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
