export const FETCH_QUIZ_ANSWER_REQUEST = "FETCH_QUIZ_ANSWER_REQUEST";
export const FETCH_QUIZ_ANSWER_SUCCESS = "FETCH_QUIZ_ANSWER_SUCCESS";
export const FETCH_QUIZ_ANSWER_FAIL = "FETCH_QUIZ_ANSWER_FAIL";

export const ADD_QUIZ_ANSWER_REQUEST = "ADD_QUIZ_ANSWER_REQUEST";
export const ADD_QUIZ_ANSWER_SUCCESS = "ADD_QUIZ_ANSWER_SUCESS";
export const ADD_QUIZ_ANSWER_FAIL = "ADD_QUIZ_ANSWER_FAIL";

export const EDIT_QUIZ_ANSWER_REQUEST = "EDIT_QUIZ_ANSWER_REQUEST";
export const EDIT_QUIZ_ANSWER_SUCCESS = "EDIT_QUIZ_ANSWER_SUCESS";
export const EDIT_QUIZ_ANSWER_FAIL = "EDIT_QUIZ_ANSWER_FAIL";

export const DELETE_QUIZ_ANSWER_REQUEST = "DELETE_QUIZ_ANSWER_REQUEST";
export const DELETE_QUIZ_ANSWER_SUCCESS = "DELETE_QUIZ_ANSWER_SUCESS";
export const DELETE_QUIZ_ANSWER_FAIL = "DELETE_QUIZ_ANSWER_FAIL";

export const CLEAR = "CLEAR";
