import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import { addSkills, clear } from "../../redux/Skills/SkillsAction";

//components
import Loading from "../layout/Loading";

//css
import "./Skill.css";

function SkillAddModal() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [name_ar, setName_ar] = useState("");
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if (name !== "" && name_ar !== ""&& type !== ""  ) setButtonState(false);
    else setButtonState(true);
  }, [name, name_ar,type]);
  const { addLoading } = useSelector((state) => state.skillsReducer);

  const resetForm = () => {
    setName("");
    setName_ar("");
    setType("");
    dispatch(clear());
  };

  const addSkillHandle = (e) => {
    e.preventDefault();
    dispatch(addSkills(name, name_ar, type));
    setName("");
    setType("");
    setName_ar("");
  };

  return (
    <div>
      <div className="modal fade" id="add-skill-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={(e) => addSkillHandle(e)}>
                  <div className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Name :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={name}
                        placeholder="Enter the name"
                        required
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Arabic Name :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={name_ar}
                        placeholder="Enter the name in Arabic"
                        required
                        onChange={(e) => {
                          setName_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Type :</span>
                      <select
                        onChange={(e) => setType(e.target.value)}
                        required
                        defaultValue={""}
                      >
                        <option value="">Please select Type</option>

                        <option value="S">Skills</option>
                        <option value="E">Expertises</option>
                      </select>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addSkillHandle}
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      disabled={buttonState ? true : false}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkillAddModal;
