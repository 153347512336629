import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import { getOpportunityById } from "../../redux/opportunity/opportunityActions";
import { getSkillsByIds } from "../../redux/Skills/SkillsAction";

//component
import Loading from "../layout/Loading";

//css
import "./Opportunity.css";

function OpportunityViewModal({ id }) {
  const dispatch = useDispatch();
  const { oneLoading, oneopportunity } = useSelector(
    (state) => state.opportunityReducer
  );
  const { skills } = useSelector((state) => state.skillsReducer);
  const [myOpportunity, setMyOpportunity] = useState();

  useEffect(() => {
    if (oneopportunity?.data?.data?.length) {
      let vol = {
        organization_name: oneopportunity.data.data[0].organization?.name,
        volunteer_name: oneopportunity.data.data[0].volunteer?.first_name,
        district_name: oneopportunity.data.data[0].district?.name,
        governate_name: oneopportunity.data.data[0].district?.governate?.name,
        country_name:
          oneopportunity.data.data[0].district?.governate?.country?.name,
        title: oneopportunity.data.data[0].title,
        type: oneopportunity.data.data[0].type,
        frequency: oneopportunity.data.data[0].frequency,
        respond_time: oneopportunity.data.data[0].respond_time,
        occupation: oneopportunity.data.data[0].occupation,
        is_group: oneopportunity.data.data[0].is_group,
        transporation: oneopportunity.data.data[0].transporation,
        food: oneopportunity.data.data[0].food,
        online: oneopportunity.data.data[0].online,
        languages: oneopportunity.data.data[0].languages,
        hours: oneopportunity.data.data[0].hours,
        start_date: new Date(oneopportunity.data.data[0].start_date)
          .toISOString()
          .split("T")[0],
        end_date: new Date(oneopportunity.data.data[0].end_date)
          .toISOString()
          .split("T")[0],
        deadline_date: new Date(oneopportunity.data.data[0].deadline_date)
          .toISOString()
          .split("T")[0],
        nb_of_spots: oneopportunity.data.data[0].nb_of_spots,
        nb_of_volunteers: oneopportunity.data.data[0].nb_of_volunteers,
        experience_required: oneopportunity.data.data[0].experience_required,
        cost: oneopportunity.data.data[0].cost,
        final_comments: oneopportunity.data.data[0].final_comments,
        wheelchair_accessible:
          oneopportunity.data.data[0].wheelchair_accessible,
        supports_disability: oneopportunity.data.data[0].supports_disability,
        physical_efforts: oneopportunity.data.data[0].physical_efforts,
        committed: oneopportunity.data.data[0].committed,
        tasks_required: oneopportunity.data.data[0].tasks_required,
        img_url: oneopportunity.data.data[0].img_url,
        skills: oneopportunity.data.data[0].skills,
        age: oneopportunity.data.age,
        gender: oneopportunity.data.gender,
        disabilities: oneopportunity.data.disabilities,
        impacts: oneopportunity.data.impacts,
      };

      if (vol?.skills !== null) {
        dispatch(getSkillsByIds(vol?.skills));
      }
      setMyOpportunity(vol);
    }
  }, [oneopportunity, dispatch]);

  const showAges = (ourAges) => {
    let agesString = "";
    for (let i = 0; i < ourAges.length; i++) {
      agesString += "• ";
      agesString += ourAges[i].age;
      agesString += "\n";
    }
    return agesString;
  };

  const showGenders = (ourGenders) => {
    let gendersString = "";
    for (let i = 0; i < ourGenders.length; i++) {
      gendersString += "• ";
      gendersString += ourGenders[i].gender;
      gendersString += "\n";
    }
    return gendersString;
  };

  const showDisabilities = (ourDisabilities) => {
    let disabilitiesString = "";
    for (let i = 0; i < ourDisabilities.length; i++) {
      disabilitiesString += "• ";
      disabilitiesString += ourDisabilities[i].disability.text;
      disabilitiesString += "\n";
    }
    return disabilitiesString;
  };

  const showSkills = () => {
    let skillsString = "";
    for (let i = 1; i < skills.data?.length; i++) {
      skillsString += "• ";
      skillsString += skills.data[i]?.name;
      skillsString += "\n";
    }
    return skillsString;
  };

  const showImpacts = (ourImpacts) => {
    let impactsString = "";
    for (let i = 0; i < ourImpacts.length; i++) {
      impactsString += "• ";
      impactsString += ourImpacts[i].impact.title;
      impactsString += "\n";
    }
    return impactsString;
  };

  useEffect(() => {
    if (id !== 0) dispatch(getOpportunityById(id));
  }, [dispatch, id]);

  return (
    <div className="modal fade" id="view-opportunity-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Opportunity View More </h4>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          {oneLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form>
                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Organization
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.organization_name !== null
                            ? myOpportunity?.organization_name || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Wheelchair Access
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.wheelchair_accessible !== null
                            ? myOpportunity?.wheelchair_accessible || "false"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Genders
                    </span>
                    <textarea
                      value={
                        myOpportunity?.gender?.length > 0
                          ? showGenders(myOpportunity.gender) || ""
                          : "None"
                      }
                      readOnly={true}
                      rows={myOpportunity?.gender?.length > 0 ? "3" : "1"}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Title
                    </span>
                    <input
                      type="text"
                      value={
                        myOpportunity?.title !== null
                          ? myOpportunity?.title || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        District
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.district_name !== null
                            ? myOpportunity?.district_name || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Governate
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.governate_name !== null
                            ? myOpportunity?.governate_name || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Country
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.country_name !== null
                            ? myOpportunity?.country_name || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Cost
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.cost !== null
                            ? myOpportunity?.cost || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Experience Required
                    </span>
                    <textarea
                      value={
                        myOpportunity?.experience_required !== null
                          ? myOpportunity?.experience_required || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Languages
                    </span>
                    <input
                      type="text"
                      value={
                        myOpportunity?.languages !== null
                          ? myOpportunity?.languages || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Type
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.type !== null
                            ? myOpportunity?.type || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Frequency
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.frequency !== null
                            ? myOpportunity?.frequency || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Respond time
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.respond_time !== null
                            ? myOpportunity?.respond_time || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Occupation
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.occupation !== null
                            ? myOpportunity?.occupation || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Is Group
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.is_group !== null
                            ? myOpportunity?.is_group || "false"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Transporation
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.transporation !== null
                            ? myOpportunity?.transporation || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Tasks Required
                    </span>
                    <textarea
                      value={
                        myOpportunity?.tasks_required !== null
                          ? myOpportunity?.tasks_required || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Food
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.food !== null
                            ? myOpportunity?.food || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Online
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.online !== null
                            ? myOpportunity?.online || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Start Date
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.start_date !== null
                            ? myOpportunity?.start_date.split("T")[0] || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        End Date
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.end_date !== null
                            ? myOpportunity?.end_date.split("T")[0] || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Deadline
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.deadline_date !== null
                            ? myOpportunity?.deadline_date.split("T")[0] || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Spots' Number
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.nb_of_spots !== null
                            ? myOpportunity?.nb_of_spots || "0"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Volunteers' Number
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.nb_of_volunteers !== null
                            ? myOpportunity?.nb_of_volunteers || "0"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Hours
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.hours !== null
                            ? myOpportunity?.hours || "0"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Disabilities
                    </span>
                    <textarea
                      value={
                        myOpportunity?.disabilities?.length > 0
                          ? showDisabilities(myOpportunity.disabilities) || ""
                          : "None"
                      }
                      readOnly={true}
                      rows={myOpportunity?.disabilities?.length > 0 ? "3" : "1"}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Impacts
                    </span>
                    <textarea
                      value={
                        myOpportunity?.impacts?.length > 0
                          ? showImpacts(myOpportunity.impacts) || ""
                          : "None"
                      }
                      readOnly={true}
                      rows={myOpportunity?.impacts?.length > 0 ? "3" : "1"}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Skills
                    </span>
                    <textarea
                      value={
                        myOpportunity?.skills !== null
                          ? showSkills() || ""
                          : "None"
                      }
                      readOnly={true}
                      rows={myOpportunity?.skills !== null ? "3" : "1"}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Ages
                    </span>
                    <textarea
                      value={
                        myOpportunity?.age?.length > 0
                          ? showAges(myOpportunity.age) || ""
                          : "None"
                      }
                      readOnly={true}
                      rows={myOpportunity?.age?.length > 0 ? "3" : "1"}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Committed
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.committed !== null
                            ? myOpportunity?.committed || "false"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Physical Efforts
                      </span>
                      <input
                        type="text"
                        value={
                          myOpportunity?.physical_efforts !== null
                            ? myOpportunity?.physical_efforts || "false"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Final Comments
                    </span>
                    <textarea
                      value={
                        myOpportunity?.final_comments !== null
                          ? myOpportunity?.final_comments || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default OpportunityViewModal;
