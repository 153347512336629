import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import {
  clear,
  updateEpisodeQuiz,
} from "../../redux/episodeQuiz/episodeQuizActions";
import { getEpisode } from "../../redux/episode/episodeActions";

//component
import Loading from "../layout/Loading";

//css
import "./EpisodeQuiz.css";

function EditEpisodeQuizModel({
  id,
  question,
  question_ar,
  
  episode_id,
  setQuestion,
  setQuestion_ar,

  setEpisode_id,
}) {
  const dispatch = useDispatch();
  const { editLoading } = useSelector((state) => state.episodeQuizReducer);
  const { episode } = useSelector((state) => state.episodeReducer);
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if ( episode_id !== "" &&  question !== "" && question_ar !== ""  ) setButtonState(false);
    else setButtonState(true);
  }, [episode_id,question, question_ar]);
  useEffect(() => {
    dispatch(getEpisode());
  }, [dispatch]);

  const resetForm = () => {
    setQuestion("");
    setQuestion_ar("");
  
    setEpisode_id("");
    dispatch(clear());
  };

  const editEpisodeQuizHandle = (e) => {
    e.preventDefault();
    dispatch(
      updateEpisodeQuiz(
        id,
        episode_id,
        question,
        question_ar,
      
      )
    );
    setQuestion("");
    setQuestion_ar("");

    setEpisode_id("");
  };

  return (
    <div className="modal fade" id="update-episodeQuiz-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Edit Episode Quiz</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={resetForm}
            >
              &times;
            </button>
          </div>
          {editLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form onSubmit={editEpisodeQuizHandle}>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Episode :</span>
                    <select
                      onChange={(e) => setEpisode_id(e.target.value)}
                      required
                      value={episode_id}
                    >
                      {episode.data?.map((episode) => (
                        <option key={episode.id} value={episode.id}>
                          {episode.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <br />

                  <div className="row formRow">
                    <span className="col-md-3 textForm">Question :</span>
                    <input
                      value={question}
                      placeholder="Enter the question"
                      onChange={(e) => setQuestion(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Arabic Question :</span>
                    <input
                      value={question_ar}
                      placeholder="Enter the question in Arabic"
                      onChange={(e) => setQuestion_ar(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                 
                  <br />
                  <div className="modal-footer">
                    <button
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={resetForm}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      onClick={editEpisodeQuizHandle}
                      disabled={buttonState ? true : false}
                    >
                      Edit
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditEpisodeQuizModel;
