import React, { useState ,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import {
  addInstitutions,
  clear,
} from "../../redux/Institutions/InstitutionsAction";

//components
import Loading from "../layout/Loading";

//css
import "./Institution.css";

function InstitutionAddModal() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [name_ar, setName_ar] = useState("");
  const { addLoading } = useSelector((state) => state.institutionsReducer);
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if (name !== "" && name_ar !== "" ) setButtonState(false);
    else setButtonState(true);
  }, [name, name_ar]);

  const resetForm = () => {
    setName("");
    setName_ar("");
    dispatch(clear());
  };

  const addInstitutionHandle = (e) => {
    e.preventDefault();
    dispatch(addInstitutions(name, name_ar));
    setName("");
    setName_ar("");
  };

  return (
    <div>
      <div className="modal fade" id="add-institution-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add Institution</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={addInstitutionHandle}>
                  <div className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Institution :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={name}
                        placeholder="Enter the institution's name"
                        required
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm">
                        Arabic Institution :
                      </span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={name_ar}
                        placeholder="Enter the institution's name in Arabic"
                        required
                        onChange={(e) => {
                          setName_ar(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addInstitutionHandle}
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      disabled={buttonState ? true : false}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstitutionAddModal;
