import axios from "axios";

//redux
import {
    FETCH_INSTITUTIONS_REQUEST,
    FETCH_INSTITUTIONS_SUCCESS,
    FETCH_INSTITUTIONS_FAIL,
  
    ADD_INSTITUTIONS_REQUEST,
    ADD_INSTITUTIONS_SUCCESS,
    ADD_INSTITUTIONS_FAIL,
  
    EDIT_INSTITUTIONS_REQUEST,
    EDIT_INSTITUTIONS_SUCCESS,
    EDIT_INSTITUTIONS_FAIL,
  
    DELETE_INSTITUTIONS_REQUEST,
    DELETE_INSTITUTIONS_SUCCESS,
    DELETE_INSTITUTIONS_FAIL,
  
    CLEAR
} from "./InstitutionsTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_INSTITUTIONS_REQUEST,
  };
};

export const fetchSuccess = (Institutions) => {
  return {
    type: FETCH_INSTITUTIONS_SUCCESS,
    payload: Institutions,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_INSTITUTIONS_FAIL,
    payload: error,
  };
};

export const addInstitutionsRequest = () => {
  return {
    type: ADD_INSTITUTIONS_REQUEST,
  };
};

export const addInstitutionsSuccess = () => {
  return {
    type: ADD_INSTITUTIONS_SUCCESS,
  };
};

export const addInstitutionsFail = (error) => {
  return {
    type: ADD_INSTITUTIONS_FAIL,
    payload: error,
  };
};

export const editInstitutionsRequest = () => {
  return {
    type: EDIT_INSTITUTIONS_REQUEST,
  };
};

export const editInstitutionsSuccess = () => {
  return {
    type: EDIT_INSTITUTIONS_SUCCESS,
  };
};

export const editInstitutionsFail = (error) => {
  return {
    type: EDIT_INSTITUTIONS_FAIL,
    payload: error,
  };
};

export const deleteInstitutionsRequest = () => {
  return {
    type: DELETE_INSTITUTIONS_REQUEST,
  };
};

export const deleteInstitutionsSuccess = () => {
  return {
    type: DELETE_INSTITUTIONS_SUCCESS,
  };
};

export const deleteInstitutionsFail = (error) => {
  return {
    type: DELETE_INSTITUTIONS_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getInstitutions = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/institutions";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteInstitutions = (id) => async (dispatch) => {
  dispatch(deleteInstitutionsRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/institutions/" + id);
    dispatch(deleteInstitutionsSuccess());
    dispatch(getInstitutions());
  } catch (e) {
    dispatch(deleteInstitutionsFail(e.response.data));
  }
};

export const addInstitutions = (name, name_ar) => async (dispatch) => {
  dispatch(addInstitutionsRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/institutions", {
        name,
        name_ar,
    });
    dispatch(addInstitutionsSuccess());
    dispatch(getInstitutions());
  } catch (e) {
    dispatch(addInstitutionsFail(e.response.data));
  }
};

export const updateInstitutions= (id, name, name_ar) => async (dispatch) => {
  dispatch(editInstitutionsRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/institutions/" + id, {
      name,
      name_ar,
    });
    dispatch(editInstitutionsSuccess());
    dispatch(getInstitutions());
  } catch (e) {
    dispatch(editInstitutionsFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
