import React from "react";

function EpisodeQuizeUserDetailed({ quizes }) {
  return (
    <div>
      <div className="modal fade" id="detailed-message-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Detailed Quize User</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p style={{ textAlign: "left" }}>
                {quizes?.map((g) => (
                  <>
                    {" "}
                    <div key={g?.id} value={g?.id}>
                      <p style={{ color: "red" }}>
                        {g?.episode_quize?.question}
                      </p>
                    </div>
                    {g.answer_string ? (
                      <div
                        key={g?.id}
                        value={g?.id}
                        style={{ marginBottom: "20px" }}
                      >
                        {g?.answer_string}
                      </div>
                    ) : (
                      <div
                        key={g?.id}
                        value={g?.id}
                        style={{ marginBottom: "20px" }}
                      >
                        {g?.episode_quiz_answer.answer}
                      </div>
                    )}
                  </>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EpisodeQuizeUserDetailed;
