import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ReactDatatable from "@ashvin27/react-datatable";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";

//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import CommunityActionViewModal from "./CommunityActionViewModal";

//redux
import { getCommunityActions } from "../../redux/communityAction/communityActionActions";

//utils
import isAuth from "../../utils/isAuth";
import * as XLSX from "xlsx";

import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
function CommunityAction() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, communityActions, error } = useSelector(
    (state) => state.communityActionReducer
  );
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);

  function exportToExcel() {
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter(
        (entry, index) => index !== 0 && index !== 2
      );
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Community Action.xlsx");
  }

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getCommunityActions());
  }, [dispatch]);

  useEffect(() => {
    if (communityActions && communityActions.data) {
      const mappedRecords = communityActions.data.map((communityAction) => {
        return {
          key: communityAction.id,
          id: communityAction.id,
          picture:
            process.env.REACT_APP_API +
            "/Opportunity/" +
            communityAction.img_url,

          volunteer_name: communityAction.volunteer?.first_name
            ? communityAction.volunteer?.first_name
            : "None",
          district_name: communityAction.district?.name,
          title: communityAction.title,
          governate_name: communityAction.district?.governate?.name,
          country_name: communityAction.district?.governate?.country?.name,
          type: communityAction.type,
          frequency: communityAction.frequency,
          respond_time: communityAction.respond_time,
          occupation: communityAction.occupation,
          is_group: communityAction.is_group,
          transportation: communityAction.transportation,
          food: communityAction.food,
          online: communityAction.online,
          languages: communityAction.languages,
          hours: communityAction.hours,
          start_date: communityAction.start_date,
          end_date: communityAction.end_date,
          deadline_date: communityAction.deadline_date,
          nb_of_spots: communityAction.nb_of_spots,
          nb_of_volunteers: communityAction.nb_of_volunteers,
          experience_required: communityAction.experience_required,
          cost: communityAction.cost,
          final_comments: communityAction.final_comments,
          wheelchair_accessible: communityAction.wheelchair_accessible,
          supports_disability: communityAction.supports_disability,
          physical_efforts: communityAction.physical_efforts,
          committed: communityAction.committed,
          tasks_required: communityAction.tasks_required,
          skills: communityAction.skills,
          age: communityAction.age,
          gender: communityAction.gender,
          disabilities: communityAction.disabilities,
          impacts: communityAction.impacts,
        };
      });
      setRecords(mappedRecords);
    }
  }, [communityActions]);

  const columns = [
    {
      field: "picture",
      headerName: "Picture",
      height: "20rem",
      flex: 1,
      renderCell: (params) => (
        <img
          src={params.row.picture}
          alt={`Picture ${params.row.id}`}
          style={{ width: "7rem", height: "4rem" }}
        />
      ),
    },
    {
      field: "volunteer_name",
      headerName: "Volunteer",
      flex: 1,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "district_name",
      headerName: "District",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#view-communityAction-modal"
              className="btn btn-success btn-sm"
              style={{ marginRight: "5px", width: "30px" }}
              onClick={() => {
                setId(record.row.id);
              }}
            >
              <i className="fa fa-info"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const handleReloadClick = () => {
    window.location.reload();
  };
  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <CommunityActionViewModal id={id} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
            </div>

            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header
                    title="Community Actions"
                    subtitle="List of Community Actions"
                  />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommunityAction;
