import axios from "axios";

//redux
import {
    FETCH_Contacts_REQUEST,
    FETCH_Contacts_SUCCESS,
    FETCH_Contacts_FAIL,
  
    ADD_Contacts_REQUEST,
    ADD_Contacts_SUCCESS,
    ADD_Contacts_FAIL,
  
    EDIT_Contacts_REQUEST,
    EDIT_Contacts_SUCCESS,
    EDIT_Contacts_FAIL,
  
    DELETE_Contacts_REQUEST,
    DELETE_Contacts_SUCCESS,
    DELETE_Contacts_FAIL,
  
    CLEAR
  } from "./ContactsTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_Contacts_REQUEST,
  };
};

export const fetchSuccess = (Contacts) => {
  return {
    type: FETCH_Contacts_SUCCESS,
    payload: Contacts,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_Contacts_FAIL,
    payload: error,
  };
};

export const addContactsRequest = () => {
  return {
    type: ADD_Contacts_REQUEST,
  };
};

export const addContactsSuccess = () => {
  return {
    type: ADD_Contacts_SUCCESS,
  };
};

export const addContactsFail = (error) => {
  return {
    type: ADD_Contacts_FAIL,
    payload: error,
  };
};

export const editContactsRequest = () => {
  return {
    type: EDIT_Contacts_REQUEST,
  };
};

export const editContactsSuccess = () => {
  return {
    type: EDIT_Contacts_SUCCESS,
  };
};

export const editContactsFail = (error) => {
  return {
    type: EDIT_Contacts_FAIL,
    payload: error,
  };
};

export const deleteContactsRequest = () => {
  return {
    type: DELETE_Contacts_REQUEST,
  };
};

export const deleteContactsSuccess = () => {
  return {
    type: DELETE_Contacts_SUCCESS,
  };
};

export const deleteContactsFail = (error) => {
  return {
    type: DELETE_Contacts_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getContacts = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/contacts";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteContacts = (id) => async (dispatch) => {
  dispatch(deleteContactsRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/contacts/" + id);
    dispatch(deleteContactsSuccess());
    dispatch(getContacts());
  } catch (e) {
    dispatch(deleteContactsFail(e.response.data));
  }
};

export const addContacts = (email, text ,type,phone) => async (dispatch) => {
  dispatch(addContactsRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/contacts", {
        email,
        text,
        type,
        phone,
    });
    dispatch(addContactsSuccess());
    dispatch(getContacts());
  } catch (e) {
    dispatch(addContactsFail(e.response.data));
  }
};

export const updateContacts= (id, email, text ,type,phone) => async (dispatch) => {
  dispatch(editContactsRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/contacts/" + id, {
        email,
        text,
        type,
        phone,
    });
    dispatch(editContactsSuccess());
    dispatch(getContacts());
  } catch (e) {
    dispatch(editContactsFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
