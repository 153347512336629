export const FETCH_VIDEOS_REQUEST = "FETCH_VIDEOS_REQUEST";
export const FETCH_VIDEOS_SUCCESS = "FETCH_VIDEOS_SUCCESS";
export const FETCH_VIDEOS_FAIL = "FETCH_VIDEOS_FAIL";

export const ADD_VIDEOS_REQUEST = "ADD_VIDEOS_REQUEST";
export const ADD_VIDEOS_SUCCESS = "ADD_VIDEOS_SUCESS";
export const ADD_VIDEOS_FAIL = "ADD_VIDEOS_FAIL";

export const EDIT_VIDEOS_REQUEST = "EDIT_VIDEOS_REQUEST";
export const EDIT_VIDEOS_SUCCESS = "EDIT_VIDEOS_SUCESS";
export const EDIT_VIDEOS_FAIL = "EDIT_VIDEOS_FAIL";

export const DELETE_VIDEOS_REQUEST = "DELETE_VIDEOS_REQUEST";
export const DELETE_VIDEOS_SUCCESS = "DELETE_VIDEOS_SUCESS";
export const DELETE_VIDEOS_FAIL = "DELETE_VIDEOS_FAIL";

export const CLEAR = "CLEAR";