import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import { clear, updateSkills } from "../../redux/Skills/SkillsAction";

//component
import Loading from "../layout/Loading";

//css
import "./Skill.css";

function SkillEditModal({
  id,
  name,
  name_ar,
  type,
  setName,
  setName_ar,
  setType,
}) {
  const dispatch = useDispatch();
  const { editLoading } = useSelector((state) => state.skillsReducer);
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if (name !== "" && name_ar !== "" && type !== "") setButtonState(false);
    else setButtonState(true);
  }, [name, name_ar, type]);
  const resetForm = () => {
    setName("");
    setName_ar("");
    setType("");

    dispatch(clear());
  };

  const editSkillHandle = (e) => {
    e.preventDefault();
    dispatch(updateSkills(id, name, name_ar, type));
    setName("");
    setName_ar("");
    setType("");
  };

  return (
    <div className="modal fade" id="update-skill-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Edit </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={resetForm}
            >
              &times;
            </button>
          </div>
          {editLoading ? (
            <Loading />
          ) : (
            <>
              <form onSubmit={editSkillHandle}>
                <div className="modal-body">
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Name :</span>
                    <input
                      value={name}
                      placeholder="Enter the name"
                      onChange={(e) => setName(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Arabic Name :</span>
                    <input
                      value={name_ar}
                      placeholder="Enter the name in Arabic"
                      onChange={(e) => setName_ar(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Type :</span>
                    <select
                      onChange={(e) => setType(e.target.value)}
                      required
                      value={type}
                    >
                      <option value="">Please select Type</option>

                      <option value="S">Skills</option>
                      <option value="E">Expertises</option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                     className="close-btn"
                    data-dismiss="modal"
                    onClick={resetForm}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="add-btn"
                    data-dismiss="modal"
                    onClick={editSkillHandle}
                    disabled={buttonState ? true : false}
                  >
                    Edit
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SkillEditModal;
