import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import { clear, updateVolunteer } from "../../redux/volunteer/volunteerActions";

//component
import Loading from "../layout/Loading";

//css
import "./Volunteer.css";

function VolunteerEditModal({ id, skillBased, timeBased, setSkillBaseds, setTimeBaseds }) {  
  const dispatch = useDispatch();
  const [buttonState, setButtonState] = useState(true);
  const { editLoading } = useSelector((state) => state.volunteerReducer);

  const resetForm = () => {
    setSkillBaseds("");
    setTimeBaseds("");
    dispatch(clear());
  };

  const editVolunteerHandle = (e) => {
    e.preventDefault();    
    dispatch(updateVolunteer(id, timeBased, skillBased));
    setTimeBaseds("");
    setSkillBaseds("");
  };

  return (
    <div className="modal fade" id="update-volunteer-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Edit Volunteer</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={resetForm}
            >
              &times;
            </button>
          </div>
          {editLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form onSubmit={editVolunteerHandle}>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">skillBased:</span>
                    <input
                      value={skillBased}
                      type="number"
                      placeholder="Enter hours skillBased"
                      onChange={(e) => setSkillBaseds(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">timeBased :</span>
                    <input
                      value={timeBased}
                      type="number"
                      placeholder="Enter hours timeBased"
                      onChange={(e) => setTimeBaseds(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={resetForm}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      onClick={editVolunteerHandle}
                    >
                      Edit
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default VolunteerEditModal;
