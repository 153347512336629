import axios from "axios";

//redux
import {
  FETCH_OPPORTUNITY_REQUEST,
  FETCH_OPPORTUNITY_SUCCESS,
  FETCH_OPPORTUNITY_FAIL,
  FETCH_ONE_OPPORTUNITY_REQUEST,
  FETCH_ONE_OPPORTUNITY_SUCCESS,
  FETCH_ONE_OPPORTUNITY_FAIL,
  EDIT_OPPORTUNITY_REQUEST,
  EDIT_OPPORTUNITY_SUCCESS,
  EDIT_OPPORTUNITY_FAIL,
  CLEAR
} from "./opportunityTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_OPPORTUNITY_REQUEST,
  };
};

export const fetchSuccess = (opportunities) => {
  return {
    type: FETCH_OPPORTUNITY_SUCCESS,
    payload: opportunities,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_OPPORTUNITY_FAIL,
    payload: error,
  };
};

export const fetchOneRequest = () => {
  return {
    type: FETCH_ONE_OPPORTUNITY_REQUEST,
  };
};

export const fetchOneSuccess = (oneopportunity) => {
  return {
    type: FETCH_ONE_OPPORTUNITY_SUCCESS,
    payload: oneopportunity,
  };
};

export const editOpportunityRequest = () => {
  return {
    type: EDIT_OPPORTUNITY_REQUEST,
  };
};

export const editOpportunitySuccess = () => {
  return {
    type: EDIT_OPPORTUNITY_SUCCESS,
  };
};

export const editOpportunityFail = (error) => {
  return {
    type: EDIT_OPPORTUNITY_FAIL,
    payload: error,
  };
};

export const fetchOneFail = (error) => {
  return {
    type: FETCH_ONE_OPPORTUNITY_FAIL,
    payload: error,
  };
};

export const getOpportunities = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/opportunities/All";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const getOpportunityById = (id) => async (dispatch) => {
  dispatch(fetchOneRequest());
  try {
    let url = process.env.REACT_APP_API + "/opportunities/" + id;
    const response = await axios.get(url);
    dispatch(fetchOneSuccess(response.data));
  } catch (e) {
    dispatch(fetchOneFail(e.response.data));
  }
};

export const updateOpportunity = (id, title,final_comments,nb_of_volunteers,cost,hours,languages,type,is_group,transporation,online,food,wheelchair_accessible,start_date,end_date,deadline_date,nb_of_spots,supports_disability,physical_efforts,committed,is_call_to_action,tasks_required,occupation,frequency) => async (dispatch) => {
  dispatch(editOpportunityRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/opportunities/" + id, {
      title,
      final_comments,
      nb_of_volunteers,
      cost,
      hours,
      languages,
      type,
      is_group,
      transporation,
      online,
      food,
      wheelchair_accessible,
      start_date,
      end_date,
      deadline_date,
      nb_of_spots,
      supports_disability,
      physical_efforts,
      committed,
      is_call_to_action,
      tasks_required,
      occupation,
      frequency,
    });
    dispatch(editOpportunitySuccess());
    dispatch(getOpportunities());
  } catch (e) {
    dispatch(editOpportunityFail(e.response.data));
  }
};
export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};
export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};