import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import { addQuiz, clear } from "../../redux/quiz/quizAction";
//components
import Loading from "../layout/Loading";

//css
import "./Quiz.css";

function QuizAddModal() {
  const dispatch = useDispatch();
  const [question, setQuestion] = useState("");
  const [question_ar, setQuestion_ar] = useState("");
  const [type, setType] = useState("");
  const { addLoading } = useSelector((state) => state.quizReducer);
  const [buttonState, setButtonState] = useState(true);

  const resetForm = () => {
    setQuestion("");
    setQuestion_ar("");
    setType("");
    dispatch(clear());
  };

  const addQuizHandle = (e) => {
    e.preventDefault();
    dispatch(addQuiz(question, question_ar, type));
    setQuestion("");
    setQuestion_ar("");
    setType("");
  };

  useEffect(() => {
    if (question !== "" &&question_ar !== "" && type !== "") setButtonState(false);
    else setButtonState(true);
  }, [question,question_ar ,type]);

  return (
    <div>
      <div className="modal fade" id="add-quiz-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add Quiz</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={resetForm}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form id="my-form" onSubmit={addQuizHandle}>
                  <div id="my-div" className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Question :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={question}
                        placeholder="Enter the question"
                        required
                        onChange={(e) => {
                          setQuestion(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">
                        Arabic Question :
                      </span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={question_ar}
                        placeholder="Enter the question in Arabic"
                        required
                        onChange={(e) => {
                          setQuestion_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br></br>
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Type :</span>

                      <select
                        className="  inputt col-md-8 form-controls"
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                        required
                        value={type}
                      >
                        <option value="" disabled>
                          Please select Type
                        </option>
                        <option value="organization">Organization</option>
                        <option value="location">Location</option>
                        <option value="impact">Impact</option>
                        <option value="sector">Sector</option>
                        <option value="skill">Skill</option>
                      </select>
                    </div>
                    <br />
                  </div>
                </form>

                <div className="modal-footer">
                  <button
                    id="close"
                    type="button"
                     className="close-btn"
                    data-dismiss="modal"
                    onClick={resetForm}
                  >
                    Close
                  </button>

                  <button
                    type="submit"
                    id="addAnswer"
                    className="add-btn"
                    data-dismiss="modal"
                    data-target="#add-quizAnswer-modal"
                    onClick={addQuizHandle}
                    disabled={buttonState ? true : false}
                    data-toggle="modal"
                  >
                    Add Question
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizAddModal;
