import {
    FETCH_TEAM_REQUEST,
    FETCH_TEAM_SUCCESS,
    FETCH_TEAM_FAIL,
    ADD_TEAM_REQUEST,
    ADD_TEAM_SUCCESS,
    ADD_TEAM_FAIL,
    EDIT_TEAM_REQUEST,
    EDIT_TEAM_SUCCESS,
    EDIT_TEAM_FAIL,
    DELETE_TEAM_REQUEST,
    DELETE_TEAM_SUCCESS,
    DELETE_TEAM_FAIL,
    CLEAR,
  } from "./teamTypes";
  
  export const initialState = {
    loading: false,
    teams: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const teamReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TEAM_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_TEAM_SUCCESS:
        return {
          loading: false,
          teams: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_TEAM_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
      case ADD_TEAM_REQUEST:
        return {
          ...state,
          addLoading: true,
          addError: "",
        };
      case ADD_TEAM_SUCCESS:
        return {
          ...state,
          addLoading: false,
          addError: "",
        };
      case ADD_TEAM_FAIL:
        return {
          ...state,
          addLoading: false,
          addError: action.payload,
        };
  
      case EDIT_TEAM_REQUEST:
        return {
          ...state,
          editLoading: true,
          editError: "",
        };
      case EDIT_TEAM_SUCCESS:
        return {
          ...state,
          editLoading: false,
          editError: "",
        };
      case EDIT_TEAM_FAIL:
        return {
          ...state,
          editLoading: false,
          editError: action.payload,
        };
  
      case DELETE_TEAM_REQUEST:
        return {
          ...state,
          deleteLoading: true,
          deleteError: "",
        };
      case DELETE_TEAM_SUCCESS:
        return {
          ...state,
          deleteLoading: false,
          deleteError: "",
        };
      case DELETE_TEAM_FAIL:
        return {
          ...state,
          deleteLoading: false,
          deleteError: action.payload,
        };
  
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  
  export default teamReducer;
  