import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ReactDatatable from "@ashvin27/react-datatable";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import CountryAddModel from "./CountryAddModal";
import CountryEditModel from "./CountryEditModal";
import CountryDeleteModal from "./CountryDeleteModal";

import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
//redux
import { getCountries } from "../../redux/country/countryActions";

//utils
import isAuth from "../../utils/isAuth";

function Country() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(records);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Countrr.xlsx");
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, countries, error, addError, editError, deleteError } =
    useSelector((state) => state.countryReducer);
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [name_ar, setName_ar] = useState("");
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    setRecords(
      countries.data?.map((country) => {
        return {
          key: country.id,
          id: country.id,
          name: country.name,
          name_ar: country.name_ar,
        };
      })
    );
  }, [countries]);

  const columns = [
    {
      field: "name",
      headerName: "Country",
      flex: 1,
    },
    {
      field: "name_ar",
      headerName: "Arabic Name",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      cell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-country-modal"
              className="add-btn"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.id);
                setName(record.name);
                setName_ar(record.name_ar);
              }}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              data-toggle="modal"
              data-target="#delete-country-modal"
              className="btn btn-danger btn-sm"
              onClick={() => {
                setId(record.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  // const [currentDateTime, setCurrentDateTime] = useState(new Date());
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentDateTime(new Date());
  //   }, 1000);

  //   // Clear the interval when the component unmounts
  //   return () => clearInterval(intervalId);
  // }, []);
  // // Function to format the date and time
  // const formatDateTime = (date) => {
  //   const monthNames = [
  //     "January", "February", "March", "April", "May", "June",
  //     "July", "August", "September", "October", "November", "December"
  //   ];

  //   const month = monthNames[date.getMonth()];
  //   const day = date.getDate();
  //   const year = date.getFullYear();
  //   const hours = date.getHours();
  //   const minutes = date.getMinutes();
  //   const seconds = date.getSeconds();

  //   return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
  // };

  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <CountryAddModel />
        <CountryEditModel
          id={id}
          name={name}
          name_ar={name_ar}
          setName={setName}
          setName_ar={setName_ar}
        />
        <CountryDeleteModal id={id} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            {/* <div className="date">
              <p>{formatDateTime(currentDateTime)}</p>
            </div> */}
            <button
              className="btn btn-outline-primary float-right mt-3 mr-2"
              data-toggle="modal"
              data-target="#add-country-modal"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Country
            </button>

            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
              {addError && (
                <div style={{ color: "red" }}> {addError.message}</div>
              )}
              {editError && (
                <div style={{ color: "red" }}> {editError.message}</div>
              )}
              {deleteError && (
                <div style={{ color: "red" }}> {deleteError.message}</div>
              )}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header title="Countries" subtitle="List of Countries" />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Country;
