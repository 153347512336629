import {
    FETCH_EPISODEVIDEO_REQUEST,
    FETCH_EPISODEVIDEO_SUCCESS,
    FETCH_EPISODEVIDEO_FAIL,
  
    ADD_EPISODEVIDEO_REQUEST,
    ADD_EPISODEVIDEO_SUCCESS,
    ADD_EPISODEVIDEO_FAIL,
  
    EDIT_EPISODEVIDEO_REQUEST,
    EDIT_EPISODEVIDEO_SUCCESS,
    EDIT_EPISODEVIDEO_FAIL,
  
    DELETE_EPISODEVIDEO_REQUEST,
    DELETE_EPISODEVIDEO_SUCCESS,
    DELETE_EPISODEVIDEO_FAIL,
  
    CLEAR
  } from "./episodeVideoTypes";
  
  export const initialState = {
    loading: false,
    episodeVideo: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const episodeVideoReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case FETCH_EPISODEVIDEO_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_EPISODEVIDEO_SUCCESS:
        return {
          loading: false,
          episodeVideo: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_EPISODEVIDEO_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
      case ADD_EPISODEVIDEO_REQUEST:
        return {
          ...state,
          addLoading: true,
          addError: "",
        };
      case ADD_EPISODEVIDEO_SUCCESS:
        return {
          ...state,
          addLoading: false,
          addError: ""
        };
      case ADD_EPISODEVIDEO_FAIL:
        return {
          ...state,
          addLoading: false,
          addError: action.payload,
        };
  
      case EDIT_EPISODEVIDEO_REQUEST:
        return {
          ...state,
          editLoading: true,
          editError: "",
        };
      case EDIT_EPISODEVIDEO_SUCCESS:
        return {
          ...state,
          editLoading: false,
          editError: ""
        };
      case EDIT_EPISODEVIDEO_FAIL:
        return {
          ...state,
          editLoading: false,
          editError: action.payload,
        };
  
      case DELETE_EPISODEVIDEO_REQUEST:
        return {
          ...state,
          deleteLoading: true,
          deleteError: "",
        };
      case DELETE_EPISODEVIDEO_SUCCESS:
        return {
          ...state,
          deleteLoading: false,
          deleteError: ""
        };
      case DELETE_EPISODEVIDEO_FAIL:
        return {
          ...state,
          deleteLoading: false,
          deleteError: action.payload,
        };
  
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  
  export default episodeVideoReducer;
  