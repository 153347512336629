import React from "react";
import DashboardCardStat from "../DashboardCardStat";
import { AiOutlineUser } from 'react-icons/ai';

function numberOfVolunteers(props) {
  return (
    <>
    <DashboardCardStat
      title={props.numberOfVolunteers}
      description="Number Of Volunteers"
      link="/volunteers"
      icon={<div className="circle-container"><AiOutlineUser  size={30}/></div>}
      iconColor="iconc  fa-2x"
    />
    </>
  );
}

export default numberOfVolunteers;
