import React from "react";
import DashboardCardStat from "../DashboardCardStat";
import { GiSparkles } from 'react-icons/gi';

function firstTimeVolunteers(props) {
  return (
    <>
    <DashboardCardStat
      title={props.firstTimeVolunteers}
      description="First Time Volunteers"
      link="/volunteers"
      icon={<div className="circle-container"><GiSparkles  size={30} color="gray"/></div>}
      iconColor="iconc  fa-2x"
    /></>
  );
}

export default firstTimeVolunteers;
