import axios from "axios";

//redux
import {
  FETCH_QUIZ_ANSWER_REQUEST,
  FETCH_QUIZ_ANSWER_SUCCESS,
  FETCH_QUIZ_ANSWER_FAIL,
  ADD_QUIZ_ANSWER_REQUEST,
  ADD_QUIZ_ANSWER_SUCCESS,
  ADD_QUIZ_ANSWER_FAIL,
  EDIT_QUIZ_ANSWER_REQUEST,
  EDIT_QUIZ_ANSWER_SUCCESS,
  EDIT_QUIZ_ANSWER_FAIL,
  DELETE_QUIZ_ANSWER_REQUEST,
  DELETE_QUIZ_ANSWER_SUCCESS,
  DELETE_QUIZ_ANSWER_FAIL,
  CLEAR,
} from "./quizAnswerTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_QUIZ_ANSWER_REQUEST,
  };
};

export const fetchSuccess = (quizzesAnswers) => {
  return {
    type: FETCH_QUIZ_ANSWER_SUCCESS,
    payload: quizzesAnswers,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_QUIZ_ANSWER_FAIL,
    payload: error,
  };
};

export const addQuizAnswerRequest = () => {
  return {
    type: ADD_QUIZ_ANSWER_REQUEST,
  };
};

export const addQuizAnswerSuccess = () => {
  return {
    type: ADD_QUIZ_ANSWER_SUCCESS,
  };
};

export const addQuizAnswerFail = (error) => {
  return {
    type: ADD_QUIZ_ANSWER_FAIL,
    payload: error,
  };
};

export const editQuizAnswerRequest = () => {
  return {
    type: EDIT_QUIZ_ANSWER_REQUEST,
  };
};

export const editQuizAnswerSuccess = () => {
  return {
    type: EDIT_QUIZ_ANSWER_SUCCESS,
  };
};

export const editQuizAnswerFail = (error) => {
  return {
    type: EDIT_QUIZ_ANSWER_FAIL,
    payload: error,
  };
};

export const deleteQuizAnswerRequest = () => {
  return {
    type: DELETE_QUIZ_ANSWER_REQUEST,
  };
};

export const deleteQuizAnswerSuccess = () => {
  return {
    type: DELETE_QUIZ_ANSWER_SUCCESS,
  };
};

export const deleteQuizAnswerFail = (error) => {
  return {
    type: DELETE_QUIZ_ANSWER_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getQuizAnswer = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/quizes_answers";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteQuizAnswer = (id) => async (dispatch) => {
  dispatch(deleteQuizAnswerRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/quizes_answers/" + id);
    dispatch(deleteQuizAnswerSuccess());
    dispatch(getQuizAnswer());
  } catch (e) {
    dispatch(deleteQuizAnswerFail(e.response.data));
  }
};

export const addQuizAnswer =
  (
    quize_id,
    sector,
    district_id,
    impact,
    skill,
    type,
    answer,
    answer_ar
  ) =>
  async (dispatch) => {
   
    let skills = []
    let impacts = []
    let sectors = []
    dispatch(addQuizAnswerRequest());
    skill.forEach(e => {
      skills.push( e.skill_id)
    });
      impact.forEach(e => {
        impacts.push( e.impact_id)
       
      });
      
        sector.forEach(e => {
          sectors.push( e.sector_id)
     });
   
    try {
      await axios.post(process.env.REACT_APP_API + "/quizes_answers", {
        quize_id,
        impact:impacts,
        district_id,
        sector:sectors,
        skill:skills,
        type,
        answer,
        answer_ar,
      });
      
      dispatch(addQuizAnswerSuccess());
      dispatch(getQuizAnswer());
    } catch (e) {
      dispatch(addQuizAnswerFail(e.response.data));
    }
  };

export const updateQuizAnswer = (id, answer, answer_ar) => async (dispatch) => {
  dispatch(editQuizAnswerRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/quizes_answers/" + id, {
      answer,
      answer_ar,
    });
    dispatch(editQuizAnswerSuccess());
    dispatch(getQuizAnswer());
  } catch (e) {
    dispatch(editQuizAnswerFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
