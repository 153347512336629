import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import OpportunityViewModal from "./OpportunityViewModal";
import OpportunityEditModel from "./OpportunityEditModal";
import * as XLSX from "xlsx";
import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
//redux
import { getOpportunities } from "../../redux/opportunity/opportunityActions";

//utils
import isAuth from "../../utils/isAuth";

function Opportunity() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  function exportToExcel() {
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter(
        (entry, index) => index !== 0 && index !== 2
      );
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Opportunities.xlsx");
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, opportunities, error } = useSelector(
    (state) => state.opportunityReducer
  );
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [final_comments, setFinal_comments] = useState("");
  const [nb_of_volunteers, setNb_of_volunteers] = useState("");
  const [cost, setCost] = useState("");
  const [hours, setHours] = useState("");
  const [languages, setLanguages] = useState("");
  const [type, setType] = useState("");
  const [is_group, setIs_group] = useState("");
  const [transporation, setTransporation] = useState("");
  const [online, setOnline] = useState("");
  const [food, setFood] = useState("");
  const [wheelchair_accessible, setWheelchair_accessible] = useState("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [deadline_date, setDeadline_date] = useState("");
  const [nb_of_spots, setNb_of_spots] = useState("");
  const [supports_disability, setSupports_disability] = useState("");
  const [physical_efforts, setPhysical_efforts] = useState("");
  const [committed, setCommitted] = useState("");
  const [tasks_required, setTasks_required] = useState("");
  const [is_call_to_action, setIs_call_to_action] = useState("");
  const [occupation, setOccupation] = useState("");
  const [frequency, setFrequency] = useState("");

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getOpportunities());
  }, [dispatch]);

  useEffect(() => {
    if (opportunities && opportunities.data) {
      const mappedRecords = opportunities.data.map((opportunity) => {
        return {
          key: opportunity.id,
          id: opportunity.id,
          picture:
            process.env.REACT_APP_API + "/Opportunity/" + opportunity.img_url,

          organization_Name: opportunity.organization?.name
            ? opportunity.organization?.name
            : "None",
          title: opportunity.title,
          district_Name: opportunity.district?.name,
          type: opportunity.type,
          frequency: opportunity.frequency,
          respond_time: opportunity.respond_time,
          occupation: opportunity.occupation,
          is_group: opportunity.is_group,
          transportation: opportunity.transportation,
          food: opportunity.food,
          online: opportunity.online,
          languages: opportunity.languages,
          hours: opportunity.hours,
          start_date: opportunity.start_date
            ? new Date(opportunity.start_date).toISOString().split("T")[0]
            : "",
          end_date: opportunity.end_date
            ? new Date(opportunity.end_date).toISOString().split("T")[0]
            : "",
          deadline_date: opportunity.deadline_date
            ? new Date(opportunity.deadline_date).toISOString().split("T")[0]
            : "",
          nb_of_spots: opportunity.nb_of_spots,
          nb_of_volunteers: opportunity.nb_of_volunteers,
          experience_required: opportunity.experience_required,
          cost: opportunity.cost,
          final_comments: opportunity.final_comments,
          wheelchair_accessible: opportunity.wheelchair_accessible,
          supports_disability: opportunity.supports_disability,
          physical_efforts: opportunity.physical_efforts,
          committed: opportunity.committed,
          tasks_required: opportunity.tasks_required,
        };
      });
      setRecords(mappedRecords);
    }
  }, [opportunities]);

  const columns = [
    {
      field: "picture",
      headerName: "Picture",
      height: "20rem",
      flex: 1,
      renderCell: (params) => (
        <img
          src={params.row.picture}
          alt={`Picture ${params.row.id}`}
          style={{ width: "7rem", height: "4rem" }}
        />
      ),
    },
    {
      field: "organization_Name",
      headerName: "Organization",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "district_Name",
      headerName: "District",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      className: "centerText action",
      align: "left",
      sortable: false,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#view-opportunity-modal"
              className="actions"
              style={{ marginRight: "5px", width: "30px" }}
              onClick={() => {
                setId(record.row.id);
              }}
            >
              <i className="fa fa-info"></i>
            </button>
            <button
              data-toggle="modal"
              data-target="#update-opp-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setTitle(record.row.title);
                setFinal_comments(record.row.final_comments);
                setNb_of_volunteers(record.row.nb_of_volunteers);
                setCost(record.row.cost);
                setHours(record.row.hours);
                setLanguages(record.row.languages);
                setType(record.row.type);
                setIs_group(record.row.is_group);
                setTransporation(record.row.transporation);
                setOnline(record.row.online);
                setFood(record.row.food);
                setWheelchair_accessible(record.row.wheelchair_accessible);
                setStart_date(record.row.start_date);
                setEnd_date(record.row.end_date);
                setDeadline_date(record.row.deadline_date);
                setNb_of_spots(record.row.nb_of_spots);
                setSupports_disability(record.row.supports_disability);
                setPhysical_efforts(record.row.physical_efforts);
                setCommitted(record.row.committed);
                setIs_call_to_action(record.row.is_call_to_action);
                setTasks_required(record.row.tasks_required);
                setOccupation(record.row.occupation);
                setFrequency(record.row.frequency);
              }}
            >
              <i className="fa fa-edit"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const handleReloadClick = () => {
    window.location.reload();
  };
  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <OpportunityEditModel
          id={id}
          title={title}
          final_comments={final_comments}
          nb_of_volunteers={nb_of_volunteers}
          cost={cost}
          hours={hours}
          languages={languages}
          type={type}
          is_group={is_group}
          transporation={transporation}
          online={online}
          food={food}
          wheelchair_accessible={wheelchair_accessible}
          start_date={start_date}
          end_date={end_date}
          deadline_date={deadline_date}
          nb_of_spots={nb_of_spots}
          occupation={occupation}
          supports_disability={supports_disability}
          physical_efforts={physical_efforts}
          committed={committed}
          is_call_to_action={is_call_to_action}
          tasks_required={tasks_required}
          frequency={frequency}
          setTitle={setTitle}
          setFinal_comments={setFinal_comments}
          setNb_of_volunteers={setNb_of_volunteers}
          setCost={setCost}
          setHours={setHours}
          setLanguages={setLanguages}
          setType={setType}
          setIs_group={setIs_group}
          setTransporation={setTransporation}
          setOnline={setOnline}
          setFood={setFood}
          setWheelchair_accessible={setWheelchair_accessible}
          setOccupation={setOccupation}
          setStart_date={setStart_date}
          setEnd_date={setEnd_date}
          setDeadline_date={setDeadline_date}
          setNb_of_spots={setNb_of_spots}
          setCommitted={setCommitted}
          setIs_call_to_action={setIs_call_to_action}
          setSupports_disability={setSupports_disability}
          setPhysical_efforts={setPhysical_efforts}
          setTasks_required={setTasks_required}
          setFrequency={setFrequency}
        />
        <OpportunityViewModal id={id} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header
                    title="Opportunity"
                    subtitle="List of Opportunities"
                  />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Opportunity;
