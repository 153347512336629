import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import { addEpisode, clear } from "../../redux/episode/episodeActions";

//components
import Loading from "../layout/Loading";

//css
import "./Episode.css";

function EpisodeAddModal() {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [title_ar, setTitle_ar] = useState("");
  const { addLoading } = useSelector((state) => state.episodeReducer);
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if (title !== "" && title_ar !== ""  ) setButtonState(false);
    else setButtonState(true);
  }, [title, title_ar]);
  const resetForm = () => {
    setTitle("");
    setTitle_ar("");
    dispatch(clear());
  };

  const addEpisodeHandle = (e) => {
    e.preventDefault();
    dispatch(addEpisode(title, title_ar));
    setTitle("");
    setTitle_ar("");
  };

  return (
    <div>
      <div className="modal fade" id="add-episode-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add Episode</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={addEpisodeHandle}>
                  <div className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Episode :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={title}
                        placeholder="Enter the episode's name"
                        required
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm">
                        Arabic Episode :
                      </span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={title_ar}
                        placeholder="Enter the episode's name in Arabic"
                        required
                        onChange={(e) => {
                          setTitle_ar(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addEpisodeHandle}
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      disabled={buttonState ? true : false}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EpisodeAddModal;
