import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import { addTeam, clear } from "../../redux/Team/teamAction";

//components
import Loading from "../layout/Loading";

//css
import "./team.css";

function TeamAddModal() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [name_ar, setName_ar] = useState("");
  const [image, setImage] = useState("");
  const [position, setPosition] = useState("");
  const [position_ar, setPosition_ar] = useState("");
  const [description, setDescription] = useState("");
  const [description_ar, setDescription_ar] = useState("");
  const [linked_in, setLinked_in] = useState("");
  const { addLoading } = useSelector((state) => state.teamReducer);
  const [buttonState, setButtonState] = useState(true);
  const [newImgSource, setNewImgSource] = useState("");
  const [imgName, setImgName] = useState("default.png");
  const defaultSource = process.env.REACT_APP_API + "/Team/default.png";
  const re = /(?:\.([^.]+))?$/;

  const resetForm = () => {
    setName("");
    setName_ar("");
    setDescription("");
    setDescription_ar("");
    setPosition("");
    setPosition_ar("");
    setLinked_in("");
    setImage("");
    setNewImgSource(defaultSource);
    setImgName("default.png");
    dispatch(clear());
  };

  const addteamHandle = (e) => {
    e.preventDefault();
    dispatch(
      addTeam(
        name,
        name_ar,
        description,
        description_ar,
        position,
        position_ar,
        linked_in,
        image
      )
    );
    setName("");
    setName_ar("");
    setDescription("");
    setDescription_ar("");
    setPosition("");
    setPosition_ar("");
    setLinked_in("");
    setImage("");
    setNewImgSource(defaultSource);
    setImgName("default.png");
  };

  const removeImg = (e) => {
    e.preventDefault();
    setNewImgSource(defaultSource);
    setImgName("default.png");
    setImage("");
    document.getElementById("files").value = null;
  };

  const changeImgName = (Name) => {
    setImgName(Name);
  };

  useEffect(() => {
    if (
      name !== "" &&
      name_ar !== "" &&
      description !== "" &&
      description_ar !== "" &&
      position !== "" &&
      position_ar !== "" &&
      linked_in !== ""
    )
      setButtonState(false);
    else setButtonState(true);
  }, [
    name,
    name_ar,
    description,
    description_ar,
    position,
    position_ar,
    linked_in,
  ]);

  return (
    <div>
      <div className="modal fade" id="add-team-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add Team</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={addteamHandle} encType="multipart/form-data">
                  <div className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-3 textForm2">Image :</span>

                      <div className="row">
                        <div className="row">
                          <img
                            src={
                              newImgSource !== "" ? newImgSource : defaultSource
                            }
                            style={{
                              height: "130px",
                              width: "130px",
                              marginLeft: "40px",
                            }}
                            alt={image}
                          />
                          {imgName !== "default.png" && (
                            <button className="tag-remove" onClick={removeImg}>
                              &#10006;
                            </button>
                          )}
                        </div>

                        <div className="row">
                          <label
                            htmlFor="files"
                            className="button"
                            style={{
                              marginLeft: "50px",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            {imgName !== "default.png"
                              ? "Change Image"
                              : "Choose Image"}
                          </label>
                          <input
                            id="files"
                            type="file"
                            className="inputt"
                            style={{ visibility: "hidden", width: "1px" }}
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                setImage(e.target.files[0]);
                                if (e.target.files[0].name.length > 15)
                                  changeImgName(
                                    e.target.files[0].name.substring(0, 15) +
                                      "...." +
                                      re.exec(e.target.files[0].name)[1]
                                  );
                                else changeImgName(e.target.files[0].name);
                                setNewImgSource(
                                  URL.createObjectURL(e.target.files[0])
                                );
                              }
                            }}
                          />

                          <h6
                            style={{
                              marginLeft: "15px",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            {imgName !== "default.png" ? imgName : ""}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm2">Name :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        placeholder="Enter the team's name"
                        value={name}
                        required
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm2">Arabic Name :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        placeholder="Enter the team's title in Arabic"
                        value={name_ar}
                        required
                        onChange={(e) => {
                          setName_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm2">Description :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 "
                        placeholder="Enter the discription"
                        value={description}
                        required
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm2">
                        Arabic Description :
                      </span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        placeholder="Enter the description in arabic"
                        value={description_ar}
                        required
                        onChange={(e) => {
                          setDescription_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm2">position :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        placeholder="Enter the position"
                        value={position}
                        required
                        onChange={(e) => {
                          setPosition(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm2">
                        Arabic Position :
                      </span>
                      <input
                        type="text"
                        className="  inputt col-md-8 "
                        placeholder="Enter the team's name"
                        value={position_ar}
                        required
                        onChange={(e) => {
                          setPosition_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm2">LinkedIn :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        placeholder="Enter the team's title in Arabic"
                        value={linked_in}
                        required
                        onChange={(e) => {
                          setLinked_in(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <br />
                  </div>
                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addteamHandle}
                      type="submit"
                      disabled={buttonState ? true : false}
                      className="add-btn"
                      data-dismiss="modal"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamAddModal;
