import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons/faUserAlt";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons/faUserFriends";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons/faLightbulb";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons/faAddressCard";
import { faCoins } from "@fortawesome/free-solid-svg-icons/faCoins";
import { faHandsHelping } from "@fortawesome/free-solid-svg-icons/faHandsHelping";
import { faReplyAll } from "@fortawesome/free-solid-svg-icons/faReplyAll";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons/faUserCircle";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons/faFileAlt";
import Navbar from "../layout/Navbar";
//components
import Sidebar from "../layout/Sidebar";
import DashboardCard from "./DashboardCard";
import DashboardCardStat from "./DashboardCardStat";
import "./dashboardTest.css"
//utils
import isAuth from "../../utils/isAuth";
import { getStat } from "../../redux/Stat/StatAction";
function DashboardTest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dash, setDash] = useState(
    localStorage.getItem("dashboardExpanded") === "true"
  );
  const { stat } =
    useSelector((state) => state.StatReducer);

  useEffect(() => {
    localStorage.setItem("dashboardExpanded", dash);
  }, [dash]);
  useEffect(() => {
    dispatch(getStat());
  }, [dispatch]);
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  const ToggleMenu = (e) => {
    var element = document.getElementById("wrapper");
    if (element.classList.contains("toggled")) {
      document.getElementById("wrapper").classList.remove("toggled");
    } else {
      document.getElementById("wrapper").classList.add("toggled");
    }
  };


  function toggleSidebar() {
    setDash(!dash);
  }
  return (

    <div className="all">
      <div className="d-flex" id="wrapper">

        <Sidebar />


        <div id="page-content-wrapper home">
          <Navbar />
          <div className="container-fluid home">
            <br></br><br></br>


            <div className="row px-2">

              <DashboardCardStat
                title={stat?.data?.total_volunteers}
                link="/volunteers"
                description="Total Volunteer"
                iconColor="iconc  fa-2x"
                icon={faUserFriends}
              />

              <DashboardCardStat
                title={stat?.data?.total_opportunities}
                description="Total Opportunities"
                link="/opportunities"
                icon={faLightbulb}
                iconColor="iconc  fa-2x"

              />
              <DashboardCardStat
                title={stat?.data?.total_organizations}

                description="Total organizations"
                link="/organizations"
                icon={faAddressCard}
                iconColor="iconc  fa-2x"

              />
              <DashboardCardStat
                title={stat?.data?.total_beneficiary}
                description="Total Beneficiary"


                link="/constant"
                icon={faHandsHelping}
                iconColor="iconc  fa-2x"

              />

              <DashboardCardStat
                title={stat?.data?.total_cost}

                description="Total Cost"
                link="/constant"
                iconColor="iconc  fa-2x"
                icon={faCoins}
              />
              <DashboardCardStat
                title={stat?.data?.total_Requests}

                description="Total Requests"

                iconColor="iconc  fa-2x"
                icon={faReplyAll}
              />
              <DashboardCardStat
                title={stat?.data?.total_emails}

                description="Total News Subscription"
                link="/email_subscriptions"
                iconColor="iconc  fa-2x"
                icon={faFileAlt}
              />
              <DashboardCardStat
                title={stat?.data?.total_trusted_partner}

                description="Total Trusted Partner"
                link="/trustedpart"
                iconColor="iconc  fa-2x"
                icon={faUserCircle}
              />





              <DashboardCard

                buttonText="  Admins"
                icon={faUserAlt}
                iconColor="text-info"
                link="/admins"

              />

              <DashboardCard


                buttonText="  Blogs"
                icon={faUserAlt}
                iconColor="text-info"
                link="/blogs"
                cardColor="sec"
              />
              <DashboardCard

                buttonText="  Carousels"
                icon={faUserAlt}
                iconColor="text-info"
                link="/carousels"
                cardColor="sec"

              />
              <DashboardCard

                buttonText="  Community Actions"
                icon={faUserAlt}
                iconColor="text-info"
                link="/community_actions"

              />


              <br />
              {!dash && (
                <div style={{ width: "100%" }}>
                  <button
                    className="list-group-item  list-group-item-action mb-3"
                    style={{
                      color: "#464646",
                      display: "block",
                      margin: "0 auto",
                      textAlign: "center",
                      width: "auto",
                      whiteSpace: "nowrap",
                    }}
                    onClick={toggleSidebar}
                  >
                    <b>{dash ? "Show Less  ↑" : "Show More  ↓"}</b>
                  </button>
                </div>
              )}


              {dash && (<DashboardCard

                buttonText="  Contacts"
                icon={faUserAlt}
                iconColor="text-secondary"
                link="/contacts"

              />
              )}
              {dash && (<DashboardCard

                buttonText="  Districts"
                icon={faUserAlt}
                iconColor="text-secondary"
                link="/districts"
                cardColor="sec"

              />


              )}



              {dash && (
                <DashboardCard

                  buttonText="  Disabilities"
                  icon={faUserAlt}
                  iconColor="text-secondary"
                  link="/disability"
                  cardColor="sec"
                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Donation"
                  icon={faUserAlt}
                  iconColor="text-secondary"
                  link="/donate"

                />
              )}




              {dash && (
                <DashboardCard

                  buttonText="  Email Subsriptions"
                  icon={faUserAlt}
                  iconColor="text-info"
                  link="/email_subscriptions"


                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Episodes"
                  icon={faUserAlt}
                  iconColor="text-info"
                  link="/episode"

                  cardColor="sec"
                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Episode Quizzes"
                  icon={faUserAlt}
                  iconColor="text-info"
                  link="/episodeQuiz"
                  cardColor="sec"
                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Episode Videos"
                  icon={faUserAlt}
                  iconColor="text-info"
                  link="/episodeVideo"

                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Faqs"
                  icon={faUserAlt}
                  iconColor="text-secondary"
                  link="/episode"

                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Governates"
                  icon={faUserAlt}
                  iconColor="text-secondary"
                  link="/governates"
                  cardColor="sec"
                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Impacts"
                  icon={faUserAlt}
                  iconColor="text-secondary"
                  link="/impact"
                  cardColor="sec"
                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Institutions"
                  icon={faUserAlt}
                  iconColor="text-secondary"
                  link="/institutions"

                />
              )}

              {dash && (
                <DashboardCard

                  buttonText="  Master class"
                  icon={faUserAlt}
                  iconColor="text-info"
                  link="/masterclass"

                />
              )}

              {dash && (
                <DashboardCard

                  buttonText="  Master Classes Testimonials"
                  icon={faUserAlt}
                  iconColor="text-info"
                  link="/masterclasstestimonial"
                  cardColor="sec"
                />
              )}

              {dash && (
                <DashboardCard

                  buttonText="  Nationalities"
                  icon={faUserAlt}
                  iconColor="text-info"
                  link="/nationalities"
                  cardColor="sec"
                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Open To Work"
                  icon={faUserAlt}
                  iconColor="text-info"
                  link="/opentowork"

                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Opportunities"
                  icon={faUserAlt}
                  iconColor="text-info"
                  link="/opportunities"

                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Organizations"
                  icon={faUserAlt}
                  iconColor="text-secondary"
                  link="/organizations"
                  cardColor="sec"
                />
              )}

              {dash && (
                <DashboardCard

                  buttonText="  Quizzes"
                  icon={faUserAlt}
                  iconColor="text-secondary"
                  link="/quizzes"
                  cardColor="sec"
                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Reasons"
                  icon={faUserAlt}
                  iconColor="text-secondary"
                  link="/reasons"

                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Sectosr"
                  icon={faUserAlt}
                  iconColor="text-info"
                  link="/sector"

                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Skills"
                  icon={faUserAlt}
                  iconColor="text-info"
                  link="/skills"
                  cardColor="sec"
                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Statistic"
                  icon={faUserAlt}
                  iconColor="text-info"
                  link="/constant"
                  cardColor="sec"
                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Teams"
                  icon={faUserAlt}
                  iconColor="text-info"
                  link="/team"


                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Testimonials"
                  icon={faUserAlt}
                  iconColor="text-secondary"
                  link="/testimonial"

                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Trusted Partners"
                  icon={faUserAlt}
                  iconColor="text-secondary"
                  link="/trustedpart"
                  cardColor="sec"
                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Videos"
                  icon={faUserAlt}
                  iconColor="text-secondary"
                  link="/videos"
                  cardColor="sec"
                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Volunteers"
                  icon={faUserAlt}
                  iconColor="text-secondary"
                  link="/volunteers"


                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Volunteer Spotlights"
                  icon={faUserAlt}
                  iconColor="text-info"
                  link="/volunteer_spotlights"

                />
              )}
              {dash && (
                <DashboardCard

                  buttonText="  Volunteer Spotlights QA"
                  icon={faUserAlt}
                  iconColor="text-info"
                  link="/volunteer_spotlights_QA"
                  cardColor="sec"
                />
              )}
              {dash && (
                <div style={{ width: "100%" }}>
                  <button
                    className="list-group-item  list-group-item-action mb-3"
                    style={{
                      color: "#464646",
                      display: "block",
                      margin: "0 auto",
                      textAlign: "center",
                      width: "auto",
                      whiteSpace: "nowrap",
                    }}
                    onClick={toggleSidebar}
                  >
                    <b>{dash ? "Show Less  ↑" : "Show More  ↓"}</b>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardTest;
