export const FETCH_ORGANIZATION_REQUEST = "FETCH_ORGANIZATION_REQUEST";
export const FETCH_ORGANIZATION_SUCCESS = "FETCH_ORGANIZATION_SUCCESS";
export const FETCH_ORGANIZATION_FAIL = "FETCH_ORGANIZATION_FAIL";



export const EDIT_ORGANIZATION_REQUEST = "EDIT_ORGANIZATION_REQUEST";
export const EDIT_ORGANIZATION_SUCCESS = "EDIT_ORGANIZATION_SUCESS";
export const EDIT_ORGANIZATION_FAIL = "EDIT_ORGANIZATION_FAIL";

export const FETCH_ONE_ORGANIZATION_REQUEST = "FETCH_ONE_ORGANIZATION_REQUEST";
export const FETCH_ONE_ORGANIZATION_SUCCESS = "FETCH_ONE_ORGANIZATION_SUCCESS";
export const FETCH_ONE_ORGANIZATION_FAIL = "FETCH_ONE_ORGANIZATION_FAIL";

export const CLEAR = "CLEAR";
