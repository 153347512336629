import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import { getCommunityActionById } from "../../redux/communityAction/communityActionActions";
import { getSkillsByIds } from "../../redux/Skills/SkillsAction";

//component
import Loading from "../layout/Loading";

//css
import "./CommunityAction.css";

function CommunityActionViewModal({ id }) {
  const dispatch = useDispatch();
  const { oneLoading, oneCommunityAction } = useSelector(
    (state) => state.communityActionReducer
  );
  const { skills } = useSelector((state) => state.skillsReducer);
  const [myCommunityAction, setMyCommunityAction] = useState();

  useEffect(() => {
    if (oneCommunityAction?.data?.data?.length) {
      let vol = {
        volunteer_name: oneCommunityAction.data.data[0].volunteer?.first_name,
        organization_name: oneCommunityAction.data.data[0].organization?.name,
        district_name: oneCommunityAction.data.data[0].district?.name,
        governate_name:
          oneCommunityAction.data.data[0].district?.governate?.name,
        country_name:
          oneCommunityAction.data.data[0].district?.governate?.country?.name,
        title: oneCommunityAction.data.data[0].title,
        type: oneCommunityAction.data.data[0].type,
        frequency: oneCommunityAction.data.data[0].frequency,
        respond_time: oneCommunityAction.data.data[0].respond_time,
        occupation: oneCommunityAction.data.data[0].occupation,
        is_group: oneCommunityAction.data.data[0].is_group,
        transporation: oneCommunityAction.data.data[0].transporation,
        food: oneCommunityAction.data.data[0].food,
        online: oneCommunityAction.data.data[0].online,
        languages: oneCommunityAction.data.data[0].languages,
        hours: oneCommunityAction.data.data[0].hours,
        start_date: oneCommunityAction.data.data[0].start_date,
        end_date: oneCommunityAction.data.data[0].end_date,
        deadline_date: oneCommunityAction.data.data[0].deadline_date,
        nb_of_spots: oneCommunityAction.data.data[0].nb_of_spots,
        nb_of_volunteers: oneCommunityAction.data.data[0].nb_of_volunteers,
        experience_required:
          oneCommunityAction.data.data[0].experience_required,
        cost: oneCommunityAction.data.data[0].cost,
        final_comments: oneCommunityAction.data.data[0].final_comments,
        wheelchair_accessible:
          oneCommunityAction.data.data[0].wheelchair_accessible,
        supports_disability:
          oneCommunityAction.data.data[0].supports_disability,
        physical_efforts: oneCommunityAction.data.data[0].physical_efforts,
        committed: oneCommunityAction.data.data[0].committed,
        tasks_required: oneCommunityAction.data.data[0].tasks_required,
        skills: oneCommunityAction.data.data[0].skills,
        age: oneCommunityAction.data.age,
        gender: oneCommunityAction.data.gender,
        disabilities: oneCommunityAction.data.disabilities,
        impacts: oneCommunityAction.data.impacts,
      };

      if (vol?.skills !== null) {
        dispatch(getSkillsByIds(vol?.skills));
      }

      setMyCommunityAction(vol);
    }
  }, [oneCommunityAction, dispatch]);

  const showAges = (ourAges) => {
    let agesString = "";
    for (let i = 0; i < ourAges.length; i++) {
      agesString += "• ";
      agesString += ourAges[i].age;
      agesString += "\n";
    }
    return agesString;
  };

  const showGenders = (ourGenders) => {
    let gendersString = "";
    for (let i = 0; i < ourGenders.length; i++) {
      gendersString += "• ";
      gendersString += ourGenders[i].gender;
      gendersString += "\n";
    }
    return gendersString;
  };

  const showDisabilities = (ourDisabilities) => {
    let disabilitiesString = "";
    for (let i = 0; i < ourDisabilities.length; i++) {
      disabilitiesString += "• ";
      disabilitiesString += ourDisabilities[i].disability.text;
      disabilitiesString += "\n";
    }
    return disabilitiesString;
  };

  const showImpacts = (ourImpacts) => {
    let impactsString = "";
    for (let i = 0; i < ourImpacts.length; i++) {
      impactsString += "• ";
      impactsString += ourImpacts[i].impact.title;
      impactsString += "\n";
    }
    return impactsString;
  };

  const showSkills = () => {
    let skillsString = "";
    for (let i = 1; i < skills.data?.length; i++) {
      skillsString += "• ";
      skillsString += skills.data[i]?.name;
      skillsString += "\n";
    }
    return skillsString;
  };

  useEffect(() => {
    if (id !== 0) {
      dispatch(getCommunityActionById(id));
    }
  }, [dispatch, id]);

  return (
    <div className="modal fade" id="view-communityAction-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Community Action View More </h4>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          {oneLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form>
                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Volunteer
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.volunteer_name !== null
                            ? myCommunityAction?.volunteer_name || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Wheelchair Access
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.wheelchair_accessible !== null
                            ? myCommunityAction?.wheelchair_accessible ||
                              "false"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Genders
                    </span>
                    <textarea
                      value={
                        myCommunityAction?.gender?.length > 0
                          ? showGenders(myCommunityAction.gender) || ""
                          : "None"
                      }
                      readOnly={true}
                      rows={myCommunityAction?.gender?.length > 0 ? "3" : "1"}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Title
                    </span>
                    <input
                      type="text"
                      value={
                        myCommunityAction?.title !== null
                          ? myCommunityAction?.title || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        District
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.district_name !== null
                            ? myCommunityAction?.district_name || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Governate
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.governate_name !== null
                            ? myCommunityAction?.governate_name || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Country
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.country_name !== null
                            ? myCommunityAction?.country_name || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Cost
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.cost !== null
                            ? myCommunityAction?.cost || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Experience Required
                    </span>
                    <textarea
                      value={
                        myCommunityAction?.experience_required !== null
                          ? myCommunityAction?.experience_required || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Languages
                    </span>
                    <input
                      type="text"
                      value={
                        myCommunityAction?.languages !== null
                          ? myCommunityAction?.languages || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%" }}
                    />
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Type
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.type !== null
                            ? myCommunityAction?.type || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Frequency
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.frequency !== null
                            ? myCommunityAction?.frequency || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Respond time
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.respond_time !== null
                            ? myCommunityAction?.respond_time || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Occupation
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.occupation !== null
                            ? myCommunityAction?.occupation || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Is Group
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.is_group !== null
                            ? myCommunityAction?.is_group || "false"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Transporation
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.transporation !== null
                            ? myCommunityAction?.transporation || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Tasks Required
                    </span>
                    <textarea
                      value={
                        myCommunityAction?.tasks_required !== null
                          ? myCommunityAction?.tasks_required || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Food
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.food !== null
                            ? myCommunityAction?.food || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Online
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.online !== null
                            ? myCommunityAction?.online || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Start Date
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.start_date !== null
                            ? myCommunityAction?.start_date.split("T")[0] || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        End Date
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.end_date !== null
                            ? myCommunityAction?.end_date.split("T")[0] || ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Skills
                    </span>
                    <textarea
                      value={
                        myCommunityAction?.skills !== null
                          ? showSkills() || ""
                          : "None"
                      }
                      readOnly={true}
                      rows={myCommunityAction?.skills !== null ? "3" : "1"}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Deadline
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.deadline_date !== null
                            ? myCommunityAction?.deadline_date.split("T")[0] ||
                              ""
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Spots' Number
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.nb_of_spots !== null
                            ? myCommunityAction?.nb_of_spots || "0"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Volunteers' Number
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.nb_of_volunteers !== null
                            ? myCommunityAction?.nb_of_volunteers || "0"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Hours
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.hours !== null
                            ? myCommunityAction?.hours || "0"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Disabilities
                    </span>
                    <textarea
                      value={
                        myCommunityAction?.disabilities?.length > 0
                          ? showDisabilities(myCommunityAction.disabilities) ||
                            ""
                          : "None"
                      }
                      readOnly={true}
                      rows={
                        myCommunityAction?.disabilities?.length > 0 ? "3" : "1"
                      }
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Impacts
                    </span>
                    <textarea
                      value={
                        myCommunityAction?.impacts?.length > 0
                          ? showImpacts(myCommunityAction.impacts) || ""
                          : "None"
                      }
                      readOnly={true}
                      rows={myCommunityAction?.impacts?.length > 0 ? "3" : "1"}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Ages
                    </span>
                    <textarea
                      value={
                        myCommunityAction?.age?.length > 0
                          ? showAges(myCommunityAction.age) || ""
                          : "None"
                      }
                      readOnly={true}
                      rows={myCommunityAction?.age?.length > 0 ? "3" : "1"}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />

                  <div className="formRow">
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Committed
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.committed !== null
                            ? myCommunityAction?.committed || "false"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                    <div className="formRow">
                      <span
                        className="col-md-5 textForm3"
                        style={{ width: "240px", marginLeft: "-20px" }}
                      >
                        Physical Efforts
                      </span>
                      <input
                        type="text"
                        value={
                          myCommunityAction?.physical_efforts !== null
                            ? myCommunityAction?.physical_efforts || "false"
                            : "None"
                        }
                        readOnly={true}
                        className="inputt col-md-7 form-controls"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="formRow">
                    <span
                      className="col-md-2 textForm3"
                      style={{ marginLeft: "13px" }}
                    >
                      Final Comments
                    </span>
                    <textarea
                      value={
                        myCommunityAction?.final_comments !== null
                          ? myCommunityAction?.final_comments || ""
                          : "None"
                      }
                      readOnly={true}
                      className="inputt form-controls"
                      style={{ width: "79%", resize: "none" }}
                    ></textarea>
                  </div>
                  <br />
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CommunityActionViewModal;
