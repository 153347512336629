import {
    FETCH_MASTERCLASS_REQUEST,
    FETCH_MASTERCLASS_SUCCESS,
    FETCH_MASTERCLASS_FAIL,
  
    EDIT_MASTERCLASS_REQUEST,
    EDIT_MASTERCLASS_SUCCESS,
    EDIT_MASTERCLASS_FAIL,
    DELETE_MASTERCLASS_REQUEST,
    DELETE_MASTERCLASS_SUCCESS,
    DELETE_MASTERCLASS_FAIL,
   
    
    CLEAR
  } from "./MasterclassTypes";
  
  export const initialState = {
    loading: false,
    masterclass: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const MasterclassReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case FETCH_MASTERCLASS_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_MASTERCLASS_SUCCESS:
        return {
          loading: false,
          masterclass: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_MASTERCLASS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
    
  
      case EDIT_MASTERCLASS_REQUEST:
        return {
          ...state,
          editLoading: true,
          editError: "",
        };
      case EDIT_MASTERCLASS_SUCCESS:
        return {
          ...state,
          editLoading: false,
          editError: ""
        };
      case EDIT_MASTERCLASS_FAIL:
        return {
          ...state,
          editLoading: false,
          editError: action.payload,
        };
  
    
        case DELETE_MASTERCLASS_REQUEST:
          return {
            ...state,
            deleteLoading: true,
            deleteError: "",
          };
        case DELETE_MASTERCLASS_SUCCESS:
          return {
            ...state,
            deleteLoading: false,
            deleteError: ""
          };
        case DELETE_MASTERCLASS_FAIL:
          return {
            ...state,
            deleteLoading: false,
            deleteError: action.payload,
          };
  
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  
  export default MasterclassReducer;
  