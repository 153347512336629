export const FETCH_EPISODEQUIZ_REQUEST = "FETCH_EPISODEQUIZ_REQUEST";
export const FETCH_EPISODEQUIZ_SUCCESS = "FETCH_EPISODEQUIZ_SUCCESS";
export const FETCH_EPISODEQUIZ_FAIL = "FETCH_EPISODEQUIZ_FAIL";

export const ADD_EPISODEQUIZ_REQUEST = "ADD_EPISODEQUIZ_REQUEST";
export const ADD_EPISODEQUIZ_SUCCESS = "ADD_EPISODEQUIZ_SUCESS";
export const ADD_EPISODEQUIZ_FAIL = "ADD_EPISODEQUIZ_FAIL";

export const ADD_EPISODEQUIZANSWER_REQUEST = "ADD_EPISODEQUIZANSWER_REQUEST";
export const ADD_EPISODEQUIZANSWER_SUCCESS = "ADD_EPISODEQUIZANSWER_SUCESS";
export const ADD_EPISODEQUIZANSWER_FAIL = "ADD_EPISODEQUIZANSWER_FAIL";

export const EDIT_EPISODEQUIZ_REQUEST = "EDIT_EPISODEQUIZ_REQUEST";
export const EDIT_EPISODEQUIZ_SUCCESS = "EDIT_EPISODEQUIZ_SUCESS";
export const EDIT_EPISODEQUIZ_FAIL = "EDIT_EPISODEQUIZ_FAIL";

export const DELETE_EPISODEQUIZ_REQUEST = "DELETE_EPISODEQUIZ_REQUEST";
export const DELETE_EPISODEQUIZ_SUCCESS = "DELETE_EPISODEQUIZ_SUCESS";
export const DELETE_EPISODEQUIZ_FAIL = "DELETE_EPISODEQUIZ_FAIL";

export const CLEAR = "CLEAR";