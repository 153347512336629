import axios from "axios";

//redux
import {
  FETCH_VOLUNTEER_SPOTLIGHTS_REQUEST,
  FETCH_VOLUNTEER_SPOTLIGHTS_SUCCESS,
  FETCH_VOLUNTEER_SPOTLIGHTS_FAIL,
  ADD_VOLUNTEER_SPOTLIGHTS_REQUEST,
  ADD_VOLUNTEER_SPOTLIGHTS_SUCCESS,
  ADD_VOLUNTEER_SPOTLIGHTS_FAIL,
  EDIT_VOLUNTEER_SPOTLIGHTS_REQUEST,
  EDIT_VOLUNTEER_SPOTLIGHTS_SUCCESS,
  EDIT_VOLUNTEER_SPOTLIGHTS_FAIL,
  DELETE_VOLUNTEER_SPOTLIGHTS_REQUEST,
  DELETE_VOLUNTEER_SPOTLIGHTS_SUCCESS,
  DELETE_VOLUNTEER_SPOTLIGHTS_FAIL,
  CLEAR,
} from "./volunteerSpotlightsTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_VOLUNTEER_SPOTLIGHTS_REQUEST,
  };
};

export const fetchSuccess = (volunteerSpotlights) => {
  return {
    type: FETCH_VOLUNTEER_SPOTLIGHTS_SUCCESS,
    payload: volunteerSpotlights,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_VOLUNTEER_SPOTLIGHTS_FAIL,
    payload: error,
  };
};

export const addVolunteerSpotlightsRequest = () => {
  return {
    type: ADD_VOLUNTEER_SPOTLIGHTS_REQUEST,
  };
};

export const addVolunteerSpotlightsSuccess = () => {
  return {
    type: ADD_VOLUNTEER_SPOTLIGHTS_SUCCESS,
  };
};

export const addVolunteerSpotlightsFail = (error) => {
  return {
    type: ADD_VOLUNTEER_SPOTLIGHTS_FAIL,
    payload: error,
  };
};

export const editVolunteerSpotlightsRequest = () => {
  return {
    type: EDIT_VOLUNTEER_SPOTLIGHTS_REQUEST,
  };
};

export const editVolunteerSpotlightsSuccess = () => {
  return {
    type: EDIT_VOLUNTEER_SPOTLIGHTS_SUCCESS,
  };
};

export const editVolunteerSpotlightsFail = (error) => {
  return {
    type: EDIT_VOLUNTEER_SPOTLIGHTS_FAIL,
    payload: error,
  };
};

export const deleteVolunteerSpotlightsRequest = () => {
  return {
    type: DELETE_VOLUNTEER_SPOTLIGHTS_REQUEST,
  };
};

export const deleteVolunteerSpotlightsSuccess = () => {
  return {
    type: DELETE_VOLUNTEER_SPOTLIGHTS_SUCCESS,
  };
};

export const deleteVolunteerSpotlightsFail = (error) => {
  return {
    type: DELETE_VOLUNTEER_SPOTLIGHTS_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getVolunteerSpotlights = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/volspotlight";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteVolunteerSpotlights = (id) => async (dispatch) => {
  dispatch(deleteVolunteerSpotlightsRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/volspotlight/" + id);
    dispatch(deleteVolunteerSpotlightsSuccess());
    dispatch(getVolunteerSpotlights());
  } catch (e) {
    dispatch(deleteVolunteerSpotlightsFail(e.response.data));
  }
};

export const addVolunteerSpotlights =
  (image, title, description) => async (dispatch) => {
    dispatch(addVolunteerSpotlightsRequest());
    var formData = new FormData();
    if (image) formData.append("image", image);

    formData.append("title", title);
    formData.append("description", description);
    try {
      await axios.post(process.env.REACT_APP_API + "/volspotlight", formData);
      dispatch(addVolunteerSpotlightsSuccess());
      dispatch(getVolunteerSpotlights());
    } catch (e) {
      dispatch(addVolunteerSpotlightsFail(e.response.data));
    }
  };

export const updateVolunteerSpotlights =
  (id, image, title, description) => async (dispatch) => {
    dispatch(editVolunteerSpotlightsRequest());
    var formData = new FormData();
    if (image) formData.append("image", image);

    formData.append("title", title);
    formData.append("description", description);
    try {
      await axios.put(
        process.env.REACT_APP_API + "/volspotlight/" + id,
        formData
      );
      dispatch(editVolunteerSpotlightsSuccess());
      dispatch(getVolunteerSpotlights());
    } catch (e) {
      dispatch(editVolunteerSpotlightsFail(e.response.data));
    }
  };

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
