import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ReactDatatable from "@ashvin27/react-datatable";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import NationalityAddModal from "./NationalityAddModal";
import NationalityEditModel from "./NationalityEditModal";
import NationalityDeleteModal from "./NationalityDeleteModal";
import * as XLSX from "xlsx";
import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
//redux
import { getNationalities } from "../../redux/nationality/nationalityActions";

//utils
import isAuth from "../../utils/isAuth";

function Nationality() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, nationalities, error, addError, editError, deleteError } =
    useSelector((state) => state.nationalityReducer);
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [name_ar, setName_ar] = useState("");

  function exportToExcel() {
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter((entry, index) => index !== 0);
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Master Class.xlsx");
  }

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getNationalities());
  }, [dispatch]);

  useEffect(() => {
    if (nationalities && nationalities.data) {
      const mappedRecords = nationalities.data.map((nationality) => {
        return {
          key: nationality.id,
          id: nationality.id,
          name: nationality.name,
          arabic_Name: nationality.name_ar,
        };
      });
      setRecords(mappedRecords);
    }
  }, [nationalities]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "arabic_Name",
      headerName: "Arabic Name",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      className: "centerText action",
      align: "left",
      sortable: false,
      renderCell: (params) => (
        <Fragment>
          <button
            data-toggle="modal"
            data-target="#update-nationality-modal"
            className="actions"
            style={{ marginRight: "5px" }}
            onClick={() => {
              setId(params.row.id);
              setName(params.row.name);
              setName_ar(params.row.arabic_Name);
            }}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            data-toggle="modal"
            data-target="#delete-nationality-modal"
            className="actions"
            onClick={() => {
              setId(params.row.id);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </Fragment>
      ),
    },
  ];

  const ToggleMenu = (e) => {
    var element = document.getElementById("wrapper");
    if (element.classList.contains("toggled")) {
      document.getElementById("wrapper").classList.remove("toggled");
    } else {
      document.getElementById("wrapper").classList.add("toggled");
    }
  };
  const handleReloadClick = () => {
    window.location.reload();
  };

  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <NationalityAddModal />
        <NationalityEditModel
          id={id}
          name={name}
          name_ar={name_ar}
          setName={setName}
          setName_ar={setName_ar}
        />
        <NationalityDeleteModal id={id} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button
              className="add"
              data-toggle="modal"
              data-target="#add-nationality-modal"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Nationality
            </button>

            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
              {addError && (
                <div style={{ color: "red" }}> {addError.message}</div>
              )}
              {editError && (
                <div style={{ color: "red" }}> {editError.message}</div>
              )}
              {deleteError && (
                <div style={{ color: "red" }}> {deleteError.message}</div>
              )}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header
                    title="Nationality"
                    subtitle="List of Nationalities"
                  />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nationality;
