import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

import ReactDatatable from "@ashvin27/react-datatable";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import EpisodeVideoAddModel from "./EpisodeVideoAddModal";
import EpisodeVideoEditModel from ".//EpisodeVideoEditModal";
import EpisodeVideoDeleteModal from "./EpisodeVideoDeleteModal";
import EpisodeVideoView from "./EpisodeVideoView";
//redux
import { getEpisodeVideo } from "../../redux/episodeVideo/episodeVideoActions";

//utils
import isAuth from "../../utils/isAuth";

import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
function EpisodeVideo() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, episodeVideo, error, addError, editError, deleteError } =
    useSelector((state) => state.episodeVideoReducer);
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [title_ar, setTitle_ar] = useState("");
  const [desc, setDesc] = useState("");
  const [desc_ar, setDesc_ar] = useState("");
  const [length, setLength] = useState("");
  const [name_trai, setName_trai] = useState("");
  const [name_ar_trai, setName_ar_trai] = useState("");
  const [desc_trai, setDesc_trai] = useState("");
  const [desc_ar_trai, setDesc_ar_trai] = useState("");
  const [linked_in, setLinked_in] = useState("");
  const [link, setLink] = useState("");
  const [episode_id, setEpisode_id] = useState("");

  function exportToExcel() {
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter(
        (entry, index) => index !== 0 && index !== 2
      );
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Episode Videos.xlsx");
  }

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getEpisodeVideo());
  }, [dispatch]);

  useEffect(() => {
    if (episodeVideo && episodeVideo.data) {
      const mappedRecords = episodeVideo.data.map((episodeVideo) => {
        return {
          key: episodeVideo.id,
          id: episodeVideo.id,
          episode_id: episodeVideo.episode_id,
          episode_Title: episodeVideo.episode ? episodeVideo.episode.title : "",
          video_Title: episodeVideo.title,
          arabic_Video_Title: episodeVideo.title_ar,
          description: episodeVideo.desc,
          arabic_Description: episodeVideo.desc_ar,
          trainer_Name: episodeVideo.name_trai,
          arabic_Trainer_Name: episodeVideo.name_ar_trai,
          trainer_Description: episodeVideo.desc_trai,
          arabic_Trainer_Description: episodeVideo.desc_ar_trai,
          trainer_LinkedIn: episodeVideo.linked_in,
          video_Length: episodeVideo.length,
          video_Link: episodeVideo.link,
        };
      });
      setRecords(mappedRecords);
    }
  }, [episodeVideo]);

  const [expandedText, setExpandedText] = useState(false);

  const handleSeeMoreClick = (rowKey) => {
    setExpandedText((prevExpandedText) => ({
      ...prevExpandedText,
      [rowKey]: !prevExpandedText[rowKey],
    }));
  };
  const columns = [
    {
      field: "episode_Title",
      headerName: "Episode Title",
      flex: 1,
    },
    {
      field: "video_Title",
      headerName: "Subtitle",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      cell: (rowData) => {
        const text = rowData.description;
        const rowKey = rowData.key;
        const isExpanded = expandedText[rowKey];

        if (text.length > 50) {
          if (isExpanded) {
            return (
              <div>
                {text}
                <a
                  className="see-more"
                  href="#"
                  onClick={() => handleSeeMoreClick(rowKey)}
                >
                  See Less
                </a>
              </div>
            );
          } else {
            return (
              <div>
                {text.substring(0, 50)}
                <a
                  className="see-more"
                  href="#"
                  onClick={() => handleSeeMoreClick(rowKey)}
                >
                  See More
                </a>
              </div>
            );
          }
        } else {
          return text;
        }
      },
    },
    {
      field: "video_Length",
      headerName: "Length",
      flex: 1,
    },
    {
      field: "video_Link",
      headerName: "Video Link",
      flex: 1,
    },
    {
      field: "trainer_Name",
      headerName: "Trainer",
      flex: 1,
    },
    {
      field: "trainer_Description",
      headerName: "Trainer Description",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 150,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-episodeVideoview-modal"
              className="actions"
              style={{ marginRight: "5px", width: "30px" }}
              onClick={() => {
                setId(record.row.id);
                setTitle(record.row.video_Title);
                setTitle_ar(record.row.arabic_Video_Title);
                setDesc(record.row.description);
                setDesc_ar(record.row.arabic_Description);
                setName_trai(record.row.trainer_Name);
                setName_ar_trai(record.row.arabic_Trainer_Name);
                setDesc_ar_trai(record.row.arabic_Trainer_Description);
                setDesc_trai(record.row.trainer_Description);
                setLinked_in(record.row.trainer_LinkedIn);
                setLength(record.row.video_Length);
                setLink(record.row.video_Link);
                setEpisode_id(record.row.episode_id);
              }}
            >
              <i className="fa fa-info"></i>
            </button>
            <button
              data-toggle="modal"
              data-target="#update-episodeVideo-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setTitle(record.row.video_Title);
                setTitle_ar(record.row.arabic_Video_Title);
                setDesc(record.row.description);
                setDesc_ar(record.row.arabic_Description);
                setName_trai(record.row.trainer_Name);
                setName_ar_trai(record.row.arabic_Trainer_Name);
                setDesc_ar_trai(record.row.arabic_Trainer_Description);
                setDesc_trai(record.row.trainer_Description);
                setLinked_in(record.row.trainer_LinkedIn);
                setLength(record.row.video_Length);
                setLink(record.row.video_Link);
                setEpisode_id(record.row.episode_id);
              }}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              data-toggle="modal"
              data-target="#delete-episodeVideo-modal"
              className="actions"
              onClick={() => {
                setId(record.row.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const handleReloadClick = () => {
    window.location.reload();
  };
  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <EpisodeVideoAddModel />
        <EpisodeVideoView
          id={id}
          title={title}
          title_ar={title_ar}
          length={length}
          link={link}
          desc={desc}
          desc_ar={desc_ar}
          name_trai={name_trai}
          name_ar_trai={name_ar_trai}
          desc_trai={desc_trai}
          desc_ar_trai={desc_ar_trai}
          linked_in={linked_in}
          episode_id={episode_id}
          setDesc={setDesc}
          setDesc_ar={setDesc_ar}
          setName_trai={setName_trai}
          setName_ar_trai={setName_ar_trai}
          setDesc_trai={setDesc_trai}
          setDesc_ar_trai={setDesc_ar_trai}
          setLinked_in={setLinked_in}
          setTitle={setTitle}
          setTitle_ar={setTitle_ar}
          setLength={setLength}
          setLink={setLink}
          setEpisode_id={setEpisode_id}
        />
        <EpisodeVideoEditModel
          id={id}
          title={title}
          title_ar={title_ar}
          length={length}
          link={link}
          desc={desc}
          desc_ar={desc_ar}
          name_trai={name_trai}
          name_ar_trai={name_ar_trai}
          desc_trai={desc_trai}
          desc_ar_trai={desc_ar_trai}
          linked_in={linked_in}
          episode_id={episode_id}
          setDesc={setDesc}
          setDesc_ar={setDesc_ar}
          setName_trai={setName_trai}
          setName_ar_trai={setName_ar_trai}
          setDesc_trai={setDesc_trai}
          setDesc_ar_trai={setDesc_ar_trai}
          setLinked_in={setLinked_in}
          setTitle={setTitle}
          setTitle_ar={setTitle_ar}
          setLength={setLength}
          setLink={setLink}
          setEpisode_id={setEpisode_id}
        />
        <EpisodeVideoDeleteModal id={id} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button
              className="add"
              data-toggle="modal"
              data-target="#add-episodeVideo-modal"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Episode Video
            </button>
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
              {addError && (
                <div style={{ color: "red" }}> {addError.message}</div>
              )}
              {editError && (
                <div style={{ color: "red" }}> {editError.message}</div>
              )}
              {deleteError && (
                <div style={{ color: "red" }}> {deleteError.message}</div>
              )}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header
                    title="Episode Video"
                    subtitle="List of Episode Videoes"
                  />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EpisodeVideo;
