import {
    FETCH_CONSTANT_REQUEST,
    FETCH_CONSTANT_SUCCESS,
    FETCH_CONSTANT_FAIL,
  
    EDIT_CONSTANT_REQUEST,
    EDIT_CONSTANT_SUCCESS,
    EDIT_CONSTANT_FAIL,
  
    
  
    CLEAR
  } from "./constantTypes";
  
  export const initialState = {
    loading: false,
    constants: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const constantReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case FETCH_CONSTANT_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_CONSTANT_SUCCESS:
        return {
          loading: false,
          constants: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_CONSTANT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
   
  
      case EDIT_CONSTANT_REQUEST:
        return {
          ...state,
          editLoading: true,
          editError: "",
        };
      case EDIT_CONSTANT_SUCCESS:
        return {
          ...state,
          editLoading: false,
          editError: ""
        };
      case EDIT_CONSTANT_FAIL:
        return {
          ...state,
          editLoading: false,
          editError: action.payload,
        };

  
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  
  export default constantReducer;
  