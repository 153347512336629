export const FETCH_EPISODE_REQUEST = "FETCH_EPISODE_REQUEST";
export const FETCH_EPISODE_SUCCESS = "FETCH_EPISODE_SUCCESS";
export const FETCH_EPISODE_FAIL = "FETCH_EPISODE_FAIL";

export const ADD_EPISODE_REQUEST = "ADD_EPISODE_REQUEST";
export const ADD_EPISODE_SUCCESS = "ADD_EPISODE_SUCESS";
export const ADD_EPISODE_FAIL = "ADD_EPISODE_FAIL";

export const EDIT_EPISODE_REQUEST = "EDIT_EPISODE_REQUEST";
export const EDIT_EPISODE_SUCCESS = "EDIT_EPISODE_SUCESS";
export const EDIT_EPISODE_FAIL = "EDIT_EPISODE_FAIL";

export const DELETE_EPISODE_REQUEST = "DELETE_EPISODE_REQUEST";
export const DELETE_EPISODE_SUCCESS = "DELETE_EPISODE_SUCESS";
export const DELETE_EPISODE_FAIL = "DELETE_EPISODE_FAIL";

export const CLEAR = "CLEAR";
