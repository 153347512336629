import React from "react";
import { useDispatch } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import { deleteNationality } from "../../redux/nationality/nationalityActions";

function NationalityDeleteModal(props) {
  const dispatch = useDispatch();

  const deleteNationalityHandler = () => {
    dispatch(deleteNationality(props.id));
  };

  return (
    <div>
      <div className="modal fade" id="delete-nationality-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Delete Nationality</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p className="text-centers">
                Are you sure you want to delete this nationality?
              </p>
              <div className="row mt-2 d-flex justify-content-center">
                <button
                  className="add-btn-del"
                  onClick={deleteNationalityHandler}
                  data-dismiss="modal"
                >
                  Delete
                </button>
                <button className="close-btn-del" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NationalityDeleteModal;
