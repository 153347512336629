import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import { clear, updateTRUSTEDPART } from "../../redux/TrustedPart/TrustedPartAction";

//component
import Loading from "../layout/Loading";

//css
import "./TrustedPart.css";

function TrustedPartEditModel({
    id,
    image,
    section,
    section_ar,
    name, 
    name_ar,
    description,
    description_ar,
    description_title,
    description_title_ar,
    
    setImage,
    setSection,
    setSection_ar,
    setName, 
    setName_ar,
    setDescription,
    setDescription_ar,
    setDescription_title,
    setDescription_title_ar,
}) {
    const dispatch = useDispatch();
    const { editLoading } = useSelector((state) => state.TrustedPartReducer);
    const [buttonState, setButtonState] = useState(true);
    const [newImgSource, setNewImgSource] = useState("");
    const [imgName, setImgName] = useState();
    const [newPicture, setNewPicture] = useState("");
    const defaultSource =
        process.env.REACT_APP_API + "/TrustedPartner/" + (image ? image : "default.png");
    const re = /(?:\.([^.]+))?$/;
  
    const resetForm = () => {
      
      setSection("");
      setSection_ar("");
      setName("");
      setName_ar("");
      setDescription("");
      setDescription_ar("");
      setDescription_title("");
      setDescription_title_ar("");
      setNewImgSource(defaultSource);
      setImgName("default.png");
      setImage("");

        if (image) {
            let index = image.indexOf("+") + 1;
            setImgName(image.substring(index, image.length));
        }
        setNewPicture("");
        setNewImgSource("");
        dispatch(clear());
    };

    const editTrustedPartHandle = (e) => {
        e.preventDefault();
        dispatch(updateTRUSTEDPART(id, newPicture, section, section_ar, name, name_ar, description, description_ar, description_title, description_title_ar));
    };

    const removeImg = (e) => {
        e.preventDefault();
        setNewImgSource(process.env.REACT_APP_API + "/TrustedPartner/default.png");
        setImgName("default.png");
        setNewPicture("default.png");
        document.getElementById("myFiles").value = null;
    };

    const changeImgName = (Name) => {
        setImgName(Name);
    };

    useEffect(() => {
        if (image) {
            let index = image.indexOf("+") + 1;
            setImgName(image.substring(index, image.length));
        }
    }, [image]);

    useEffect(() => {
        if (
         
    
          section!== "" &&
          section_ar!== "" &&
          name!== "" &&
          name_ar!== "" &&
          description!== "" &&
          description_ar!== "" &&
          description_title!== "" &&
          description_title_ar
        )
          setButtonState(false);
        else setButtonState(true);
      }, [section,section_ar,name,name_ar,description,description_ar,description_title,description_title_ar]);

    return (
        <div className="modal fade" id="update-TrustedPart-modal">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-headers">
                        <h4 className="modal-title">Edit Trusted Partner</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={resetForm}
                        >
                            &times;
                        </button>
                    </div>
                    {editLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="modal-body">
                                <form onSubmit={editTrustedPartHandle} encType="multipart/form-data">
                                    <div className="row formRow">
                                        <span className="col-md-3 textForm2">Image :</span>

                                        <div className="row">
                                            <div className="row">
                                                <img
                                                    src={
                                                        newImgSource !== "" ? newImgSource : defaultSource
                                                    }
                                                    style={{
                                                        height: "130px",
                                                        width: "130px",
                                                        marginLeft: "40px",
                                                    }}
                                                    alt={image}
                                                />
                                                {imgName !== "default.png" && (
                                                    <button className="tag-remove" onClick={removeImg}>
                                                        &#10006;
                                                    </button>
                                                )}
                                            </div>

                                            <div className="row">
                                                <label
                                                    htmlFor="myFiles"
                                                    className="button"
                                                    style={{
                                                        marginLeft: "50px",
                                                        marginTop: "auto",
                                                        marginBottom: "auto",
                                                    }}
                                                >
                                                    {imgName !== "default.png"
                                                        ? "Change Image"
                                                        : "Choose Image"}
                                                </label>
                                                <input
                                                    id="myFiles"
                                                    type="file"
                                                    className="  inputt "
                                                    style={{ visibility: "hidden", width: "1px" }}
                                                    onChange={(e) => {
                                                        if (e.target.files[0]) {
                                                            setNewPicture(e.target.files[0]);
                                                            if (e.target.files[0].name.length > 15)
                                                                changeImgName(
                                                                    e.target.files[0].name.substring(0, 15) +
                                                                    "...." +
                                                                    re.exec(e.target.files[0].name)[1]
                                                                );
                                                            else changeImgName(e.target.files[0].name);
                                                            setNewImgSource(
                                                                URL.createObjectURL(e.target.files[0])
                                                            );
                                                        }
                                                    }}
                                                />

                                                <h6
                                                    style={{
                                                        marginLeft: "15px",
                                                        marginTop: "auto",
                                                        marginBottom: "auto",
                                                    }}
                                                >
                                                    {imgName !== "default.png"
                                                        ? imgName?.length > 15
                                                            ? imgName.substring(0, 15) +
                                                            "...." +
                                                            re.exec(imgName)[1]
                                                            : imgName
                                                        : ""}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <br />

                                   
                    <br></br>



                    <div className="row formRow">
                      <span className="col-md-3 textForm">Section :</span>
                    <select
                        className="  inputt col-md-8 form-controls"
                        onChange={(e) => {
                          setSection(e.target.value);
                          if(e.target.value==="Academic Partners"){
                            setSection_ar("شركاء أكاديميون")
                          }
                          else if(e.target.value==="Transportation Partners"){
                          setSection_ar("شركاء النقل")
                          }
                          else if(e.target.value==="Community Partners"){
                            setSection_ar("شركاء المجتمع")
                            }
                          
                        }}
                        required
                        value={section}
                      >
                        <option value="" disabled>
                          Please select the Section
                        </option>
                        <option value="Academic Partners">
                        Academic Partners
                          </option>
                          <option value="Transportation Partners">
                          Transportation Partners
                          </option>
                          <option value="Community Partners">
                          Community Partners
                          </option>
                       
                      </select>
                      </div>
                    <br></br>
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Section In Arabic:</span>
                    <select
                        className="  inputt col-md-8 form-controls"
                        onChange={(e) => {
                          setSection_ar(e.target.value);
                        }}
                        requiredCommunity
                        value={section_ar}
                      >
                        <option value="" disabled>
                          Please select the Section in Arabic
                        </option>
                        <option value="شركاء أكاديميون" disabled>
                        شركاء أكاديميون
                          </option>
                          <option value="شركاء النقل" disabled>
                          شركاء النقل
                          </option>
                          <option value="شركاء المجتمع" disabled>
                          شركاء المجتمع
                          </option>
                       
                      </select>
                      </div>
                    <br></br>
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Name :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={name}
                        placeholder="Enter the Name"
                        required
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <br></br>
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Arabic Name :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={name_ar}
                        placeholder="Enter the Arabic name"
                        required
                        onChange={(e) => {
                          setName_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Description :</span>
                      <textarea
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={description}
                        placeholder="Enter the Description"
                        required
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Arabic Description :</span>
                      <textarea
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={description_ar}
                        placeholder="Enter the Description In Arabic"
                        required
                        onChange={(e) => {
                          setDescription_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Description Title :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={description_title}
                        placeholder="Enter the Description Title"
                        required
                        onChange={(e) => {
                          setDescription_title(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Description Title Arabic:</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={description_title_ar}
                        placeholder="Enter the Description Title in Arabic"
                        required
                        onChange={(e) => {
                          setDescription_title_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                                    <br />

                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="close-btn"
                                            data-dismiss="modal"
                                            onClick={resetForm}
                                        >
                                            Close
                                        </button>
                                        <button
                                            type="submit"
                                            className="add-btn"
                                            data-dismiss="modal"
                                            disabled={buttonState ? true : false}
                                            onClick={editTrustedPartHandle}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TrustedPartEditModel;
