export const FETCH_EPISODEVIDEO_REQUEST = "FETCH_EPISODEVIDEO_REQUEST";
export const FETCH_EPISODEVIDEO_SUCCESS = "FETCH_EPISODEVIDEO_SUCCESS";
export const FETCH_EPISODEVIDEO_FAIL = "FETCH_EPISODEVIDEO_FAIL";

export const ADD_EPISODEVIDEO_REQUEST = "ADD_EPISODEVIDEO_REQUEST";
export const ADD_EPISODEVIDEO_SUCCESS = "ADD_EPISODEVIDEO_SUCESS";
export const ADD_EPISODEVIDEO_FAIL = "ADD_EPISODEVIDEO_FAIL";

export const EDIT_EPISODEVIDEO_REQUEST = "EDIT_EPISODEVIDEO_REQUEST";
export const EDIT_EPISODEVIDEO_SUCCESS = "EDIT_EPISODEVIDEO_SUCESS";
export const EDIT_EPISODEVIDEO_FAIL = "EDIT_EPISODEVIDEO_FAIL";

export const DELETE_EPISODEVIDEO_REQUEST = "DELETE_EPISODEVIDEO_REQUEST";
export const DELETE_EPISODEVIDEO_SUCCESS = "DELETE_EPISODEVIDEO_SUCESS";
export const DELETE_EPISODEVIDEO_FAIL = "DELETE_EPISODEVIDEO_FAIL";

export const CLEAR = "CLEAR";