import axios from "axios";

//redux
import {
    FETCH_REASONS_REQUEST,
    FETCH_REASONS_SUCCESS,
    FETCH_REASONS_FAIL,
  
    ADD_REASONS_REQUEST,
    ADD_REASONS_SUCCESS,
    ADD_REASONS_FAIL,
  
    EDIT_REASONS_REQUEST,
    EDIT_REASONS_SUCCESS,
    EDIT_REASONS_FAIL,
  
    DELETE_REASONS_REQUEST,
    DELETE_REASONS_SUCCESS,
    DELETE_REASONS_FAIL,
  
    CLEAR
} from "./ReasonsTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_REASONS_REQUEST,
  };
};

export const fetchSuccess = (Reasons) => {
  return {
    type: FETCH_REASONS_SUCCESS,
    payload: Reasons,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_REASONS_FAIL,
    payload: error,
  };
};

export const addReasonsRequest = () => {
  return {
    type: ADD_REASONS_REQUEST,
  };
};

export const addReasonsSuccess = () => {
  return {
    type: ADD_REASONS_SUCCESS,
  };
};

export const addReasonsFail = (error) => {
  return {
    type: ADD_REASONS_FAIL,
    payload: error,
  };
};

export const editReasonsRequest = () => {
  return {
    type: EDIT_REASONS_REQUEST,
  };
};

export const editReasonsSuccess = () => {
  return {
    type: EDIT_REASONS_SUCCESS,
  };
};

export const editReasonsFail = (error) => {
  return {
    type: EDIT_REASONS_FAIL,
    payload: error,
  };
};

export const deleteReasonsRequest = () => {
  return {
    type: DELETE_REASONS_REQUEST,
  };
};

export const deleteReasonsSuccess = () => {
  return {
    type: DELETE_REASONS_SUCCESS,
  };
};

export const deleteReasonsFail = (error) => {
  return {
    type: DELETE_REASONS_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getReasons = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/reasons";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteReasons = (id) => async (dispatch) => {
  dispatch(deleteReasonsRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/reasons/" + id);
    dispatch(deleteReasonsSuccess());
    dispatch(getReasons());
  } catch (e) {
    dispatch(deleteReasonsFail(e.response.data));
  }
};

export const addReasons = (name, name_ar) => async (dispatch) => {
  dispatch(addReasonsRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/reasons", {
        name,
        name_ar,
    });
    dispatch(addReasonsSuccess());
    dispatch(getReasons());
  } catch (e) {
    dispatch(addReasonsFail(e.response.data));
  }
};

export const updateReasons= (id, name, name_ar) => async (dispatch) => {
  dispatch(editReasonsRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/reasons/" + id, {
      name,
      name_ar,
    });
    dispatch(editReasonsSuccess());
    dispatch(getReasons());
  } catch (e) {
    dispatch(editReasonsFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
