import React from "react";

function ContactDetailedMessage({ detailedMessage }) {

  return (
    <div>
      <div className="modal fade" id="detailed-message-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Detailed Message</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p className="text-center">{detailedMessage}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactDetailedMessage;
