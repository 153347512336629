import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import { clear, updateTeam } from "../../redux/Team/teamAction";

//component
import Loading from "../layout/Loading";

//css
import "./team.css";

function TeamEditModel({
  id,
  image,
  name,
  name_ar,
  description,
  description_ar,
  position,
  position_ar,
  linked_in,
  setImage,
  setName,
  setName_ar,
  setDescription,
  setDescription_ar,
  setPosition,
  setPosition_ar,
  setLinked_in,
}) {
  const dispatch = useDispatch();
  const { editLoading } = useSelector((state) => state.teamReducer);
  const [buttonState, setButtonState] = useState(true);
  const [newImgSource, setNewImgSource] = useState("");
  const [imgName, setImgName] = useState();
  const [newPicture, setNewPicture] = useState("");
  const oldImgSource =
    process.env.REACT_APP_API + "/Team/" + (image ? image : "default.png");
  const re = /(?:\.([^.]+))?$/;

  const resetForm = () => {
    setImage();
    setName("");
    setName_ar("");
    setDescription("");
    setDescription_ar("");
    setPosition("");
    setPosition_ar("");
    setLinked_in("");

    if (image) {
      let index = image.indexOf("+") + 1;
      setImgName(image.substring(index, image.length));
    }
    setNewPicture("");
    setNewImgSource("");
    dispatch(clear());
  };

  const editTeamHandle = (e) => {
    e.preventDefault();
    dispatch(
      updateTeam(
        id,
        name,
        name_ar,
        description,
        description_ar,
        position,
        position_ar,
        linked_in,
        newPicture
      )
    );
  };

  const removeImg = (e) => {
    e.preventDefault();
    setNewImgSource(process.env.REACT_APP_API + "/Team/default.png");
    setImgName("default.png");
    setNewPicture("default.png");
    document.getElementById("myFiles").value = null;
  };

  const changeImgName = (Name) => {
    setImgName(Name);
  };

  useEffect(() => {
    if (image) {
      let index = image.indexOf("+") + 1;
      setImgName(image.substring(index, image.length));
    }
  }, [image]);

  useEffect(() => {
    if (
      name !== "" &&
      name_ar !== "" &&
      description !== "" &&
      description_ar !== "" &&
      position !== "" &&
      position_ar !== "" &&
      linked_in !== ""
    )
      setButtonState(false);
    else setButtonState(true);
  }, [
    name,
    name_ar,
    description,
    description_ar,
    position,
    position_ar,
    linked_in,
  ]);

  return (
    <div className="modal fade" id="update-team-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Edit team</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={resetForm}
            >
              &times;
            </button>
          </div>
          {editLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form onSubmit={editTeamHandle} encType="multipart/form-data">
                  <div className="row formRow">
                    <span className="col-md-3 textForm2">Image :</span>

                    <div className="row">
                      <div className="row">
                        <img
                          src={
                            newImgSource !== "" ? newImgSource : oldImgSource
                          }
                          style={{
                            height: "130px",
                            width: "130px",
                            marginLeft: "40px",
                          }}
                          alt={image}
                        />
                        {imgName !== "default.png" && (
                          <button className="tag-remove" onClick={removeImg}>
                            &#10006;
                          </button>
                        )}
                      </div>

                      <div className="row">
                        <label
                          htmlFor="myFiles"
                          className="button"
                          style={{
                            marginLeft: "50px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        >
                          {imgName !== "default.png"
                            ? "Change Image"
                            : "Choose Image"}
                        </label>
                        <input
                          id="myFiles"
                          type="file"
                          className="  inputt "
                          style={{ visibility: "hidden", width: "1px" }}
                          onChange={(e) => {
                            if (e.target.files[0]) {
                              setNewPicture(e.target.files[0]);
                              if (e.target.files[0].name.length > 15)
                                changeImgName(
                                  e.target.files[0].name.substring(0, 15) +
                                    "...." +
                                    re.exec(e.target.files[0].name)[1]
                                );
                              else changeImgName(e.target.files[0].name);
                              setNewImgSource(
                                URL.createObjectURL(e.target.files[0])
                              );
                            }
                          }}
                        />

                        <h6
                          style={{
                            marginLeft: "15px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        >
                          {imgName !== "default.png"
                            ? imgName?.length > 15
                              ? imgName.substring(0, 15) +
                                "...." +
                                re.exec(imgName)[1]
                              : imgName
                            : ""}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <br />

                  <div className="row formRow">
                    <span className="col-md-3 textForm2">Name :</span>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-controls"
                      placeholder="Enter the team's name"
                      value={name}
                      required
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm2">Arabic Name :</span>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-controls"
                      placeholder="Enter the team's name in Arabic"
                      value={name_ar}
                      required
                      onChange={(e) => {
                        setName_ar(e.target.value);
                      }}
                    />
                  </div>
                  <br />

                  <div className="row formRow">
                    <span className="col-md-3 textForm2">Description :</span>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-controls"
                      placeholder="Enter the Description"
                      value={description}
                      required
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm2">
                      Arabic Description :
                    </span>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-controls"
                      placeholder="Enter the team's description in Arabic"
                      value={description_ar}
                      required
                      onChange={(e) => {
                        setDescription_ar(e.target.value);
                      }}
                    />
                  </div>
                  <br />

                  <div className="row formRow">
                    <span className="col-md-3 textForm2">Position :</span>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-controls"
                      placeholder="Enter the position"
                      value={position}
                      required
                      onChange={(e) => {
                        setPosition(e.target.value);
                      }}
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm2">
                      Arabic Position :
                    </span>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-controls"
                      placeholder="Enter the position in Arabic"
                      value={position_ar}
                      required
                      onChange={(e) => {
                        setPosition_ar(e.target.value);
                      }}
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm2">LinkedIn :</span>
                    <input
                      type="text"
                      className="  inputt col-md-8 form-controls"
                      placeholder="Enter the position in Arabic"
                      value={linked_in}
                      required
                      onChange={(e) => {
                        setLinked_in(e.target.value);
                      }}
                    />
                  </div>
                  <br />

                  <div className="modal-footer">
                    <button
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={resetForm}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      disabled={buttonState ? true : false}
                      onClick={editTeamHandle}
                    >
                      Edit
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TeamEditModel;
