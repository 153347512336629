import axios from "axios";

//redux
import {
    FETCH_VIDEOS_REQUEST,
    FETCH_VIDEOS_SUCCESS,
    FETCH_VIDEOS_FAIL,
  
    ADD_VIDEOS_REQUEST,
    ADD_VIDEOS_SUCCESS,
    ADD_VIDEOS_FAIL,
  
    EDIT_VIDEOS_REQUEST,
    EDIT_VIDEOS_SUCCESS,
    EDIT_VIDEOS_FAIL,
  
    DELETE_VIDEOS_REQUEST,
    DELETE_VIDEOS_SUCCESS,
    DELETE_VIDEOS_FAIL,
  
    CLEAR
  } from "./videosTypes";
  

export const fetchRequest = () => {
  return {
    type: FETCH_VIDEOS_REQUEST,
  };
};

export const fetchSuccess = (Videos) => {
  return {
    type: FETCH_VIDEOS_SUCCESS,
    payload: Videos,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_VIDEOS_FAIL,
    payload: error,
  };
};

export const addVideosRequest = () => {
  return {
    type: ADD_VIDEOS_REQUEST,
  };
};

export const addVideosSuccess = () => {
  return {
    type: ADD_VIDEOS_SUCCESS,
  };
};

export const addVideosFail = (error) => {
  return {
    type: ADD_VIDEOS_FAIL,
    payload: error,
  };
};

export const editVideosRequest = () => {
  return {
    type: EDIT_VIDEOS_REQUEST,
  };
};

export const editVideosSuccess = () => {
  return {
    type: EDIT_VIDEOS_SUCCESS,
  };
};

export const editVideosFail = (error) => {
  return {
    type: EDIT_VIDEOS_FAIL,
    payload: error,
  };
};

export const deleteVideosRequest = () => {
  return {
    type: DELETE_VIDEOS_REQUEST,
  };
};

export const deleteVideosSuccess = () => {
  return {
    type: DELETE_VIDEOS_SUCCESS,
  };
};

export const deleteVideosFail = (error) => {
  return {
    type: DELETE_VIDEOS_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};


export const getVideos = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/video";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};


export const deleteVideos = (id) => async (dispatch) => {
  dispatch(deleteVideosRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/video/" + id);
    dispatch(deleteVideosSuccess());
    dispatch(getVideos());
  } catch (e) {
    dispatch(deleteVideosFail(e.response.data));
  }
};

export const addVideos = (link,title) => async (dispatch) => {
  dispatch(addVideosRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/video", {
        link,
        title,      
    });
    dispatch(addVideosSuccess());
    dispatch(getVideos());
  } catch (e) {
    dispatch(addVideosFail(e.response.data));
  }
};

export const updateVideos= (id,link,title) => async (dispatch) => {
  dispatch(editVideosRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/video/" + id, {
      link,
      title,
    });
    dispatch(editVideosSuccess());
    dispatch(getVideos());
  } catch (e) {
    dispatch(editVideosFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
