import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

import ReactDatatable from "@ashvin27/react-datatable";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";

//redux
import { getOpenToWork } from "../../redux/volunteer/volunteerActions.js";

//utils
import isAuth from "../../utils/isAuth";

function OpenToWork() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  function exportToExcel() {
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter(
        (entry, index) => index !== 0 && index !== 6
      );
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Open To Work Volunteers.xlsx");
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, volunteers, error } = useSelector(
    (state) => state.volunteerReducer
  );
  const [records, setRecords] = useState([]);
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getOpenToWork());
  }, [dispatch]);

  useEffect(() => {
    if (volunteers && volunteers.data) {
      const mappedRecords = volunteers.data.map((volunteer) => {
        return {
          key: volunteer.id,
          id: volunteer.id,
          first_Name: volunteer.first_name,
          last_Name: volunteer.last_name,
          phone_Number:
            volunteer.phone_number !== null && volunteer.phone_number !== ""
              ? volunteer.phone_number
              : "None",
          linkedIn: volunteer.linked_in,
          open_To_Work: volunteer.open_to_work,
        };
      });
      setRecords(mappedRecords);
    }
  }, [volunteers]);

  const columns = [
    {
      field: "first_Name",
      headerName: "First Name",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "last_Name",
      headerName: "Last Name",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "phone_Number",
      headerName: "Phone",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "linkedIn",
      headerName: "LinkedIn",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
  ];

  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header
                    title="Open to Work"
                    subtitle="List of People Who are Open to Work"
                  />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenToWork;
