import React from 'react';
import { Link } from 'react-router-dom';

function DashboardCard({ title,  buttonText, icon, iconColor, link, cardColor }) {
  return (
    <div className="col-lg-3 col-sm-6 " style={{minHeight: "10rem"}}>
      <div className={`card ${cardColor} text-white shadow-lg d-flex justify-content-center  card` } style={{minHeight: "8rem"}}>
        <div className="card-body ">
          <Link to={link} className="btn btn-light  d-flex justify-content-center but"> {buttonText}</Link>
        </div>
      </div>
    </div>
  );
}

export default DashboardCard;
