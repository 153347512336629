import {
    FETCH_IMPACT_REQUEST,
    FETCH_IMPACT_SUCCESS,
    FETCH_IMPACT_FAIL,
    ADD_IMPACT_REQUEST,
    ADD_IMPACT_SUCCESS,
    ADD_IMPACT_FAIL,
    EDIT_IMPACT_REQUEST,
    EDIT_IMPACT_SUCCESS,
    EDIT_IMPACT_FAIL,
    DELETE_IMPACT_REQUEST,
    DELETE_IMPACT_SUCCESS,
    DELETE_IMPACT_FAIL,
    CLEAR,
  } from "./impactTypes";
  
  export const initialState = {
    loading: false,
    impacts: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const impactReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_IMPACT_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_IMPACT_SUCCESS:
        return {
          loading: false,
          impacts: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_IMPACT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
      case ADD_IMPACT_REQUEST:
        return {
          ...state,
          addLoading: true,
          addError: "",
        };
      case ADD_IMPACT_SUCCESS:
        return {
          ...state,
          addLoading: false,
          addError: "",
        };
      case ADD_IMPACT_FAIL:
        return {
          ...state,
          addLoading: false,
          addError: action.payload,
        };
  
      case EDIT_IMPACT_REQUEST:
        return {
          ...state,
          editLoading: true,
          editError: "",
        };
      case EDIT_IMPACT_SUCCESS:
        return {
          ...state,
          editLoading: false,
          editError: "",
        };
      case EDIT_IMPACT_FAIL:
        return {
          ...state,
          editLoading: false,
          editError: action.payload,
        };
  
      case DELETE_IMPACT_REQUEST:
        return {
          ...state,
          deleteLoading: true,
          deleteError: "",
        };
      case DELETE_IMPACT_SUCCESS:
        return {
          ...state,
          deleteLoading: false,
          deleteError: "",
        };
      case DELETE_IMPACT_FAIL:
        return {
          ...state,
          deleteLoading: false,
          deleteError: action.payload,
        };
  
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  
  export default impactReducer;
  