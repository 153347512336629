import axios from "axios";

//redux
import {
  FETCH_EPISODE_REQUEST,
  FETCH_EPISODE_SUCCESS,
  FETCH_EPISODE_FAIL,
  ADD_EPISODE_REQUEST,
  ADD_EPISODE_SUCCESS,
  ADD_EPISODE_FAIL,
  EDIT_EPISODE_REQUEST,
  EDIT_EPISODE_SUCCESS,
  EDIT_EPISODE_FAIL,
  DELETE_EPISODE_REQUEST,
  DELETE_EPISODE_SUCCESS,
  DELETE_EPISODE_FAIL,
  CLEAR,
} from "./episodeTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_EPISODE_REQUEST,
  };
};

export const fetchSuccess = (Episode) => {
  return {
    type: FETCH_EPISODE_SUCCESS,
    payload: Episode,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_EPISODE_FAIL,
    payload: error,
  };
};

export const addEpisodeRequest = () => {
  return {
    type: ADD_EPISODE_REQUEST,
  };
};

export const addEpisodeSuccess = () => {
  return {
    type: ADD_EPISODE_SUCCESS,
  };
};

export const addEpisodeFail = (error) => {
  return {
    type: ADD_EPISODE_FAIL,
    payload: error,
  };
};

export const editEpisodeRequest = () => {
  return {
    type: EDIT_EPISODE_REQUEST,
  };
};

export const editEpisodeSuccess = () => {
  return {
    type: EDIT_EPISODE_SUCCESS,
  };
};

export const editEpisodeFail = (error) => {
  return {
    type: EDIT_EPISODE_FAIL,
    payload: error,
  };
};

export const deleteEpisodeRequest = () => {
  return {
    type: DELETE_EPISODE_REQUEST,
  };
};

export const deleteEpisodeSuccess = () => {
  return {
    type: DELETE_EPISODE_SUCCESS,
  };
};

export const deleteEpisodeFail = (error) => {
  return {
    type: DELETE_EPISODE_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getEpisode = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/episodes";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteEpisode = (id) => async (dispatch) => {
  dispatch(deleteEpisodeRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/episodes/" + id);
    dispatch(deleteEpisodeSuccess());
    dispatch(getEpisode());
  } catch (e) {
    dispatch(deleteEpisodeFail(e.response.data));
  }
};

export const addEpisode = (title,title_ar) => async (dispatch) => {
  dispatch(addEpisodeRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/episodes", {
      title,
      title_ar,
     
    });
    dispatch(addEpisodeSuccess());
    dispatch(getEpisode());
  } catch (e) {
    dispatch(addEpisodeFail(e.response.data));
  }
};

export const updateEpisode = (id, title,title_ar) => async (dispatch) => {
  dispatch(editEpisodeRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/episodes/" + id, {
      title,
      title_ar,

    });
    dispatch(editEpisodeSuccess());
    dispatch(getEpisode());
  } catch (e) {
    dispatch(editEpisodeFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
