export const FETCH_COMMUNITY_ACTION_REQUEST = "FETCH_COMMUNITY_ACTION_REQUEST";
export const FETCH_COMMUNITY_ACTION_SUCCESS = "FETCH_COMMUNITY_ACTION_SUCCESS";
export const FETCH_COMMUNITY_ACTION_FAIL = "FETCH_COMMUNITY_ACTION_FAIL";

export const FETCH_ONE_COMMUNITY_ACTION_REQUEST =
  "FETCH_ONE_COMMUNITY_ACTION_REQUEST";
export const FETCH_ONE_COMMUNITY_ACTION_SUCCESS =
  "FETCH_ONE_COMMUNITY_ACTION_SUCCESS";
export const FETCH_ONE_COMMUNITY_ACTION_FAIL =
  "FETCH_ONE_COMMUNITY_ACTION_FAIL";

export const CLEAR = "CLEAR";
