export const FETCH_COUNTRY_REQUEST = "FETCH_COUNTRY_REQUEST";
export const FETCH_COUNTRY_SUCCESS = "FETCH_COUNTRY_SUCCESS";
export const FETCH_COUNTRY_FAIL = "FETCH_COUNTRY_FAIL";

export const ADD_COUNTRY_REQUEST = "ADD_COUNTRY_REQUEST";
export const ADD_COUNTRY_SUCCESS = "ADD_COUNTRY_SUCESS";
export const ADD_COUNTRY_FAIL = "ADD_COUNTRY_FAIL";

export const EDIT_COUNTRY_REQUEST = "ADD_COUNTRY_REQUEST";
export const EDIT_COUNTRY_SUCCESS = "ADD_COUNTRY_SUCESS";
export const EDIT_COUNTRY_FAIL = "ADD_COUNTRY_FAIL";

export const DELETE_COUNTRY_REQUEST = "ADD_COUNTRY_REQUEST";
export const DELETE_COUNTRY_SUCCESS = "ADD_COUNTRY_SUCESS";
export const DELETE_COUNTRY_FAIL = "ADD_COUNTRY_FAIL";

export const CLEAR = "CLEAR";