import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import { getQuiz } from "../../redux/quiz/quizAction";
import { addQuizAnswer, clear } from "../../redux/quizAnswer/quizAnswerAction";
import { getImpact } from "../../redux/impact/impactAction";
import { getSector } from "../../redux/sector/sectorActions";
import { getSkills } from "../../redux/Skills/SkillsAction";
import { getCountries } from "../../redux/country/countryActions";
import {
  getGovernates,
  getGovernatesByCountryId,
} from "../../redux/governate/governateActions";
import {
  getDistricts,
  getDistrictByGovernateId,
} from "../../redux/district/districtActions";

//components
import Loading from "../layout/Loading";

//css
import "./Quiz.css";

function QuizAddAnswerModal() {
  const dispatch = useDispatch();
  const [question, setQuestion] = useState("");

  const [answer, setAnswer] = useState("");
  const [answer_ar, setAnswer_ar] = useState("");
  const [type, setType] = useState(null);

  const [impact, setImpact] = useState("");
  const [sectors, setSectors] = useState("");
  const [skill, setSkill] = useState("");
  const [organizationtype, setOrganizationtype] = useState(false);

  const [location, setLocation] = useState(false);
  const { impacts } = useSelector((state) => state.impactReducer);
  const { sector } = useSelector((state) => state.sectorReducer);
  const { skills } = useSelector((state) => state.skillsReducer);
  const { quizzes } = useSelector((state) => state.quizReducer);
  const { countries } = useSelector((state) => state.countryReducer);
  const { governates } = useSelector((state) => state.governateReducer);
  const { districts } = useSelector((state) => state.districtReducer);

  const [quize_id, setQuize_id] = useState("");
  const [sector_id, setSector_id] = useState(null);
  const [district_id, setDistrict_id] = useState(null);
  const [impact_id, setImpact_id] = useState(null);
  const [skill_id, setSkill_id] = useState(null);
  const [skillls, setSkills] = useState([]);
  const [sectorrs, setSectorrs] = useState([]);
  const [impactts, setImpactts] = useState([]);
  const { addLoading } = useSelector((state) => state.quizReducer);
  const [buttonState, setButtonState] = useState(true);
  // useEffect(() => {
  //   if (
  //     answer !== "" &&
  //     answer_ar !== "" &&
  //     quize_id !== "" &&
  //     (district_id !== "" ||
  //       impact_id !== "" ||
  //       skill_id !== "" ||
  //       sector !== "" ||
  //       type !== "")
  //   )
  //     setButtonState(false);
  //   else setButtonState(true);
  // }, [
  //   answer,
  //   answer_ar,
  //   quize_id,
  //   district_id,
  //   impact_id,
  //   skill_id,
  //   sector,
  //   type,
  // ]);
  const resetForm = () => {
    setQuize_id("");
    setSectorrs([]);
    setDistrict_id("");
    setImpactts([]);
    setSkills([]);
    setQuestion("");
    setImpact(false);
    setSectors(false);
    setSkill(false);
    setLocation(false);
    setOrganizationtype(false);
    dispatch(clear());
  };
  const addQuizAnswerHandle = (e) => {
    e.preventDefault();

    dispatch(
      addQuizAnswer(
        quize_id,
        sectorrs,
        district_id,
        impactts,
        skillls,
        type,
        answer,
        answer_ar
      )
    );

    setQuize_id("");
    setSectorrs([]);
    setDistrict_id("");
    setImpactts([]);
    setSkills([]);
    setType("");
    setAnswer("");
    setAnswer_ar("");
    setOrganizationtype(false);
    setLocation(false);
    setImpact(false);
    setSectors(false);
    setSkill(false);
  };
  const RestType = () => {
    setOrganizationtype(false);
    setLocation(false);
    setImpact(false);
    setSectors(false);
    setSkill(false);
  };

  useEffect(() => {
    dispatch(getQuiz());
    dispatch(getImpact());
    dispatch(getDistricts());
    dispatch(getGovernates());
    dispatch(getCountries());
    dispatch(getSector());
  }, [dispatch]);


//for skills
  const handleAddSkill = () => {
    setSkills([...skillls, { skill_id: '' }]);
  };


  const handleSkillChange = (index, event) => {
    const newSkillls = [...skillls];
    newSkillls[index].skill_id = event.target.value;
    setSkills(newSkillls);
  };



//for impact
  const handleAddImpact = () => {
    setImpactts([...impactts, { impact_id: '' }]);
  };


  const handleImpactChange = (index, event) => {
    const newImpactts = [...impactts];
    newImpactts[index].impact_id = event.target.value;
    setImpactts(newImpactts);
  };

//for sectors
const handleAddSector = () => {
  setSectorrs([...sectorrs, { sector_id: '' }]);
};


const handleSectorChange = (index, event) => {
  const newSectorrs = [...sectorrs];
  newSectorrs[index].sector_id = event.target.value;
  setSectorrs(newSectorrs);
};



  const First = () => {
    if (question === "organization") {
      RestType();
      setOrganizationtype(true);
    } else if (question === "location") {
      RestType();
      setLocation(true);
    } else if (question === "impact") {
      RestType();
      setImpact(true);
    } else if (question === "sector") {
      RestType();
      setSectors(true);
    } else if (question === "skill") {
      RestType();
      setSkill(true);
    }
  };

  useEffect(() => {
    dispatch(getSkills());
  }, [dispatch]);

  useEffect(() => {
    if (quize_id && quizzes.data.length > 0) {
      // eslint-disable-next-line eqeqeq
      setQuestion(quizzes.data.filter((s) => s.id == quize_id)[0].type);
    }
  }, [quize_id, quizzes]);

  return (
    <div>
      <div className="modal fade" id="add-quizAnswer-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add Answer</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={addQuizAnswerHandle}>
                  <div className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Quiz :</span>

                      <select
                        className="  inputt col-md-8 form-controls"
                        onChange={(e) => {
                          setQuize_id(e.target.value);
                        }}
                        onClick={(e) => {
                          First();
                        }}
                        required
                        value={quize_id}
                      >
                        <option value="" disabled>
                          Please select Quiz
                        </option>
                        {quizzes.data?.map((g) => (
                          <option key={g.id} value={g.id}>
                            {g.question}
                          </option>
                        ))}
                      </select>
                    </div>

                    {organizationtype && (
                      <div>
                        <br />
                        <div className="row formRow">
                          <span className="col-md-3 textForm">
                            Organization Type :
                          </span>
                          <select
                            className="  inputt col-md-8 form-controls"
                            onChange={(e) => {
                              setType(e.target.value);
                            }}
                            required
                            defaultValue=""
                          >
                            <option value="" disabled>
                              Please select Organization Type
                            </option>

                            <option value="Skill-based">Skill based</option>
                            <option value="Time-based">Time Base</option>
                          </select>
                        </div>
                      </div>
                    )}

                    {location && (
                      <div>
                        <br />
                        <div className="row formRow">
                          <span className="col-md-3 textForm">Country :</span>
                          <select
                            className="  inputt col-md-8 form-controls"
                            onChange={(e) => {
                              if (e.target.value) {
                                dispatch(
                                  getGovernatesByCountryId(e.target.value)
                                );
                                document.getElementById(
                                  "inputId"
                                ).disabled = false;
                              }
                            }}
                            required
                            defaultValue=""
                          >
                            <option value="" disabled>
                              Please select country
                            </option>
                            {countries.data?.map((g) => (
                              <option key={g.id} value={g.id}>
                                {g.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <br />
                        <div className="row formRow">
                          <span className="col-md-3 textForm">Governate :</span>
                          <select
                            id="inputId"
                            className="  inputt col-md-8 form-controls"
                            required
                            defaultValue=""
                            disabled
                            onChange={(e) => {
                              if (e.target.value) {
                                dispatch(
                                  getDistrictByGovernateId(e.target.value)
                                );
                                document.getElementById(
                                  "inputId1"
                                ).disabled = false;
                              }
                            }}
                          >
                            <option value="" disabled>
                              Please select governates
                            </option>
                            {governates.data?.map((g) => (
                              <option key={g.id} value={g.id}>
                                {g.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <br />
                        <div className="row formRow">
                          <span className="col-md-3 textForm">District :</span>
                          <select
                            onChange={(e) => setDistrict_id(e.target.value)}
                            required
                            className="  inputt col-md-8 form-controls"
                            defaultValue=""
                            id="inputId1"
                            disabled
                          >
                            <option value="" disabled>
                              Please select district
                            </option>
                            {districts.data?.map((g) => (
                              <option key={g.id} value={g.id}>
                                {g.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}

                    {impact && (
                       <div>
                       <br />
                       {impactts.map((impactts, index) => (
                     <div key={index}>
                       <div className="row formRow">

                         <span className="col-md-3 textForm"> Impact {index + 1}:</span>
                         <select
                            className="  inputt col-md-8 form-controls"
                            value={impactts.name} onChange={(event) => handleImpactChange(index, event)}
                            required
                            defaultValue=""
                          >
                            <option value="" disabled>
                              Please select Impact
                            </option>
                            {impacts.Impacts?.map((g) => (
                              <option key={g.id} value={g.id}>
                                {g.title}
                              </option>
                            ))}
                          </select>
                      
                      
                         
                     

                       </div>
                       <br></br>

                      

                     </div>
                   ))}
                       <br />
                       <button className="add-btn" type="button" onClick={handleAddImpact}>
                     Add Impact
                   </button>
                     </div>
                 
                    )}

                    {sectors && (
                      <div>
                        <br />
                        {sectorrs.map((sectorrs, index) => (
                      <div key={index}>
                        <div className="row formRow">

                          <span className="col-md-3 textForm"> Sector {index + 1}:</span>
                          <select
                            className="  inputt col-md-8 form-controls"
                         
                            required
                            defaultValue={""}
                            value={sectorrs.name} onChange={(event) => handleSectorChange(index, event)}
                          >
                            <option value="" disabled>
                              Please select Sector
                            </option>
                            {sector.data?.map((g) => (
                              <option key={g.id} value={g.id}>
                                {g.name}
                              </option>
                            ))}
                          </select>
                          
                      

                        </div>
                        <br></br>

                       

                      </div>
                    ))}
                        <br />
                        <button className="add-btn" type="button" onClick={handleAddSector}>
                      Add Sector
                    </button>
                      </div>
                    )}
                       
               
                    {skill && (
                      
                      <div>
                        <br></br>
                         {skillls.map((skillls, index) => (
                      <div key={index}>
                        <div className="row formRow">

                          <span className="col-md-3 textForm"> Skill {index + 1}:</span>
                          <select
                            className="  inputt col-md-8 form-controls"
                            value={skillls.name} onChange={(event) => handleSkillChange(index, event)}
                            required
                            defaultValue=""
                          >
                            <option value="" disabled>
                              Please select Skills
                            </option>
                            {skills.data?.map((g) => (
                              <option key={g.id} value={g.id}>
                                {g.name}
                              </option>
                            ))}
                          </select>
                      

                        </div>
                        <br></br>

                       

                      </div>
                    ))}
                        <br />
                        <button className="add-btn" type="button" onClick={handleAddSkill}>
                      Add Skill
                    </button>
                      </div>
                    )}

                    <br></br>
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Answer :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={answer}
                        placeholder="Enter the Answer"
                        required
                        onChange={(e) => {
                          setAnswer(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm">Arabic Answer :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={answer_ar}
                        placeholder="Enter the Answer in Arabic"
                        required
                        onChange={(e) => {
                          setAnswer_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br></br>
                  </div>

                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addQuizAnswerHandle}
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      
                    >
                      Add Answer
                    </button>
                    
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizAddAnswerModal;
