export const FETCH_GOVERNATE_REQUEST = "FETCH_GOVERNATE_REQUEST";
export const FETCH_GOVERNATE_SUCCESS = "FETCH_GOVERNATE_SUCCESS";
export const FETCH_GOVERNATE_FAIL = "FETCH_GOVERNATE_FAIL";

export const ADD_GOVERNATE_REQUEST = "ADD_GOVERNATE_REQUEST";
export const ADD_GOVERNATE_SUCCESS = "ADD_GOVERNATE_SUCESS";
export const ADD_GOVERNATE_FAIL = "ADD_GOVERNATE_FAIL";

export const EDIT_GOVERNATE_REQUEST = "ADD_GOVERNATE_REQUEST";
export const EDIT_GOVERNATE_SUCCESS = "ADD_GOVERNATE_SUCESS";
export const EDIT_GOVERNATE_FAIL = "ADD_GOVERNATE_FAIL";

export const DELETE_GOVERNATE_REQUEST = "ADD_GOVERNATE_REQUEST";
export const DELETE_GOVERNATE_SUCCESS = "ADD_GOVERNATE_SUCESS";
export const DELETE_GOVERNATE_FAIL = "ADD_GOVERNATE_FAIL";

export const CLEAR = "CLEAR";