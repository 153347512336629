import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ReactDatatable from "@ashvin27/react-datatable";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import TeamAddModal from "./teamAddModal";
import TeamEditModel from "./teamEditModal";
import TeamDeleteModal from "./teamDeleteModal";

//redux
import { getTeam } from "../../redux/Team/teamAction";

//utils
import isAuth from "../../utils/isAuth";
import * as XLSX from "xlsx";
import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
function Team() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, teams, error, addError, editError, deleteError } =
    useSelector((state) => state.teamReducer);
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [name_ar, setName_ar] = useState("");
  const [position, setPosition] = useState("");
  const [position_ar, setPosition_ar] = useState("");
  const [description, setDescription] = useState("");
  const [description_ar, setDescription_ar] = useState("");
  const [linked_in, setLinked_in] = useState("");
  const [imageName, setImageName] = useState("");

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getTeam());
  }, [dispatch]);

  function exportToExcel() {
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter(
        (entry, index) => index !== 0 && index !== 3
      );
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Teams.xlsx");
  }

  useEffect(() => {
    if (teams && teams.data) {
      const mappedRecords = teams.data.map((team) => {
        return {
          key: team.id,
          id: team.id,
          imageName: team.image,
          image: process.env.REACT_APP_API + "/Team/" + team.image,

          name: team.name_ar,
          arabic_Name: team.name,
          description: team.description,
          arabic_Description: team.description_ar,
          position: team.position,
          arabic_Position: team.position_ar,
          linkedIn: team.linked_in,
        };
      });
      setRecords(mappedRecords);
    }
  }, [teams]);

  const columns = [
    {
      field: "image",
      headerName: "Picture",
      height: "20rem",
      flex: 1,
      renderCell: (params) => (
        <img
          src={params.row.image}
          alt={`Picture ${params.row.id}`}
          style={{ width: "7rem", height: "4rem" }}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      className: "centerText",
      align: "center",
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      className: "centerText",
      align: "center",
      sortable: true,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1,
      className: "centerText",
      align: "center",
      sortable: true,
    },
    {
      field: "linkedIn",
      headerName: "LinkedIn",
      flex: 1,
      className: "centerText",
      align: "center",
      sortable: true,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      className: "action centerText",
      align: "center",
      sortable: false,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-team-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setImage(record.row.image);
                setName(record.row.name);
                setName_ar(record.row.arabic_Name);
                setDescription(record.row.description);
                setDescription_ar(record.row.arabic_Description);
                setPosition(record.row.position);
                setPosition_ar(record.row.arabic_Position);
                setLinked_in(record.row.linkedIn);
                setImageName(record.row.imageName);
              }}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              data-toggle="modal"
              data-target="#delete-team-modal"
              className="actions"
              onClick={() => {
                setId(record.row.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const handleReloadClick = () => {
    window.location.reload();
  };
  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <TeamAddModal />
        <TeamEditModel
          id={id}
          image={imageName}
          name={name}
          name_ar={name_ar}
          description={description}
          description_ar={description_ar}
          position={position}
          position_ar={position_ar}
          linked_in={linked_in}
          setImage={setImage}
          setName={setName}
          setName_ar={setName_ar}
          setDescription={setDescription}
          setDescription_ar={setDescription_ar}
          setPosition={setPosition}
          setPosition_ar={setPosition_ar}
          setLinked_in={setLinked_in}
        />
        <TeamDeleteModal id={id} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button
              className="add"
              data-toggle="modal"
              data-target="#add-team-modal"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Team
            </button>
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
              {addError && (
                <div style={{ color: "red" }}> {addError.message}</div>
              )}
              {editError && (
                <div style={{ color: "red" }}> {editError.message}</div>
              )}
              {deleteError && (
                <div style={{ color: "red" }}> {deleteError.message}</div>
              )}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header title="Team" subtitle="List of Teams" />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
