import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import {
  clear,
  updateEpisodeQuizAnswer,
} from "../../redux/episodeQuizAnswer/episodeQuizAnswerAction";

//component
import Loading from "../layout/Loading";

//css
import "./EpisodeQuiz.css";

function EpisodeQuizEditModal({
  id,
  is_true,
  answer,
  answer_ar,
  setIs_true,
  setAnswer,
  setAnswer_ar,
}) {
  const dispatch = useDispatch();
  const { editLoading } = useSelector(
    (state) => state.episodeQuizanswerReducer
  );
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if (answer !== "" && answer_ar !== "" && is_true !== "")
      setButtonState(false);
    else setButtonState(true);
  }, [answer, answer_ar, is_true]);
  const resetForm = () => {
    setIs_true("");
    setAnswer("");
    setAnswer_ar("");

    dispatch(clear());
  };

  const editQuizHandle = (e) => {
    e.preventDefault();
    dispatch(updateEpisodeQuizAnswer(id, is_true, answer, answer_ar));

    setIs_true("");
    setAnswer("");
    setAnswer_ar("");
  };

  return (
    <div className="modal fade" id="update-episodequizeditAnswer-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Edit Answer</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={resetForm}
            >
              &times;
            </button>
          </div>
          {editLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form onSubmit={editQuizHandle}>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Answer:</span>
                    <input
                      value={answer}
                      onChange={(e) => setAnswer(e.target.value)}
                      placeholder="Enter the answer"
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Arabic Answer:</span>
                    <input
                      value={answer_ar}
                      placeholder="Enter the answer in Arabic"
                      onChange={(e) => setAnswer_ar(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br></br>

                  <div className="row formRow">
                    <span className="col-md-3 textForm">Answer State :</span>
                    <div className="l">
                      <input
                        type="radio"
                        value={true}
                        checked={is_true === true}
                        onChange={() => setIs_true(true)}
                      />
                      <label>&nbsp;True</label>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div className="l">
                      <input
                        type="radio"
                        value={false}
                        checked={is_true === false}
                        onChange={() => setIs_true(false)}
                      />
                      <label>&nbsp;False</label>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={resetForm}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      onClick={editQuizHandle}
                      disabled={buttonState ? true : false}
                    >
                      Edit
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EpisodeQuizEditModal;
