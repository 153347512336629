export const FETCH_VOLUNTEER_SPOTLIGHTS_QA_REQUEST = "FETCH_VOLUNTEER_SPOTLIGHTS_QA_REQUEST";
export const FETCH_VOLUNTEER_SPOTLIGHTS_QA_SUCCESS = "FETCH_VOLUNTEER_SPOTLIGHTS_QA_SUCCESS";
export const FETCH_VOLUNTEER_SPOTLIGHTS_QA_FAIL = "FETCH_VOLUNTEER_SPOTLIGHTS_QA_FAIL";

export const ADD_VOLUNTEER_SPOTLIGHTS_QA_REQUEST = "ADD_VOLUNTEER_SPOTLIGHTS_QA_REQUEST";
export const ADD_VOLUNTEER_SPOTLIGHTS_QA_SUCCESS = "ADD_VOLUNTEER_SPOTLIGHTS_QA_SUCESS";
export const ADD_VOLUNTEER_SPOTLIGHTS_QA_FAIL = "ADD_VOLUNTEER_SPOTLIGHTS_QA_FAIL";

export const EDIT_VOLUNTEER_SPOTLIGHTS_QA_REQUEST = "ADD_VOLUNTEER_SPOTLIGHTS_QA_REQUEST";
export const EDIT_VOLUNTEER_SPOTLIGHTS_QA_SUCCESS = "ADD_VOLUNTEER_SPOTLIGHTS_QA_SUCESS";
export const EDIT_VOLUNTEER_SPOTLIGHTS_QA_FAIL = "ADD_VOLUNTEER_SPOTLIGHTS_QA_FAIL";

export const DELETE_VOLUNTEER_SPOTLIGHTS_QA_REQUEST = "ADD_VOLUNTEER_SPOTLIGHTS_QA_REQUEST";
export const DELETE_VOLUNTEER_SPOTLIGHTS_QA_SUCCESS = "ADD_VOLUNTEER_SPOTLIGHTS_QA_SUCESS";
export const DELETE_VOLUNTEER_SPOTLIGHTS_QA_FAIL = "ADD_VOLUNTEER_SPOTLIGHTS_QA_FAIL";

export const CLEAR = "CLEAR";