export const FETCH_NATIONALITY_REQUEST = "FETCH_NATIONALITY_REQUEST";
export const FETCH_NATIONALITY_SUCCESS = "FETCH_NATIONALITY_SUCCESS";
export const FETCH_NATIONALITY_FAIL = "FETCH_NATIONALITY_FAIL";

export const ADD_NATIONALITY_REQUEST = "ADD_NATIONALITY_REQUEST";
export const ADD_NATIONALITY_SUCCESS = "ADD_NATIONALITY_SUCESS";
export const ADD_NATIONALITY_FAIL = "ADD_NATIONALITY_FAIL";

export const EDIT_NATIONALITY_REQUEST = "ADD_NATIONALITY_REQUEST";
export const EDIT_NATIONALITY_SUCCESS = "ADD_NATIONALITY_SUCESS";
export const EDIT_NATIONALITY_FAIL = "ADD_NATIONALITY_FAIL";

export const DELETE_NATIONALITY_REQUEST = "ADD_NATIONALITY_REQUEST";
export const DELETE_NATIONALITY_SUCCESS = "ADD_NATIONALITY_SUCESS";
export const DELETE_NATIONALITY_FAIL = "ADD_NATIONALITY_FAIL";

export const CLEAR = "CLEAR";