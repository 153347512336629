export const FETCH_FAQ_REQUEST = "FETCH_FAQ_REQUEST";
export const FETCH_FAQ_SUCCESS = "FETCH_FAQ_SUCCESS";
export const FETCH_FAQ_FAIL = "FETCH_FAQ_FAIL";

export const ADD_FAQ_REQUEST = "ADD_FAQ_REQUEST";
export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCESS";
export const ADD_FAQ_FAIL = "ADD_FAQ_FAIL";

export const EDIT_FAQ_REQUEST = "ADD_FAQ_REQUEST";
export const EDIT_FAQ_SUCCESS = "ADD_FAQ_SUCESS";
export const EDIT_FAQ_FAIL = "ADD_FAQ_FAIL";

export const DELETE_FAQ_REQUEST = "ADD_FAQ_REQUEST";
export const DELETE_FAQ_SUCCESS = "ADD_FAQ_SUCESS";
export const DELETE_FAQ_FAIL = "ADD_FAQ_FAIL";

export const CLEAR = "CLEAR";