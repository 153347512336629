import {
  FETCH_EPISODEQUIZUSER_REQUEST,
  FETCH_EPISODEQUIZUSER_SUCCESS,
  FETCH_EPISODEQUIZUSER_FAIL,
} from "./episodeQuizUserTypes";

export const initialState = {
  loading: false,
  episodeQuiz: [],
  error: "",
};

const episodeQuizUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EPISODEQUIZUSER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_EPISODEQUIZUSER_SUCCESS:
      return {
        loading: false,
        episodeQuizUser: action.payload,
        error: "",
      };
    case FETCH_EPISODEQUIZUSER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default episodeQuizUserReducer;
