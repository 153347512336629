import axios from "axios";

//redux
import {
  FETCH_EMAIL_REQUEST,
  FETCH_EMAIL_SUCCESS,
  FETCH_EMAIL_FAIL,
  ADD_EMAIL_REQUEST,
  ADD_EMAIL_SUCCESS,
  ADD_EMAIL_FAIL,
  DELETE_EMAIL_REQUEST,
  DELETE_EMAIL_SUCCESS,
  DELETE_EMAIL_FAIL,
  CLEAR,
} from "./emailTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_EMAIL_REQUEST,
  };
};

export const fetchSuccess = (emails) => {
  return {
    type: FETCH_EMAIL_SUCCESS,
    payload: emails,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_EMAIL_FAIL,
    payload: error,
  };
};

export const addEmailRequest = () => {
  return {
    type: ADD_EMAIL_REQUEST,
  };
};

export const addEmailSuccess = () => {
  return {
    type: ADD_EMAIL_SUCCESS,
  };
};

export const addEmailFail = (error) => {
  return {
    type: ADD_EMAIL_FAIL,
    payload: error,
  };
};

export const deleteEmailRequest = () => {
  return {
    type: DELETE_EMAIL_REQUEST,
  };
};

export const deleteEmailSuccess = () => {
  return {
    type: DELETE_EMAIL_SUCCESS,
  };
};

export const deleteEmailFail = (error) => {
  return {
    type: DELETE_EMAIL_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getEmails = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/email_subscriptions";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteEmail = (id) => async (dispatch) => {
  dispatch(deleteEmailRequest());
  try {
    await axios.delete(
      process.env.REACT_APP_API + "/email_subscriptions/" + id
    );
    dispatch(deleteEmailSuccess());
    dispatch(getEmails());
  } catch (e) {
    dispatch(deleteEmailFail(e.response.data));
  }
};

export const addEmailSubscription = (email) => async (dispatch) => {
  dispatch(addEmailRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/email_subscriptions", {
      email,
    });
    dispatch(addEmailSuccess());
    dispatch(getEmails());
  } catch (e) {
    dispatch(addEmailFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
