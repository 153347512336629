import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
import { Box } from "@mui/material";

//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import Header from "../Header/Header";
import OrganizationFeedbackDeleteModal from "./OrganizationFeedbackDeleteModal";

//redux
import { getOrganizationFeedback } from "../../redux/organizationFeedback/organizationFeedbackActions";

//utils
import isAuth from "../../utils/isAuth";

function OrganizationFeedback() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, organizationFeedback, error } = useSelector(
    (state) => state.organizationFeedbackReducer
  );
  const [id, setId] = useState(0);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getOrganizationFeedback());
  }, [dispatch]);

  useEffect(() => {
    if (organizationFeedback && organizationFeedback.data) {
      const mappedRecords = organizationFeedback?.data.map((data) => {
        return {
          key: data.id,
          id: data.id,
          orgName: data.organization.name,
          reqName:
            data.request.volunteer.first_name +
            " " +
            data.request.volunteer.last_name,
        };
      });
      setRecords(mappedRecords);
    }
  }, [organizationFeedback]);

  const columns = [
    {
      field: "orgName",
      headerName: "Organization Name",
      flex: 1,
    },
    {
      field: "reqName",
      headerName: "Request Name",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#delete-OrganizationFeedback-modal"
              className="actions"
              onClick={() => {
                setId(record.row.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <OrganizationFeedbackDeleteModal id={id} />

        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header
                    title="Organization Feedback"
                    subtitle="List of Organization Feedbacks"
                  />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganizationFeedback;
