import React from "react";
import DashboardCardStat from "../DashboardCardStat";
import { AiOutlineHeart } from 'react-icons/ai';

function livesTouched(props) {
  return (
    <>
    <DashboardCardStat
      title={props.livesTouched}
      description="Lives Touched"
      link="/"
      icon={<div className="circle-container"><AiOutlineHeart  size={30}/></div>}
      iconColor="iconc  fa-2x"
    /></>
  );
}

export default livesTouched;
