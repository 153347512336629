export const FETCH_VOLUNTEER_REQUEST = "FETCH_VOLUNTEER_REQUEST";
export const FETCH_VOLUNTEER_SUCCESS = "FETCH_VOLUNTEER_SUCCESS";
export const FETCH_VOLUNTEER_FAIL = "FETCH_VOLUNTEER_FAIL";

export const FETCH_ONE_VOLUNTEER_REQUEST = "FETCH_ONE_VOLUNTEER_REQUEST";
export const FETCH_ONE_VOLUNTEER_SUCCESS = "FETCH_ONE_VOLUNTEER_SUCCESS";
export const FETCH_ONE_VOLUNTEER_FAIL = "FETCH_ONE_VOLUNTEER_FAIL";


export const EDIT_VOLUNTEER_REQUEST = "EDIT_VOLUNTEER_REQUEST";
export const EDIT_VOLUNTEER_SUCCESS = "EDIT_VOLUNTEER_SUCESS";
export const EDIT_VOLUNTEER_FAIL = "EDIT_VOLUNTEER_FAIL";

export const CLEAR = "CLEAR";
