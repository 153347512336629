import axios from "axios";

//redux
import {
  FETCH_MASTERCLASS_REQUEST,
  FETCH_MASTERCLASS_SUCCESS,
  FETCH_MASTERCLASS_FAIL,
  EDIT_MASTERCLASS_REQUEST,
  EDIT_MASTERCLASS_SUCCESS,
  EDIT_MASTERCLASS_FAIL,
  DELETE_MASTERCLASS_REQUEST,
  DELETE_MASTERCLASS_SUCCESS,
  DELETE_MASTERCLASS_FAIL,
  CLEAR,
} from "./MasterclassTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_MASTERCLASS_REQUEST,
  };
};

export const fetchSuccess = (masterclass) => {
  return {
    type: FETCH_MASTERCLASS_SUCCESS,
    payload: masterclass,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_MASTERCLASS_FAIL,
    payload: error,
  };
};

export const editMasterclassRequest = () => {
  return {
    type: EDIT_MASTERCLASS_REQUEST,
  };
};

export const editMasterclassSuccess = () => {
  return {
    type: EDIT_MASTERCLASS_SUCCESS,
  };
};

export const editMasterclassFail = (error) => {
  return {
    type: EDIT_MASTERCLASS_FAIL,
    payload: error,
  };
};
export const deleteMasterclassRequest = () => {
  return {
    type: DELETE_MASTERCLASS_REQUEST,
  };
};

export const deleteMasterclassSuccess = () => {
  return {
    type: DELETE_MASTERCLASS_SUCCESS,
  };
};

export const deleteMasterclassFail = (error) => {
  return {
    type: DELETE_MASTERCLASS_FAIL,
    payload: error,
  };
};
export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getMasterclass = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/masterclasses";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteMasterclass = (id) => async (dispatch) => {
  dispatch(deleteMasterclassRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/masterclasses/" + id);
    dispatch(deleteMasterclassSuccess());

    dispatch(getMasterclass());
  } catch (e) {
    dispatch(deleteMasterclassFail(e.response.data));
  }
};

export const updateMasterclass =
  (user_id, email, job, name, user_name) => async (dispatch) => {
    dispatch(editMasterclassRequest());
    try {
      await axios.put(
        process.env.REACT_APP_API + "/masterclasses/edit/" + user_id,
        {
          email,
          job,
          name,
          user_name,
        }
      );
      dispatch(editMasterclassSuccess());
      dispatch(getMasterclass());
    } catch (e) {
      dispatch(editMasterclassFail(e.response.data));
    }
  };

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};

export const changeStatusMasterclass =
  (cash_id, masterclass_id) => async (dispatch) => {
    try {
      await axios.put(
        process.env.REACT_APP_API + "/cash/ChangeStatusMasterclass/" + cash_id,
        {
          masterClassId: [masterclass_id],
        }
      );
      dispatch(getMasterclass());
    } catch (e) {
      console.log(e.response.data);
    }
  };
