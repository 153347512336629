export const FETCH_DISTRICT_REQUEST = "FETCH_DISTRICT_REQUEST";
export const FETCH_DISTRICT_SUCCESS = "FETCH_DISTRICT_SUCCESS";
export const FETCH_DISTRICT_FAIL = "FETCH_DISTRICT_FAIL";

export const ADD_DISTRICT_REQUEST = "ADD_DISTRICT_REQUEST";
export const ADD_DISTRICT_SUCCESS = "ADD_DISTRICT_SUCESS";
export const ADD_DISTRICT_FAIL = "ADD_DISTRICT_FAIL";

export const EDIT_DISTRICT_REQUEST = "ADD_DISTRICT_REQUEST";
export const EDIT_DISTRICT_SUCCESS = "ADD_DISTRICT_SUCESS";
export const EDIT_DISTRICT_FAIL = "ADD_DISTRICT_FAIL";

export const DELETE_DISTRICT_REQUEST = "ADD_DISTRICT_REQUEST";
export const DELETE_DISTRICT_SUCCESS = "ADD_DISTRICT_SUCESS";
export const DELETE_DISTRICT_FAIL = "ADD_DISTRICT_FAIL";

export const CLEAR = "CLEAR";