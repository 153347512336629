import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ReactDatatable from "@ashvin27/react-datatable";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import BlogAddModal from "./BlogAddModal";
import BlogEditModal from "./BlogEditModal";
import BlogDeleteModal from "./BlogDeleteModal";
import TextCell from "./TextCell";
//redux
import { getBlogs } from "../../redux/blog/blogActions";

//utils
import isAuth from "../../utils/isAuth";

function Blog() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, blogs, error, addError, editError, deleteError } =
    useSelector((state) => state.blogReducer);
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [picture, setPicture] = useState("");
  const [author, setAuthor] = useState("");
  const [imageName, setImageName] = useState("");

  function exportToExcel() {
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter(
        (entry, index) => index !== 0 && index !== 5
      );
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Blogs.xlsx");
  }

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getBlogs());
  }, [dispatch]);

  useEffect(() => {
    if (blogs && blogs.Blogs) {
      const mappedRecords = blogs.Blogs.map((blog) => {
        return {
          key: blog.id,
          id: blog.id,
          imageName: blog.picture,
          title: blog.title,
          text: blog.text,
          picture: process.env.REACT_APP_API + "/Blogs/" + blog.picture,
          author: blog.author,
        };
      });
      setRecords(mappedRecords);
    }
  }, [blogs]);

  const [expandedText, setExpandedText] = useState({});

  const handleSeeMoreClick = (rowKey) => {
    setExpandedText((prevExpandedText) => ({
      ...prevExpandedText,
      [rowKey]: !prevExpandedText[rowKey],
    }));
  };
  console.log("Records :  ", records);
  const columns = [
    {
      field: "picture",
      headerName: "Picture",
      flex: 1,
      renderCell: (params) => (
        <img
          src={params.row.picture}
          alt={`Picture ${params.row.id}`}
          style={{ width: "7rem", height: "4rem" }}
        />
      ),
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "text",
      headerName: "Text",
      flex: 1,
      cell: (rowData) => {
        const text = rowData.text;
        const rowKey = rowData.key;
        const isExpanded = expandedText[rowKey];

        if (text.length > 50) {
          if (isExpanded) {
            return (
              <div>
                {text}
                <a
                  className="see-more"
                  href="#"
                  onClick={() => handleSeeMoreClick(rowKey)}
                >
                  See Less
                </a>
              </div>
            );
          } else {
            return (
              <div>
                {text.substring(0, 50)}
                <a
                  className="see-more"
                  href="#"
                  onClick={() => handleSeeMoreClick(rowKey)}
                >
                  ... See More
                </a>
              </div>
            );
          }
        } else {
          return text;
        }
      },
    },
    {
      field: "author",
      headerName: "Author",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      renderCell: (record) => (
        <Fragment>
          <button
            data-toggle="modal"
            data-target="#update-blog-modal"
            className="actions"
            style={{ marginRight: "5px" }}
            onClick={() => {
              setId(record.row.id);
              setTitle(record.row.title);
              setText(record.row.text);
              setPicture(record.row.picture);
              setAuthor(record.row.author);
              setImageName(record.row.imageName);
            }}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            data-toggle="modal"
            data-target="#delete-blog-modal"
            className="actions"
            onClick={() => {
              setId(record.row.id);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </Fragment>
      ),
    },
  ];
  const handleReloadClick = () => {
    window.location.reload();
  };
  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />

        <BlogAddModal />
        <BlogEditModal
          id={id}
          title={title}
          text={text}
          picture={imageName}
          author={author}
          setTitle={setTitle}
          setText={setText}
          setPicture={setPicture}
          setAuthor={setAuthor}
        />
        <BlogDeleteModal id={id} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div className="header-container">
              <button
                className="btn btn-outline-primary float-right mt-3 mr-2 add"
                data-toggle="modal"
                data-target="#add-blog-modal"
              >
                <FontAwesomeIcon icon={faPlus} /> Add Blog
              </button>
            </div>
            <div style={{ textAlign: "center" }} className="errors">
              {error && <div style={{ color: "red" }}> {error.message}</div>}
              <br></br>
              {addError && (
                <div style={{ color: "red" }} className="single-error">
                  {" "}
                  {addError.message}
                </div>
              )}
              <br></br>
              {editError && (
                <div style={{ color: "red" }} className="single-error">
                  {" "}
                  {editError.message}
                </div>
              )}
              <br></br>
              {deleteError && (
                <div style={{ color: "red" }} className="single-error">
                  {" "}
                  {deleteError.message}
                </div>
              )}
            </div>

            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header title="Blog" subtitle="List of Blogs" />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
