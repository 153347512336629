import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";
import { getEpisodeQuiz } from "../../redux/episodeQuiz/episodeQuizActions";

//redux
import {
  addEpisodeQuizAnswer,
  clear,
} from "../../redux/episodeQuizAnswer/episodeQuizAnswerAction";

//components
import Loading from "../layout/Loading";

//css
import "./EpisodeQuiz.css";

function EpisodeQuizAddAnswerModal() {
  const dispatch = useDispatch();
  const { addLoading } = useSelector((state) => state.episodeQuizanswerReducer);
  const { episodeQuiz } = useSelector((state) => state.episodeQuizReducer);
  const [episode_quiz_id, setEpisode_quiz_id] = useState("");
  const [questions, setQuestions] = useState([{ answer: '', answer_ar: '', is_true: false }]);

  // const [buttonState, setButtonState] = useState(true);
  // useEffect(() => {
  //   if (
  //     episode_quiz_id !== "" &&
  //     answer !== "" &&
  //     answer_ar !== "" &&
  //     is_true !== ""
  //   )
  //     setButtonState(false);
  //   else setButtonState(true);
  // }, [episode_quiz_id, answer, answer_ar, is_true]);
  const resetForm = () => {
    //   setAnswer("");
    //   setAnswer_ar("");
    //   setIs_true("");
    setQuestions([{}]);
    setEpisode_quiz_id("");

    dispatch(clear());
  };





  const handleAddQuestion = () => {
    setQuestions([...questions, { answer: '', answer_ar: '', is_true: false }]);
  };


  const handleAnswerChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].answer = event.target.value;
    setQuestions(newQuestions);
  };

  const handleAnswerArabicChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].answer_ar = event.target.value;
    setQuestions(newQuestions);
  };

  const handleIsCorrectChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].is_true = event.target.checked;
    setQuestions(newQuestions);
  };


  const addQuizAnswerHandle = (e) => {
    e.preventDefault();

    dispatch(addEpisodeQuizAnswer(episode_quiz_id, questions));

    setQuestions([{}]);
    setEpisode_quiz_id("");
  };

  useEffect(() => {
    dispatch(getEpisodeQuiz);
  }, [dispatch]);

  return (
    <div>
      <div
        className="modal fade"
        id="add-episodequizAnswer-modal"
        data-reset="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add Episode Quiz Answer</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={addQuizAnswerHandle}>
                  <div className="modal-body">


                    <div className="row formRow">
                      <span className="col-md-3 textForm">Episode Quiz :</span>

                      <select
                        className="  inputt col-md-8 form-controls"
                        onChange={(e) => {
                          setEpisode_quiz_id(e.target.value);
                        }}
                        required
                        value={episode_quiz_id}
                      >
                        <option value="" disabled>
                          Please select episode Quiz
                        </option>
                        {episodeQuiz.data?.map((g) => (
                          <option key={g.id} value={g.id}>
                            {g.question}
                          </option>
                        ))}
                      </select>
                    </div>
                    <br></br>
                    {questions.map((question, index) => (
                      <div key={index}>
                        <div className="row formRow">

                          <span className="col-md-3 textForm"> Answer {index}:</span>
                          <input className="  inputt col-md-8 form-controls" type="text" value={question.answer} onChange={(event) => handleAnswerChange(index, event)} />

                        </div>
                        <br></br>

                        <div className="row formRow">
                          <span className="col-md-3 textForm">Arabic Anwer {index}:</span>

                          <input className="  inputt col-md-8 form-controls" type="text" value={question.answer_ar} onChange={(event) => handleAnswerArabicChange(index, event)} />

                        </div>
                        <br></br>

                        <div className="row formRow">
                          <span className="col-md-3 textForm">Is Correct :</span>

                          <input className="check" type="checkbox" checked={question.is_true} onChange={(event) => handleIsCorrectChange(index, event)} />

                        </div>
                        <br></br>

                      </div>
                    ))}
                    <button className="add-btn" type="button" onClick={handleAddQuestion}>
                      Add Question
                    </button>

                  </div>

                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addQuizAnswerHandle}
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"

                    >
                      Add Answer
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EpisodeQuizAddAnswerModal;
