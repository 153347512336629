import axios from "axios";

//redux
import {
  FETCH_ADMIN_REQUEST,
  FETCH_ADMIN_SUCCESS,
  FETCH_ADMIN_FAIL,
  ADD_ADMIN_REQUEST,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAIL,
  EDIT_ADMIN_REQUEST,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAIL,
  DELETE_ADMIN_REQUEST,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAIL,
  CLEAR,
} from "./adminTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_ADMIN_REQUEST,
  };
};

export const fetchSuccess = (admins) => {
  return {
    type: FETCH_ADMIN_SUCCESS,
    payload: admins,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_ADMIN_FAIL,
    payload: error,
  };
};

export const addAdminRequest = () => {
  return {
    type: ADD_ADMIN_REQUEST,
  };
};

export const addAdminSuccess = () => {
  return {
    type: ADD_ADMIN_SUCCESS,
  };
};

export const addAdminFail = (error) => {
  return {
    type: ADD_ADMIN_FAIL,
    payload: error,
  };
};

export const editAdminRequest = () => {
  return {
    type: EDIT_ADMIN_REQUEST,
  };
};

export const editAdminSuccess = () => {
  return {
    type: EDIT_ADMIN_SUCCESS,
  };
};

export const editAdminFail = (error) => {
  return {
    type: EDIT_ADMIN_FAIL,
    payload: error,
  };
};

export const deleteAdminRequest = () => {
  return {
    type: DELETE_ADMIN_REQUEST,
  };
};

export const deleteAdminSuccess = () => {
  return {
    type: DELETE_ADMIN_SUCCESS,
  };
};

export const deleteAdminFail = (error) => {
  return {
    type: DELETE_ADMIN_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getAdmins = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/users/getadmins";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteAdmin = (id) => async (dispatch) => {
  dispatch(deleteAdminRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/users/deleteAdmin/" + id);
    dispatch(deleteAdminSuccess());
   dispatch(getAdmins());
  } catch (e) {
    dispatch(deleteAdminFail(e.response.data));
  }
};

export const addAdmin = (email, username, role) => async (dispatch) => {
  dispatch(addAdminRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/users/createAdmin/", {
      email,
      username,
      role,
    });
    dispatch(addAdminSuccess());
    dispatch(getAdmins());
  } catch (e) {
    dispatch(addAdminFail(e.response.data));
  }
};

export const updateAdmin = (id, email, username, role) => async (dispatch) => {
  dispatch(editAdminRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/users/editAdmin/" + id, {
      email,
      username,
      role,
    });
    dispatch(editAdminSuccess());
    dispatch(getAdmins());
  } catch (e) {
    dispatch(editAdminFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
