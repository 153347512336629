import React from "react";
import DashboardCardStat from "../DashboardCardStat";
import { IoGitCompareSharp } from 'react-icons/io5';

function totalVolunteerMatches(props) {
  return (
    <>
    <DashboardCardStat
      title={props.totalVolunteerMatches}
      description="Total volunteer matches"
      link="/volunteers"
      icon={<div className="circle-container">  <div className="centered-icon"><IoGitCompareSharp size={32} color="gray"/></div></div>}
      iconColor="iconc  fa-2x"
    /></>
  );
}

export default totalVolunteerMatches;
