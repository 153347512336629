import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ReactDatatable from "@ashvin27/react-datatable";
import * as XLSX from "xlsx";
import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import VolunteerSpotlightsAddModal from "./VolunteerSpotlightsAddModal";
import VolunteerSpotlightsEditModel from "./VolunteerSpotlightsEditModal";
import VolunteerSpotlightsDeleteModal from "./VolunteerSpotlightsDeleteModal";

//redux
import { getVolunteerSpotlights } from "../../redux/volunteerSpotlights/volunteerSpotlightsActions.js";

//utils
import isAuth from "../../utils/isAuth";

function VolunteerSpotlights() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loading,
    volunteerSpotlights,
    error,
    addError,
    editError,
    deleteError,
  } = useSelector((state) => state.volunteerSpotlightsReducer);
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [imageName, setImageName] = useState("");

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getVolunteerSpotlights());
  }, [dispatch]);

  useEffect(() => {
    if (volunteerSpotlights.data !== undefined) {
      setRecords(
        volunteerSpotlights.data?.map((volunteer_spotlight) => {
          console.log(volunteer_spotlight.image);
          return {
            key: volunteer_spotlight.id,
            id: volunteer_spotlight.id,
            imageName: volunteer_spotlight.image,
            description: volunteer_spotlight.description,
            title: volunteer_spotlight.title,
            picture:
              process.env.REACT_APP_API +
              "/VolunteerSpotlight/" +
              volunteer_spotlight.image,
          };
        })
      );
    }
  }, [volunteerSpotlights]);

  const columns = [
    {
      field: "picture",
      headerName: "Picture",
      flex: 1,
      renderCell: (params) => (
        <img
          src={params.row.picture}
          alt={`Picture ${params.row.id}`}
          style={{ width: "7rem", height: "4rem" }}
        />
      ),
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      className: "action centerText",
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-volunteer_spotlights-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.id);
                setImage(record.image);
                setTitle(record.title);
                setDescription(record.description);
                setImageName(record.imageName);
              }}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              data-toggle="modal"
              data-target="#delete-volunteer_spotlights-modal"
              className="actions"
              onClick={() => {
                setId(record.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  function exportToExcel() {
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter(
        (entry, index) => index !== 0 && index !== 3
      );
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Volunteer Spotlights.xlsx");
  }

  const ToggleMenu = (e) => {
    var element = document.getElementById("wrapper");
    if (element.classList.contains("toggled")) {
      document.getElementById("wrapper").classList.remove("toggled");
    } else {
      document.getElementById("wrapper").classList.add("toggled");
    }
  };

  const handleReloadClick = () => {
    window.location.reload();
  };

  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <VolunteerSpotlightsAddModal />
        <VolunteerSpotlightsEditModel
          id={id}
          image={imageName}
          description={description}
          title={title}
          setImage={setImage}
          setTitle={setTitle}
          setDescription={setDescription}
        />
        <VolunteerSpotlightsDeleteModal id={id} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button
              className="add"
              data-toggle="modal"
              data-target="#add-volunteer_spotlights-modal"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Volunteer Spotlights
            </button>
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
              {addError && (
                <div style={{ color: "red" }}> {addError.message}</div>
              )}
              {editError && (
                <div style={{ color: "red" }}> {editError.message}</div>
              )}
              {deleteError && (
                <div style={{ color: "red" }}> {deleteError.message}</div>
              )}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header
                    title="Volunteer Spotlight"
                    subtitle="List of Volunteers Spotlights"
                  />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VolunteerSpotlights;
