import React from "react";
import DashboardCardStat from "../DashboardCardStat";
import MailOutlineIcon from '@mui/icons-material/MailOutline';

function jobPlacements(props) {
  return (
    <>
    <DashboardCardStat
      title={props.jobPlacements}
      description="Job placements"
      link="/"
      icon={<div className="circle-container"><MailOutlineIcon fontSize="medium"/></div>}
      iconColor="iconc  fa-2x"
    /></>
  );
}

export default jobPlacements;
