import {
  FETCH_OPPORTUNITY_REQUEST,
  FETCH_OPPORTUNITY_SUCCESS,
  FETCH_OPPORTUNITY_FAIL,
  FETCH_ONE_OPPORTUNITY_REQUEST,
  FETCH_ONE_OPPORTUNITY_SUCCESS,
  FETCH_ONE_OPPORTUNITY_FAIL,
  EDIT_OPPORTUNITY_REQUEST,
  EDIT_OPPORTUNITY_SUCCESS,
  EDIT_OPPORTUNITY_FAIL,
  CLEAR,
} from "./opportunityTypes";

export const initialState = {
  loading: false,
  oneLoading: false,
  opportunities: [],
  oneopportunity: [],
  error: "",
};

const opportunityReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OPPORTUNITY_REQUEST:
      return {
        ...state,
        oneLoading: true,
        loading: true,
        error: "",
      };
    case FETCH_OPPORTUNITY_SUCCESS:
      return {
        loading: false,
        oneLoading: true,
        opportunities: action.payload,
        error: "",
      };
    case FETCH_OPPORTUNITY_FAIL:
      return {
        ...state,
        loading: false,
        oneLoading: true,
        error: action.payload.message,
      };

    case FETCH_ONE_OPPORTUNITY_REQUEST:
      return {
        ...state,
        loading: false,
        oneLoading: true,
        error: "",
      };
    case FETCH_ONE_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        loading: false,
        oneLoading: false,
        oneopportunity: action.payload,
        error: "",
      };
    case FETCH_ONE_OPPORTUNITY_FAIL:
      return {
        ...state,
        loading: false,
        oneLoading: false,
        error: action.payload.message,
      };


      case EDIT_OPPORTUNITY_REQUEST:
        return {
          ...state,
          editLoading: true,
          editError: "",
        };
        
      case EDIT_OPPORTUNITY_SUCCESS:
        return {
          ...state,
          editLoading: false,
          editError: ""
        };

      case EDIT_OPPORTUNITY_FAIL:
        return {
          ...state,
          editLoading: false,
          editError: action.payload,
        };


    case CLEAR:
      return {
        ...state,
        loading: false,
        oneLoading: false,
        error: "",
      };
    default:
      return state;
  }
};

export default opportunityReducer;
