import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ReactDatatable from "@ashvin27/react-datatable";
import * as XLSX from "xlsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";

//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import EpisodeQuizAddModel from "./EpisodeQuizAddModal";
import EpisodeQuizEditModel from ".//EpisodeQuizEditModal";
import EpisodeQuizDeleteModal from "./EpisodeQuizDeleteModal";

//redux
import { getEpisodeQuiz } from "../../redux/episodeQuiz/episodeQuizActions";

//utils
import isAuth from "../../utils/isAuth";

import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
function EpisodeQuiz() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, episodeQuiz, error, addError, editError, deleteError } =
    useSelector((state) => state.episodeQuizReducer);
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);
  const [question, setQuestion] = useState("");
  const [question_ar, setQuestion_ar] = useState("");
  const [answer, setAnswer] = useState("");
  const [answer_ar, setAnswer_ar] = useState("");
  const [episode_id, setEpisode_id] = useState("");

  function exportToExcel() {
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter(
        (entry, index) => index !== 0 && index !== 2
      );
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Episodes Quizzes.xlsx");
  }

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getEpisodeQuiz());
  }, [dispatch]);

  console.log("me", episodeQuiz);
  useEffect(() => {
    if (episodeQuiz && episodeQuiz.data) {
      const mappedRecords = episodeQuiz.data.map((episodeQuiz) => {
        return {
          key: episodeQuiz.id,
          id: episodeQuiz.id,
          episode_id: episodeQuiz.episode_id,
          episode_Title: episodeQuiz.episode ? episodeQuiz.episode.title : "",
          question: episodeQuiz.question,
          arabic_Question: episodeQuiz.question_ar,
          title_ar: episodeQuiz.episode?.title_ar,
        };
      });
      setRecords(mappedRecords);
    }
  }, [episodeQuiz]);

  const columns = [
    {
      field: "episode_Title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "title_ar",
      headerName: "Episode",
      flex: 1,
    },
    {
      field: "question",
      headerName: "Question",
      flex: 1,
    },
    {
      field: "arabic_Question",
      headerName: "Arabic Question",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-episodeQuiz-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setQuestion(record.row.question);
                setQuestion_ar(record.row.arabic_Question);
                setAnswer(record.row.answer);
                setAnswer_ar(record.row.arabic_Answer);
                setEpisode_id(record.row.episode_id);
              }}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              data-toggle="modal"
              data-target="#delete-episodeQuiz-modal"
              className="actions"
              onClick={() => {
                setId(record.row.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const handleReloadClick = () => {
    window.location.reload();
  };
  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <EpisodeQuizAddModel />
        <EpisodeQuizEditModel
          id={id}
          question={question}
          question_ar={question_ar}
          answer={answer}
          answer_ar={answer_ar}
          episode_id={episode_id}
          setQuestion={setQuestion}
          setQuestion_ar={setQuestion_ar}
          setAnswer={setAnswer}
          setAnswer_ar={setAnswer_ar}
          setEpisode_id={setEpisode_id}
        />
        <EpisodeQuizDeleteModal id={id} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <Link to="/episodeQuiz">
              <button className="add">
                <i className="fa fa-arrow-left" aria-hidden="true"></i> Back to
                Answer
              </button>
            </Link>
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
              {addError && (
                <div style={{ color: "red" }}> {addError.message}</div>
              )}
              {editError && (
                <div style={{ color: "red" }}> {editError.message}</div>
              )}
              {deleteError && (
                <div style={{ color: "red" }}> {deleteError.message}</div>
              )}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header
                    title="Episode Quiz"
                    subtitle="List of Episodes Quizes"
                  />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EpisodeQuiz;
