export const FETCH_CAROUSEL_REQUEST = "FETCH_CAROUSEL_REQUEST";
export const FETCH_CAROUSEL_SUCCESS = "FETCH_CAROUSEL_SUCCESS";
export const FETCH_CAROUSEL_FAIL = "FETCH_CAROUSEL_FAIL";

export const ADD_CAROUSEL_REQUEST = "ADD_CAROUSEL_REQUEST";
export const ADD_CAROUSEL_SUCCESS = "ADD_CAROUSEL_SUCESS";
export const ADD_CAROUSEL_FAIL = "ADD_CAROUSEL_FAIL";

export const EDIT_CAROUSEL_REQUEST = "ADD_CAROUSEL_REQUEST";
export const EDIT_CAROUSEL_SUCCESS = "ADD_CAROUSEL_SUCESS";
export const EDIT_CAROUSEL_FAIL = "ADD_CAROUSEL_FAIL";

export const DELETE_CAROUSEL_REQUEST = "ADD_CAROUSEL_REQUEST";
export const DELETE_CAROUSEL_SUCCESS = "ADD_CAROUSEL_SUCESS";
export const DELETE_CAROUSEL_FAIL = "ADD_CAROUSEL_FAIL";

export const CLEAR = "CLEAR";