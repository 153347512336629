export const FETCH_QUIZ_REQUEST = "FETCH_QUIZ_REQUEST";
export const FETCH_QUIZ_SUCCESS = "FETCH_QUIZ_SUCCESS";
export const FETCH_QUIZ_FAIL = "FETCH_QUIZ_FAIL";

export const ADD_QUIZ_REQUEST = "ADD_QUIZ_REQUEST";
export const ADD_QUIZ_SUCCESS = "ADD_QUIZ_SUCESS";
export const ADD_QUIZ_FAIL = "ADD_QUIZ_FAIL";

export const EDIT_QUIZ_REQUEST = "EDIT_QUIZ_REQUEST";
export const EDIT_QUIZ_SUCCESS = "EDIT_QUIZ_SUCESS";
export const EDIT_QUIZ_FAIL = "EDIT_QUIZ_FAIL";

export const DELETE_QUIZ_REQUEST = "DELETE_QUIZ_REQUEST";
export const DELETE_QUIZ_SUCCESS = "DELETE_QUIZ_SUCESS";
export const DELETE_QUIZ_FAIL = "DELETE_QUIZ_FAIL";

export const CLEAR = "CLEAR";
