import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import { addVideos, clear } from "../../redux/videos/videosAction";

//components
import Loading from "../layout/Loading";

//css
import "./Videos.css";

function VideosAddModal() {
  const dispatch = useDispatch();
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if (link !== "" && title !== "") setButtonState(false);
    else setButtonState(true);
  }, [link, title]);
  const { addLoading } = useSelector((state) => state.skillsReducer);

  const resetForm = () => {
    setLink("");
    setTitle("");

    dispatch(clear());
  };

  const addVideosHandle = (e) => {
    e.preventDefault();
    dispatch(addVideos(link, title));
    setLink("");
    setTitle("");
  };

  return (
    <div>
      <div className="modal fade" id="add-videos-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add video</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={addVideosHandle}>
                  <div className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Video Link :</span>
                      <input
                        type="link"
                        className="  inputt col-md-8 form-controls"
                        value={link}
                        placeholder="Enter the video's link"
                        required
                        onChange={(e) => {
                          setLink(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Video Title :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={title}
                        placeholder="Enter the video's title"
                        required
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                  </div>
                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addVideosHandle}
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      disabled={buttonState ? true : false}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideosAddModal;
