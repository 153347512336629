export const FETCH_CONSTANT_REQUEST = "FETCH_CONSTANT_REQUEST";
export const FETCH_CONSTANT_SUCCESS = "FETCH_CONSTANT_SUCCESS";
export const FETCH_CONSTANT_FAIL = "FETCH_CONSTANT_FAIL";



export const EDIT_CONSTANT_REQUEST = "EDIT_CONSTANT_REQUEST";
export const EDIT_CONSTANT_SUCCESS = "EDIT_CONSTANT_SUCESS";
export const EDIT_CONSTANT_FAIL = "EDIT_CONSTANT_FAIL";



export const CLEAR = "CLEAR";