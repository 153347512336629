import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import { addEpisodeQuizAnswers } from "../../redux/episodeQuiz/episodeQuizActions";
import { getEpisode } from "../../redux/episode/episodeActions";

//components
import Loading from "../layout/Loading";

import "react-toastify/dist/ReactToastify.css";
import { getEpisodeQuiz } from "../../redux/episodeQuiz/episodeQuizActions";

//redux
import {
  addEpisodeQuizAnswer,
  clear,
} from "../../redux/episodeQuizAnswer/episodeQuizAnswerAction";

//css
import "./EpisodeQuiz.css";

function EpisodeQuizAddModal() {
  const dispatch = useDispatch();
  const [question, setQuestion] = useState("");
  const [question_ar, setQuestion_ar] = useState("");

  const [episode_id, setEpisode_id] = useState("");
  const { addLoading } = useSelector((state) => state.episodeQuizReducer);
  const { episode } = useSelector((state) => state.episodeReducer);
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if (episode_id !== "" && question !== "") setButtonState(false);
    else setButtonState(true);
  }, [episode_id, question, question_ar]);
  useEffect(() => {
    dispatch(getEpisode());
  }, [dispatch]);

  const resetForm = () => {
    setQuestion("");
    setQuestion_ar("");

    setEpisode_id("");
    dispatch(clear());

    setQuestions([{}]);
    setEpisode_quiz_id("");

    dispatch(clear());
  };

  const [type, setType] = useState("");

  // const { addLoading } = useSelector((state) => state.episodeQuizanswerReducer);
  const { episodeQuiz } = useSelector((state) => state.episodeQuizReducer);

  const [episode_quiz_id, setEpisode_quiz_id] = useState("");
  const [questions, setQuestions] = useState([
    { answer: "", answer_ar: "", is_true: false },
  ]);

  const handleAddQuestion = () => {
    setQuestions([...questions, { answer: "", answer_ar: "", is_true: false }]);
  };

  const handleAnswerChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].answer = event.target.value;
    setQuestions(newQuestions);
  };

  const handleAnswerArabicChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].answer_ar = event.target.value;
    setQuestions(newQuestions);
  };

  const handleIsCorrectChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].is_true = event.target.checked;
    setQuestions(newQuestions);
  };

  const [lastAddedQuestionId, setLastAddedQuestionId] = useState(null);

  const addEpisodeAnswerQuizHandle = (e) => {
    e.preventDefault();

    dispatch(
      addEpisodeQuizAnswers(episode_id, question, question_ar, type, questions)
    );

    setQuestions([{}]);
    setEpisode_quiz_id("");
  };

  useEffect(() => {
    dispatch(getEpisodeQuiz);
  }, [dispatch]);

  return (
    <div>
      <div className="modal fade" id="add-episodeQuiz-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add Episode Quiz</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>

            <div className="modal-body">
              <div className="row formRow">
                <span className="col-md-3 textForm">Episode :</span>
                <select
                  onChange={(e) => setEpisode_id(e.target.value)}
                  required
                  defaultValue={""}
                  className="inputt col-md-8 form-controls"
                >
                  <option value="" disabled>
                    Please select Episode
                  </option>
                  {episode.data?.map((episode) => (
                    <option key={episode.id} value={episode.id}>
                      {episode.title}
                    </option>
                  ))}
                </select>
              </div>
              <br />

              <div className="row formRow">
                <span className="col-md-3 textForm">Question :</span>
                <input
                  type="text"
                  className="inputt col-md-8 form-controls"
                  value={question}
                  placeholder="Enter the question"
                  required
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </div>
              <br />

              <div className="row formRow">
                <span className="col-md-3 textForm">Arabic Question</span>
                <input
                  type="text"
                  className="  inputt col-md-8 form-controls"
                  value={question_ar}
                  placeholder="Enter the question in Arabic"
                  // required
                  onChange={(e) => {
                    setQuestion_ar(e.target.value);
                  }}
                />
              </div>

              <br />

              <div className="row formRow">
                <span className="col-md-3 textForm">Type:</span>
                <select
                  className="col-md-8 form-controls"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  <option value="">Select type</option>
                  <option value="radio">Radio</option>
                  <option value="checkbox">Checkbox</option>
                  <option value="string">Input</option>
                </select>
              </div>

              <div className="modal-body">
                {questions.map((question, index) => (
                  <div key={index}>
                    <div className="row formRow">
                      <span className="col-md-3 textForm">
                        {" "}
                        Answer {index}:
                      </span>
                      <input
                        className="inputt col-md-8 form-controls"
                        type="text"
                        value={question.answer}
                        onChange={(event) => handleAnswerChange(index, event)}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-3 textForm">
                        Arabic Anwer {index}:
                      </span>

                      <input
                        className="  inputt col-md-8 form-controls"
                        type="text"
                        value={question.answer_ar}
                        onChange={(event) =>
                          handleAnswerArabicChange(index, event)
                        }
                      />
                    </div>
                    <br></br>

                    <div className="row formRow">
                      <span className="col-md-3 textForm">Is Correct :</span>
                      <input
                        className="check"
                        type="checkbox"
                        checked={question.is_true}
                        onChange={(event) =>
                          handleIsCorrectChange(index, event)
                        }
                      />
                    </div>
                    <br />
                  </div>
                ))}
                <button
                  className="add-btn"
                  type="button"
                  onClick={handleAddQuestion}
                >
                  Add Answer
                </button>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="submit"
                className="add-btn"
                data-dismiss="modal"
                data-target="#add-episodequizAnswer-modal"
                data-toggle="modal"
                disabled={buttonState ? true : false}
                onClick={(e) => {
                  addEpisodeAnswerQuizHandle(e);
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EpisodeQuizAddModal;
