import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ReactDatatable from "@ashvin27/react-datatable";
import * as XLSX from "xlsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";

import DistrictAddModal from "./DistrictAddModal";
import DistrictEditModal from "./DistrictEditModal";

//redux
import { getDistricts } from "../../redux/district/districtActions";

//utils
import isAuth from "../../utils/isAuth";

function District() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, districts, error, addError, editError, deleteError } =
    useSelector((state) => state.districtReducer);
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [name_ar, setName_ar] = useState("");
  const [governate_id, setGovernate_id] = useState("");

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getDistricts());
  }, [dispatch]);

  useEffect(() => {
    if (districts && districts.data) {
      const mappedRecords = districts.data.map((district) => {
        return {
          key: district.id,
          id: district.id,
          governate_id: district.governate.id,
          governate_name: district.governate.name,
          name: district.name,
          name_ar: district.name_ar,
        };
      });
      setRecords(mappedRecords);
    }
  }, [districts]);

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(records);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "District.xlsx");
  };
  const columns = [
    {
      field: "governate_name",
      headerName: "Governorate",
      flex: 1,
    },
    {
      field: "name",
      headerName: "District",
      flex: 1,
    },
    {
      field: "name_ar",
      headerName: "Arabic Name",
      flex: 1,
    },
    //   {
    //     field: "action",
    //     headerName: "Action",
    //     flex: 0.5,
    //     minWidth: 230,
    //     cellClassName: "action centerText",
    //     sortable: false,
    //     renderCell: (params) => (
    //       <div>
    //       <button
    //           data-toggle="modal"
    //           data-target="#add-district-modal"
    //           className="actions"
    //           onClick={() => {
    //               setId(params.row.id);
    //           }}
    //       >
    //           <i className="fa fa-plus"></i>
    //       </button>
    //   </div>

    //     ),
    // },
    // Commented out action column
    // {
    //   field: "action",
    //   headerName: "Action",
    //   flex: 1,
    //   width: 100,
    //   cell: (record) => {
    //     return (
    //       <Fragment>
    //         <button
    //           data-toggle="modal"
    //           data-target="#update-district-modal"
    //           className="btn btn-primary btn-sm"
    //           style={{ marginRight: "5px" }}
    //           onClick={() => {
    //             setId(record.id);
    //             setName(record.name);
    //             setName_ar(record.name_ar);
    //             setGovernate_id(record.governate_id);
    //           }}
    //         >
    //           <i className="fa fa-edit"></i>
    //         </button>
    //         <button
    //           data-toggle="modal"
    //           data-target="#delete-district-modal"
    //           className="actions"
    //           onClick={() => {
    //             setId(record.id);
    //           }}
    //         >
    //           <i className="fa fa-trash"></i>
    //         </button>
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  const handleReloadClick = () => {
    window.location.reload();
  };
  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        {/* <DistrictAddModal /> */}
        <DistrictEditModal
          id={id}
          name={name}
          name_ar={name_ar}
          governate_id={governate_id}
          setName={setName}
          setName_ar={setName_ar}
          setGovernate_id={setGovernate_id}
        />
        <DistrictAddModal />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            {/* <button
              className="btn btn-outline-primary float-right mt-3 mr-2"
              data-toggle="modal"
              data-target="#add-district-modal"
            >
              <FontAwesomeIcon icon={faPlus} /> Add District
            </button> */}
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
              {addError && (
                <div style={{ color: "red" }}> {addError.message}</div>
              )}
              {editError && (
                <div style={{ color: "red" }}> {editError.message}</div>
              )}
              {deleteError && (
                <div style={{ color: "red" }}> {deleteError.message}</div>
              )}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <button
                    className="btn btn-outline-primary float-right mt-3 mr-2 add"
                    data-toggle="modal"
                    data-target="#add-district-modal"
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add District
                  </button>
                  <Header title="District" subtitle="List of Districts" />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default District;
