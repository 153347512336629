import axios from "axios";

//redux
import {

  FETCH_EPISODEQUIZANSWER_REQUEST,
  FETCH_EPISODEQUIZANSWER_SUCCESS,
  FETCH_EPISODEQUIZANSWER_FAIL,
  ADD_EPISODEQUIZANSWER_REQUEST,
  ADD_EPISODEQUIZANSWER_SUCCESS,
  ADD_EPISODEQUIZANSWER_FAIL,
  EDIT_EPISODEQUIZANSWER_REQUEST,
  EDIT_EPISODEQUIZANSWER_SUCCESS,
  EDIT_EPISODEQUIZANSWER_FAIL,
  DELETE_EPISODEQUIZANSWER_REQUEST,
  DELETE_EPISODEQUIZANSWER_SUCCESS,
  DELETE_EPISODEQUIZANSWER_FAIL,
  CLEAR,
} from "./episodeQuizAnswerTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_EPISODEQUIZANSWER_REQUEST,
  };
};

export const fetchSuccess = (episodequizzesAnswers) => {
  return {
    type: FETCH_EPISODEQUIZANSWER_SUCCESS,
    payload: episodequizzesAnswers,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_EPISODEQUIZANSWER_FAIL,
    payload: error,
  };
};

export const addEpisodeQuizAnswerRequest = () => {
  return {
    type: ADD_EPISODEQUIZANSWER_REQUEST,
  };
};

export const addEpisodeQuizAnswerSuccess = () => {
  return {
    type: ADD_EPISODEQUIZANSWER_SUCCESS,
  };
};

export const addEpisodeQuizAnswerFail = (error) => {
  return {
    type: ADD_EPISODEQUIZANSWER_FAIL,
    payload: error,
  };
};

export const editEpisodeQuizAnswerRequest = () => {
  return {
    type: EDIT_EPISODEQUIZANSWER_REQUEST,
  };
};

export const editEpisodeQuizAnswerSuccess = () => {
  return {
    type: EDIT_EPISODEQUIZANSWER_SUCCESS,
  };
};

export const editEpisodeQuizAnswerFail = (error) => {
  return {
    type: EDIT_EPISODEQUIZANSWER_FAIL,
    payload: error,
  };
};

export const deleteEpisodeQuizAnswerRequest = () => {
  return {
    type: DELETE_EPISODEQUIZANSWER_REQUEST,
  };
};

export const deleteEpisodeQuizAnswerSuccess = () => {
  return {
    type: DELETE_EPISODEQUIZANSWER_SUCCESS,
  };
};

export const deleteEpisodeQuizAnswerFail = (error) => {
  return {
    type: DELETE_EPISODEQUIZANSWER_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};



export const getEpisodeQuizAnswer = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/episode_quiz_answers";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};


export const deleteEpisodeQuizAnswer = (id) => async (dispatch) => {
  dispatch(deleteEpisodeQuizAnswerRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/episode_quiz_answers/" + id);
    dispatch(deleteEpisodeQuizAnswerSuccess());
    dispatch(getEpisodeQuizAnswer());
  } catch (e) {
    dispatch(deleteEpisodeQuizAnswerFail(e.response.data));
  }
};

export const addEpisodeQuizAnswer =
  (
    episode_quiz_id,
    episode_quiz_answer,
  ) =>
    async (dispatch) => {
      let array = []
      dispatch(addEpisodeQuizAnswerRequest());
     episode_quiz_answer.forEach(e => {
      array.push({
        "episode_quiz_id": episode_quiz_id,
        "is_true": e.is_true,
        "answer": e.answer,
        "answer_ar": e.answer_ar 
      })
     });
      try {
        await axios.post(process.env.REACT_APP_API + "/episode_quiz_answers", {
          "episode_quiz_answer": array,
      });
        dispatch(addEpisodeQuizAnswerSuccess());
        dispatch(getEpisodeQuizAnswer());
      } catch (e) {
        dispatch(addEpisodeQuizAnswerFail(e.response.data));
      }
    };

export const updateEpisodeQuizAnswer = (id, is_true, answer, answer_ar) => async (dispatch) => {
  dispatch(editEpisodeQuizAnswerRequest());
  try {
    await axios.put(process.env.REACT_APP_API + "/episode_quiz_answers/" + id, {

      is_true,
      answer,
      answer_ar

    });
    dispatch(editEpisodeQuizAnswerSuccess());
    dispatch(getEpisodeQuizAnswer());
  } catch (e) {
    dispatch(editEpisodeQuizAnswerFail(e.response.data));
  }
};


export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
