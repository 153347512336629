import {
  FETCH_ORGANIZATIONFEEDBACK_REQUEST,
  FETCH_ORGANIZATIONFEEDBACK_SUCCESS,
  FETCH_ORGANIZATIONFEEDBACK_FAIL,
} from "./organizationFeedbackTypes";

export const initialState = {
  loading: false,
  episodeQuiz: [],
  error: "",
};

const organizationFeedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATIONFEEDBACK_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_ORGANIZATIONFEEDBACK_SUCCESS:
      return {
        loading: false,
        organizationFeedback: action.payload,
        error: "",
      };
    case FETCH_ORGANIZATIONFEEDBACK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default organizationFeedbackReducer;
