import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import { addBlog, clear } from "../../redux/blog/blogActions";

//components
import Loading from "../layout/Loading";

//css
import "./Blog.css";

function BlogAddModal() {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [picture, setPicture] = useState("");
  const [author, setAuthor] = useState("");
  const { addLoading } = useSelector((state) => state.blogReducer);
  const [buttonState, setButtonState] = useState(true);
  const [newImgSource, setNewImgSource] = useState("");
  const [imgName, setImgName] = useState("default.png");
  const defaultSource = process.env.REACT_APP_API + "/Blogs/default.png";
  const re = /(?:\.([^.]+))?$/;

  const resetForm = () => {
    setTitle("");
    setText("");
    setPicture("");
    setAuthor("");
    setNewImgSource(defaultSource);
    setImgName("default.png");
    dispatch(clear());
  };

  const addBlogHandle = (e) => {
    e.preventDefault();
    dispatch(addBlog(title, text, picture, author));
    setTitle("");
    setText("");
    setPicture("");
    setAuthor("");
    setNewImgSource(defaultSource);
    setImgName("default.png");
  };

  const removeImg = (e) => {
    e.preventDefault();
    setNewImgSource(defaultSource);
    setImgName("default.png");
    setPicture("");
    document.getElementById("files").value = null;
  };

  const changeImgName = (Name) => {
    setImgName(Name);
  };

  useEffect(() => {
    if (text !== "" && title !== "" && author !== "") setButtonState(false);
    else setButtonState(true);
  }, [text, title, author]);

  return (
    <div>
      <div className="modal fade" id="add-blog-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add Blog</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={addBlogHandle} encType="multipart/form-data">
                  <div className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-2 textForm">Title :</span>
                      <input
                        type="text"
                        className="inputt col-md-9 form-controls"
                        value={title}
                        placeholder="Enter the blog's title"
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-2 textForm">Text :</span>
                      <textarea
                        className="inputt col-md-9 form-controls"
                        rows="5"
                        placeholder="Enter the blog's text"
                        value={text}
                        onChange={(e) => {
                          setText(e.target.value);
                        }}
                      ></textarea>
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="inputt col-md-9 textForm">Picture :</span>

                      <div className="row">
                        <div className="row">
                          <img
                            src={
                              newImgSource !== "" ? newImgSource : defaultSource
                            }
                            style={{
                              height: "130px",
                              width: "130px",
                              marginLeft: "40px",
                            }}
                            alt={picture}
                          />
                          {imgName !== "default.png" && (
                            <button className="tag-remove" onClick={removeImg}>
                              &#10006;
                            </button>
                          )}
                        </div>

                        <div className="row">
                          <label
                            htmlFor="files"
                            className="button"
                            style={{
                              marginLeft: "50px",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            {imgName !== "default.png"
                              ? "Change Image"
                              : "Choose Image"}
                          </label>
                          <input
                            id="files"
                            type="file"
                            className="inputt"
                            accept="image/*"
                            style={{ visibility: "hidden", width: "1px" }}
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                setPicture(e.target.files[0]);
                                if (e.target.files[0].name.length > 15)
                                  changeImgName(
                                    e.target.files[0].name.substring(0, 15) +
                                      "...." +
                                      re.exec(e.target.files[0].name)[1]
                                  );
                                else changeImgName(e.target.files[0].name);
                                setNewImgSource(
                                  URL.createObjectURL(e.target.files[0])
                                );
                              }
                            }}
                          />

                          <h6
                            style={{
                              marginLeft: "15px",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            {imgName !== "default.png" ? imgName : ""}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-2 textForm">Author :</span>
                      <input
                        type="text"
                        className="inputt col-md-9 form-controls"
                        value={author}
                        placeholder="Enter the author's name"
                        onChange={(e) => {
                          setAuthor(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addBlogHandle}
                      type="submit"
                      disabled={buttonState ? true : false}
                      className="add-btn"
                      data-dismiss="modal"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogAddModal;
