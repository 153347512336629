import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import {
  clear,
  updateEpisodeVideo,
} from "../../redux/episodeVideo/episodeVideoActions";
import { getEpisode } from "../../redux/episode/episodeActions";

//component
import Loading from "../layout/Loading";

//css
import "./EpisodeVideo.css";

function EditEpisodeVideoModel({
  id,
  episode_id,
        title,
        title_ar,
        desc,
        desc_ar,
        length,
        link,
        name_trai,
        name_ar_trai,
        desc_trai,
        desc_ar_trai,
        linked_in,
        setEpisode_id,
        setTitle,
        setTitle_ar,
        setDesc,
        setDesc_ar,
        setLength,
        setLink,
        setName_trai,
        setName_ar_trai,
        setDesc_trai,
        setDesc_ar_trai,
        setLinked_in
}) {
  const dispatch = useDispatch();
  const { editLoading } = useSelector((state) => state.episodeVideoReducer);
  const { episode } = useSelector((state) => state.episodeReducer);
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if (title !== "" && title_ar !== "" && desc !== "" && desc_ar !== ""&& length !== "" && link !== ""&& linked_in !== "" && name_trai !== "" && name_ar_trai !== ""&& desc_trai !== "" && desc_ar_trai !== ""&& episode_id !== ""   ) setButtonState(false);
    else setButtonState(true);
  }, [title, title_ar,desc,desc_ar,length,link,linked_in,name_trai, name_ar_trai,desc_trai,desc_ar_trai,episode_id]);
  useEffect(() => {
    dispatch(getEpisode());
  }, [dispatch]);

  const resetForm = () => {
    setTitle("");
    setTitle_ar("");
    setDesc("");
    setDesc_ar("");
    setName_trai("");
    setName_ar_trai("");
    setDesc_ar_trai("");
    setDesc_trai("");
    setLinked_in("");
    setLength("");
    setLink("");
    setEpisode_id("");
   
    dispatch(clear());
  };

  const editEpisodeVideoHandle = (e) => {
    e.preventDefault();
    dispatch(updateEpisodeVideo(id, episode_id,title,title_ar,desc,desc_ar,length, link,name_trai,name_ar_trai,desc_trai,desc_ar_trai,linked_in));
    setTitle("");
    setTitle_ar("");
    setDesc("");
    setDesc_ar("");
    setName_trai("");
    setName_ar_trai("");
    setDesc_ar_trai("");
    setDesc_trai("");
    setLinked_in("");
    setLength("");
    setLink("");
    setEpisode_id("");
  };

  return (
    <div className="modal fade" id="update-episodeVideo-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Edit Episode Video</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={resetForm}
            >
              &times;
            </button>
          </div>
          {editLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form onSubmit={editEpisodeVideoHandle}>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Episode :</span>
                    <select
                      onChange={(e) => setEpisode_id(e.target.value)}
                      required
                      value={episode_id}
                    >
                      {episode.data?.map((episode) => (
                        <option key={episode.id} value={episode.id}>
                          {episode.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <br />

                  <div className="row formRow">
                    <span className="col-md-3 textForm">Title :</span>
                    <input
                      value={title}
                      placeholder="Enter the title"
                      onChange={(e) => setTitle(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                      
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Arabic Title :</span>
                    <input
                      value={title_ar}
                      placeholder="Enter the title in Arabic"
                      onChange={(e) => setTitle_ar(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br></br>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Description :</span>
                    <textarea
                      value={desc}
                      placeholder="Enter the Description"
                      onChange={(e) => setDesc(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    ></textarea>
              
                  </div>
                  <br></br>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Description in Arabic:</span>
                    <textarea
                      value={desc_ar}
                      placeholder="Enter the Description in Arabic"
                      onChange={(e) => setDesc_ar(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    ></textarea>
                  </div>
                  <br></br>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Length :</span>
                    <input
                      value={length}
                      placeholder="Enter the video's length in format   'HH:mm:ss'"
                      onChange={(e) => setLength(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Link :</span>
                    <input
                      value={link}
                      placeholder="Enter the video's link"
                      onChange={(e) => setLink(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br></br>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">trainer :</span>
                    <input
                      value={name_trai}
                      placeholder="Enter name of trainer"
                      onChange={(e) => setName_trai(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br></br>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Trainer in Arabic:</span>
                    <input
                      value={name_ar_trai}
                      placeholder="Enter the name of trainern in Arabic"
                      onChange={(e) => setName_ar_trai(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br></br>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Trainer Description :</span>
                    <textarea
                      value={desc_trai}
                      placeholder="Enter the Description of trainer"
                      onChange={(e) => setDesc_trai(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                   ></textarea>
                  </div>
                  <br></br>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Trainer Description in Arabic:</span>
                    <textarea
                      value={desc_ar_trai}
                      placeholder="Enter the Description of trainer in Arabic"
                      onChange={(e) => setDesc_ar_trai(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    ></textarea>
                  </div>
                  <br></br>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Linked In :</span>
                    <input
                      value={linked_in}
                      placeholder="Enter the linked in of trainer"
                      onChange={(e) => setLinked_in(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br></br>
                
                  <div className="modal-footer">
                    <button
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={resetForm}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      onClick={editEpisodeVideoHandle}
                      disabled={buttonState ? true : false}
                    >
                      Edit
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditEpisodeVideoModel;
