export const FETCH_EPISODEQUIZANSWER_REQUEST = "FETCH_EPISODEQUIZANSWER_REQUEST";
export const FETCH_EPISODEQUIZANSWER_SUCCESS = "FETCH_EPISODEQUIZANSWER_SUCCESS";
export const FETCH_EPISODEQUIZANSWER_FAIL = "FETCH_EPISODEQUIZANSWER_FAIL";

export const ADD_EPISODEQUIZANSWER_REQUEST = "ADD_EPISODEQUIZANSWER_REQUEST";
export const ADD_EPISODEQUIZANSWER_SUCCESS = "ADD_EPISODEQUIZANSWER_SUCESS";
export const ADD_EPISODEQUIZANSWER_FAIL = "ADD_EPISODEQUIZANSWER_FAIL";

export const EDIT_EPISODEQUIZANSWER_REQUEST = "EDIT_EPISODEQUIZANSWER_REQUEST";
export const EDIT_EPISODEQUIZANSWER_SUCCESS = "EDIT_EPISODEQUIZANSWER_SUCESS";
export const EDIT_EPISODEQUIZANSWER_FAIL = "EDIT_EPISODEQUIZANSWER_FAIL";

export const DELETE_EPISODEQUIZANSWER_REQUEST = "DELETE_EPISODEQUIZANSWER_REQUEST";
export const DELETE_EPISODEQUIZANSWER_SUCCESS = "DELETE_EPISODEQUIZANSWER_SUCESS";
export const DELETE_EPISODEQUIZANSWER_FAIL = "DELETE_EPISODEQUIZANSWER_FAIL";


export const CLEAR = "CLEAR";