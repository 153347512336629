import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux

import { getEpisode } from "../../redux/episode/episodeActions";

//component
import Loading from "../layout/Loading";

//css
import "./EpisodeVideo.css";

function EpisodeVideoView({
  episode_id,
  title,
  title_ar,
  desc,
  desc_ar,
  length,
  link,
  name_trai,
  name_ar_trai,
  desc_trai,
  desc_ar_trai,
  linked_in,
}) {
  const dispatch = useDispatch();
  const { editLoading } = useSelector((state) => state.episodeVideoReducer);
  const { episode } = useSelector((state) => state.episodeReducer);

  useEffect(() => {
    dispatch(getEpisode());
  }, [dispatch]);

  return (
    <div className="modal fade" id="update-episodeVideoview-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">View more</h4>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          {editLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <div className="row formRow">
                  <span className="col-md-3 textForm">Episode Title :</span>
                  <select disabled required defaultValue={episode_id}>
                    {episode.data?.map((episode) => (
                      <option key={episode.id} value={episode.id}>
                        {episode.title}
                      </option>
                    ))}
                  </select>
                </div>
                <br />
                <div className="row formRow">
                  <span className="col-md-3 textForm">Video Title :</span>
                  <input
                    defaultValue={title}
                    placeholder="Enter the title"
                    disabled
                    className="inputt col-md-8 form-controls"
                  />
                </div>
                <br />
                <div className="row formRow">
                  <span className="col-md-3 textForm">
                    Arabic Video Title :
                  </span>
                  <input
                    defaultValue={title_ar}
                    placeholder="Enter the title in Arabic"
                    disabled
                    className="inputt col-md-8 form-controls"
                  />
                </div>
                <br />
                <div className="row formRow">
                  <span className="col-md-3 textForm">Description :</span>
                  <textarea
                    defaultValue={desc}
                    placeholder="Enter the Description"
                    disabled
                    className="inputt col-md-8 form-controls"
                  ></textarea>
                </div>
                <br />
                <div className="row formRow">
                  <span className="col-md-3 textForm">
                    Arabic Description :
                  </span>
                  <textarea
                    defaultValue={desc_ar}
                    placeholder="Enter the Description in Arabic"
                    disabled
                    className="inputt col-md-8 form-controls"
                  ></textarea>
                </div>
                <br></br>
                <div className="row formRow">
                  <span className="col-md-3 textForm">Video Length :</span>
                  <input
                    disabled
                    value={length}
                    placeholder="Enter the video's length in format   'HH:mm:ss'"
                    className="  inputt col-md-8 form-controls"
                  />
                </div>
                <br />
                <div className="row formRow">
                  <span className="col-md-3 textForm">Video Link :</span>
                  <input
                    disabled
                    value={link}
                    placeholder="Enter the video's link"
                    className="  inputt col-md-8 form-controls"
                  />
                </div>
                <br></br>
                <div className="row formRow">
                  <span className="col-md-3 textForm">Trainer Name :</span>
                  <input
                    disabled
                    value={name_trai}
                    placeholder="Enter name of trainer"
                    className="  inputt col-md-8 form-controls"
                  />
                </div>
                <br></br>
                <div className="row formRow">
                  <span className="col-md-3 textForm">
                    Arabic Trainer Name :
                  </span>
                  <input
                    disabled
                    value={name_ar_trai}
                    placeholder="Enter name of trainer in Arabic"
                    className="  inputt col-md-8 form-controls"
                  />
                </div>
                <br></br>
                <div className="row formRow">
                  <span className="col-md-3 textForm">
                    Trainer Description :
                  </span>
                  <textarea
                    disabled
                    value={desc_trai}
                    placeholder="Enter the Description of trainer"
                    className="  inputt col-md-8 form-controls"
                  ></textarea>
                </div>
                <br></br>
                <div className="row formRow">
                  <span className="col-md-3 textForm">
                    Arabic Trainer Description :
                  </span>
                  <textarea
                    disabled
                    value={desc_ar_trai}
                    placeholder="Enter the Description of trainer in Arabic"
                    className="  inputt col-md-8 form-controls"
                  ></textarea>
                </div>
                <br></br>
                <div className="row formRow">
                  <span className="col-md-3 textForm">Trainer LinkedIn :</span>
                  <input
                    disabled
                    value={linked_in}
                    placeholder="Enter the linked in of trainer"
                    className="  inputt col-md-8 form-controls"
                  />
                </div>
                <br></br>

                <div className="modal-footer">
                  <button
                    type="button"
                     className="close-btn"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EpisodeVideoView;
