import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
import { Box } from "@mui/material";

//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import Header from "../Header/Header";

//redux
import { getOpportunityFeedbacks } from "../../redux/opportunityFeedbacks/opportunityFeedbacksActions";

//utils
import isAuth from "../../utils/isAuth";

function OpportunityFeedback() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, OpportunityFeedback, error } = useSelector(
    (state) => state.opportunityFeedbacksReducer
  );
  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getOpportunityFeedbacks());
  }, [dispatch]);

  useEffect(() => {
    if (OpportunityFeedback && OpportunityFeedback.data) {
      const mappedRecords = OpportunityFeedback?.data.map((data) => {
        return {
          key: data.id,
          id: data.id,
          support: data.support,
          ImproveAspects: data.ImproveAspects,
          involvement: data.involvement,
          overallImpact: data.overallImpact,
          personalGoals: data.personalGoals,
          soft_skills_ids: data.soft_skills_ids,
          entrepreneurialIdeas: data.entrepreneurialIdeas,
          socialCommercial: data.socialCommercial,
          socialCommercialExplanation: data.socialCommercialExplanation,
          volunteerConnect: data.volunteerConnect,
          volunteerInfluence: data.volunteerInfluence,
          otherInfluence: data.otherInfluence,
          volunteerExperience: data.volunteerExperience,
          VolunteerServices: data.VolunteerServices,
          ImproveExperience: data.ImproveExperience,
        };
      });
      setRecords(mappedRecords);
    }
  }, [OpportunityFeedback]);

  const columns = [
    {
      field: "support",
      headerName: "support",
      flex: 1,
    },
    {
      field: "ImproveAspects",
      headerName: "ImproveAspects",
      flex: 1,
    },
    {
      field: "involvement",
      headerName: "involvement",
      flex: 1,
    },
    {
      field: "overallImpact",
      headerName: "overallImpact",
      flex: 1,
    },
    {
      field: "personalGoals",
      headerName: "personalGoals",
      flex: 1,
    },
    {
      field: "soft_skills_ids",
      headerName: "soft_skills_ids",
      flex: 1,
    },
    {
      field: "entrepreneurialIdeas",
      headerName: "entrepreneurialIdeas",
      flex: 1,
    },
    {
      field: "socialCommercial",
      headerName: "socialCommercial",
      flex: 1,
    },
    {
      field: "socialCommercialExplanation",
      headerName: "socialCommercialExplanation",
      flex: 1,
    },
    {
      field: "volunteerConnect",
      headerName: "volunteerConnect",
      flex: 1,
    },
    {
      field: "volunteerInfluence",
      headerName: "volunteerInfluence",
      flex: 1,
    },
    {
      field: "otherInfluence",
      headerName: "otherInfluence",
      flex: 1,
    },
    {
      field: "volunteerExperience",
      headerName: "volunteerExperience",
      flex: 1,
    },
    {
      field: "VolunteerServices",
      headerName: "VolunteerServices",
      flex: 1,
    },
    {
      field: "ImproveExperience",
      headerName: "ImproveExperience",
      flex: 1,
    },
  ];

  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />

        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header
                    title="Opportunity Feedback"
                    subtitle="List of Opportunity Feedbacks"
                  />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpportunityFeedback;
