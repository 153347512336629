import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";




import {
  addTRUSTEDPART,
  clear,
} from "../../redux/TrustedPart/TrustedPartAction";

//components
import Loading from "../layout/Loading";

//css
import "./TrustedPart.css";

function TrustedPartAddAnswerModal() {
  const dispatch = useDispatch();
  const { addLoading } = useSelector((state) => state.TrustedPartReducer);

  const [name, setName] = useState("");
  const [name_ar, setName_ar] = useState("");
  const [section, setSection] = useState("");
  const [description, setDescription] = useState("");
  const [description_ar, setDescription_ar] = useState("");
  const [description_title, setDescription_title] = useState("");
  const [section_ar, setSection_ar] = useState("");
  const [description_title_ar, setDescription_title_ar] = useState("");
 const [newImgSource, setNewImgSource] = useState("");
  const [imgName, setImgName] = useState("default.png");
  const defaultSource = process.env.REACT_APP_API + "/TrustedPartner/default.png";
  const [img_url, setImg_url] = useState("");
  const [buttonState, setButtonState] = useState(true);
  const re = /(?:\.([^.]+))?$/;
  useEffect(() => {
    if (
     
      
      section!== "" &&
      section_ar!== "" &&
      name!== "" &&
      name_ar!== "" &&
      description!== "" &&
      description_ar!== "" &&
      description_title!== "" &&
      description_title_ar
    )
      setButtonState(false);
    else setButtonState(true);
  }, [ section,section_ar,name,name_ar,description,description_ar,description_title,description_title_ar]);
  const resetForm = () => {
     
      setSection("");
      setSection_ar("");
      setName("");
      setName_ar("");
      setDescription("");
      setDescription_ar("");
      setDescription_title("");
      setDescription_title_ar("");
      setNewImgSource(defaultSource);
      setImgName("default.png");
      setImg_url("");

    dispatch(clear());
  };
  const addTrustedPartHandle = (e) => {
    e.preventDefault();

    dispatch(addTRUSTEDPART(img_url, section, section_ar, name, name_ar, description, description_ar, description_title, description_title_ar));
  
    setSection("");
    setSection_ar("");
    setName("");
    setName_ar("");
    setDescription("");
    setDescription_ar("");
    setDescription_title("");
    setDescription_title_ar("");
    setNewImgSource(defaultSource);
    setImgName("default.png");
    setImg_url("");
  };

  const removeImg = (e) => {
    e.preventDefault();
    setNewImgSource(defaultSource);
    setImgName("default.png");
    setImg_url("");
    document.getElementById("files").value = null;
  };
  const changeImgName = (Name) => {
    setImgName(Name);
  };


  return (
    <div>
      <div
        className="modal fade"
        id="add-trustedpart-modal"
        data-reset="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="title">Add Trusted Partner</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={addTrustedPartHandle}>
                  <div className="modal-body">
                  <div className="row formRow">
                      <span className="col-md-3 textForm">Image :</span>

                      <div className="row">
                        <div className="row">
                          <img
                            src={
                              newImgSource !== "" ? newImgSource : defaultSource
                            }
                            style={{
                              height: "130px",
                              width: "130px",
                              marginLeft: "40px",
                            }}
                            alt={img_url}
                          />
                          {imgName !== "default.png" && (
                            <button className="tag-remove" onClick={removeImg}>
                              &#10006;
                            </button>
                          )}
                        </div>

                        <div className="row">
                          <label
                            htmlFor="files"
                            className="button"
                            style={{
                              marginLeft: "50px",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            {imgName !== "default.png"
                              ? "Change Image"
                              : "Choose Image"}
                          </label>
                          <input
                            id="files"
                            type="file"
                            className="inputt"
                            style={{ visibility: "hidden", width: "1px" }}
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                setImg_url(e.target.files[0]);
                                if (e.target.files[0].name.length > 15)
                                  changeImgName(
                                    e.target.files[0].name.substring(0, 15) +
                                      "...." +
                                      re.exec(e.target.files[0].name)[1]
                                  );
                                else changeImgName(e.target.files[0].name);
                                setNewImgSource(
                                  URL.createObjectURL(e.target.files[0])
                                );
                              }
                            }}
                          />

                          <h6
                            style={{
                              marginLeft: "15px",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            {imgName !== "default.png" ? imgName : ""}
                          </h6>
                        </div>
                      </div>
                    </div>
                   
                    <br></br>



                    <div className="row formRow">
                      <span className="col-md-3 textForm">Section :</span>
                    <select
                        className="  inputt col-md-8 form-controls"
                        onChange={(e) => {
                          setSection(e.target.value);
                          if(e.target.value==="Academic Partners"){
                            setSection_ar("شركاء أكاديميون")
                          }
                          else if(e.target.value==="Transportation Partners"){
                          setSection_ar("شركاء النقل")
                          }
                          else if(e.target.value==="Community Partners"){
                            setSection_ar("شركاء المجتمع")
                            }
                          
                        }}
                        required
                        value={section}
                      >
                        <option value="" disabled>
                          Please select the Section
                        </option>
                        <option value="Academic Partners">
                        Academic Partners
                          </option>
                          <option value="Transportation Partners">
                          Transportation Partners
                          </option>
                          <option value="Community Partners">
                          Community Partners
                          </option>
                       
                      </select>
                      </div>
                    <br></br>
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Section In Arabic:</span>
                    <select
                        className="  inputt col-md-8 form-controls"
                        onChange={(e) => {
                          setSection_ar(e.target.value);
                        }}
                        requiredCommunity
                        value={section_ar}
                      >
                        <option value="" disabled>
                          Please select the Section in Arabic
                        </option>
                        <option value="شركاء أكاديميون" disabled>
                        شركاء أكاديميون
                          </option>
                          <option value="شركاء النقل" disabled>
                          شركاء النقل
                          </option>
                          <option value="شركاء المجتمع" disabled>
                          شركاء المجتمع
                          </option>
                       
                      </select>
                      </div>
                    <br></br>
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Name :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={name}
                        placeholder="Enter the Name"
                        required
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <br></br>
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Arabic Name :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={name_ar}
                        placeholder="Enter the Arabic name"
                        required
                        onChange={(e) => {
                          setName_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Description :</span>
                      <textarea
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={description}
                        placeholder="Enter the Description"
                        required
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Arabic Description :</span>
                      <textarea
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={description_ar}
                        placeholder="Enter the Description In Arabic"
                        required
                        onChange={(e) => {
                          setDescription_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Description Title :</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={description_title}
                        placeholder="Enter the Description Title"
                        required
                        onChange={(e) => {
                          setDescription_title(e.target.value);
                        }}
                      />
                    </div>
                    <br />
                    <div className="row formRow">
                      <span className="col-md-3 textForm">Description Title Arabic:</span>
                      <input
                        type="text"
                        className="  inputt col-md-8 form-controls"
                        value={description_title_ar}
                        placeholder="Enter the Description Title in Arabic"
                        required
                        onChange={(e) => {
                          setDescription_title_ar(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                
                    
                    </div>
                 

                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addTrustedPartHandle}
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      disabled={buttonState ? true : false}
                    >
                      Add Answer
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrustedPartAddAnswerModal;
