import React from "react";
import { Link } from "react-router-dom";
import "./dashboard.css";

function DashboardCardStat({
  title,
  description,
  buttonText,
  icon,
  iconColor,
  link,
  cardColor,
}) {
  return (
    <div className="col-lg-4 col-sm-4 " style={{ minHeight: "10rem" }}>
      <div
        className={`card ${cardColor} text-white shadow-lg d-flex justify-content-center justify-content-center  
 container cardd`}
        style={{ minHeight: "5rem" }}
       >
        <div className="card-body left">
        <div className="centered-icon">{icon}</div>
        <br></br>
          <Link to={link}>
            <p className="card-text text">{description}</p>
            <div className="left">
              <h5 className="card-title d-flex  numb">
                {title}{" "}
              </h5>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DashboardCardStat;
