import React,{useEffect,useState} from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import { clear, updateEpisode } from "../../redux/episode/episodeActions";

//component
import Loading from "../layout/Loading";

//css
import "./Episode.css";

function EpisodeEditModal({ id, title, title_ar, setTitle, setTitle_ar }) {
  const dispatch = useDispatch();
  const { editLoading } = useSelector((state) => state.episodeReducer);
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if (title !== "" && title_ar !== ""  ) setButtonState(false);
    else setButtonState(true);
  }, [title, title_ar]);
  const resetForm = () => {
    setTitle("");
    setTitle_ar("");
    dispatch(clear());
  };

  const editEpisodeHandle = (e) => {
    e.preventDefault();
    dispatch(updateEpisode(id, title, title_ar));
    setTitle("");
    setTitle_ar("");
  };

  return (
    <div className="modal fade" id="update-episode-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Edit Episode</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={resetForm}
            >
              &times;
            </button>
          </div>
          {editLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form onSubmit={editEpisodeHandle}>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Episode :</span>
                    <input
                      value={title}
                      placeholder="Enter the episode's name"
                      onChange={(e) => setTitle(e.target.value)}
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Arabic Episode :</span>
                    <input
                      value={title_ar}
                      onChange={(e) => setTitle_ar(e.target.value)}
                      placeholder="Enter the episode's name in Arabic"
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={resetForm}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      onClick={editEpisodeHandle}
                      disabled={buttonState ? true : false}
                    >
                      Edit
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EpisodeEditModal;
