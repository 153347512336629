/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { useSelector } from "react-redux";
import "./Navbar.css";
import { useDispatch } from "react-redux";

import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { logoutAdmin } from "../../redux/auth/authActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import image from "./tvcimg.png";

function Navbar() {
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  return (
    <div className="container-fluid p-0 textt ">
      <nav className="navbar navbar-dark justify-content-end navc">
        {isAuthenticated && (
          <div className="navbar-brand  text-dark ">
            {" "}
            <h4> Welcome , To TVC Dashboard</h4> {localStorage.getItem("email")}{" "}
            <button className="logout" onClick={() => dispatch(logoutAdmin())}>
              Logout <FontAwesomeIcon icon={faSignOutAlt} />
            </button>
          </div>
        )}
        <div> </div>
      </nav>
    </div>
  );
}
export default Navbar;
