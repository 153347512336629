import {
  FETCH_COMMUNITY_ACTION_REQUEST,
  FETCH_COMMUNITY_ACTION_SUCCESS,
  FETCH_COMMUNITY_ACTION_FAIL,
  FETCH_ONE_COMMUNITY_ACTION_REQUEST,
  FETCH_ONE_COMMUNITY_ACTION_SUCCESS,
  FETCH_ONE_COMMUNITY_ACTION_FAIL,
  CLEAR,
} from "./communityActionTypes";

export const initialState = {
  loading: false,
  oneLoading: false,
  communityActions: [],
  oneCommunityAction: [],
  error: "",
};

const communityActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMUNITY_ACTION_REQUEST:
      return {
        ...state,
        oneLoading: true,
        loading: true,
        error: "",
      };
    case FETCH_COMMUNITY_ACTION_SUCCESS:
      return {
        loading: false,
        oneLoading: true,
        communityActions: action.payload,
        error: "",
      };
    case FETCH_COMMUNITY_ACTION_FAIL:
      return {
        ...state,
        loading: false,
        oneLoading: true,
        error: action.payload.message,
      };

    case FETCH_ONE_COMMUNITY_ACTION_REQUEST:
      return {
        ...state,
        loading: false,
        oneLoading: true,
        error: "",
      };
    case FETCH_ONE_COMMUNITY_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        oneLoading: false,
        oneCommunityAction: action.payload,
        error: "",
      };
    case FETCH_ONE_COMMUNITY_ACTION_FAIL:
      return {
        ...state,
        loading: false,
        oneLoading: false,
        error: action.payload.message,
      };

    case CLEAR:
      return {
        ...state,
        loading: false,
        oneLoading: false,
        error: "",
      };
    default:
      return state;
  }
};

export default communityActionReducer;
