export const FETCH_SKILLS_REQUEST = "FETCH_SKILLS_REQUEST";
export const FETCH_SKILLS_SUCCESS = "FETCH_SKILLS_SUCCESS";
export const FETCH_SKILLS_FAIL = "FETCH_SKILLS_FAIL";

export const FETCH_ONE_SKILLS_REQUEST = "FETCH_ONE_SKILLS_REQUEST";
export const FETCH_ONE_SKILLS_SUCCESS = "FETCH_ONE_SKILLS_SUCCESS";
export const FETCH_ONE_SKILLS_FAIL = "FETCH_ONE_SKILLS_FAIL";

export const FETCH_ONE_EXPERTISES_REQUEST = "FETCH_ONE_EXPERTISES_REQUEST";
export const FETCH_ONE_EXPERTISES_SUCCESS = "FETCH_ONE_EXPERTISES_SUCCESS";
export const FETCH_ONE_EXPERTISES_FAIL = "FETCH_ONE_EXPERTISES_FAIL";

export const ADD_SKILLS_REQUEST = "ADD_SKILLS_REQUEST";
export const ADD_SKILLS_SUCCESS = "ADD_SKILLS_SUCESS";
export const ADD_SKILLS_FAIL = "ADD_SKILLS_FAIL";

export const EDIT_SKILLS_REQUEST = "EDIT_SKILLS_REQUEST";
export const EDIT_SKILLS_SUCCESS = "EDIT_SKILLS_SUCESS";
export const EDIT_SKILLS_FAIL = "EDIT_SKILLS_FAIL";

export const DELETE_SKILLS_REQUEST = "DELETE_SKILLS_REQUEST";
export const DELETE_SKILLS_SUCCESS = "DELETE_SKILLS_SUCESS";
export const DELETE_SKILLS_FAIL = "DELETE_SKILLS_FAIL";

export const CLEAR = "CLEAR";
