import axios from "axios";

//redux
import {
  FETCH_COMMUNITY_ACTION_REQUEST,
  FETCH_COMMUNITY_ACTION_SUCCESS,
  FETCH_COMMUNITY_ACTION_FAIL,
  FETCH_ONE_COMMUNITY_ACTION_REQUEST,
  FETCH_ONE_COMMUNITY_ACTION_SUCCESS,
  FETCH_ONE_COMMUNITY_ACTION_FAIL,
} from "./communityActionTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_COMMUNITY_ACTION_REQUEST,
  };
};

export const fetchSuccess = (communityActions) => {
  return {
    type: FETCH_COMMUNITY_ACTION_SUCCESS,
    payload: communityActions,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_COMMUNITY_ACTION_FAIL,
    payload: error,
  };
};

export const fetchOneRequest = () => {
  return {
    type: FETCH_ONE_COMMUNITY_ACTION_REQUEST,
  };
};

export const fetchOneSuccess = (oneCommunityAction) => {
  return {
    type: FETCH_ONE_COMMUNITY_ACTION_SUCCESS,
    payload: oneCommunityAction,
  };
};

export const fetchOneFail = (error) => {
  return {
    type: FETCH_ONE_COMMUNITY_ACTION_FAIL,
    payload: error,
  };
};

export const getCommunityActions = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/opportunities/AllCommunity";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const getCommunityActionById = (id) => async (dispatch) => {
  dispatch(fetchOneRequest());
  try {
    let url = process.env.REACT_APP_API + "/opportunities/" + id;
    const response = await axios.get(url);
    dispatch(fetchOneSuccess(response.data));
  } catch (e) {
    dispatch(fetchOneFail(e.response.data));
  }
};
