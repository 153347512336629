import React from "react";
import { useDispatch } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import { deleteTeam } from "../../redux/Team/teamAction";

function TeamDeleteModal(props) {
  const dispatch = useDispatch();

  const deleteteamHandler = () => {
    dispatch(deleteTeam(props.id));
  };

  return (
    <div>
      <div className="modal fade" id="delete-team-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Delete Team</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p className="text-centers">
                Are you sure you want to delete this team?
              </p>
              <div className="row mt-2 d-flex justify-content-center">
                <button
                  className="add-btn-del"
                  onClick={deleteteamHandler}
                  data-dismiss="modal"
               > Delete
                </button>
                <button className="close-btn-del" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamDeleteModal;
