import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

//redux
import { addAdmin, clear } from "../../redux/admin/adminActions";

//components
import Loading from "../layout/Loading";

//css
import "./Admin.css";

function AdminAddModal() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState(2);
  const { addLoading } = useSelector((state) => state.adminReducer);
  const [buttonState, setButtonState] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const resetForm = () => {
    setEmail("");
    setUsername("");
    setRole("");
    dispatch(clear());
  };

  const addAdminHandle = (e) => {
    e.preventDefault();
    dispatch(addAdmin(email, username, role));
    setEmail("");
    setUsername("");
    setRole("");
  };

  useEffect(() => {
    if (email !== "") {
      if (!emailRegEx.test(email)) {
        setButtonState(true);
        setErrorMessage("This Email is not valid !");
      } else {
        setButtonState(false);
        setErrorMessage("");
      }
    } else {
      setButtonState(true);
      setErrorMessage("");
    }
  }, [email, emailRegEx]);

  return (
    <div>
      <div className="modal fade" id="add-admin-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-headers">
              <h4 className="modal-title">Add Admin</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => resetForm()}
              >
                &times;
              </button>
            </div>
            {addLoading ? (
              <Loading />
            ) : (
              <>
                <form onSubmit={addAdminHandle}>
                  <div className="modal-body">
                    <div className="row formRow">
                      <span className="col-md-2 textForm">Email :</span>
                      <input
                        type="text"
                        className="  inputt col-md-9 form-controls"
                        value={email}
                        placeholder="Enter the admin's email"
                        required
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    {errorMessage && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "center",
                          paddingTop: "10px",
                        }}
                      >
                        {errorMessage}
                      </div>
                    )}
                    <br />

                    <div className="row formRow">
                      <span className="col-md-2 textForm">Username :</span>
                      <input
                        type="text"
                        className="  input col-md-9 form-controls"
                        value={username}
                        placeholder="Enter the admin's username"
                        required
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                      />
                    </div>
                    <br />

                    <div className="row formRow">
                      <span className="col-md-2 textForm">Role :</span>
                      <select
                        onChange={(e) => setRole(e.target.value)}
                        defaultValue={2}
                      >
                        <option value={1}>Super Admin</option>
                        <option value={2}>Admin</option>
                      </select>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      id="close"
                      type="button"
                      className=" close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <button
                      onClick={addAdminHandle}
                      type="submit"
                      disabled={buttonState ? true : false}
                      className="add-btn"
                      data-dismiss="modal"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminAddModal;
