import axios from "axios";

//utils
import setAuthToken from "../../utils/setAuthToken";

//redux
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_LOADING,
  LOGOUT,
  VERIFY,
  STATISTICS_SUCCESS ,
  STATISTICS_FAIL,
  STATISTICS_LOADING
} from "./authTypes";

export const loginError = (error) => {
  return {
    type: LOGIN_FAIL,
    payload: error,
  };
};

export const loginLoading = () => {
  return {
    type: LOGIN_LOADING,
  };
};

export const loginSuccess = (userData) => {
  return {
    type: LOGIN_SUCCESS,
    payload: userData,
  };
};


export const statisticError = (error) => {
  return {
    type: STATISTICS_FAIL,
    payload: error,
  };
};

export const statisticLoading = () => {
  return {
    type: STATISTICS_LOADING,
  };
};

export const statisticSuccess = (userData) => {
  return {
    type: STATISTICS_SUCCESS,
    payload: userData,
  };
};



export const getStatistics = () => async(dispatch) => {
  dispatch(statisticLoading());
  try {
    let url = process.env.REACT_APP_API + "/statistics";
    const response = await axios.get(url);
    dispatch(statisticSuccess(response.data.data));
  } catch (e) {
    dispatch(statisticError(e.response));
  }
};


export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const loginAdmin = (email) => (dispatch) => {
  dispatch(loginLoading());
  axios
    .post(process.env.REACT_APP_API + "/login", email)
    .then((res) => {
      if (![1, 2].includes(res.data.role))
        return dispatch(loginError("Only admin or Super admin"));
      localStorage.setItem("id", res.data.id);
      localStorage.setItem("log", "logged");
      dispatch({ type: VERIFY });
    })
    .catch((err) => dispatch(loginError(err.response.data.message)));
};

export const verifyAdmin = (userData) => (dispatch) => {
  dispatch(loginLoading());
  axios
    .post(process.env.REACT_APP_API + "/verify/" + userData.id, {
      code: parseInt(userData.OTP),
    })
    .then((res) => {
      localStorage.setItem("jwtToken", res.data.token);
      localStorage.setItem("email", res.data.user.email);
      localStorage.setItem("role", res.data.user.role);
      dispatch(loginSuccess());
    })
    .catch((err) => dispatch(loginError(err.response.data.message)));
};

export const logoutAdmin = () => (dispatch) => {
  localStorage.removeItem("id");
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("email");
  localStorage.removeItem("log");
  setAuthToken(false);
  dispatch(logout());
  window.location.href = "/";
};
