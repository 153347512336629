import axios from "axios";

//redux
import {
  FETCH_GOVERNATE_REQUEST,
  FETCH_GOVERNATE_SUCCESS,
  FETCH_GOVERNATE_FAIL,
  ADD_GOVERNATE_REQUEST,
  ADD_GOVERNATE_SUCCESS,
  ADD_GOVERNATE_FAIL,
  EDIT_GOVERNATE_REQUEST,
  EDIT_GOVERNATE_SUCCESS,
  EDIT_GOVERNATE_FAIL,
  DELETE_GOVERNATE_REQUEST,
  DELETE_GOVERNATE_SUCCESS,
  DELETE_GOVERNATE_FAIL,
  CLEAR,
} from "./governateTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_GOVERNATE_REQUEST,
  };
};

export const fetchSuccess = (governates) => {
  return {
    type: FETCH_GOVERNATE_SUCCESS,
    payload: governates,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_GOVERNATE_FAIL,
    payload: error,
  };
};

export const addGovernateRequest = () => {
  return {
    type: ADD_GOVERNATE_REQUEST,
  };
};

export const addGovernateSuccess = () => {
  return {
    type: ADD_GOVERNATE_SUCCESS,
  };
};

export const addGovernateFail = (error) => {
  return {
    type: ADD_GOVERNATE_FAIL,
    payload: error,
  };
};

export const editGovernateRequest = () => {
  return {
    type: EDIT_GOVERNATE_REQUEST,
  };
};

export const editGovernateSuccess = () => {
  return {
    type: EDIT_GOVERNATE_SUCCESS,
  };
};

export const editGovernateFail = (error) => {
  return {
    type: EDIT_GOVERNATE_FAIL,
    payload: error,
  };
};

export const deleteGovernateRequest = () => {
  return {
    type: DELETE_GOVERNATE_REQUEST,
  };
};

export const deleteGovernateSuccess = () => {
  return {
    type: DELETE_GOVERNATE_SUCCESS,
  };
};

export const deleteGovernateFail = (error) => {
  return {
    type: DELETE_GOVERNATE_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getGovernates = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/governates";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const getGovernatesByCountryId = (id) => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url =
      process.env.REACT_APP_API + "/governates/getGovernateByCountry/" + id;
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteGovernate = (id) => async (dispatch) => {
  dispatch(deleteGovernateRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/governates/" + id);
    dispatch(deleteGovernateSuccess());
    dispatch(getGovernates());
  } catch (e) {
    dispatch(deleteGovernateFail(e.response.data));
  }
};

export const addGovernate = (country_id, name, name_ar) => async (dispatch) => {
  dispatch(addGovernateRequest());
  try {
    await axios.post(process.env.REACT_APP_API + "/governates", {
      country_id,
      name,
      name_ar,
    });
    dispatch(addGovernateSuccess());
    dispatch(getGovernates());
  } catch (e) {
    dispatch(addGovernateFail(e.response.data));
  }
};

export const updateGovernate =
  (id, country_id, name, name_ar) => async (dispatch) => {
    dispatch(editGovernateRequest());
    try {
      await axios.put(process.env.REACT_APP_API + "/governates/" + id, {
        country_id,
        name,
        name_ar,
      });
      dispatch(editGovernateSuccess());
      dispatch(getGovernates());
    } catch (e) {
      dispatch(editGovernateFail(e.response.data));
    }
  };

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
