import {
    FETCH_INSTITUTIONS_REQUEST,
    FETCH_INSTITUTIONS_SUCCESS,
    FETCH_INSTITUTIONS_FAIL,
  
    ADD_INSTITUTIONS_REQUEST,
    ADD_INSTITUTIONS_SUCCESS,
    ADD_INSTITUTIONS_FAIL,
  
    EDIT_INSTITUTIONS_REQUEST,
    EDIT_INSTITUTIONS_SUCCESS,
    EDIT_INSTITUTIONS_FAIL,
  
    DELETE_INSTITUTIONS_REQUEST,
    DELETE_INSTITUTIONS_SUCCESS,
    DELETE_INSTITUTIONS_FAIL,
  
    CLEAR
  } from "./InstitutionsTypes";
  
  export const initialState = {
    loading: false,
    institutions: [],
    error: "",
    addLoading: false,
    addError: "",
    editLoading: false,
    editError: "",
    deleteLoading: false,
    deleteError: "",
  };
  
  const InstitutionsReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case FETCH_INSTITUTIONS_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_INSTITUTIONS_SUCCESS:
        return {
          loading: false,
          institutions: action.payload,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      case FETCH_INSTITUTIONS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
  
      case ADD_INSTITUTIONS_REQUEST:
        return {
          ...state,
          addLoading: true,
          addError: "",
        };
      case ADD_INSTITUTIONS_SUCCESS:
        return {
          ...state,
          addLoading: false,
          addError: ""
        };
      case ADD_INSTITUTIONS_FAIL:
        return {
          ...state,
          addLoading: false,
          addError: action.payload,
        };
  
      case EDIT_INSTITUTIONS_REQUEST:
        return {
          ...state,
          editLoading: true,
          editError: "",
        };
      case EDIT_INSTITUTIONS_SUCCESS:
        return {
          ...state,
          editLoading: false,
          editError: ""
        };
      case EDIT_INSTITUTIONS_FAIL:
        return {
          ...state,
          editLoading: false,
          editError: action.payload,
        };
  
      case DELETE_INSTITUTIONS_REQUEST:
        return {
          ...state,
          deleteLoading: true,
          deleteError: "",
        };
      case DELETE_INSTITUTIONS_SUCCESS:
        return {
          ...state,
          deleteLoading: false,
          deleteError: ""
        };
      case DELETE_INSTITUTIONS_FAIL:
        return {
          ...state,
          deleteLoading: false,
          deleteError: action.payload,
        };
  
      case CLEAR:
        return {
          ...state,
          loading: false,
          error: "",
          addLoading: false,
          addError: "",
          editLoading: false,
          editError: "",
          deleteLoading: false,
          deleteError: "",
        };
      default:
        return state;
    }
  };
  
  export default InstitutionsReducer;
  