import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import ReactDatatable from "@ashvin27/react-datatable";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";

//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import QuizAddModal from "./QuizAddModal";
import QuizEditModel from "./QuizEditModal";
import QuizDeleteModal from "./QuizDeleteModal";

//redux
import { getQuiz } from "../../redux/quiz/quizAction";
import { getQuizAnswer } from "../../redux/quizAnswer/quizAnswerAction";
//utils
import isAuth from "../../utils/isAuth";
import QuizAddAnswerModal from "./QuizAddAnswerModal";

import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
function Quiz() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, addError, editError, deleteError } = useSelector(
    (state) => state.quizReducer
  );
  const { quizzesAnswers } = useSelector((state) => state.quizAnswerReducer);
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);

  const [answer_ar, setAnswer_ar] = useState("");
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  function exportToExcel() {
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter(
        (entry, index) => index !== 0 && index !== 2
      );
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Quizzes Answers.xlsx");
  }

  useEffect(() => {
    dispatch(getQuiz());
    dispatch(getQuizAnswer());
  }, [dispatch]);

  useEffect(() => {
    if (quizzesAnswers && quizzesAnswers.data) {
      const mappedRecords = quizzesAnswers.data.map((quizzesAnswers) => {
        return {
          key: quizzesAnswers.id,
          id: quizzesAnswers.id,
          quize_id: quizzesAnswers.quize.id,
          question: quizzesAnswers.quize.question,
          arabic_Question: quizzesAnswers.quize.question_ar,
          quiz_Type: quizzesAnswers.quize.type,
          answer: quizzesAnswers.answer,
          arabic_Answer: quizzesAnswers.answer_ar,
        };
      });
      setRecords(mappedRecords);
    }
  }, [quizzesAnswers]);

  const columns = [
    {
      field: "question",
      headerName: "Questions",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "arabic_Question",
      headerName: "Arabic Questions",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "quiz_Type",
      headerName: "Question Type",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "answer",
      headerName: "Answers",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "arabic_Answer",
      headerName: "Arabic Answers",
      flex: 1,
      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 151,
      className: "centerText4 action",
      align: "left",
      sortable: false,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-quizAnswer-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setAnswer(record.row.answer);
                setAnswer_ar(record.row.arabic_Answer);
              }}
            >
              Answers &nbsp;
              <i className="fa fa-edit"></i>
            </button>
            <button
              data-toggle="modal"
              data-target="#delete-quiz-modal"
              className="actions"
              onClick={() => {
                setId(record.row.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const handleReloadClick = () => {
    window.location.reload();
  };
  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <QuizAddAnswerModal />
        <QuizAddModal />
        <QuizEditModel
          id={id}
          answer={answer}
          answer_ar={answer_ar}
          setAnswer={setAnswer}
          setAnswer_ar={setAnswer_ar}
        />
        <QuizDeleteModal id={id} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div className="add-cont">
              <Link to="/quizzesedit">
                <button className="adds">
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                  Edit/Delete Question
                </button>
              </Link>
              <button
                className="adds"
                data-toggle="modal"
                data-target="#add-quizAnswer-modal"
              >
                <FontAwesomeIcon icon={faPlus} /> Add Answer
              </button>
              <button
                className="adds"
                data-toggle="modal"
                data-target="#add-quiz-modal"
              >
                <FontAwesomeIcon icon={faPlus} /> Add Question
              </button>
            </div>

            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
              {addError && (
                <div style={{ color: "red" }}> {addError.message}</div>
              )}
              {editError && (
                <div style={{ color: "red" }}> {editError.message}</div>
              )}
              {deleteError && (
                <div style={{ color: "red" }}> {deleteError.message}</div>
              )}
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header title="QUIZ" subtitle="List of Quizes" />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quiz;
