import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ReactDatatable from "@ashvin27/react-datatable";
import { updateOrganization } from "../../redux/organization/organizationActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import * as XLSX from "xlsx";
import { Box } from "@mui/material";
import Header from "./../Header/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme";
//components
import Sidebar from "../layout/Sidebar";
import Loading from "../layout/Loading";
import OrganizationViewModal from "./OrganizationViewModal.js";

//redux
import { getOrganizations } from "../../redux/organization/organizationActions.js";
import { getSector } from "../../redux/sector/sectorActions";

//utils
import isAuth from "../../utils/isAuth";
import "./Organization.css";
import Select from "react-select";

function Organization() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { sector } = useSelector((state) => state.sectorReducer);
  const [sectorVal, setSectorVal] = useState(0);
  const [contactVal, setContactVal] = useState("");
  const [sectors, setSectors] = useState({});

  function exportToExcel() {
    const recordsWithoutFirstKey = [];

    records.forEach((record) => {
      const entries = Object.entries(record);
      const filteredEntries = entries.filter(
        (entry, index) => index !== 0 && index !== 3 && index !== 5
      );
      const recordWithoutFirstKey = Object.fromEntries(filteredEntries);
      recordsWithoutFirstKey.push(recordWithoutFirstKey);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(recordsWithoutFirstKey);

    const columnProperties = [];

    // Set column width and horizontal alignment for each column
    for (let i = 0; i < Object.keys(recordsWithoutFirstKey[0]).length; i++) {
      columnProperties[i] = { width: 20, style: { halign: "center" } };
    }
    ws["!cols"] = columnProperties;

    // Capitalize column titles
    const columnTitles = Object.keys(recordsWithoutFirstKey[0]);
    const capitalizedTitles = columnTitles.map((title) => {
      return title.charAt(0).toUpperCase() + title.slice(1);
    });

    // Set capitalized titles as first row and center-align them
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; C++) {
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].v = capitalizedTitles[C];
      ws[XLSX.utils.encode_cell({ c: C, r: 0 })].s = {
        alignment: { horizontal: "center" },
        font: { bold: true },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Organizations.xlsx");
  }

  useEffect(() => {
    if (sector?.data) {
      const sectors_array = sector.data.map((d) => ({
        value: d.id,
        label: d.name,
      }));
      setSectors(sectors_array);
    }
  }, [sector]);

  useEffect(() => {
    if (sectorVal != 0 || contactVal !== "") {
      console.log(contactVal.value);
      dispatch(getOrganizations(sectorVal, contactVal));
    }
  }, [sectorVal, contactVal]);

  const updateOrganizationhandle = (id) => {
    dispatch(updateOrganization(id));
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, organizations, error } = useSelector(
    (state) => state.organizationReducer
  );
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getOrganizations());
    dispatch(getSector());
  }, [dispatch]);

  useEffect(() => {
    if (organizations && organizations.Organizations) {
      const mappedRecords = organizations.Organizations.map((organization) => {
        const sectorNames = organization.organization_sectors.map(
          (sector) => sector.sector.name
        );

        return {
          key: organization.id,
          id: organization.id,
          logoName: organization.logo,
          logo:
            process.env.REACT_APP_API + "/Organization/" + organization.logo,
          imageName: organization.img_url,
          img_url:
            process.env.REACT_APP_API + "/Organization/" + organization.img_url,
          name: organization.name,
          type: organization.type,
          approved: organization.approved ? "Yes" : "No",
          masterclass: organization.masterclass ? "Yes" : "No",
          registration_Number: organization.registration_number,
          about: organization.about,
          email_Address: organization.email_address,
          instagram: organization.instagram,
          linkedIn: organization.linked_in,
          facebook: organization.facebook,
          website: organization.website,
          phone: organization.phone_number,
          occupation: organization.occupation,
          verified: organization.verified ? "Verified" : "Not Verified",
          country: organization.country ? organization.country.name : "N/A",
          district: organization.district ? organization.district.name : "N/A",
          sectors: sectorNames.join(", "),
        };
      });
      setRecords(mappedRecords);
    }
  }, [organizations]);

  const columns = [
    {
      field: "logo",
      headerName: "Logo",
      height: "20rem",
      width: 200,

      renderCell: (params) => (
        <img
          src={params.row.logo}
          alt={`Picture ${params.row.id}`}
          style={{ width: "7rem", height: "4rem" }}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,

      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,

      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "registration_Number",
      headerName: "registration_Number",
      width: 200,

      className: "centerText",
      align: "left",
      sortable: true,
    },

    {
      field: "approved",
      headerName: "Approved",
      width: 100,

      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "country",
      headerName: "Country",
      width: 100,

      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "district",
      headerName: "district",
      width: 100,

      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "about",
      headerName: "about",
      width: 200,

      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "occupation",
      headerName: "occupation",
      width: 100,

      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "sectors",
      headerName: "sectors",
      width: 300,

      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "email_Address",
      headerName: "email",
      width: 200,

      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "phone",
      headerName: "phone",
      width: 200,

      className: "centerText",
      align: "left",
      sortable: true,
    },

    {
      field: "instagram",
      headerName: "instagram",
      width: 200,

      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "linkedIn",
      headerName: "linkedIn",
      width: 200,

      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "facebook",
      headerName: "facebook",
      width: 200,

      className: "centerText",
      align: "left",
      sortable: true,
    },
    {
      field: "website",
      headerName: "website",
      width: 200,

      className: "centerText",
      align: "left",
      sortable: true,
    },

    {
      field: "verified",
      headerName: "Verified",
      width: 200,
      className: "centerText action",
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              onClick={() => updateOrganizationhandle(record.id)}
              className={
                record.row.verified === "Verified" ? "greenButton" : "redButton"
              }
            >
              {record.row.verified}
            </button>
          </Fragment>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 100,
      className: "action centerText",
      align: "left",
      sortable: false,
      renderCell: (record) => {
        return (
          <Fragment>
            {/* <button
              data-toggle="modal"
              data-target="#update-admin-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
              }}
            >
              <i className="fa fa-edit"></i>
            </button> */}
            <button
              data-toggle="modal"
              data-target="#view-organization-modal"
              className="actions"
              style={{ marginRight: "5px", width: "30px" }}
              onClick={() => {
                setId(record.row.id);
              }}
            >
              <i className="fa fa-info"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  const handleReloadClick = () => {
    window.location.reload();
  };
  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <OrganizationViewModal id={id} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
            </div>
            {/* 
            <Select
              value={sectorVal}
              className="complete_profile_select"
              name="sector"
              onChange={setSectorVal}
              placeholder="Sector"
              required
              options={sectors}
              isSearchable={true}
            />

            <input
              onChange={setContactVal}
              name="Contact"
              placeholder="Contact"
              type="text"
              required
            /> */}
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header
                    title="Organization"
                    subtitle="List of Organizations"
                  />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Organization;
