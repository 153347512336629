export const FETCH_DISABILITY_REQUEST = "FETCH_DISABILITY_REQUEST";
export const FETCH_DISABILITY_SUCCESS = "FETCH_DISABILITY_SUCCESS";
export const FETCH_DISABILITY_FAIL = "FETCH_DISABILITY_FAIL";

export const ADD_DISABILITY_REQUEST = "ADD_DISABILITY_REQUEST";
export const ADD_DISABILITY_SUCCESS = "ADD_DISABILITY_SUCESS";
export const ADD_DISABILITY_FAIL = "ADD_DISABILITY_FAIL";

export const EDIT_DISABILITY_REQUEST = "EDIT_DISABILITY_REQUEST";
export const EDIT_DISABILITY_SUCCESS = "EDIT_DISABILITY_SUCESS";
export const EDIT_DISABILITY_FAIL = "EDIT_DISABILITY_FAIL";

export const DELETE_DISABILITY_REQUEST = "DELETE_DISABILITY_REQUEST";
export const DELETE_DISABILITY_SUCCESS = "DELETE_DISABILITY_SUCESS";
export const DELETE_DISABILITY_FAIL = "DELETE_DISABILITY_FAIL";

export const CLEAR = "CLEAR";