import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import {
  clear,
  updateVolunteerSpotlightsQA,
} from "../../redux/volunteerSpotlightsQA/volunteerSpotlightsQAActions";
import { getVolunteerSpotlights } from "../../redux/volunteerSpotlights/volunteerSpotlightsActions";

//component
import Loading from "../layout/Loading";
import "./VolunteerSpotlightsQA.css";
//css
import "./VolunteerSpotlightsQA.css";

function EditVolunteerSpotlightsQAModel({
  id,
  question,
  answer,
  volunteerSpotlightsId,
  setQuestion,
  setAnswer,
  setVolunteerSpotlightsId,
}) {
  const dispatch = useDispatch();
  const { editLoading } = useSelector(
    (state) => state.volunteerSpotlightsQAReducer
  );
  const { volunteerSpotlights } = useSelector(
    (state) => state.volunteerSpotlightsReducer
  );
  const [buttonState, setButtonState] = useState(true);
  useEffect(() => {
    if (question !== "" && answer !== "" && volunteerSpotlightsId !== "" ) setButtonState(false);
    else setButtonState(true);
  }, [question, answer,volunteerSpotlightsId]);
  useEffect(() => {
    dispatch(getVolunteerSpotlights());
  }, [dispatch]);

  const resetForm = () => {
    setQuestion("");
    setAnswer("");
    setVolunteerSpotlightsId("");
    dispatch(clear());
  };

  const editVolunteerSpotlightsQAHandle = (e) => {
    e.preventDefault();
    dispatch(
      updateVolunteerSpotlightsQA(id, volunteerSpotlightsId, question, answer)
    );
    setQuestion("");
    setAnswer("");
    setVolunteerSpotlightsId("");
  };

  return (
    <div className="modal fade" id="update-volunteerSpotlightsQA-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Edit Volunteer spotlight QA</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={resetForm}
            >
              &times;
            </button>
          </div>
          {editLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form onSubmit={editVolunteerSpotlightsQAHandle}>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">
                      Volunteer spotlight :
                    </span>
                    <select
                      onChange={(e) => setVolunteerSpotlightsId(e.target.value)}
                      required
                      value={volunteerSpotlightsId}
                    >
                      {volunteerSpotlights.data?.map((VolunteerSpotlight) => (
                        <option
                          key={VolunteerSpotlight.id}
                          value={VolunteerSpotlight.id}
                        >
                          {VolunteerSpotlight.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <br />

                  <div className="row formRow">
                    <span className="col-md-3 textForm">Question:</span>
                    <input
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                      placeholder="Enter the question"
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Answer :</span>
                    <input
                      value={answer}
                      onChange={(e) => setAnswer(e.target.value)}
                      placeholder="Enter the answer"
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="modal-footer">
                    <button
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={resetForm}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      onClick={editVolunteerSpotlightsQAHandle}
                      disabled={buttonState ? true : false}
                    >
                      Edit
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditVolunteerSpotlightsQAModel;
