import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

//redux
import {
  clear,
  updateMasterClassTestimonial,
} from "../../redux/MasterClassTestimonial/MasterClassTestimonialAction";

//component
import Loading from "../layout/Loading";

//css
import "./MasterClassTestimonial.css";

function MasterClassTestimonialEditModal({
  id,
  name,
  name_ar,
  text,
  text_ar,
  setName,
  setName_ar,
  setText,
  setText_ar,
}) {
  const dispatch = useDispatch();
  const { editLoading } = useSelector(
    (state) => state.MasterClassTestimonialReducer
  );
  const [buttonState, setButtonState] = useState(true);

  useEffect(() => {
    if (name !== "" && name_ar !== "" && text !== "" && text_ar !== "")
      setButtonState(false);
    else setButtonState(true);
  }, [name, name_ar, text, text_ar]);

  const resetForm = () => {
    setName("");
    setName_ar("");
    setText("");
    setText_ar("");
    dispatch(clear());
  };

  const editMasterClassTestimonialHandle = (e) => {
    e.preventDefault();
    dispatch(updateMasterClassTestimonial(id, name, name_ar, text, text_ar));
    setName("");
    setName_ar("");
    setText("");
    setText_ar("");
  };

  return (
    <div className="modal fade" id="update-masterclasstestimonial-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Edit Master Class Testimonial</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={resetForm}
            >
              &times;
            </button>
          </div>
          {editLoading ? (
            <Loading />
          ) : (
            <>
              <div className="modal-body">
                <form onSubmit={editMasterClassTestimonialHandle}>
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Name :</span>
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter the name"
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Arabic Name :</span>
                    <input
                      value={name_ar}
                      onChange={(e) => setName_ar(e.target.value)}
                      placeholder="Enter the arabic name"
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Text :</span>
                    <input
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      placeholder="Enter the text"
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="row formRow">
                    <span className="col-md-3 textForm">Arabic Text :</span>
                    <input
                      value={text_ar}
                      onChange={(e) => setText_ar(e.target.value)}
                      placeholder="Enter the arabic text"
                      className="  inputt col-md-8 form-controls"
                    />
                  </div>
                  <br />
                  <div className="modal-footer">
                    <button
                      type="button"
                       className="close-btn"
                      data-dismiss="modal"
                      onClick={resetForm}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="add-btn"
                      data-dismiss="modal"
                      onClick={editMasterClassTestimonialHandle}
                      disabled={buttonState ? true : false}
                    >
                      Edit
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MasterClassTestimonialEditModal;
