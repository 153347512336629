export const FETCH_BLOG_REQUEST = "FETCH_BLOG_REQUEST";
export const FETCH_BLOG_SUCCESS = "FETCH_BLOG_SUCCESS";
export const FETCH_BLOG_FAIL = "FETCH_BLOG_FAIL";

export const ADD_BLOG_REQUEST = "ADD_BLOG_REQUEST";
export const ADD_BLOG_SUCCESS = "ADD_BLOG_SUCESS";
export const ADD_BLOG_FAIL = "ADD_BLOG_FAIL";

export const EDIT_BLOG_REQUEST = "EDIT_BLOG_REQUEST";
export const EDIT_BLOG_SUCCESS = "EDIT_BLOG_SUCESS";
export const EDIT_BLOG_FAIL = "EDIT_BLOG_FAIL";

export const DELETE_BLOG_REQUEST = "DELETE_BLOG_REQUEST";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCESS";
export const DELETE_BLOG_FAIL = "DELETE_BLOG_FAIL";

export const CLEAR = "CLEAR";
