export const FETCH_MASTERCLASS_TESTIMONIAL_REQUEST =
  "FETCH_MASTERCLASS_TESTIMONIAL_REQUEST";
export const FETCH_MASTERCLASS_TESTIMONIAL_SUCCESS =
  "FETCH_MASTERCLASS_TESTIMONIAL_SUCCESS";
export const FETCH_MASTERCLASS_TESTIMONIAL_FAIL =
  "FETCH_MASTERCLASS_TESTIMONIAL_FAIL";

export const ADD_MASTERCLASS_TESTIMONIAL_REQUEST =
  "ADD_MASTERCLASS_TESTIMONIAL_REQUEST";
export const ADD_MASTERCLASS_TESTIMONIAL_SUCCESS =
  "ADD_MASTERCLASS_TESTIMONIAL_SUCCESS";
export const ADD_MASTERCLASS_TESTIMONIAL_FAIL =
  "ADD_MASTERCLASS_TESTIMONIAL_FAIL";

export const EDIT_MASTERCLASS_TESTIMONIAL_REQUEST =
  "EDIT_MASTERCLASS_TESTIMONIAL_REQUEST";
export const EDIT_MASTERCLASS_TESTIMONIAL_SUCCESS =
  "EDIT_MASTERCLASS_TESTIMONIAL_SUCESS";
export const EDIT_MASTERCLASS_TESTIMONIAL_FAIL =
  "EDIT_MASTERCLASS_TESTIMONIAL_FAIL";

export const DELETE_MASTERCLASS_TESTIMONIAL_REQUEST =
  "DELETE_MASTERCLASS_TESTIMONIAL_REQUEST";
export const DELETE_MASTERCLASS_TESTIMONIAL_SUCCESS =
  "DELETE_MASTERCLASS_TESTIMONIAL_SUCESS";
export const DELETE_MASTERCLASS_TESTIMONIAL_FAIL =
  "DELETE_MASTERCLASS_TESTIMONIAL_FAIL";

export const CLEAR = "CLEAR";
