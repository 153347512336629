import axios from "axios";

//redux
import {
  FETCH_CAROUSEL_REQUEST,
  FETCH_CAROUSEL_SUCCESS,
  FETCH_CAROUSEL_FAIL,
  ADD_CAROUSEL_REQUEST,
  ADD_CAROUSEL_SUCCESS,
  ADD_CAROUSEL_FAIL,
  EDIT_CAROUSEL_REQUEST,
  EDIT_CAROUSEL_SUCCESS,
  EDIT_CAROUSEL_FAIL,
  DELETE_CAROUSEL_REQUEST,
  DELETE_CAROUSEL_SUCCESS,
  DELETE_CAROUSEL_FAIL,
  CLEAR,
} from "./carouselTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_CAROUSEL_REQUEST,
  };
};

export const fetchSuccess = (carousels) => {
  return {
    type: FETCH_CAROUSEL_SUCCESS,
    payload: carousels,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_CAROUSEL_FAIL,
    payload: error,
  };
};

export const addCarouselRequest = () => {
  return {
    type: ADD_CAROUSEL_REQUEST,
  };
};

export const addCarouselSuccess = () => {
  return {
    type: ADD_CAROUSEL_SUCCESS,
  };
};

export const addCarouselFail = (error) => {
  return {
    type: ADD_CAROUSEL_FAIL,
    payload: error,
  };
};

export const editCarouselRequest = () => {
  return {
    type: EDIT_CAROUSEL_REQUEST,
  };
};

export const editCarouselSuccess = () => {
  return {
    type: EDIT_CAROUSEL_SUCCESS,
  };
};

export const editCarouselFail = (error) => {
  return {
    type: EDIT_CAROUSEL_FAIL,
    payload: error,
  };
};

export const deleteCarouselRequest = () => {
  return {
    type: DELETE_CAROUSEL_REQUEST,
  };
};

export const deleteCarouselSuccess = () => {
  return {
    type: DELETE_CAROUSEL_SUCCESS,
  };
};

export const deleteCarouselFail = (error) => {
  return {
    type: DELETE_CAROUSEL_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getCarousels = () => async (dispatch) => {
  
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/carousel/getAll";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
   
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const deleteCarousel = (id) => async (dispatch) => {
  dispatch(deleteCarouselRequest());
  try {
    await axios.delete(process.env.REACT_APP_API + "/carousel/" + id);
    dispatch(deleteCarouselSuccess());
    dispatch(getCarousels());
  } catch (e) {
    dispatch(deleteCarouselFail(e.response.data));
  }
};

export const addCarousel = (image, link, title) => async (dispatch) => {
  dispatch(addCarouselRequest());
  var formData = new FormData();
  if (image) formData.append("image", image);
  formData.append("link", link);
  formData.append("title", title);
  try {
    await axios.post(process.env.REACT_APP_API + "/carousel", formData);
    dispatch(addCarouselSuccess());

    dispatch(getCarousels());
  } catch (e) {
    dispatch(addCarouselFail(e.response.data));
  }
};

export const updateCarousel = (id, image, link, title) => async (dispatch) => {
  dispatch(editCarouselRequest());
  var formData = new FormData();
  if (image) formData.append("image", image);
  formData.append("link", link);
  formData.append("title", title);
  try {
    await axios.put(process.env.REACT_APP_API + "/carousel/" + id, formData);
    dispatch(editCarouselSuccess());
    dispatch(getCarousels());
  } catch (e) {
    dispatch(editCarouselFail(e.response.data));
  }
};

export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
