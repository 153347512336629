export const FETCH_MASTERCLASS_REQUEST = "FETCH_MASTERCLASS_REQUEST";
export const FETCH_MASTERCLASS_SUCCESS = "FETCH_MASTERCLASS_SUCCESS";
export const FETCH_MASTERCLASS_FAIL = "FETCH_MASTERCLASS_FAIL";

export const EDIT_MASTERCLASS_REQUEST = "EDIT_MASTERCLASS_REQUEST";
export const EDIT_MASTERCLASS_SUCCESS = "EDIT_MASTERCLASS_SUCESS";
export const EDIT_MASTERCLASS_FAIL = "EDIT_MASTERCLASS_FAIL";

export const DELETE_MASTERCLASS_REQUEST = "DELETE_MASTERCLASS_REQUEST";
export const DELETE_MASTERCLASS_SUCCESS = "DELETE_MASTERCLASS_SUCESS";
export const DELETE_MASTERCLASS_FAIL = "DELETE_MASTERCLASS_FAIL";


export const CLEAR = "CLEAR";