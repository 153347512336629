import {
  FETCH_QUIZ_ANSWER_REQUEST,
  FETCH_QUIZ_ANSWER_SUCCESS,
  FETCH_QUIZ_ANSWER_FAIL,
  ADD_QUIZ_ANSWER_REQUEST,
  ADD_QUIZ_ANSWER_SUCCESS,
  ADD_QUIZ_ANSWER_FAIL,
  EDIT_QUIZ_ANSWER_REQUEST,
  EDIT_QUIZ_ANSWER_SUCCESS,
  EDIT_QUIZ_ANSWER_FAIL,
  DELETE_QUIZ_ANSWER_REQUEST,
  DELETE_QUIZ_ANSWER_SUCCESS,
  DELETE_QUIZ_ANSWER_FAIL,
  CLEAR,
} from "./quizAnswerTypes";

export const initialState = {
  loading: false,
  quizzesAnswers: [],
  error: "",
  addLoading: false,
  addError: "",
  editLoading: false,
  editError: "",
  deleteLoading: false,
  deleteError: "",
};

const quizAnswerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUIZ_ANSWER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_QUIZ_ANSWER_SUCCESS:
      return {
        loading: false,
        quizzesAnswers: action.payload,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    case FETCH_QUIZ_ANSWER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };

    case ADD_QUIZ_ANSWER_REQUEST:
      return {
        ...state,
        addLoading: true,
        addError: "",
      };
    case ADD_QUIZ_ANSWER_SUCCESS:
      return {
        ...state,
        addLoading: false,
        addError: "",
      };
    case ADD_QUIZ_ANSWER_FAIL:
      return {
        ...state,
        addLoading: false,
        addError: action.payload,
      };

    case EDIT_QUIZ_ANSWER_REQUEST:
      return {
        ...state,
        editLoading: true,
        editError: "",
      };
    case EDIT_QUIZ_ANSWER_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editError: "",
      };
    case EDIT_QUIZ_ANSWER_FAIL:
      return {
        ...state,
        editLoading: false,
        editError: action.payload,
      };

    case DELETE_QUIZ_ANSWER_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: "",
      };
    case DELETE_QUIZ_ANSWER_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: "",
      };
    case DELETE_QUIZ_ANSWER_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.payload,
      };

    case CLEAR:
      return {
        ...state,
        loading: false,
        error: "",
        addLoading: false,
        addError: "",
        editLoading: false,
        editError: "",
        deleteLoading: false,
        deleteError: "",
      };
    default:
      return state;
  }
};

export default quizAnswerReducer;
