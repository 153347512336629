export const FETCH_Contacts_REQUEST = "FETCH_Contacts_REQUEST";
export const FETCH_Contacts_SUCCESS = "FETCH_Contacts_SUCCESS";
export const FETCH_Contacts_FAIL = "FETCH_Contacts_FAIL";

export const ADD_Contacts_REQUEST = "ADD_Contacts_REQUEST";
export const ADD_Contacts_SUCCESS = "ADD_Contacts_SUCESS";
export const ADD_Contacts_FAIL = "ADD_Contacts_FAIL";

export const EDIT_Contacts_REQUEST = "EDIT_Contacts_REQUEST";
export const EDIT_Contacts_SUCCESS = "EDIT_Contacts_SUCESS";
export const EDIT_Contacts_FAIL = "EDIT_Contacts_FAIL";

export const DELETE_Contacts_REQUEST = "DELETE_Contacts_REQUEST";
export const DELETE_Contacts_SUCCESS = "DELETE_Contacts_SUCESS";
export const DELETE_Contacts_FAIL = "DELETE_Contacts_FAIL";

export const CLEAR = "CLEAR";