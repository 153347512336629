import axios from "axios";

//redux
import {
    FETCH_STAT_REQUEST,
    FETCH_STAT_SUCCESS,
    FETCH_STAT_FAIL,
  
 
    CLEAR
} from "./StatTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_STAT_REQUEST,
  };
};

export const fetchSuccess = (Reasons) => {
  return {
    type: FETCH_STAT_SUCCESS,
    payload: Reasons,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_STAT_FAIL,
    payload: error,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR,
  };
};

export const getStat = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/statistics";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};


export const clear = () => (dispatch) => {
  dispatch(clearSuccess());
};
